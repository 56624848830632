(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('AssetTypeDialogController', AssetTypeDialogController);

    AssetTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams',  'entity', 'AssetType', 'User','AllCustomAssetTypeAttribute', '$state', '$q'];

    function AssetTypeDialogController ($timeout, $scope, $stateParams,  entity, AssetType, User,AllCustomAssetTypeAttribute, $state, $q) {
        var vm = this;
        $scope.IMAGE_EXT_REGEXP =/^(.*?)\.(jpg||jpeg||png)$/;

        vm.assetType = entity;
        $scope.attributetypes = ['INTEGER', 'FLOAT', 'STRING','BOOLEAN'];
        var userAdminAuthorities = ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER',
            'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_VENDOR'
        ];
        vm.clear = clear;
        vm.colorcode = colorcode;
        vm.assetImageName = assetImageName;
        vm.decodeImage = decodeImage;
        vm.authorities = [];
        vm.customCaseTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        var zoomlevelLabel,zoomlevelObj,zoomlevelObjfixed;
        
		$scope.allimages = false;
		 $scope.toggle = function() {
			$scope.allimages = !$scope.allimages;
		};

        zoomlevelObj = document.getElementById("zoomlevel");
        setSelectedValue(zoomlevelObj, vm.assetType.zoomLevel);

        zoomlevelObjfixed = document.getElementById("zoomlevelFixed");
        setSelectedValue(zoomlevelObjfixed, vm.assetType.zoomLevel);

        /* zoomlevelLabel = document.getElementById("zoomlevelLabel");
        setSelectedValue(zoomlevelLabel, vm.assetType.zoomLevelLabel); */
       
		vm.save = save;
		 $(":file").filestyle({buttonBefore: true});      
	     $(":file").filestyle('buttonText', 'Browse File');
       
         vm.addNonReferenceAttribute = function(){
            vm.items=[{ name: '',attributeDataType: {},defaultValue :''}];
            var tempItems = [];
		    vm.items.forEach(function(value){
			if(value.assetAttributeTypes == null){
				value.assetAttributeTypes = "NON_REFERENCE";
			}
			tempItems.push(value);
		});
         }
	     //vm.items=[{name: '',dataType: '',defaultValue :''}];
	     vm.referenceAttribute = []
	     vm.addNewReferenceAttribute = function(){
            if(!vm.assetType.id){
                vm.referenceAttribute = [
                    { name: "", attributeDataType: {}, defaultValue: "" },
                    {
                        name: "",
                        attributeDataType: {},
                        defaultValue: "",
                    },{
                        name: "",
                        attributeDataType: {},
                        defaultValue: "",
                    }
                ];
            }
            
            if(vm.assetType.id &&  vm.assetType.assetTypeReferenceAttributes.length == 0 ){ 
                vm.referenceAttribute = [
                    { name: "", attributeDataType: {}, defaultValue: "" },
                    {
                        name: "",
                        attributeDataType: {},
                        defaultValue: "",
                    },{
                        name: "",
                        attributeDataType: {},
                        defaultValue: "",
                    }
                ];
            }

            var tempItems = [];
            vm.referenceAttribute.forEach(function (value) {
                if (value.assetAttributeTypes == null) {
                    value.assetAttributeTypes = "THREE_PNT_REFERENCE";
                }
                tempItems.push(value);
            });
         }
		

	     if(vm.assetType.assetTypeAttributes!=null && vm.assetType.assetTypeAttributes.length!=0){
	        	vm.items=vm.assetType.assetTypeAttributes;
	        	
	        }

           

            if (
                vm.assetType.assetTypeReferenceAttributes != null &&
                vm.assetType.assetTypeReferenceAttributes.length != 0
            ) {
                vm.assetType.assetTypeReferenceAttributes.forEach(function (val){
                    vm.referenceAttribute.push(val['assetAttributeName'])
                    vm.referenceAttribute.push(val['assetAttributeValue'])
                    if(val['assetAttributeImage'] != null){
                        val['assetAttributeImage'].assetTypeRfrncAttribOrder = val.assetTypeRfrncAttribOrder
                    }
                    vm.referenceAttribute.push(val['assetAttributeImage'])
                })
            }
	     
		function loadPage() {
            vm.multiAuthorities = [];
            var selectedAuthorities = [];
			AllCustomAssetTypeAttribute.getAll(function(response) {
				vm.customAssetTypeAttributes = response;
				response.forEach(function(arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customAssetTypeAttributeValues;
				});
                if (vm.assetType.lineWidth != null) {
					var lineWidthObj = document.getElementById("lineWidth");
					setSelectedValue(lineWidthObj, vm.assetType.lineWidth);
				}
				if (vm.assetType.transparency != null) {
					var transparencyObj = document.getElementById("transparency");
					setSelectedValue(transparencyObj, vm.assetType.transparency);
				}
			});
            if(vm.assetType.id != null){
                vm.assetType.authorities.forEach(function (authority) {
                    selectedAuthorities.push(authority);
                });
            }
            
            userAdminAuthorities.forEach(function(authority){
                if (selectedAuthorities.indexOf(authority) !== -1) {
                    vm.multiAuthorities.push({ name: authority, ticked: true });
                } else {
                    vm.multiAuthorities.push({ name: authority, ticked: false });
                }
            });
            
		}
        function setSelectedValue(selectObj, valueToSet) {
            for (var i = 0; i < selectObj.options.length; i++) {
                if (selectObj.options[i].text== valueToSet) {
                    selectObj.options[i].selected = true;
                    return;
                }
            }
        }

        if(vm.assetType.assetTypeAttributes!=null && vm.assetType.assetTypeAttributes.length!=0){
        	vm.items=vm.assetType.assetTypeAttributes;
        }
        
        function colorcode(color) {
        	vm.assetType.colorcode = color;
            
        }
        function assetImageName(image) {
        	vm.assetType.image = image;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        function save () {
            vm.isSaving = true;
            vm.assetType.authorities = [];
            vm.authorities.forEach(function(auth){
                vm.assetType.authorities.push(auth.name);
            });
            if(document.getElementById("lineWidth") != null && document.getElementById("transparency") != null){
                vm.assetType.lineWidth = parseInt(document.getElementById("lineWidth").value);
                vm.assetType.transparency = parseInt(document.getElementById("transparency").value);
            }

            if(vm.assetType.layout == 'SPREAD'){
                vm.assetType.zoomLevel = parseInt(document.getElementById("zoomlevel").value);
            }

            if(vm.assetType.layout == 'FIXED'){
                vm.assetType.zoomLevel = parseInt(document.getElementById("zoomlevelFixed").value);
                //vm.assetType.zoomLevelLabel = parseInt(document.getElementById("zoomlevelLabel").value);
            }
            
            if(vm.assetType.isSeniorElement){
                vm.assetType.zoomLevel = null;
            }
            if (vm.assetType.id !== null) {
            	vm.assetType.assetTypeAttributes=[];

                if(vm.items != null && vm.items.length > 0){ 
                    for(var i = 0; i < vm.items.length; i++){
                        var attributes ={"name" :  vm.items[i]};
                        //vm.assetType.assetTypeAttributes.push({"name" :  vm.items[i].name});
                         vm.assetType.assetTypeAttributes.push({"assetAttributeTypes" : vm.items[i].assetAttributeTypes,"id" : vm.items[i].id , "name" :  vm.items[i].name,  "attributeDataType" :  vm.items[i].attributeDataType,"defaultValue" :  vm.items[i].defaultValue, 
                         assetTypeAttribOrder:vm.items[i].assetTypeAttribOrder, 
                         assetTypePopupDisplay:vm.items[i].assetTypePopupDisplay
                        //  assetTypeListViewDisplay:vm.items[i].assetTypeListViewDisplay
                        });
                    }
                }
            	

                if(vm.assetType.layout == "FIXED" && vm.referenceAttribute != null && vm.referenceAttribute.length > 0){
                    vm.assetType.assetTypeReferenceAttributes=[];
                    callAssetTypeReference()
                }
                AssetType.update(vm.assetType, onSaveSuccess, onSaveError);
            } else {
                vm.assetType.assetTypeAttributes=[];

                if(vm.items != null && vm.items.length > 0){
                    for(var i = 0; i < vm.items.length; i++){
                        var attributes ={"name" :  vm.items[i]};
                       // vm.assetType.assetTypeAttributes.push({"name" :  vm.items[i].name});
                         vm.assetType.assetTypeAttributes.push({"assetAttributeTypes" : vm.items[i].assetAttributeTypes,"id" : vm.items[i].id , "name" :  vm.items[i].name,   "attributeDataType" :  vm.items[i].attributeDataType,"defaultValue" :  vm.items[i].defaultValue,
                         assetTypeAttribOrder:vm.items[i].assetTypeAttribOrder,
                         assetTypePopupDisplay:vm.items[i].assetTypePopupDisplay
                        //  assetTypeListViewDisplay:vm.items[i].assetTypeListViewDisplay
                        });
                    }
                }
            	
                if(vm.assetType.layout == "FIXED" && vm.referenceAttribute != null && vm.referenceAttribute.length > 0){
                    /* for(var i = 0; i < vm.referenceAttribute.length; i++){
                        var attributes ={"name" :  vm.referenceAttribute[i]};
                        //vm.assetType.assetTypeAttributes.push({"name" :  vm.referenceAttribute[i].name});
                         vm.assetType.assetTypeReferenceAttributes.push({
                            "assetTypeRfrncAttribOrder":  vm.referenceAttribute[i].assetTypeAttribOrder,
                            "assetAttributeName" : {
                                "assetAttributeTypes": vm.referenceAttribute[i].assetAttributeTypes,
                                "name": vm.referenceAttribute[i].name,
                                "attributeDataType": vm.referenceAttribute[i].attributeDataType,
                                "defaultValue" : vm.referenceAttribute[i].arridefaultValue,
                                "assetTypeAttribOrder": null,
                            }

                        });
                    } */
                    vm.assetType.assetTypeReferenceAttributes=[];
                    callAssetTypeReference()
                }
                AssetType.save(vm.assetType, onSaveSuccess, onSaveError);
            }
        }
       
        function callAssetTypeReference(){
            for (var i = 0; i < vm.referenceAttribute.length; i += 3) {
                        
                var obj = {
                    "assetTypeRfrncAttribOrder":  vm.referenceAttribute[i+2].assetTypeRfrncAttribOrder 
                    };
              
                obj["assetAttributeName"] = vm.referenceAttribute[i] ? {"assetAttributeTypes": vm.referenceAttribute[i].assetAttributeTypes,
                "name": vm.referenceAttribute[i].name,
                "attributeDataType": vm.referenceAttribute[i].attributeDataType,
                "defaultValue" : vm.referenceAttribute[i].defaultValue,
                "assetTypeAttribOrder": "0",} : null;
                obj["assetAttributeValue"] = vm.referenceAttribute[i + 1] ? {"assetAttributeTypes": vm.referenceAttribute[i+1].assetAttributeTypes,
                "name": vm.referenceAttribute[i+1].name,
                "attributeDataType": vm.referenceAttribute[i+1].attributeDataType,
                "defaultValue" : vm.referenceAttribute[i+1].defaultValue,
                "assetTypeAttribOrder": "1",} : null;
                obj["assetAttributeImage"] = vm.referenceAttribute[i + 2] ? {"assetAttributeTypes": vm.referenceAttribute[i+2].assetAttributeTypes,
                "name": vm.referenceAttribute[i+2].name,
                "attributeDataType": vm.referenceAttribute[i+2].attributeDataType,
                "defaultValue" : vm.referenceAttribute[i+2].defaultValue,
                "assetTypeAttribOrder": "2",} : null;

                vm.assetType.assetTypeReferenceAttributes.push(obj);
              }
        }
       /* $scope.fileNameChanged = function() {
        	
        	 var filesSelected = document.getElementById("assetImage").files;
        	 for(var i=0; i<filesSelected.length; i++){
 				var fileReader = new FileReader();
                  fileReader.onload = function(fileLoadedEvent) {
 			    	var test=window.btoa(fileLoadedEvent.target.result);
 			    	 vm.assetType.image= test;
 			    }
 			      fileReader.readAsBinaryString(filesSelected[i]);
 			}
 		   
        	}*/
        
        $("#assetImage").change(function(){
        	var filesSelected = document.getElementById("assetImage").files;
       	 	for(var i=0; i<filesSelected.length; i++){
				var fileReader = new FileReader();
                fileReader.onload = function(fileLoadedEvent) {
			    	var test=window.btoa(fileLoadedEvent.target.result);
			    	 vm.assetType.image= test;
			    	
			    }
			    fileReader.readAsBinaryString(filesSelected[i]);
			}
		    
		});
        
        

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:assetTypeUpdate", result);
            vm.isSaving = false;
            var previousId = sessionStorage.getItem("prevId");
            var prevEditDetail = sessionStorage.getItem("prevEditId");
            if (previousId) {
                sessionStorage.setItem("AssetTypeToEditAsset", "assetTypeToAsset");
                $state.go("asset.edit", {
                    id: previousId
                });
                sessionStorage.removeItem("prevId");
            } else if (prevEditDetail) {
                sessionStorage.setItem("AssetTypeToEditDetails", "assetTypeToAssetDetails");
                $state.go("asset-detail.edit", {
                    id: prevEditDetail
                });
                sessionStorage.removeItem("prevEditId");
            } else if(sessionStorage.getItem("createAssetType")) {
                $state.go("asset.new", {
                });
            }
             else if(sessionStorage.getItem("createAssetTypeAssetType")){
                $state.go("asset-type");
            } else {
                $state.go("asset-type");
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

      /*  vm.addmore = function() {
            vm.items.push({name: ''});
          };
            
         vm.remove = function(index) {
            vm.items.splice(index, 1);
          };*/
        
        vm.addmore = function(index) {
        	vm.items.splice((index+1), 0,{name: '',attributeDataType:{}, defaultValue:'', assetAttributeTypes:"NON_REFERENCE"});
             
          };

          vm.addReferenceAttribute = function (index) {
              for (var i = 0; i < 3; i++) {
                  /* 1039 change to i<3 */
                  vm.referenceAttribute.splice(index + 1, 0, {
                      name: "",
                      attributeDataType: {},
                      defaultValue: "",
                      assetAttributeTypes: "THREE_PNT_REFERENCE",
                  });
              }
          };

          $scope.removeRow = function (index) {
              for (var i = 0; i < 3; i++) {
                  /* 1039 change to i<3*/
                  vm.referenceAttribute.splice(index - i, 1);
              }
          };

       vm.remove = function(index) {
            vm.items.splice(index, 1);
          };
          function decodeImage(img){
        	   return window.atob(img);
           }

    }
})();
