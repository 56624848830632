(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('GeofenceController', GeofenceController);

    GeofenceController.$inject = ['$scope', '$rootScope','$state', '$compile', 'DEFAULT_LATITUDE', 'DEFAULT_LONGITUDE', 'Geofence', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'GeofenceSearch', 'UserAccount', 'GeofenceIDs', 'UserGeofences', 'AssetGroupES', 'AssetByID', 'GeofenceChildAssets', 'Report', 'UserMasterGeofences', 'GeofenceByID', 'MiniGeofencesByMasterID', 'BufferGeofencesByMiniID','AssetLinkList'];

    function GeofenceController($scope, $rootScope, $state, $compile, DEFAULT_LATITUDE, DEFAULT_LONGITUDE, Geofence, ParseLinks, AlertService, pagingParams, paginationConstants, GeofenceSearch, UserAccount, GeofenceIDs, UserGeofences, AssetGroupES, AssetByID, GeofenceChildAssets, Report, UserMasterGeofences, GeofenceByID, MiniGeofencesByMasterID, BufferGeofencesByMiniID,AssetLinkList) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        $scope.search = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;
        vm.filterGeofenceSearchMap = filterGeofenceSearchMap;
        vm.filterAssetsOnGeofenveMapView = filterAssetsOnGeofenveMapView;
        vm.closeMapFilterModal = closeMapFilterModal;
        vm.saveGeofenceFilter = saveGeofenceFilter;
        vm.geofencesTable = geofencesTable;
        vm.downloadAttendenceReport = downloadAttendenceReport;
        vm.downloadAttendenceReportKml = downloadAttendenceReportKml;
        $scope.tab = 1;
        var locations = {};
        var latlng;

        var noDataText = null;
        var markers = [];
        var polylines = [];
        vm.userGeofences = [];
        vm.geofenceChildUsers = [];
        var openedInfoWindow = null;

        vm.userMasterGeofences = [];
        vm.previousPolygons = [];

        vm.selectGeofenceEntity = selectGeofenceEntity;
        vm.selectAllGeofences = selectAllGeofences;
        vm.isAllGeofencesSelected = false;
        vm.selectedGeofences = [];

        vm.gotoLatitute = gotoLatitute;
        vm.closeGotoLatitute = closeGotoLatitute;
        vm.showZoomLocation = showZoomLocation;
        var centerLatLng;
        vm.selectCityFilter= false;

        $scope.geofencelabel = {
            search          : "Search",
            nothingSelected : "Geofence"      
        }

        $scope.geofenceMap = function(){
            vm.selectedGeofenceId = vm.selectedGeofence[0].id
            saveGeofenceFilter()
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    } /* 
                    {
                        elementType: "geometry.fill",
                        stylers: [
                            {
                                color: "#EFEFEF",
                            },
                        ],
                    }, */,
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    }
                ],
            });
        }

        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title","Locate Me");
			locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon)
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            var goTo = document.createElement("button");
            goTo.setAttribute("title", "Location Coordinates");
            goTo.classList.add("custom-map-control-button");
            var gotoIcon = document.createElement("i");
            gotoIcon.classList.add("fa");
            gotoIcon.classList.add("fa-location-arrow");
            gotoIcon.classList.add("geo");
            goTo.appendChild(gotoIcon);
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
                goTo
            ); 

            goTo.addEventListener("click", function () {
                gotoLatitute()
            })

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");	
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        { enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0}
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(
                        false,
                        infoWindow,
                        map.getCenter()
                    );
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
            if ($scope.tab == 1) {
                /* if(vm.userGeofences.length > 0){
                    filterSearch();
                } */
                filterSearch();
            } else if ($scope.tab == 2) {
                vm.selectCityFilter = false
                var centerGeofence = new google.maps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE);
                locations.map = new google.maps.Map(document.getElementById("map_canvas1"), myOption(15,centerGeofence));
                geoLocation(locations.map)
                
                vm.userMasterGeofences = [];
                UserMasterGeofences.getAll(function (response) {
                    vm.userMasterGeofences = response;
                });
                //filterGeofenceSearchMap();
                vm.mapAssets = [];
                //getGroupGeofenceAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets);
            }
        }

        if ($scope.tab == 1) {
            UserAccount.get(function (data) {
                vm.userId = data.id
                var isAdmin = false;
                data.authorities.forEach(function (val) {
                    if (val == "ROLE_USER_ADMIN") {
                        isAdmin = true;
                    } else if (val == "ROLE_HOD") {
                        isAdmin = true;
                    }
                });
                filterSearch();
                /* if (isAdmin) {
                    GeofenceIDs.getAll(function (response) {
                        response.forEach(function (geofence) {
                            if (geofence.name != 'Bangalore') {
                                vm.userGeofences.push(geofence);
                                if (vm.selectedGeofenceId == null) {
                                    vm.selectedGeofenceId = geofence.id;
                                    vm.canFilter = true;
                                }
                            }
                        });
                    }); 
                    filterSearch();
                } else {
                    UserGeofences.getAll(function (response) {
                        response.forEach(function (geofence) {
                            if (geofence.name != 'Bangalore') {
                                vm.userGeofences.push(geofence);
                                if (vm.selectedGeofenceId == null) {
                                    vm.selectedGeofenceId = geofence.id;
                                    vm.canFilter = true;
                                }
                            }
                        });
                    filterSearch();
                    });
                } */
            });
        }

        function filterGeofenceSearchMap() {
            var data = vm.geofences;
            drawResponseMap(data);
        }

        vm.sendAssetLinkRequest = function(geoId,geoName){
            $('#mappingFixed').show()
            vm.geoName = geoName
            vm.geoId = geoId
        }

        vm.postRequest = function (){
            AssetLinkList.post({
                "entityName": "Assets",
                "entityLayout": "SPREAD",
                "reqByUserId": vm.userId,
                "messageType": "ADD",
                "messageStatus": "OPEN",
                "geofences": [
                    {
                        "id": vm.geoId
                    }
                ] 
            }, function (response) {
                vm.success = response.status == 200 ? "success" : ""
                if (vm.success) {
                    $rootScope.highlightAssetLink = true
                    vm.mappingFixed()
                    setTimeout(function () {
                        vm.success = "";
                        $scope.$apply()
                    }, 5000);
                }else{
                    $rootScope.highlightAssetLink = false
                }
            })
        }

        vm.mappingFixed= function(){
            $("#mappingFixed").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mappingFixed").hide();
        }
        function drawResponseMap(data) {
            //var myOptions = null;
            var centerGeofence
            data.forEach(function (assets) {
                JSON.parse(assets.coordinates).forEach(function (value, key) {
                    var lat = value.lat.toString();
                    var long = value.lng.toString();
                    centerGeofence = new google.maps.LatLng(lat, long)
                    /* myOptions = {
                        zoom: 15,
                        center: new google.maps.LatLng(lat, long),
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    }; */
                });
            });
            if ($scope.tab == 2) {
                locations.map = new google.maps.Map(document.getElementById("map_canvas1"), myOption(15,centerGeofence));
                geoLocation(locations.map)

                
                locations.overlay = new google.maps.OverlayView();
                locations.overlay.draw = function () { };
                locations.overlay.setMap($scope.map);
                locations.element = document.getElementById('map_canvas1');
                
                // drawLatestGeofence(data);
                // Geofence.query({
                //     page: 0,
                //     size: 100000,
                // }, onSuccessPolygon, onErrorPolygon);

                // function onSuccessPolygon(data, headers) {
                //     vm.geofencesPolygon = [];
                //     data.forEach(function (value) {
                //         if (value.zone) {
                //             value.type = "MASTER";
                //         } else {
                //             value.type = "MINI";
                //         }
                //         vm.geofencesPolygon.push(value);
                //     });
                //     drawLatestGeofence(vm.geofencesPolygon);
                // }

                // function onErrorPolygon(error) {  
                //     // AlertService.error(error.data.message);
                // }
            }
        }

        function selectAllGeofences() {
            var toggleStatus = vm.isAllGeofencesSelected;
            if (toggleStatus) {
                vm.canDownload = true;
                vm.selectedGeofences = [];
                vm.geofences.forEach(function (geofence) {
                    vm.selectedGeofences.push(geofence.id);
                });
            } else {
                vm.canDownload = false;
                vm.selectedGeofences = [];
            }
            angular.forEach(vm.geofences, function (item) { item.isChecked = toggleStatus; });
        }

        function selectGeofenceEntity(geofence) {
            if (geofence.id != null) {
                if (geofence.isChecked) {
                    vm.canDownload = true;
                    vm.selectedGeofences.push(geofence.id);
                } else {
                    var index = vm.selectedGeofences.indexOf(geofence.id);
                    if (index > -1) {
                        vm.selectedGeofences.splice(index, 1);
                    }
                }
                if (vm.selectedGeofences.length == 0) {
                    vm.canDownload = false;
                    // getAssetsForListView();
                } else {
                    vm.canDownload = true;
                }
            }
        };

        function downloadAttendenceReport() {
			Report.downloadGeofenceDump({
				ids: vm.selectedGeofences
			}, function (response) {
				var a = document.createElement("a");
				a.style = "display: none";
				var file = new Blob([(response)], { type: 'application/csv' });
				var fileURL = URL.createObjectURL(file);
				a.href = fileURL;
				a.download = "geofence.csv";
				a.click();
				window.URL.revokeObjectURL(fileURL);
			});
		}

		function downloadAttendenceReportKml() {
			Report.downloadGeofenceDumpKml({
				ids: vm.selectedGeofences
			}, function (response) {
				var a = document.createElement("a");
				a.style = "display: none";
				var file = new Blob([(response)], { type: 'application/text' });
				var fileURL = URL.createObjectURL(file);
				a.href = fileURL;
				a.download = "geofence.kml";
				a.click();
				window.URL.revokeObjectURL(fileURL);
			});
		}

        function drawLatestGeofence(response) {
            if (response) {
                initializeGeofenceMap(response, function (latlang) {
                    locations.latlng = latlang;
                    // function() {
                    $.each(response, function (index, value) {

                        try {
                            if (value.type == "MASTER") {
                                drawPolygon(value, latlang, "#fd7a24", 1);
                            }

                        } catch (err) {
                            console.log(err);
                        }
                    });
                    $.each(response, function (index, value) {

                        try {
                            if (value.type == "MINI") {
                                drawPolygon(value, latlang, "#fd7a24", 2);
                            }

                        } catch (err) {
                            console.log(err);
                        }
                    });
                    $.each(response, function (index, value) {

                        try {
                            if (value.type == "BUFFER") {
                                drawPolygon(value, latlang,"#fd7a24", 3);
                            }

                        } catch (err) {
                            console.log(err);
                        }
                    });
                });
            }
        }

        function drawPolygon(value, latlang, color, zIndex) {
            var path = JSON.parse(value.coordinates);
            // drawPolylineForGeoFence(path, locations.map, value.name);
            var infowindow = new google.maps.InfoWindow(
                {
                    content: '<div class="infoWindowhead">'
                        + "name"
                        + '<div class="infoWindowContent"> <b>Description :</b> '
                        + "des" + '</div></div>'
                });

            infowindow.setPosition(latlang);
            var strokeColor = "#ff6a08";

            if(color == "green"){
                strokeColor = "#00e658";
            } else if (color == "blue") {
                strokeColor = "#0066CC";
            }
            
            var tempPolygon = new google.maps.Polygon({
                paths: path,
                strokeColor: strokeColor,
                strokeOpacity: 0.5,
                strokeWeight: 1.2,
                fillColor: strokeColor,
                fillOpacity: 0.1,
                zIndex: zIndex
            });
            google.maps.event.addListener(tempPolygon, 'mouseout', function (event) {
                infowindow.close();
            });

            google.maps.event.addListener(tempPolygon, 'click', function (event) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
                infowindow.setContent('<div class="infoWindowhead">'
                    + '<b>Geofence Name : </b><br><br>' + value.name + '</br></div>'
                );

                infowindow.mousemove;
                infowindow.open(locations.map);
                infowindow.setPosition(event.latLng);
                openedInfoWindow = infowindow;
            });
            tempPolygon.setMap(locations.map);
            return tempPolygon
        }

        function GetCenterFromDegrees(data) {
            if (!(data.length > 0)) {
                return false;
            }

            var num_coords = data.length;

            var X = 0.0;
            var Y = 0.0;
            var Z = 0.0;

            for (var i = 0; i < data.length; i++) {
                var lat = (data[i].lat * Math.PI) / 180;
                var lon = (data[i].lng * Math.PI) / 180;

                var a = Math.cos(lat) * Math.cos(lon);
                var b = Math.cos(lat) * Math.sin(lon);
                var c = Math.sin(lat);

                X += a;
                Y += b;
                Z += c;
            }

            X /= num_coords;
            Y /= num_coords;
            Z /= num_coords;

            var lon = Math.atan2(Y, X);
            var hyp = Math.sqrt(X * X + Y * Y);
            var lat = Math.atan2(Z, hyp);

            var newX = (lat * 180) / Math.PI;
            var newY = (lon * 180) / Math.PI;

            centerLatLng = ({
                lat: newX,
                lng: newY
            });

            return centerLatLng
        }

        $('#masterGeofence').on('change', function() {
            vm.selectCityFilter = true;
            var value = $(this).val();
            if(value != ""){
            GeofenceByID.get(value, function (geofenceDetails) {
                var points = JSON.parse(geofenceDetails.coordinates);
                GetCenterFromDegrees(points)
                locations.latlng = {lat:points[0].lat, lng:points[0].lng};
                /* var myOptions = {
                    zoom: 10,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                }; */

                locations.map = new google.maps.Map(document
                    .getElementById("map_canvas1"), myOption(10,locations.latlng));
                geoLocation(locations.map)   
                drawPolygon(geofenceDetails, locations.latlng, "red", 1);
                MiniGeofencesByMasterID.get(geofenceDetails.id, function (response) {
                    vm.userGeofences = [];
                    response.forEach(function(value){
                        vm.previousPolygons.push(drawPolygon(value, locations.latlng, "red", 2));
                        value.label = value.name.substring(0, 15) + "..";
                        vm.userGeofences.push(value);
                    });
                });
                locations.map.setCenter(centerLatLng)
            });
            }
        });

        function drawPolylineForGeoFence(latlngsToDraw, map, details) {
            var spreadAssetCoordinates = [];
            $.each(latlngsToDraw, function (index, value) {

                spreadAssetCoordinates.push({
                    lat: value.lat,
                    lng: value.lng
                });
            });

            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                    lat: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lat,
                    lng: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lng
                };
                var infowindow = new google.maps.InfoWindow(
                    {
                        content: '<div class="infoWindowhead">'
                            + "name"
                            + '<div class="infoWindowContent"> <b>Description :</b> '
                            + "des" + '</div></div>'
                    });

                infowindow.setPosition(midLatLng);
                var ColorValue = '#68A8DE';
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: ColorValue,
                    strokeOpacity: 0.8,
                    strokeWeight: 3
                });

                polylinepath.setOptions({
                    strokeColor: ColorValue
                });

                google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
                    infowindow.close();
                });

                google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.setContent('<div class="infoWindowhead">'
                        + '<b></b>' + details + '</br></div>'
                    );

                    infowindow.mousemove;
                    infowindow.open(map);
                    infowindow.setPosition(event.latLng);
                    openedInfoWindow = infowindow;
                });
                polylinepath.setMap(map);
            }
        }

        function initializeGeofenceMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                latlng = new google.maps.LatLng(latlangs[0].pinLat, latlangs[0].pinLong);
                callback(latlng);
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {

                        latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                        callback(latlng);
                    }, function () {

                        callback(new google.maps.LatLng("5.431587", "101.128162"));
                    });
                } else {
                    callback(new google.maps.LatLng("5.431587", "101.128162"));
                }
            }
        }

        function geofencesTable(geo) {
            vm.geofences.forEach(function (item) {
                item.isExpanded = false;
            })
            if (vm.toggle == geo.id) {
                vm.toggle = undefined
            } else {
                geo.isExpanded = true;
                vm.toggle = geo.id;
            }
            GeofenceChildAssets.get(geo.id, function (response) {
                vm.geofenceChildUsers = response;
            });
        }



        function filterSearch() {
            if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
                var str = $scope.search+"";
                    str = str.replace('/', 'slash');
                GeofenceSearch.query({
                    userId: str,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        //  result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.geofences = [];
                    data.forEach(function (value) {
                        if (value.zone) {
                            value.type = "MASTER";
                        } else {
                            value.type = "MINI";
                        }
                        vm.geofences.push(value);
                    });
                    vm.page = pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function loadAll() {
            Geofence.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    //  result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.geofences = [];
                data.forEach(function (value) {
                    if (value.zone) {
                        value.type = "MASTER";
                    } else {
                        value.type = "MINI";
                    }
                    vm.geofences.push(value);
                });
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        var gotoMarker = null;
        
        var input = document.getElementById('field_pinLat');
            input.addEventListener('input', function() {
            this.value = this.value.replace(/[^0-9\,\.]/, '');
            });
        function showZoomLocation() {
            var latlng = vm.getLocationCoordinate.split(",");
            if (gotoMarker != null) {
                gotoMarker.setMap(null);
            }

            if (vm.getLocationCoordinate != null) {
                var assetCenterLatLng = {
                    lat: parseFloat(latlng[0]),
                    lng: parseFloat(latlng[1]),
                };
                locations.map.setCenter(assetCenterLatLng);
                locations.map.setZoom(18);
                var startMarker = new google.maps.Marker({
                    map: locations.map,
                    position: assetCenterLatLng,
                });

                gotoMarker = startMarker;
                $("#modalLatLongs").removeClass("in");
                $(".modal-backdrop").remove();
                $("#modalLatLongs").hide();
            }
        }
        function gotoLatitute() {
            $("#modalLatLongs").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
        }

        function closeGotoLatitute() {
            $("#modalLatLongs").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalLatLongs").hide();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: $scope.search
            });
        }

        function filterAssetsOnGeofenveMapView() {
            $("#mapFilterGeofenceModal").show();
            noDataText = document.getElementById("noData");
            noDataText.style.marginLeft = "25px";
            vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
        }

        function closeMapFilterModal() {
            $("#mapFilterGeofenceModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterGeofenceModal").hide();
            vm.selectedGeofenceId = vm.prevSelectedGeofenceId;
        }

        function saveGeofenceFilter() {
            vm.mapAssets = [];
            //getGroupGeofenceAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets);
            vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
            vm.previousPolygons.forEach(function(polygon){
                polygon.setMap(null);
            });
            vm.userGeofences.forEach(function(value){
                if(value.id == vm.selectedGeofenceId){
                    vm.previousPolygons.push(drawPolygon(value, locations.latlng, "green", 2));
                } else {
                    vm.previousPolygons.push(drawPolygon(value, locations.latlng, "red", 2));
                }
            });
           
            BufferGeofencesByMiniID.get(vm.selectedGeofenceId, function (response) {
                response.forEach(function(value){
                    vm.previousPolygons.push(drawPolygon(value, locations.latlng, "blue", 3));
                });
            });
            $("#mapFilterGeofenceModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterGeofenceModal").hide();
        }

        /* function getGroupGeofenceAssetsFromES(geofenceIdVal, lastAssetId, mapAssetLists) {
            AssetGroupES.get(geofenceIdVal, lastAssetId, function (data) {
                vm.mapAssets = mapAssetLists;
                data.assets.forEach(function (asset) {
                    var tmpAsset = {};

                    tmpAsset.assetCoordinates = asset.assetCoordinates;
                    tmpAsset.assetTypeName = asset.assetTypeName;
                    tmpAsset.assetTypeColor = asset.assetColor;
                    tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
                    tmpAsset.assetTypeLayout = asset.assetTypeLayout;
                    tmpAsset.id = asset.id;
                    tmpAsset.name = asset.name;

                    vm.mapAssets.push(tmpAsset);
                });
                drawLatestAssetsOnMap(vm.mapAssets);
                if (data.lastId != null) {
                    getGroupGeofenceAssetsFromES(geofenceIdVal, data.lastId, vm.mapAssets);
                } else {
                    if (vm.mapAssets.length > 0) {
                        var assetSelected = vm.mapAssets[0];
                        AssetByID.get(assetSelected.id, function (assetVal) {
                            var assetLatLng = ({
                                lat: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].latitude,
                                lng: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].longitude
                            });
                            locations.map.setCenter(assetLatLng);
                        });
                    }
                }
                // filterGeofenceSearchMap();
            });
        } */

       /*  function drawLatestAssetsOnMap(response) {
            if (response) {
                initializeGeofenceAssetMap(response, function (latlang) {
                    locations.latlng = latlang;

                    setupAssetMap(function () {
                        $.each(response, function (index, value) {
                            if (value.assetTypeLayout == null) {
                                value.assetTypeName = value.assetType.name;
                                value.assetTypeLayout = value.assetType.layout;
                                value.assetTypeImagePath = value.assetType.imagePath;
                            }
                            if (value.assetTypeLayout != null && value.assetTypeLayout == 'FIXED') {
                                if (value.assetCoordinates.length > 1) {
                                    drawPolyline(value);
                                    var value1 = value;
                                    value1.assetCoordinates = [value.assetCoordinates[value.assetCoordinates.length - 1]];

                                    drawFixedGeofenceAsset(value1, false);

                                } else {
                                    drawFixedGeofenceAsset(value, false);
                                }
                            }
                            if (value.assetTypeLayout != null && value.assetTypeLayout == 'SPREAD') {
                                drawPolyline(value, locations.map);
                            }

                        });
                    });
                });
            }
        } */

        /* function initializeGeofenceAssetMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                var latVal = null;
                var lngVal = null;
                latlangs.forEach(function (value) {
                    if (latVal == null && lngVal == null) {
                        if (value.assetCoordinates.length > 0) {
                            latVal = value.assetCoordinates[value.assetCoordinates.length - 1].latitude;
                            lngVal = value.assetCoordinates[value.assetCoordinates.length - 1].longitude;
                        }
                    }
                });
                if (latVal != null && lngVal != null) {
                    latlng = new google.maps.LatLng(latVal, lngVal);
                    callback(latlng);
                }
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (
                        position) {

                        latlng = new google.maps.LatLng(
                            position.coords.latitude,
                            position.coords.longitude);
                        callback(latlng);
                    }, function () {

                        // callback(new google.maps.LatLng("12.9716", "77.5946"));
                        callback(new google.maps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE));
                    });
                } else {
                    callback(new google.maps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE));
                }
            }
        }

        function setupAssetMap(callback) {
            if (locations.map == null) {
                var myOptions = {
                    zoom: 14,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };

                locations.map = new google.maps.Map(document
                    .getElementById("map_canvas"), myOptions);

                // google.maps.event.addListener(locations.map, 'zoom_changed', function () {
                //     vm.zoomLevel = locations.map.getZoom();
                //     if ((vm.prevZoomLevel == 17 && vm.zoomLevel == 18) || (vm.prevZoomLevel == 18 && vm.zoomLevel == 17)) {
                //         drawLatestAssetsOnMap(vm.mapAssets);
                //     }
                //     vm.prevZoomLevel = vm.zoomLevel;

                // });
            } else {
                // Loop through all the markers and polylines to remove

                for (var i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }

                for (i = 0; i < polylines.length; i++) {
                    polylines[i].setMap(null);
                }

                markers = [];
                polylines = [];
            }
            locations.overlay = new google.maps.OverlayView();
            locations.overlay.draw = function () { };
            locations.overlay.setMap($scope.map);
            locations.element = document.getElementById('map_canvas');
            callback();
        }

        function drawPolyline(spread, map) {
            var spreadAssetCoordinates = [];
            $.each(spread.assetCoordinates, function (index, value) {

                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude
                });
            });
            if (spreadAssetCoordinates.length > 1) {
                var midLatLng = {
                    lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
                    lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
                };

                var polylineColor = '#FFFFFF';
                var distanceFromPOP = '';
                var fiberCoreVal = '';
                var infowindow = new google.maps.InfoWindow();
                if (spread.assetTypeLayout == "SPREAD") {
                    polylineColor = spread.assetTypeColor
                }
                var polylineWeight = 2.5;
                var strokeOpacityVal = 1.0;

                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: strokeOpacityVal,
                    strokeWeight: polylineWeight,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false
                });

                google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
                    infowindow.close();
                });

                google.maps.event.addListener(polylinepath, 'click', function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.setContent('<div class="infoWindowhead">'
                        + '<b>Asset Name : </b><br><br>' + spread.name + '</br></div>'
                    );

                    infowindow.mousemove;
                    infowindow.open(locations.map);
                    infowindow.setPosition(event.latLng);
                    openedInfoWindow = infowindow;
                });

                polylinepath.setMap(locations.map);
                polylines.push(polylinepath);
            }
        }

        function drawFixedGeofenceAsset(fixed, hasParent) {

            $.each(fixed.assetCoordinates, function (index, value1) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }

                var infowindow = new google.maps.InfoWindow();

                var iconUrl = "";
                var isFixedAsset = false;
                iconUrl = fixed.assetTypeImagePath;
                isFixedAsset = true;

                var iconSize = 8;
                if (vm.zoomLevel == 10) {
                    iconSize = 8;
                } else if (vm.zoomLevel == 11) {
                    iconSize = 16;
                }

                if (isFixedAsset) {
                    iconSize = 24;
                }

                var startMarker = new google.maps.Marker({
                    map: locations.map,
                    position: new google.maps.LatLng(
                        value1.latitude,
                        value1.longitude),
                    title: fixed.name,
                    icon: {
                        scaledSize: new google.maps.Size(
                            iconSize, iconSize),
                        url: iconUrl,
                        anchor: new google.maps.Point(
                            iconSize / 2, iconSize / 2),
                    },
                });
                google.maps.event.addListener(startMarker, 'mouseout', function (event) {
                    infowindow.close();
                });

                google.maps.event.addListener(startMarker, 'click', function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.setContent('<div class="infoWindowhead">'
                        + '<b>Asset Name : </b><br><br>' + fixed.name + '</br></div>'
                    );

                    infowindow.mousemove;
                    infowindow.open(locations.map);
                    infowindow.setPosition(event.latLng);
                    openedInfoWindow = infowindow;
                });
                markers.push(startMarker);

                openedInfoWindow = infowindow;
            });
        } */
    }
})();