(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('CustomCaseTypeAttribute', CustomCaseTypeAttribute);

    CustomCaseTypeAttribute.$inject = ['$resource'];

    function CustomCaseTypeAttribute ($resource) {
        var resourceUrl =  'api/custom-case-type-attributes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('CustomCaseTypeAttributeList', CustomCaseTypeAttributeList);

    CustomCaseTypeAttributeList.$inject = ['$resource'];

    function CustomCaseTypeAttributeList($resource) {
        var resourceUrl = 'api/custom-case-type-attributes-list';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();


