(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("WorkOrderController", WorkOrderController);

    WorkOrderController.$inject = [
        "$filter",
        "$scope",
        "$state",
        "$compile",
        "$stateParams",
        "pagingParams",
        "paginationConstants",
        "WorkOrder",
        "WorkOrderGroup",
        "WorkOrderUpdateGroup",
        "WorkOrderBulkDelete",
        "TrCaseById",
        "AssetByID",
        "UserGroupIDs",
        "ParseLinks",
        "AlertService",
        "WorkOrderSearch",
        "WorkOrderGroupSearch",
        "WorkOrderPriority",
        "WorkOrderGroupPriority",
        "WorkOrderPrioritySearch",
        "WorkOrderGroupPrioritySearch",
        "WorkOrderForMap",
        "WorkOrderSearchForMap",
        "WorkOrderLastLocation",
        "ChildWorkflows",
        "WorkOrderWorkflowAssets",
        "UserAccount",
    ];

    function WorkOrderController(
        $filter,
        $scope,
        $state,
        $compile,
        $stateParams,
        pagingParams,
        paginationConstants,
        WorkOrder,
        WorkOrderGroup,
        WorkOrderUpdateGroup,
        WorkOrderBulkDelete,
        TrCaseById,
        AssetByID,
        UserGroupIDs,
        ParseLinks,
        AlertService,
        WorkOrderSearch,
        WorkOrderGroupSearch,
        WorkOrderPriority,
        WorkOrderGroupPriority,
        WorkOrderPrioritySearch,
        WorkOrderGroupPrioritySearch,
        WorkOrderForMap,
        WorkOrderSearchForMap,
        WorkOrderLastLocation,
        ChildWorkflows,
        WorkOrderWorkflowAssets,
        UserAccount
    ) {
        var vm = this;
        $scope.search = pagingParams.search;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterWorkOrderSearch = filterWorkOrderSearch;
        vm.filterSearchMap = filterSearchMap;
        vm.showWorkOrderBreakUps = showWorkOrderBreakUps;
        // vm.showWorkflowBOQBreakUp = showWorkflowBOQBreakUp;
        // vm.showWorkflowPBBreakUp = showWorkflowPBBreakUp;
        vm.showWorkOrderPowerBudgetBreakUps = showWorkOrderPowerBudgetBreakUps;
        vm.selectedWorkOrders = selectedWorkOrders;
        vm.selectWorkOrderEntity = selectWorkOrderEntity;
        vm.selectAllWorkOrder = selectAllWorkOrder;
        vm.updateWorkOrderGroup = updateWorkOrderGroup;
        vm.hideWorkOrderModal = hideWorkOrderModal;
        vm.hideWorkOrderPBModal = hideWorkOrderPBModal;
        // vm.hideWorkflowboqModal = hideWorkflowboqModal;
        // vm.hideWorkflowPBModal = hideWorkflowPBModal;
        vm.searchWorkOrders = searchWorkOrders;
        var latlng;
        var userPaths = {};
        var locations = {};
        var nearbyAgentsMarkers = [];
        var openedInfoWindow = null;
        vm.resolved = [];
        vm.unresolved = [];

        vm.selectWorkOrders = [];
        vm.multgroup = [];
        var selctedGroup = [];
        vm.groupOrders = true;
        vm.showActions = true;
        vm.totalSelected = 0;
        vm.assetsTable = assetsTable;
        vm.workflowTable = workflowTable;
        vm.createAccess = false;
        // vm.serviceTable = serviceTable;

        vm.items = [
            {
                name: "Update Group Mapping",
            },
            {
                name: "Delete Multiple WorkOrders",
            },
        ];

        UserAccount.get(function (data) {
            data.authorities.find(function (val) {
                if (
                    val == "ROLE_PLAN_ADMIN" ||
                    val == "ROLE_PLAN_INFRA" ||
                    val == "ROLE_PLAN_SURVEY"
                ) {
                    vm.createAccess = true;
                }
            });
        });

        $scope.tab = 4;
        $scope.$on("$destroy", function () {});
        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        if ($scope.tab == 4) {
            UserGroupIDs.getAll(function (response) {
                response.forEach(function (group) {
                    if (selctedGroup.indexOf(group.id) !== -1) {
                        vm.multgroup.push({
                            name: group.name,
                            id: group.id,
                            ticked: true,
                        });
                    } else {
                        vm.multgroup.push({
                            name: group.name,
                            id: group.id,
                            ticked: false,
                        });
                    }
                });
            });
            filterGroupSearch();
        }

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
            if ($scope.tab == 4) {
                vm.itemsPerPage = paginationConstants.itemsPerPage;
                filterGroupSearch();
            }
        };

        function workflowTable(workOrder) {
            vm.workOrders.forEach(function (item) {
                item.isExpanded = false;
            });
            if (vm.toggle == workOrder.id) {
                vm.toggle = undefined;
            } else {
                workOrder.isExpanded = true;
                vm.toggle = workOrder.id;
            }
            ChildWorkflows.query(
                {
                    parentId: workOrder.id,
                },
                onSuccess
            );

            function onSuccess(data, headers) {
                var workFlowsRes = [];
                var Cost = "";
                var pb = "";
                var totalDis = 0.0;
                data.forEach(function (values) {
                    vm.parentGroup = values.groups[0].name;
                    /* if (values.status) {
                        values.status = values.status.replace(
                            "NEW",
                            "PLANNING PHASE"
                        );
                        values.status = values.status.replace(
                            "INPROGRESS",
                            "ROLL-OUT PHASE"
                        );
                        values.status = values.status.replace(
                            "RESOLVED",
                            "OPERATION PHASE"
                        );
                    } */
                    var caseTypeTypeAttrVal =
                        values.workFlowTypeAttributeValues;
                    caseTypeTypeAttrVal.forEach(function (item) {
                        if (item.workFlowTypeAttribute.name == "Cost") {
                            Cost = item.attributeValue;
                        } else if (
                            item.workFlowTypeAttribute.name == "Power Budget"
                        ) {
                            pb = item.attributeValue;
                        } else if (
                            item.workFlowTypeAttribute.name == "Fiber Distance"
                        ) {
                            totalDis = item.attributeValue;
                        }
                    });
                    var res = {
                        id: values.id,
                        description: values.description,
                        status: values.status,
                        phase: values.phase,
                        // fiberStatus: FiberStatus,
                        groups: values.groups,
                        Cost: "$" + Cost,
                        pb: "$" + pb,
                        totalDistance: totalDis,
                    };

                    workFlowsRes.push(res);
                });
                vm.workflowAssets = workFlowsRes;
            }
        }
        // function serviceTable(value) {
        // 	ChildServices.query({
        // 		parentId: value.id
        // 	}, onSuccess);

        // 	function onSuccess(data, headers) {

        // 		var servicesRes = [];
        // 		data.forEach(function (values) {
        // 			if (values.status) {
        // 				values.status = values.status.replace("NEW", "PLANNING PHASE");
        // 				values.status = values.status.replace("INPROGRESS", "ROLL-OUT PHASE");
        // 				values.status = values.status.replace("RESOLVED", "OPERATION PHASE");
        // 			}
        // 			var res = {
        // 				id: values.id,
        // 				description: values.description,
        // 				status: values.status,
        // 				reportedByUser: values.reportedByUser,
        // 				assignedToUser: values.assignedToUser,
        // 				// fiberStatus: FiberStatus,
        // 				groups: values.groups,
        // 			};

        // 			servicesRes.push(res);
        // 		});
        // 		vm.serviceAssets = servicesRes;
        // 	}
        // }
        function assetsTable(value) {
            WorkOrderWorkflowAssets.query(
                {
                    parentId: value.id,
                },
                onSuccess
            );

            function onSuccess(data, headers) {
                var servicesRes = [];
                var Cost = "";
                var pb = "";
                var totalDistance = 0.0;
                data.forEach(function (values) {
                    // if (values.status) {
                    // 	values.status = values.status.replace("NEW", "PLANNING PHASE");
                    // 	values.status = values.status.replace("INPROGRESS", "ROLL-OUT PHASE");
                    // 	values.status = values.status.replace("RESOLVED", "OPERATION PHASE");
                    // }
                    var assetAttrVal = values.assetTypeAttributeValues;

                    assetAttrVal.forEach(function (item) {
                        if (item.assetTypeAttribute.name == "Cost") {
                            Cost = item.attributeValue;
                        } else if (
                            item.assetTypeAttribute.name == "Power Budget"
                        ) {
                            pb = item.attributeValue;
                        } else if (
                            item.assetTypeAttribute.name == "Fiber Distance"
                        ) {
                            totalDis = item.attributeValue;
                        }
                    });
                    if ((values.status = "true") || (values.status = "false")) {
                        values.status = "";
                    }
                    var res = {
                        id: values.id,
                        description: values.description,
                        status: values.status,
                        phase: values.phase,
                        // reportedByUser: values.reportedByUser,
                        // assignedToUser: values.assignedToUser,
                        // fiberStatus: FiberStatus,
                        groups: values.groups,
                        Cost: "$" + Cost,
                        pb: "$" + pb,
                        totalDistance: totalDistance,
                    };

                    servicesRes.push(res);
                });
                vm.serviceAssets = servicesRes;
            }
        }
        function searchWorkOrders() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search) &&
                $scope.search.length != 1 &&
                $scope.search.length != 2 &&
                $scope.search.length != 3 &&
                $scope.search.length != 4
            ) {
                if ($stateParams.priority == undefined) {
                    WorkOrderSearch.query(
                        {
                            search: $scope.search,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                } else {
                    WorkOrderPrioritySearch.query(
                        {
                            priority: $stateParams.priority,
                            searchtext: $scope.search,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }
                function sort() {
                    var result = [
                        vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                    ];
                    if (vm.predicate !== "id") {
                        // result.push('id');
                    }
                    return result;
                }
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    var workOrdersRes = [];
                    data.forEach(function (values) {
                        var workFlows = values.workOrderCases;
                        var disVal = 0.0;
                        var Cost = "";
                        var pb = "";
                        var totalDis = 0.0;

                        var FiberStatus = "";
                        var workOrderTypeAttrVal =
                            values.workOrderTypeAttributeValues;

                        workOrderTypeAttrVal.forEach(function (item) {
                            if (item.workOrderDataTypeName == "Fiber Status") {
                                FiberStatus = item.attributeValue;
                            }

                            if (item.name == "Cost") {
                                Cost = item.attributeValue;
                            } else if (item.name == "Power Budget") {
                                pb = item.attributeValue;
                            } else if (item.name == "Fiber Distance") {
                                totalDis = item.attributeValue;
                            }
                        });
                        /*  if (values.status) {
                            values.status = values.status.replace(
                                "NEW",
                                "PLANNING PHASE"
                            );
                            values.status = values.status.replace(
                                "INPROGRESS",
                                "ROLL-OUT PHASE"
                            );
                            values.status = values.status.replace(
                                "RESOLVED",
                                "OPERATION PHASE"
                            );
                        } */
                        var res = {
                            id: values.id,
                            description: values.description,
                            address: values.address,
                            escalated: values.escalated,
                            status: values.status,
                            phase: values.phase,
                            reportedByUser: values.reportedByUser,
                            assignedToUser: values.assignedToUser,
                            workOrderTypeName: values.workOrderTypeName,
                            pinLat: values.pinLat,
                            pinLong: values.pinLong,
                            workOrderType: values.workOrderType,
                            priority: values.priority,
                            fiberStatus: FiberStatus,
                            groups: values.groups,
                            workOrderCases: values.workOrderCases,
                            geofenceName: values.geofenceName,
                            workOrderTypeAttributeValues:
                                values.workOrderTypeAttributeValues,
                            Cost: "$" + Cost,
                            pb: "$" + pb,
                            totalDistance: totalDistance,
                        };

                        workOrdersRes.push(res);
                    });
                    vm.page = pagingParams.page;
                    vm.workOrders = [];
                    workOrdersRes.forEach(function (workOrderVal) {
                        workOrderVal.workOrderTypeAttributeValues.forEach(
                            function (val) {
                                if (val.name == "Resolved Distance") {
                                    workOrderVal.resolved = val.attributeValue;
                                }
                                if (val.name == "Unresolved Distance") {
                                    workOrderVal.unresolved =
                                        val.attributeValue;
                                }
                            }
                        );
                        var total =
                            parseFloat(workOrderVal.unresolved) +
                            parseFloat(workOrderVal.resolved);
                        var percent =
                            (parseFloat(workOrderVal.resolved) * 100) / total;
                        workOrderVal.totaldistance = total.toFixed(2);
                        if (
                            total > 0 &&
                            parseFloat(workOrderVal.resolved) > 0
                        ) {
                            workOrderVal.percentdistance = percent.toFixed(0);
                        } else {
                            workOrderVal.percentdistance = 0;
                        }
                        vm.workOrders.push(workOrderVal);
                    });
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function filterWorkOrderSearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                if ($stateParams.priority == undefined) {
                    WorkOrderSearch.query(
                        {
                            search: $scope.search,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                } else {
                    WorkOrderPrioritySearch.query(
                        {
                            priority: $stateParams.priority,
                            searchtext: $scope.search,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }
                function sort() {
                    var result = [
                        vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                    ];
                    if (vm.predicate !== "id") {
                        // result.push('id');
                    }
                    return result;
                }
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                    vm.workOrders = [];
                    data.forEach(function (workOrderVal) {
                        workOrderVal.workOrderTypeAttributeValues.forEach(
                            function (val) {
                                if (val.name == "Resolved Distance") {
                                    workOrderVal.resolved = val.attributeValue;
                                }
                                if (val.name == "Unresolved Distance") {
                                    workOrderVal.unresolved =
                                        val.attributeValue;
                                }
                            }
                        );
                        var total =
                            parseFloat(workOrderVal.unresolved) +
                            parseFloat(workOrderVal.resolved);
                        var percent =
                            (parseFloat(workOrderVal.resolved) * 100) / total;
                        workOrderVal.totaldistance = total.toFixed(2);
                        if (
                            total > 0 &&
                            parseFloat(workOrderVal.resolved) > 0
                        ) {
                            workOrderVal.percentdistance = percent.toFixed(0);
                        } else {
                            workOrderVal.percentdistance = 0;
                        }
                        vm.workOrders.push(workOrderVal);
                    });
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function filterGroupSearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                if ($stateParams.priority == undefined) {
                    WorkOrderGroupSearch.query(
                        {
                            search: $scope.search,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                } else {
                    WorkOrderGroupPrioritySearch.query(
                        {
                            priority: $stateParams.priority,
                            searchtext: $scope.search,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }
                function sort() {
                    var result = [
                        vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                    ];
                    if (vm.predicate !== "id") {
                        // result.push('id');
                    }
                    return result;
                }
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                    vm.workOrders = [];
                    data.forEach(function (workOrderVal) {
                        workOrderVal.workOrderTypeAttributeValues.forEach(
                            function (val) {
                                if (val.name == "Resolved Distance") {
                                    workOrderVal.resolved = val.attributeValue;
                                }
                                if (val.name == "Unresolved Distance") {
                                    workOrderVal.unresolved =
                                        val.attributeValue;
                                }
                            }
                        );
                        var total =
                            parseFloat(workOrderVal.unresolved) +
                            parseFloat(workOrderVal.resolved);
                        var percent =
                            (parseFloat(workOrderVal.resolved) * 100) / total;
                        workOrderVal.totaldistance = total.toFixed(2);
                        if (
                            total > 0 &&
                            parseFloat(workOrderVal.resolved) > 0
                        ) {
                            workOrderVal.percentdistance = percent.toFixed(0);
                        } else {
                            workOrderVal.percentdistance = 0;
                        }
                        vm.workOrders.push(workOrderVal);
                        vm.workOrders.push(workOrderVal);
                    });
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function loadAll() {
            if ($stateParams.priority == undefined) {
                if ($scope.tab == 4) {
                    WorkOrderGroup.query(
                        {
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                } else {
                    WorkOrder.query(
                        {
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }
            } else {
                if ($scope.tab == 4) {
                    WorkOrderGroupPriority.query(
                        {
                            priority: $stateParams.priority,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                } else {
                    WorkOrderPriority.query(
                        {
                            priority: $stateParams.priority,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }
            }

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    // result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                var workOrdersRes = [];
                data.forEach(function (values) {
                    var workFlows = values.workOrderCases;
                    var disVal = 0.0;
                    var Cost = "";
                    var pb = "";
                    var totalDistance = 0.0;

                    var FiberStatus = "";
                    var workOrderTypeAttrVal =
                        values.workOrderTypeAttributeValues;

                    workOrderTypeAttrVal.forEach(function (item) {
                        if (item.workOrderDataTypeName == "Fiber Status") {
                            FiberStatus = item.attributeValue;
                        }

                        if (item.name == "Cost") {
                            Cost = item.attributeValue;
                        } else if (item.name == "Power Budget") {
                            pb = item.attributeValue;
                        } else if (item.name == "Fiber Distance") {
                            totalDistance = item.attributeValue;
                        }
                    });
                    /* if (values.status) {
                        values.status = values.status.replace(
                            "NEW",
                            "PLANNING PHASE"
                        );
                        values.status = values.status.replace(
                            "INPROGRESS",
                            "ROLL-OUT PHASE"
                        );
                        values.status = values.status.replace(
                            "RESOLVED",
                            "OPERATION PHASE"
                        );
                    } */
                    var res = {
                        id: values.id,
                        description: values.description,
                        address: values.address,
                        escalated: values.escalated,
                        status: values.status,
                        phase: values.phase,
                        reportedByUser: values.reportedByUser,
                        assignedToUser: values.assignedToUser,
                        workOrderTypeName: values.workOrderTypeName,
                        pinLat: values.pinLat,
                        pinLong: values.pinLong,
                        workOrderType: values.workOrderType,
                        priority: values.priority,
                        fiberStatus: FiberStatus,
                        groups: values.groups,
                        workOrderCases: values.workOrderCases,
                        geofenceName: values.geofenceName,
                        workOrderTypeAttributeValues:
                            values.workOrderTypeAttributeValues,
                        Cost: "$" + Cost,
                        pb: pb,
                        totalDistance: totalDistance,
                    };

                    workOrdersRes.push(res);
                });
                vm.page = pagingParams.page;
                vm.workOrders = [];
                workOrdersRes.forEach(function (workOrderVal) {
                    workOrderVal.workOrderTypeAttributeValues.forEach(function (
                        val
                    ) {
                        if (val.name == "Resolved Distance") {
                            workOrderVal.resolved = val.attributeValue;
                        }
                        if (val.name == "Unresolved Distance") {
                            workOrderVal.unresolved = val.attributeValue;
                        }
                    });
                    var total =
                        parseFloat(workOrderVal.unresolved) +
                        parseFloat(workOrderVal.resolved);
                    var percent =
                        (parseFloat(workOrderVal.resolved) * 100) / total;
                    workOrderVal.totaldistance = total.toFixed(2);
                    if (total > 0 && parseFloat(workOrderVal.resolved) > 0) {
                        workOrderVal.percentdistance = percent.toFixed(0);
                    } else {
                        workOrderVal.percentdistance = 0;
                    }
                    vm.workOrders.push(workOrderVal);
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function filterSearchMap() {
            var data;
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                WorkOrderSearchForMap.searchtrworkOrderformap(
                    $scope.search,
                    function (response) {
                        drawResponseMap(response);
                    }
                );
            } else {
                if ($stateParams.priority == undefined) {
                    WorkOrderForMap.getAllCases(function (response) {
                        drawResponseMap(response);
                    });
                } else {
                    WorkOrderSearchForMap.prioritysearchtrworkOrderformap(
                        $stateParams.priority,
                        function (response) {
                            drawResponseMap(response);
                        }
                    );
                }
            }
            $scope.search = "";
        }
        function showWorkOrderBreakUps(workCost) {
            vm.workOrderName = workCost.description;
            vm.workBreakUps = [];
            var dateFormat = "yyyy-MM-dd";
            WorkOrder.get({ id: workCost.id }, function (workCase) {
                workCase.workOrderCases.forEach(function (data) {
                    var orderCost = 0.0;
                    var orderPb = 0.0;
                    var orderDis = 0.0;
                    data.workFlowTypeAttributeValues.forEach(function (item) {
                        if (item.workFlowTypeAttribute.name == "Cost") {
                            orderCost = "$" + item.attributeValue;
                        } else if (
                            item.workFlowTypeAttribute.name == "Power Budget"
                        ) {
                            orderPb = item.attributeValue;
                        } else if (
                            item.workFlowTypeAttribute.name == "Fiber Distance"
                        ) {
                            orderDis = item.attributeValue;
                        }
                    });

                    var orders = {
                        id: data.id,
                        description: data.description,
                        createDate: $filter("date")(
                            data.createDate,
                            dateFormat
                        ),
                        Cost: orderCost,
                        pb: orderPb,
                        totalDistance: orderDis,
                    };
                    vm.workBreakUps.push(orders);
                });
            });
            $("#workOrderBreakUpModal").show();
        }

        function showWorkOrderPowerBudgetBreakUps(workPB) {
            vm.workOrderName = workPB.description;
            vm.workBreakUps = [];
            var dateFormat = "yyyy-MM-dd";
            WorkOrder.get({ id: workPB.id }, function (workCase) {
                workCase.workOrderCases.forEach(function (data) {
                    var orderCost = 0.0;
                    var orderPb = 0.0;
                    var orderDis = 0.0;
                    data.workFlowTypeAttributeValues.forEach(function (item) {
                        if (item.workFlowTypeAttribute.name == "Cost") {
                            orderCost = "$" + item.attributeValue;
                        } else if (
                            item.workFlowTypeAttribute.name == "Power Budget"
                        ) {
                            orderPb = item.attributeValue;
                        } else if (
                            item.workFlowTypeAttribute.name == "Fiber Distance"
                        ) {
                            orderDis = item.attributeValue;
                        }
                    });

                    var orders = {
                        id: data.id,
                        description: data.description,
                        createDate: $filter("date")(
                            data.createDate,
                            dateFormat
                        ),
                        Cost: orderCost,
                        pb: orderPb,
                        totalDistance: orderDis,
                    };
                    vm.workBreakUps.push(orders);
                });
            });
            $("#PBWorkOrderBreakUp").show();
        }

        // function showWorkflowBOQBreakUp(boqBreak) {
        // 	vm.workflowBoq = [];
        // 	var res = {
        // 		description: boqBreak.description,
        // 		group: boqBreak.groups[0].name,
        // 		boq: boqBreak.Cost,
        // 		totalDistance: boqBreak.totalDistance
        // 	};
        // 	vm.workflowBoq.push(res);
        // 	$("#WorkflowboqBreakUp").show();
        // }
        function showWorkflowPBBreakUp(pbBreak) {
            vm.workflowPB = [];
            var res = {
                description: pbBreak.description,
                group: pbBreak.groups[0].name,
                pb: pbBreak.pb,
                totalDistance: pbBreak.totalDistance,
            };
            vm.workflowPB.push(res);
            $("#WorkflowPBBreakUp").show();
        }

        function hideWorkOrderModal() {
            $("#workOrderBreakUpModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#workOrderBreakUpModal").hide();
        }

        function hideWorkOrderPBModal() {
            $("#PBWorkOrderBreakUp").removeClass("in");
            $(".modal-backdrop").remove();
            $("#PBWorkOrderBreakUp").hide();
        }
        // function hideWorkflowboqModal() {
        // 	$("#WorkflowboqBreakUp").hide();
        // }
        // function hideWorkflowPBModal() {
        // 	$("#WorkflowPBBreakUp").hide();
        // }

        function selectWorkOrderEntity(workOrder) {
            if (workOrder.id != null) {
                if (workOrder.isChecked == true) {
                    vm.totalSelected++;
                    vm.showActions = false;
                    vm.isAllSelected = vm.workOrders.every(function (itm) {
                        return itm.isChecked;
                    });
                    vm.selectWorkOrders.push(workOrder.id);
                } else {
                    vm.totalSelected--;
                }
                if (vm.totalSelected == 0) {
                    vm.groupAssets = true;
                    vm.showActions = true;
                    loadAll();
                }
            }
        }

        function selectAllWorkOrder() {
            var toggleStatus = vm.isAllSelected;
            if (toggleStatus) {
                vm.showActions = false;
                vm.selectWorkOrders = [];
                vm.workOrders.forEach(function (workOrder) {
                    vm.selectWorkOrders.push(workOrder.id);
                });
                vm.showActions = true;
                vm.selectWorkOrders = [];
            }
            angular.forEach(vm.workOrders, function (itm) {
                itm.isChecked = toggleStatus;
            });
        }

        function updateWorkOrderGroup(name) {
            vm.multgroup.forEach(function (group) {
                if (group.name == name) {
                    if (vm.selectWorkOrders.length > 0) {
                        var workFlowGroupArray = [];
                        vm.selectWorkOrders.forEach(function (workOrderId) {
                            workFlowGroupArray.push({
                                workOrderId: workOrderId,
                                groupId: group.id,
                            });
                        });
                        WorkOrderUpdateGroup.update(
                            workFlowGroupArray,
                            onSuccess,
                            onError
                        );
                        function onSuccess() {
                            vm.groupOrders = true;
                            vm.showActions = true;
                            loadAll();
                            // $state.go('work-order');
                        }
                        function onError() {}
                    } else {
                        alert("Please select WorkOrder to update group");
                    }
                }
            });
        }

        function selectedWorkOrders(name) {
            if (name == "Update Group Mapping") {
                vm.groupOrders = false;
            } else if (name == "Delete Multiple WorkOrders") {
                vm.groupOrders = true;
                if (vm.selectWorkOrders.length > 0) {
                    var workOrderGroupArray = [];
                    vm.selectWorkOrders.forEach(function (workOrderId) {
                        workOrderGroupArray.push({ id: workOrderId });
                    });
                    WorkOrderBulkDelete.save(
                        workOrderGroupArray,
                        onSuccess,
                        onError
                    );
                    function onSuccess() {
                        vm.groupOrders = true;
                        vm.showActions = true;
                        loadAll();
                        // $state.go('work-order');
                    }
                    function onError(error) {
                        var msg =
                            "WorkOrder deletion failed, WorkOrder has some dependent data";
                        if (error.status == 400) {
                            AlertService.error(msg);
                        } else {
                            AlertService.error(error.data.message);
                        }
                    }
                } else {
                    alert("Please select WorkOrder to delete list");
                }
            }
        }

        function animatedMove(marker, t, current, moveto) {
            var lat = current.lat();
            var lng = current.lng();

            var deltalat = (moveto.lat() - current.lat()) / 100;
            var deltalng = (moveto.lng() - current.lng()) / 100;

            var delay = 10 * t;
            for (var i = 0; i < 100; i++) {
                (function (ind) {
                    setTimeout(function () {
                        var lat = marker.position.lat();
                        var lng = marker.position.lng();
                        lat += deltalat;
                        lng += deltalng;
                        latlng = new google.maps.LatLng(lat, lng);
                        marker.setPosition(latlng);
                    }, delay * ind);
                })(i);
            }
        }

        function drawResponseMap(data) {
            var myOptions = {
                zoom: 10,
                center: new google.maps.LatLng("5.431587", "101.128162"),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            };

            if ($scope.tab == 2 || $scope.tab == 5) {
                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas1"),
                    myOptions
                );
                locations.overlay = new google.maps.OverlayView();
                locations.overlay.draw = function () {};
                locations.overlay.setMap($scope.map);
                locations.element = document.getElementById("map_canvas1");
                drawLatestWorkOrders(data);
            } else if ($scope.tab == 3) {
                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas2"),
                    myOptions
                );
                locations.overlay = new google.maps.OverlayView();
                locations.overlay.draw = function () {};
                locations.overlay.setMap($scope.map);
                locations.element = document.getElementById("map_canvas2");
                drawCasesAndNearByAgents(data);
                WorkOrderLastLocation.getlastlogs(function (response) {
                    var lastLocByAgents = response;
                    var markerIcon = "inactive";
                    var imageUrl = "";
                    switch (markerIcon) {
                        case "inactive":
                            imageUrl = "content/images/marker_inactive.png";
                            break;
                        case "idle":
                            imageUrl = "content/images/marker_idle.png";
                            break;
                        case "active":
                            imageUrl = "content/images/marker_active.png";
                            break;
                        case "active_case":
                            imageUrl = "content/images/marker_active_case.png";
                            break;
                        default:
                            imageUrl = "content/images/green.png";
                            break;
                    }

                    var lastLocVal = [];
                    removeMarkers();
                    for (var i = 0; i < lastLocByAgents.length; i++) {
                        var path = lastLocByAgents[i].path;
                        if (path.length > 1) {
                            var marker = new google.maps.Marker({
                                position: new google.maps.LatLng(
                                    path[0].lat,
                                    path[0].lng
                                ),
                                map: locations.map,
                                icon: {
                                    scaledSize: new google.maps.Size(42, 40),
                                    origin: new google.maps.Point(0, 0),
                                    url: imageUrl,
                                    anchor: new google.maps.Point(16, 16),
                                },
                                label: {
                                    color: "#000000",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    text: lastLocByAgents[i].userName,
                                    backgroundcolor: "#ff9800",
                                },
                                title: lastLocByAgents[i].userName,
                            });
                            var markerContent =
                                '<div class="infoWindowhead">' +
                                name +
                                '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> ' +
                                "<br> <b>Lng :</b> " +
                                "</div>";
                            var infowindow = new google.maps.InfoWindow({
                                content: markerContent,
                            });
                            marker.addListener("click", function () {
                                infowindow.open(locations.map, marker);
                            });

                            animatedMove(
                                marker,
                                50,
                                marker.position,
                                new google.maps.LatLng(path[1].lat, path[1].lng)
                            );
                            nearbyAgentsMarkers.push(marker);
                        }
                    }
                });
            }
        }
        function removeMarkers() {
            for (var i = 0; i < nearbyAgentsMarkers.length; i++) {
                nearbyAgentsMarkers[i].setMap(null);
            }
            nearbyAgentsMarkers = [];
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: $scope.search,
                priority: pagingParams.priority,
            });
        }

        $scope.openInfoWindow = function (e, selectedMarker) {
            e.preventDefault();
            google.maps.event.trigger(selectedMarker, "click");
        };

        function drawLatestWorkOrders(response) {
            if (response) {
                initializeWorkOrderMap(response, function (latlang) {
                    locations.latlng = latlang;
                    // function() {
                    $.each(response, function (index, value) {
                        var infoWindow = new google.maps.InfoWindow();
                        var startMarker = new google.maps.Marker({
                            map: locations.map,
                            position: new google.maps.LatLng(
                                value.pinLat,
                                value.pinLong
                            ),
                            title: value.description,
                        });

                        var infoWindow2 = new google.maps.InfoWindow();
                        if (value.priority == "LOW") {
                            startMarker.setIcon(
                                "content/images/Pointers-L4.png"
                            );
                        } else if (value.priority == "MEDIUM") {
                            startMarker.setIcon(
                                "content/images/Pointers-M4.png"
                            );
                        } else if (value.priority == "HIGH") {
                            startMarker.setIcon(
                                "content/images/Pointers-H4.png"
                            );
                        } else if (value.priority == "CRITICAL") {
                            startMarker.setIcon(
                                "content/images/Pointer-C4.png"
                            );
                        }
                        var span =
                            '<a userId="' +
                            value.geofenceName +
                            '" class="userpath" >Load travelled path</a>';
                        startMarker.content =
                            '<div class="infoWindowContent"> <b>Case Id : </b>' +
                            value.id +
                            "<br> <b>Reported By :</b> " +
                            value.reportedByUser +
                            "<br> <b> Assigned To :</b> " +
                            value.assignedToUser +
                            "<br> <b>Address :</b> " +
                            value.address +
                            "</div>";
                        $compile(startMarker.content)($scope);
                        google.maps.event.addListener(
                            startMarker,
                            "mouseover",
                            function () {
                                infoWindow.setContent(
                                    '<div class="infoWindowhead">' +
                                        startMarker.title +
                                        "</div>" +
                                        startMarker.content
                                );
                                infoWindow.open(locations.map, startMarker);
                            }
                        );

                        google.maps.event.addListener(
                            startMarker,
                            "mouseout",
                            function () {
                                infoWindow.close();
                            }
                        );
                    });
                });
            }
        }

        function initializeWorkOrderMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                latlng = new google.maps.LatLng(
                    latlangs[0].pinLat,
                    latlangs[0].pinLong
                );
                callback(latlng);
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            );
                            callback(latlng);
                        },
                        function () {
                            callback(
                                new google.maps.LatLng("5.431587", "101.128162")
                            );
                        }
                    );
                } else {
                    callback(new google.maps.LatLng("5.431587", "101.128162"));
                }
            }
        }

        function drawCasesAndNearByAgents(response) {
            if (response) {
                initializeMap(response, function (latlang) {
                    locations.latlng = latlang;
                    // setupMap(function() {
                    $.each(response, function (index, value) {
                        var infoWindow = new google.maps.InfoWindow();
                        var startMarker = new google.maps.Marker({
                            map: locations.map,
                            position: new google.maps.LatLng(
                                value.pinLat,
                                value.pinLong
                            ),
                            title: value.description,
                        });

                        var infoWindow2 = new google.maps.InfoWindow();

                        if (value.priority == "LOW") {
                            startMarker.setIcon(
                                "content/images/Pointers-L4.png"
                            );
                        } else if (value.priority == "MEDIUM") {
                            startMarker.setIcon(
                                "content/images/Pointers-M4.png"
                            );
                        } else if (value.priority == "HIGH") {
                            startMarker.setIcon(
                                "content/images/Pointers-H4.png"
                            );
                        } else if (value.priority == "CRITICAL") {
                            startMarker.setIcon(
                                "content/images/Pointer-C4.png"
                            );
                        }
                        var span =
                            '<a userId="' +
                            value.geofenceName +
                            '" class="userpath" >Load travelled path</a>';
                        startMarker.content =
                            '<div class="infoWindowContent"><b>Case Id : </b>' +
                            value.id +
                            "<br> <b>Reported By :</b> " +
                            value.reportedByUser +
                            "<br> <b> Assigned To :</b> " +
                            value.assignedToUser +
                            "<br> <b>Address :</b> " +
                            value.address +
                            "</div>";

                        $compile(startMarker.content)($scope);
                        google.maps.event.addListener(
                            startMarker,
                            "mouseover",
                            function () {
                                infoWindow.setContent(
                                    '<div class="infoWindowhead">' +
                                        startMarker.title +
                                        "</div>" +
                                        startMarker.content
                                );
                                infoWindow.open(locations.map, startMarker);
                            }
                        );

                        // on mouseout
                        google.maps.event.addListener(
                            startMarker,
                            "mouseout",
                            function () {
                                infoWindow.close();
                            }
                        );

                        resizeMap();
                    });
                    // });
                });
            }
        }

        $scope.workOrderLocation = function (selectedCase) {
            $("#workOrderLocationModal").show();
            var myCenter = new google.maps.LatLng(
                selectedCase.pinLat,
                selectedCase.pinLong
            );
            var ele = document.getElementById("workOrderLocationModal");
            $(ele).modal("show");

            var map = new google.maps.Map(
                document.getElementById("map_canvas_workorder_location"),
                {
                    center: myCenter,
                    zoom: 12,
                }
            );
            selectedCase.workOrderCases.forEach(function (caseVal) {
                TrCaseById.get(caseVal.id, function (caseDetails) {
                    caseDetails.caseAssets.forEach(function (assetVal) {
                        if (assetVal.assetType.layout == "SPREAD") {
                            AssetByID.get(assetVal.id, function (assetVal1) {
                                var spreadAssetCoordinates = [];

                                assetVal1.assetCoordinates.forEach(function (
                                    loc
                                ) {
                                    spreadAssetCoordinates.push({
                                        lat: loc.latitude,
                                        lng: loc.longitude,
                                    });
                                });
                                var polylineColor = "#000000";
                                var polylinepath = new google.maps.Polyline({
                                    path: spreadAssetCoordinates,
                                    geodesic: true,
                                    strokeColor: polylineColor,
                                    strokeOpacity: 1.0,
                                    strokeWeight: 2.5,
                                    fillColor: polylineColor,
                                    fillOpacity: 0.3,
                                    editable: false,
                                });

                                polylinepath.setMap(map);

                                var pos = {
                                    lat: assetVal1.assetCoordinates[0].latitude,
                                    lng:
                                        assetVal1.assetCoordinates[0].longitude,
                                };
                                map.setCenter(pos);

                                var midLatLng = {
                                    lat:
                                        spreadAssetCoordinates[
                                            spreadAssetCoordinates.length - 1
                                        ].lat,
                                    lng:
                                        spreadAssetCoordinates[
                                            spreadAssetCoordinates.length - 1
                                        ].lng,
                                };
                                var infowindow = new google.maps.InfoWindow({
                                    content:
                                        '<div class="infoWindowhead">' +
                                        "name" +
                                        '<div class="infoWindowContent"> <b>Name :</b> ' +
                                        assetVal1.name +
                                        "</div></div>",
                                });

                                infowindow.setPosition(midLatLng);
                                google.maps.event.addListener(
                                    polylinepath,
                                    "mouseout",
                                    function (event) {
                                        infowindow.close();
                                    }
                                );

                                google.maps.event.addListener(
                                    polylinepath,
                                    "mouseover",
                                    function (event) {
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                        }
                                        infowindow.setContent(
                                            '<div class="infoWindowhead">' +
                                                "<b></b>" +
                                                assetVal1.name +
                                                "</br></div>"
                                        );

                                        infowindow.mousemove;
                                        infowindow.open(map);
                                        infowindow.setPosition(event.latLng);
                                        openedInfoWindow = infowindow;
                                    }
                                );
                            });
                        } else if (assetVal.assetType.layout == "FIXED") {
                            AssetByID.get(assetVal.id, function (assetVal1) {
                                createMarker(
                                    assetVal1.assetCoordinates[0],
                                    map
                                );
                                var pos = {
                                    lat: assetVal1.assetCoordinates[0].latitude,
                                    lng:
                                        assetVal1.assetCoordinates[0].longitude,
                                };
                                map.setCenter(pos);
                            });
                        }
                    });
                });
            });

            $("#workOrderLocationModal").on("show.bs.modal", function (event) {
                $("#location-map-workorder-location").css("width", "100%");
                $("#map_canvas_workorder_location").css("width", "100%");
            });
        };

        function createMarker(latlngs, map) {
            try {
                var pos = {
                    lat: latlngs.latitude,
                    lng: latlngs.longitude,
                };

                var marker = new google.maps.Marker({
                    position: pos,
                    map: map,
                    icon:
                        "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                });
            } catch (err) {}
        }

        function resizeMap() {
            setTimeout(function () {
                google.maps.event.trigger(locations.map, "resize");
                locations.map.setCenter(locations.latlng);
            }, 1000);
        }

        function initializeMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                latlng = new google.maps.LatLng(
                    latlangs[0].pinLat,
                    latlangs[0].pinLong
                );
                callback(latlng);
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            );
                            callback(latlng);
                        },
                        function () {
                            callback(
                                new google.maps.LatLng("5.431587", "101.128162")
                            );
                        }
                    );
                } else {
                    callback(new google.maps.LatLng("5.431587", "101.128162"));
                }
            }
        }

        $(".select2").select2();

        vm.success = null;
        vm.error = null;
    }
})();
