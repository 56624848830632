(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("JKDetailController", JKDetailController);

        JKDetailController.$inject = ['entity','getJkDetails','$state','getCableDetails','$scope','getTubeDetails','getSpreadAssetId','getCableName','UserAccount','$timeout','postCoreJoiningkit','getSpliceMap'
       ,'postCoreSplicing','getCoreDetails','postCoreFwdBwdPath','coreTracker','AssetByID'];
    
    function JKDetailController(entity,getJkDetails,$state,getCableDetails,$scope,getTubeDetails,getSpreadAssetId,getCableName,UserAccount,$timeout,postCoreJoiningkit,getSpliceMap
        ,postCoreSplicing,getCoreDetails,postCoreFwdBwdPath,coreTracker,AssetByID) {
        var vm = this
        vm.assetDetails = entity
        vm.noOfCables = ["01","02", "03","04","05","06"];
        vm.jkCableType = ["96F", "48F", "24F", "12F"];
        var resultObject = {}
        vm.items = []
        var map,storeJk,cableLength
        var infoWindow = new google.maps.InfoWindow();
        getJkDetails.get(entity.id,function(jkResponse){
            vm.items = jkResponse
        }) 
        
        UserAccount.get(function (data) {
            vm.tenantId = data.id; })

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    } /* 
              {
                  elementType: "geometry.fill",
                  stylers: [
                      {
                          color: "#EFEFEF",
                      },
                  ],
              }, */,
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    },
                ],
            });
        }

        vm.toggleAccordion = function(item) {
            vm.cableResponse = null  
            vm.spliceResponse = null
            vm.showSplicing = false
            vm.isEnableAutoFill = false
            $scope.expandedIndexTube = -1;
            storeJk = item
            if (item.active) {
                item.active = false;
            } else {
                getCableDetails.get(item.name,function(cableResponse){ 
                    vm.cableResponse = cableResponse

                    var intervalId = setInterval(function(){
                    coreTracker.get(entity.id,function(response){
                        var filter = response.find(function (element){
                            return element.coreAssetName == storeJk.name
                        })
    
                      if(filter){
                        if(filter.messageStatus == "INPROGRESS"){
                            vm.isShowInProgress = true
                            getSpliceMap.get(item,function(splice){
                                vm.spliceResponse = splice
                            })
                        }else if(filter.messageStatus == "DONE"){
                            clearInterval(intervalId);
                            vm.isShowInProgress = false
                            getSpliceMap.get(item,function(splice){
                                vm.spliceResponse = splice
                            })
                        }
                      }else{
                        getSpliceMap.get(item,function(splice){
                            vm.spliceResponse = splice
                            if(cableResponse && splice[0].coreTuplelist == null){
                                vm.isEnableAutoFill = true
                            }
                        })
                      }
                    },function (err){
                        getSpliceMap.get(item,function(splice){
                            vm.spliceResponse = splice
                            if(cableResponse && splice[0].coreTuplelist == null){
                                vm.isEnableAutoFill = true
                            }
                        })
                    }) })
                })
                
                // Close all other items
                vm.items.forEach(function (otherItem) {
                    otherItem.active = false;
                });
                item.active = true;
            }
        };

        vm.splicingMapGetData = function (name){
            //var sendData = JSON.parse(vm.selectedJKName)
            var url = $state.href('asset.splicing',{id: entity.id,data: name})
            window.open(url, '_blank');
        }

        $scope.expandedIndexTube = -1;
        vm.callTube = function(name,index){
            
            if ($scope.expandedIndexTube === index) {
                $scope.expandedIndexTube = -1;// Collapse if already expanded
            } else {
                $scope.expandedIndexTube = index;// Expand the clicked row
                getTubeDetails.get(name,function(tubeResponse){ 
                    vm.tubeResponse = tubeResponse
                })
            }
        }

        vm.addJoiningKitDetails = function () {
            vm.enteredJoiningKitName = entity.id + "_JK01" 
            if(vm.items){
                vm.enteredJoiningKitName = entity.id + "_JK0" + (vm.items.length + 1)
            }

            vm.jkCableName = []
            vm.jkSuccess = false
            getSpreadAssetId.get(entity.id,function(response){
                if(response.length >= 1){
                    response.forEach(function(item){
                        getCableName.get(item.id,function(cableDetails){
                            if(cableDetails.length >=1){
                                cableDetails.forEach(function(cableName){
                                    vm.jkCableName.push({"name":cableName.name,"type":cableName.type})
                                })
                            }
                        })
                    })
                  }
            })

            $("#addJoiningKitDetails").show();
            $scope.joiningKitData = {
                tenantId: vm.tenantId,
                fixedAssetId: entity.id,
                assetCoordinate: {
                    latitude: entity.assetCoordinates[0].latitude,
                    longitude: entity.assetCoordinates[0].longitude,
                },
                listOfCables: [], // Initialize listOfTubes as an empty array
            };
        };

        vm.addCable = function () {
            $scope.joiningKitData.listOfCables = []
            for(var i=1;i<=vm.selectedCable;i++){
                var newCable = {
                    name: "",
                    type: "",
                    tenantId: vm.tenantId,
                };
                $scope.joiningKitData.listOfCables.push(newCable);
            } 
        };

        vm.saveJoiningKitDetails = function () {
            $scope.joiningKitData.noOfCablesConnected = vm.selectedCable;
            $scope.joiningKitData.name = vm.enteredJoiningKitName;
            postCoreJoiningkit.get($scope.joiningKitData, function () {
                vm.jkSuccess = true;
                $timeout(function () {
                    vm.items = null
                    getJkDetails.get(entity.id,function(jkResponse){
                    vm.items = jkResponse
                }) 
                    vm.closeModal("addJoiningKitDetails");
                }, 3000),function(){
                    vm.closeModal("addJoiningKitDetails");
                };
            });
        };

        vm.closeModal = function(id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide(); 
            if (id == "addJoiningKitDetails") {
                $scope.joiningKitData = [];
                vm.enteredJoiningKitName = null;
                vm.selectedCable = null;
            }

            if(vm.editDetails){
				if(id == 'splicingDetails'){
					vm.splicingDetails.splice(vm.selectedSlicing,1)
				}
			}
        }

        $scope.addDetails = function (id) {
            vm.cableSide = []
            vm.tubeASide = []
            vm.coreASide = []
            vm.tubeBSide = []
            vm.coreBSide = []
            vm.editDetails = true
                switch (id) {
                    case "addAuto":
                        vm.spliceSuccess = false
                        vm.splicingDetails = []
                        vm.selectedAutoAside = null;
                        vm.selectedAutoBside = null;
                        vm.selectedCableASide = [];
                        vm.selectedCableBSide = [];

                        vm.isShowAuto = false
            vm.cableSideAuto = []
            
            var index = 0, temp = 0
           getCableDetails.get(storeJk.name,function(cableResponse){
                cableResponse.forEach(function (cable) {
                    var coreNames = [];
                    vm.cableSideAuto.push(cable.name)
                    getTubeDetails.get(cable.name,function (tubeResponse) {
                        tubeResponse.forEach(function (tube) {
                        temp += tube.noOfCores
                        getCoreDetails.get(tube.name,function (coreResponse) {
                            coreNames.push({ name: '<strong>' + tube.name + '</strong>', msGroup: true, order: tube.order });
                        coreResponse.forEach(function (cores) {
                            index++
                            coreNames.push({name:cores.name,selected:false,disabled:false,order: tube.order,cableName:cable.name});
                        });
                        coreNames.push({ msGroup: false,order: tube.order });
                        resultObject[cable.name] = coreNames;
                        if(index == temp){
                            vm.isShowAuto = true
                            sortArraysByOrder(resultObject);
                            $("#addAuto").show();
                        }
                        });
                    });
                    });
                    
                });
                
            }) 
            
                        break;

                    case "addManual":
                        sortArraysByOrder(resultObject);
                        $("#addManual").show();
                        break;

                    default:
                        console.log("");
                }
        };

        $scope.statuslabel = {
            search          : "Search",
            nothingSelected : "Select",
            selectAll       : "All",
            selectNone      : "None", 
            Done : "Done" 
        }

        /* vm.onSelectADone = function (){
            //updateResult(vm.selectedAutoAside, vm.selectedSpliceA);
        }

        vm.onSelectBDone = function (){
            //updateResult(vm.selectedAutoBside, vm.selectedSpliceB);
        } */

        vm.onChangeASide = function(){
            if(vm.selectedCableASide){
                vm.selectedCableASide = []
            }
            
            if (resultObject[vm.selectedAutoAside]) {
                // Filter and retrieve the elements of the selectedValue
                vm.selectedCableASide = resultObject[vm.selectedAutoAside];
            }
        }

        vm.onChangeBSide = function(){
            if(vm.selectedCableBSide){
                vm.selectedCableBSide = []
            }
            if (resultObject[vm.selectedAutoBside]) {
            // Filter and retrieve the elements of the selectedValue
            vm.selectedCableBSide = resultObject[vm.selectedAutoBside];
        } 
        }

        function updateResult(selectedValue, output,action) {
            if (resultObject[selectedValue]) {

                if(action == "disable"){
                    resultObject[selectedValue].forEach(function(itemInResult) {
                        var matchingOutput
                        if(typeof output == 'string'){
                            matchingOutput = (output === itemInResult.name)
                        }else{
                            matchingOutput = output.find(function(itemInOutput) {return itemInOutput.name === itemInResult.name} );
                        }
                        
                        if (matchingOutput) {
                            itemInResult.disabled = matchingOutput.selected;
                        }
                    });
                }else if(action == "remove"){
                    resultObject[selectedValue].forEach(function(itemInResult) {
                        var matchingOutput = (output === itemInResult.name)
                        if (matchingOutput) {
                            itemInResult.disabled = false;
                            itemInResult.selected = false
                        }
                    });
                }
            } 
        }

        function sortArraysByOrder(data) {
            //to sort dropdown options order since its straight splice
            for (var key in data) {
              if (data.hasOwnProperty(key) && Array.isArray(data[key])) {
                data[key].sort(function(a, b) {
                  return a.order - b.order;
                });
              }
            }
          }

          vm.saveDetails = function(id) {
            switch (id) {
                case "splicingDetails":
                    if (vm.splicingDetails[vm.selectedSlicing] == null) {
                        vm.splicingDetails.splice(vm.selectedSlicing, 1);
                    }
                    break;

                        case "addAuto":
                           /*  if (vm.selectedSpliceA.length === vm.selectedSpliceB.length) { 
                                updateResult(vm.selectedAutoAside, vm.selectedSpliceA,"disable");
                                updateResult(vm.selectedAutoBside, vm.selectedSpliceB,"disable");
                                vm.splicingDetails = []
                                for(var i = 0 ;i < vm.selectedSpliceA.length; i++){ 
                                    if(vm.selectedSpliceA[i].selected && vm.selectedSpliceB[i].selected){
                                        vm.splicingDetails.push({ "fromFiber" :{
                                            "coreASide": vm.selectedSpliceA[i].name,
                                            "cable": vm.selectedSpliceA[i].cableName
                                        },"toFiber" :{
                                            "coreBSide": vm.selectedSpliceB[i].name,
                                            "cable": vm.selectedSpliceB[i].cableName
                                        },"order":i+1})
                                    }
                                }
                            }else{
                                alert("Cable A Side and Cable B Side Splice Not Equal!!!")
                            } */
                            updateResult(vm.selectedAutoAside, vm.selectedSpliceA,"disable");
                                updateResult(vm.selectedAutoBside, vm.selectedSpliceB,"disable");
                                vm.splicingDetails = []
                                for(var i = 0 ;i < vm.selectedSpliceA.length; i++){ 
                                    if(vm.selectedSpliceA[i].selected && vm.selectedSpliceB[i].selected){
                                        vm.splicingDetails.push({ "fromFiber" :{
                                            "coreASide": vm.selectedSpliceA[i].name,
                                            "cable": vm.selectedSpliceA[i].cableName
                                        },"toFiber" :{
                                            "coreBSide": vm.selectedSpliceB[i].name,
                                            "cable": vm.selectedSpliceB[i].cableName
                                        },"order":i+1})
                                    }
                                }
                            break;

                    case "editSplicingDetails": 
                    
                    if (vm.selectedSpliceAEdit.length !=0 && vm.selectedSpliceBEdit.length != 0) {  
                        vm.splicingDetails.splice(vm.indexOfSplicing,1,{ "fromFiber" :{
                            "coreASide": vm.selectedSpliceAEdit[0].name,
                            "cable": vm.selectedSpliceAEdit[0].cableName
                        },"toFiber" :{
                            "coreBSide": vm.selectedSpliceBEdit[0].name,
                            "cable": vm.selectedSpliceBEdit[0].cableName
                        },"order":vm.indexOfSplicing+1})
                        updateResult(vm.selectedSpliceAEdit[0].cableName, vm.selectedSpliceAEdit,"disable");
                        updateResult(vm.selectedSpliceBEdit[0].cableName, vm.selectedSpliceBEdit,"disable");
                        $("#" + id).removeClass("in");
                        $(".modal-backdrop").remove();
                        $("#" + id).hide();
                    }else{
                        updateResult(vm.cableFrom, vm.listFrom,"disable");
                        updateResult(vm.cableTo, vm.listTo,"disable");
                        $("#" + id).removeClass("in");
                        $(".modal-backdrop").remove();
                        $("#" + id).hide();
                    }
                        
                    
                    break;

                    case "addManual": 
                    if (vm.selectedSpliceAManual.length === vm.selectedSpliceBManual.length) { 
                        updateResult(vm.selectedAutoAside, vm.selectedSpliceAManual,"disable");
                        updateResult(vm.selectedAutoBside, vm.selectedSpliceBManual,"disable");
                                vm.splicingDetails.push({ "fromFiber" :{
                                    "coreASide": vm.selectedSpliceAManual[0].name,
                                    "cable": vm.selectedSpliceAManual[0].cableName
                                },"toFiber" :{
                                    "coreBSide": vm.selectedSpliceBManual[0].name,
                                    "cable": vm.selectedSpliceBManual[0].cableName
                                },"order":vm.splicingDetails.length})
                            
                        $("#" + id).removeClass("in");
                        $(".modal-backdrop").remove();
                        $("#" + id).hide();
                    }else
                    {
                        alert("Cable A Side and Cable B Side Splice Not Equal!!!")
                    }
                    break;
                default:
                    console.log("");
            }
            
           
        }

        vm.saveSplicingDetails = function(id){
            var saveSplice = {
                "tenantId": vm.tenantId,
                "joiningKit": {
                  "name": storeJk.name,
                  "fixedAssetId":entity.id,
                  "tenantId": vm.tenantId
                },
                coreTuplelist :[]}

            // Loop through the input array 'a'
            for (var i = 0; i < vm.splicingDetails.length; i++) {
                saveSplice.coreTuplelist.push({
                    "coreASide": {
                      "name":  (vm.splicingDetails[i].hasOwnProperty('fromFiber') && vm.splicingDetails[i].fromFiber.hasOwnProperty('coreASide'))? vm.splicingDetails[i].fromFiber.coreASide : '',
                      "tenantId": vm.tenantId,
                      "order" : vm.splicingDetails[i].order
                    },
                    "coreBSide": {
                      "name":  (vm.splicingDetails[i].hasOwnProperty('toFiber') && vm.splicingDetails[i].toFiber.hasOwnProperty('coreBSide')) ? vm.splicingDetails[i].toFiber.coreBSide : '',
                      "tenantId": vm.tenantId,
                      "order" : vm.splicingDetails[i].order
                    } })
            }
            postCoreSplicing.get(saveSplice,function(){
                vm.spliceSuccess = true;
                vm.isEnableAutoFill = false;
                vm.isShowInProgress = true;
                $timeout(function () {
                    var intervalId = setInterval(function(){
                        coreTracker.get(entity.id,function(response){
                            var filter = response.find(function (element){
                                return element.coreAssetName == storeJk.name
                            })
        
                          if(filter){
                            if(filter.messageStatus == "INPROGRESS"){
                                vm.isShowInProgress = true
                                getSpliceMap.get(storeJk,function(splice){
                                    vm.spliceResponse = splice
                                })
                            }else if(filter.messageStatus == "DONE"){
                                clearInterval(intervalId);
                                vm.isShowInProgress = false
                                getSpliceMap.get(storeJk,function(splice){
                                    vm.spliceResponse = splice
                                })
                            }
                          }else{
                            getSpliceMap.get(storeJk,function(splice){
                                vm.spliceResponse = splice
                            })
                          }
                        },function (err){ })
                    }, 5000);
                    

                    $("#" + id).removeClass("in");
                    $(".modal-backdrop").remove();
                    $("#" + id).hide();
                }, 2000);
            })
        }

        $scope.editDetails = function (list,id, name) {
			vm.editDetails = false
			//vm.new = JSON.parse(JSON.stringify(vm.trCase));
            switch (name) {
                /* case "splicingDetails":
                    vm.selectedSlicing = id;
                    $("#splicingDetails").show();
                    break; */
                    case "splicingDetails":
                        vm.selectedSpliceAEdit  = []
                        vm.selectedSpliceBEdit =[]
                        vm.indexOfSplicing = id
                        vm.listFrom = list.fromFiber.coreASide
                        vm.listTo = list.toFiber.coreBSide
                        vm.cableFrom = list.fromFiber.cable
                        vm.cableTo = list.toFiber.cable
                        updateResult(vm.cableFrom,vm.listFrom ,"remove")
                        updateResult(vm.cableTo,vm.listTo ,"remove")
                        $("#editSplicingDetails").show();
                    break;
                default:
                    console.log("");
            }
        };

        $scope.removeDetails = function (list,id, name) {
            switch (name) {
                case "splicingDetails":

                    updateResult(list.fromFiber.cable, list.fromFiber.coreASide,"remove")
                    updateResult(list.toFiber.cable, list.toFiber.coreBSide,"remove")
                    vm.splicingDetails.splice(id, 1);
                   
                    break;
                default:
                    console.log("");
            }
        };

        $scope.toggle = function (id) {
            switch (id) {
                case "splicing":
                    vm.showSplicing = !vm.showSplicing;
                    break;

                default:
                    console.log("");
            }
        };

        vm.getPath = function (str,arr){
            vm.cableLength = 0
            vm.cablePathMap = null
            vm.jkPathMap = null
            vm.displayFiberPathName = arr.name
            vm.stringPath = str

            map = new google.maps.Map(
                document.getElementById("map"),
                myOption(12, {
                    lat: 12.98330744645683,
                    lng: 77.56018512933986,
                })
            );
            $("#showPathModal").show(); 

            if (arr  != null) {
                if(str == 'Backward'){
                    postCoreFwdBwdPath.getJkBwd(
                        arr,
                        function (response) {
                            vm.jkPathMap = response;
                            if(vm.jkPathMap != null && vm.jkPathMap.length >= 1){
                                postCoreFwdBwdPath.getCableBwd(
                                    arr,
                                    function (response) {
                                        vm.cablePathMap = response;
                                        if(vm.cablePathMap != null && vm.cablePathMap.length >= 1){
                                            showPathMap();
                                        }
                                        
                                    }
                                );
                            }else{
                                alert("*No Backward Path Available")
                            }
                            
                        }
                    );
                }

                if(str == 'Forward'){
                    postCoreFwdBwdPath.getJkFwd(
                        arr,
                        function (response) {
                            vm.jkPathMap = response;
                            if(vm.jkPathMap != null && vm.jkPathMap.length >=1){
                                postCoreFwdBwdPath.getCableFwd(
                                    arr,
                                    function (response) {
                                        vm.cablePathMap = response;
                                        if(vm.cablePathMap != null && vm.cablePathMap.length >= 1){
                                            showPathMap();
                                        }
                                    }
                                );
                            }else{
                                alert("*No Forward Path Available")
                            }
                        }
                    );
                }
                
                
            }
        }

        function showPathMap() {
            cableLength = 0
            vm.cableLength = 0
            map = new google.maps.Map(
                document.getElementById("map"),
                myOption(12, {
                    lat: vm.jkPathMap[0].assetCoordinate.latitude,
                    lng: vm.jkPathMap[0].assetCoordinate.longitude,
                })
            );

            var cableCoordinates = [];
            vm.cablePathMap.forEach(function (each){
                cableCoordinates = [];
                cableLength  += each.length;
                var coord = each.assetCoordinates;
                coord.forEach(function (item) {
                    cableCoordinates.push({
                        lat: item.latitude,
                        lng: item.longitude,
                    });
                });

                var cablePath = new google.maps.Polyline({
                    path: cableCoordinates,
                    strokeColor: "#fd7a24",
                    strokeOpacity: 1,
                    strokeWeight: 5,
                });
    
                cablePath.setMap(map);

                cablePath.addListener('click', function (event) {
                    closeInfoWindows();
                    AssetByID.get(each.spreadAssetId, function (response) { 
                        infoWindow.setContent('<div class="infoWindowhead"> <b> Cable  Name : ' +
                        each.name +
                    ' </b></div><div class="infoWindowContent">' +
                    "<b>Asset Name : </b>" + response.name + "<br><b>Asset Type : </b>"+
                    response.assetTypeName + "</div>");
                    infoWindow.setPosition(event.latLng);
                    infoWindow.open(map, cablePath);
                    }) 
                });
            }) 
            if(cableLength >= 1){
                vm.cableLength = cableLength.toFixed(2)
            }
            
            vm.jkPathMap.forEach(function (each){
                var coord = each;
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(
                        coord.assetCoordinate.latitude,
                        coord.assetCoordinate.longitude
                    ),
                    icon: {
                        url: "content/images/Chamber.png",
                        scaledSize: new google.maps.Size(24, 24),
                        anchor: new google.maps.Point(
                            24 / 2,
                            24 / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                    map: map,
                });

                marker.addListener('click', function () {
                    closeInfoWindows();
                    AssetByID.get(each.fixedAssetId, function (response) {
                        infoWindow.setContent('<div class="infoWindowhead"> <b> JK  Name : ' +
                        each.name +
                    ' </b></div><div class="infoWindowContent">' +
                    "<b>Asset Name : </b>" + response.name + "<br><b>Asset Type : </b>"+
                    response.assetTypeName + "<br><br><b>Latitude</b> :" +
                    each.assetCoordinate.latitude +
                    "<br><b>Longitude</b> :" +
                    each.assetCoordinate.longitude +
                    "<br></div>");
                    infoWindow.open(map, marker);
                    })
                });
            })
        }

        // Function to close all info windows
  function closeInfoWindows() {
    infoWindow.close();
  }
          
    }
})();