(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("EntityDumpController", EntityDumpController);

    EntityDumpController.$inject = [
        "Report",
        "myservice"
    ];

    function EntityDumpController(
        Report,
        myservice
    ) {
        var vm = this;
        vm.refresh = refresh
        vm.highlightRow = false

        if(myservice.getValue() == 'true'){
            vm.highlightRow = true;
        }
       
        Report.getListEntity(function (response){
            vm.displayList = response
        })

        function refresh(){
            Report.getListEntity(function (response){
                vm.displayList = response
            })
        }
    }
})();
