(function () {
    'use strict';

    angular.module('trakeyeApp').controller('UserManagementDialogController', UserManagementDialogController);

    UserManagementDialogController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', 'entity', 'User', 'JhiLanguageService', 'UserGroupIDs', 'Geofence', 'TrakeyeType', 'AllTrakeyeTypes', 'GeofenceMasterMiniIDs',
        'UserIDs', 'Principal', 'ReportingUsers', 'Vendors','Configs','UserAccount'
    ];

    function UserManagementDialogController($rootScope, $scope, $stateParams, $state, entity, User, JhiLanguageService, UserGroupIDs, Geofence, TrakeyeType, AllTrakeyeTypes, GeofenceMasterMiniIDs, UserIDs, Principal, ReportingUsers, Vendors, Configs,UserAccount) {
        $scope.EMAIL_REGEXP = /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
        $scope.PHONE_REGEXP = /^[0-9]+$/;
        $scope.IMEI_REGEXP = /^[0-9]+$/;
        $scope.IMAGE_EXT_REGEXP = /^(.*?)\.(jpg||jpeg||png)$/;
        var vm = this;

        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        vm.decodeImage = decodeImage;
        $scope.hasDeviceInfo = false;

        if(vm.user.hasDeviceInfo){
            $scope.hasDeviceInfo = true;
        }
        /* var userAdminAuthorities = ['ROLE_USER_ADMIN', 'ROLE_USER', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER',
            'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_VENDOR'
        ];
        var hodAuthorities = ['ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'];
        var cityManagerAuthorities = ['ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'];
        var assistantManagerAuthorities = ['ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'];
        var teamLeaderAuthorities = ['ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'];  */

        vm.navigateGeofence = navigateGeofence
        vm.navigateGroupfromUserMng = navigateGroupfromUserMng;
        vm.navigateFiberifyTypefromGroup = navigateFiberifyTypefromGroup;

        $scope.allimages = false;
        $(":file").filestyle({
            buttonBefore: true
        });
        $(":file").filestyle('buttonText', 'Browse File');

        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;

        };

        $("#userImage").change(function () {
            var filesSelected = document.getElementById("userImage").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();

                fileReader.onload = function (fileLoadedEvent) {
                    var test = window.btoa(fileLoadedEvent.target.result);
                    vm.user.userImage = test;
                }
                fileReader.readAsBinaryString(filesSelected[i]);
            }

        });

        var entity_old = angular.copy(vm.user)
        Principal.identity().then(function (identity) {
            /*
             * if(identity.authorities &&
             * identity.authorities.indexOf('ROLE_SUPER_ADMIN') !==- 1){
             * vm.authorities = ['ROLE_USER_ADMIN'];
             * 
             * }else if(identity.authorities &&
             * identity.authorities.indexOf('ROLE_USER_ADMIN') !==- 1){
             */
            /* vm.authorities = [
                //'ROLE_USER', 
                'ROLE_USER_ADMIN',
                //'ROLE_VENDOR_ADMIN',
                'ROLE_USER',
                'ROLE_CITY_MANAGER',
                'ROLE_CITY_PLANNER',
                'ROLE_ASSISTANT_MANAGER',
                'ROLE_TEAM_LEADER',
                'ROLE_NETWORK_ENGINEER',
                'ROLE_FRT',
                'ROLE_PATROLLER'
            ]; */

            /* if ($rootScope.has_ROLE_USER_ADMIN) {
                vm.authorities = userAdminAuthorities;
            } else if ($rootScope.has_ROLE_USER) {
                vm.authorities = hodAuthorities;
            } else if ($rootScope.has_ROLE_CITY_MANAGER || $rootScope.has_ROLE_CITY_PLANNER) {
                vm.authorities = cityManagerAuthorities;
            } else if ($rootScope.has_ROLE_ASSISTANT_MANAGER) {
                vm.authorities = assistantManagerAuthorities;
            } else if ($rootScope.has_ROLE_TEAM_LEADER) {
                vm.authorities = teamLeaderAuthorities;
            } */

            Configs.get("Roles",function(response){
                vm.authorities = response
            })


            vm.selectattributes = selectedTrakeyeTypeAttributes;
            vm.geofences = loadAllGeofences(); // Geofence.query();
            vm.trakeyeTypes = loadAllTrakeyeTypes(); // TrakeyeType.query();

            // }
            if (vm.user.id == null) {
                vm.user.langKey = 'en';
                // vm.user.authorities=vm.authorities;
                vm.user.fromTime = 8;
                vm.user.toTime = 20;

            }

        });

        function loadAllTrakeyeTypes() {
            AllTrakeyeTypes.getAll(function (response) {
                vm.trakeyeTypes = response;
            });

        }

        function loadAllGeofences() {

            vm.multiGeofence = [];
            vm.multiGroup = [];
            var selectedGeofence = [];
            var selectedGroup = [];
            vm.multiUser = [];
            vm.multiVendor = [];
            var selectedUsers = [];
            var selectedVendors = [];

            if (vm.user.id != null) {
                vm.user.geofences.forEach(function (geofence) {
                    selectedGeofence.push(geofence);
                });

                vm.user.groups.forEach(function (group) {
                    selectedGroup.push(group.id);
                });

                vm.user.ownedBy.forEach(function (user) {
                    selectedUsers.push(user.id);
                });

                if (vm.user.vendors != null) {
                    vm.user.vendors.forEach(function (user) {
                        selectedVendors.push(user.id);
                    });
                }

                if (selectedUsers.length === 0) {
                    selectedUsers.push(vm.user.id);
                }

            } else {

                Principal.identity().then(function (identity) {
                    selectedUsers.push(identity.id);
                });
            }

            GeofenceMasterMiniIDs.getAll(function (response) {
                response.forEach(function (geofence) {
                    // var zone = "MINI";

                    // if (geofence.zone == true) {
                    //     zone = "MASTER";
                    // }

                    if (selectedGeofence.indexOf(geofence.id) !== -1) {
                        vm.multiGeofence.push({ name: geofence.name, id: geofence.id, nameZone: geofence.name + " - " + geofence.geofenceType, ticked: true });
                    } else {
                        vm.multiGeofence.push({ name: geofence.name, id: geofence.id, nameZone: geofence.name + " - " + geofence.geofenceType, ticked: false });
                    }
                });
            });

            UserGroupIDs.getAll(function (response) {
                response.forEach(function (group) {
                    if (selectedGroup.indexOf(group.id) !== -1) {
                        vm.multiGroup.push({ name: group.name, id: group.id, ticked: true });
                    } else {
                        vm.multiGroup.push({ name: group.name, id: group.id, ticked: false });
                    }
                });

                if (selectedGroup.length === 0) {
                    var tempGroups = [];
                    vm.multiGroup.forEach(function (groupVal) {
                        if (groupVal.name === "Default Group") {
                            groupVal.ticked = true;
                        }
                        tempGroups.push(groupVal);
                    });
                    vm.multiGroup = tempGroups;
                }
            });

            // UserIDs.getAdminAll(function(response) {
            //     response.forEach(function(user) {
            //         if (selectedUsers.indexOf(user.id) !== -1) {
            //             vm.multiUser.push({ login: user.login, id: user.id, ticked: true });
            //         } else {
            //             vm.multiUser.push({ login: user.login, id: user.id, ticked: false });
            //         }
            //     });
            // });
            ReportingUsers.getAll(function (response) {
                response.forEach(function (user) {
                    if (selectedUsers.indexOf(user.id) !== -1) {
                        vm.multiUser.push({ login: user.login, id: user.id, ticked: true });
                    } else {
                        vm.multiUser.push({ login: user.login, id: user.id, ticked: false });
                    }
                });
            });

            Vendors.getAll(function (response) {
                response.forEach(function (user) {
                    if (selectedVendors.indexOf(user.id) !== -1) {
                        vm.multiVendor.push({ login: user.login, id: user.id, ticked: true });
                    } else {
                        vm.multiVendor.push({ login: user.login, id: user.id, ticked: false });
                    }
                });
            });
        }

        function selectedTrakeyeTypeAttributes() {
            if (vm.user.trakeyeType) {
                if (entity_old.trakeyeType && vm.user.trakeyeType.id === entity_old.trakeyeType.id) {
                    vm.user.trakeyeTypeAttributeValues = [];
                    vm.user.trakeyeTypeAttributeValues = entity_old.trakeyeTypeAttributeValues;
                } else {
                    vm.user.trakeyeTypeAttributeValues = [];
                    $.each(vm.user.trakeyeType.trakeyeTypeAttribute, function (key, value) {
                        vm.user.trakeyeTypeAttributeValues.push({
                            trakeyeTypeAttribute: vm.user.trakeyeType.trakeyeTypeAttribute[key]
                        });
                    });
                }
            }

        }

        vm.selectfrom = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $state.go('user-management');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {

            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function decodeImage(img) {
            return window.atob(img);
        }

        function navigateGeofence() {
            sessionStorage.setItem("prevPage", "user-management.new");
            $state.go('geofence.new');
        }

        function navigateGroupfromUserMng() {
            sessionStorage.setItem("prevPage", "user-management.new");
            $state.go('group.new');
        }

        function navigateFiberifyTypefromGroup() {
            sessionStorage.setItem("prevPage", "user-management.new");
            $state.go('trakeye-type.new');
        }


        /* UserAccount.get(function (data) {
            data.authorities.forEach(function (val) {  
                if(val == "ROLE_USER_ADMIN" || val == "ROLE_ASSISTANT_MANAGER" || val == "ROLE_TEAM_LEADER"){
                    vm.reportsEnable = false
                }else{
                    vm.reportsEnable = true
                }
            })})  */

            vm.reportsEnable = true
            vm.deviceInfo = true
            Configs.get("InputFields", function (response) {
                if (response.length > 0) {
                    response.forEach(function(val){
                        if(val.configKey == "DeviceInfo" && val.configValue == "True" ){
                            vm.deviceInfo = false
                        }

                        if(val.configKey == "ReportsTo" && val.configValue == "True"){
                            vm.reportsEnable = false
                        }
                    })
                }
            });
        
    }
})();