(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("CableDetailController", CableDetailController);

        CableDetailController.$inject = ['entity','getCableName','postCoreCable','$timeout','$scope','UserAccount','getCoreDetails','getTubeDetails'];
    
    function CableDetailController(entity,getCableName,postCoreCable,$timeout,$scope,UserAccount,getCoreDetails,getTubeDetails) {
        var vm = this
        vm.assetDetails = entity
        vm.cableType = ["96F", "48F", "24F", "12F"];
        vm.noOfTubes = [];
        for (var i = 1; i <= 8; i++) {
            vm.noOfTubes.push("0" + i);
        }
        vm.cable48Type = ["04","06"]
        vm.cableResponse = []
        vm.tubeResponse = []

        UserAccount.get(function (data) {
            vm.tenantId = data.id; })

        getCableName.get(entity.id,function(cableResponse){
                vm.cableResponse = cableResponse
        })

        var cableTypeMapping = {
            "12F": { tube: 1, tubeCount: 12, coreIndex: 0, coreCount: 12 },
            "24F": { tube: 2, tubeCount: 12, coreIndex: 1, coreCount: 12 },
            "48F": { tube: 0, tubeCount: 0, coreIndex: 0, coreCount: 0 },
            "96F": { tube: 8, tubeCount: 12, coreIndex: 7, coreCount: 12 },
        };

        // Assuming vm.selectedCableType holds the selected cable type
       

        vm.fiberType = function () {
            $scope.expandedIndex = -1 

            var selectedCableType = vm.selectedCableType;
            $scope.cableData.listOfTubes = [];
            
            if(vm.selected48Type == "04"){
                cableTypeMapping["48F"].tube = 4
                cableTypeMapping["48F"].tubeCount = 12
                cableTypeMapping["48F"].coreIndex = 3
                cableTypeMapping["48F"].coreCount = 12
            }else if(vm.selected48Type == "06"){
                cableTypeMapping["48F"].tube = 6
                cableTypeMapping["48F"].tubeCount = 8
                cableTypeMapping["48F"].coreIndex = 5
                cableTypeMapping["48F"].coreCount = 8
            }

            if (cableTypeMapping[selectedCableType]) {
            var cableProperties = cableTypeMapping[selectedCableType];

            $scope.addTube(cableProperties.tube, cableProperties.tubeCount);
            $scope.addCore(
                cableProperties.coreIndex,
                cableProperties.coreCount
            );
            vm.selectedTube = cableProperties.tube;
        }
        };

        $scope.addTube = function (iteration, noCore) {
            for (var i = 1; i <= iteration; i++) {
                var newTube = {
                    name: vm.enteredCableName + "_" + "T" + i,
                    noOfCores: noCore,
                    colorCodeHex: vm.tubeColor[i-1].hexcode,
                    tenantId: vm.tenantId,
                    coreList: [],
                    order : i
                };
                $scope.cableData.listOfTubes.push(newTube);
            }
        };

        $scope.addCore = function (index, iteration) {
            for (var j = 0; j <= index; j++) {
                for (var i = 1; i <= iteration; i++) {
                    var newCore = {
                        name:
                            $scope.cableData.listOfTubes[j].name +
                            "_" +
                            "C" +
                            i,
                        colorCodeHex: vm.coreColor[i-1].hexcode,
                        tenantId: vm.tenantId,
                        order:i
                    };
                    $scope.cableData.listOfTubes[j].coreList.push(newCore);
                }
            }
        };

        vm.coreColor = [
            { name: "Blue", hexcode: "0000FF" },
            { name: "Orange", hexcode: "FFA500" },
            { name: "Green", hexcode: "008000" },
            { name: "Brown", hexcode: "A52A2A" },
            { name: "Gray", hexcode: "71797E" },
            { name: "White", hexcode: "FFFFFF" },
            { name: "Red", hexcode: "FF0000" },
            { name: "Black", hexcode: "000000" },
            { name: "Yellow", hexcode: "FFFF00" },
            { name: "Violet", hexcode: "EE82EE" },
            { name: "Rose", hexcode: "FF007F" },
            { name: "Aqua", hexcode: "00FFFF" },
        ];

        vm.tubeColor = [
            { name: "Blue", hexcode: "0000FF" },
            { name: "Orange", hexcode: "FFA500" },
            { name: "Green", hexcode: "008000" },
            { name: "Brown", hexcode: "A52A2A" },
            { name: "Gray", hexcode: "71797E" },
            { name: "White", hexcode: "FFFFFF" },
            { name: "Red", hexcode: "FF0000" },
            { name: "Black", hexcode: "000000" },
        ];

        
        $scope.expandedIndex = -1;

        $scope.toggleCoreList = function (index) {
            if ($scope.expandedIndex === index) {
                $scope.expandedIndex = -1; // Collapse if already expanded
            } else {
                $scope.expandedIndex = index; // Expand the clicked row
            }
        };

        vm.closeModal = function(id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();

            if (id == "addCableDetails") {
                $scope.cableData = [];
                vm.enteredCableName = null;
                vm.selectedCableType = null;
                vm.selectedTube = null;
            }
        }

        vm.selectCable = function (cableName) {
            vm.cableResponse.forEach(function (item) {
                item.isExpanded = false;
            });
            vm.tubeResponse.forEach(function (item) {
                item.expanding = false;
            });
            if (vm.toggle == cableName.name) {
                vm.toggle = undefined;
            } else {
                cableName.isExpanded = true;
                vm.toggle = cableName.name;

                getTubeDetails.get(cableName.name,function(tubeResponse){ 
                    vm.tubeResponse = tubeResponse
                })
            }
        }

        vm.selectTube = function (tubeName) {
            vm.tubeResponse.forEach(function (item) {
                item.expanding = false;
            });
            if (vm.toggleTube == tubeName.name) {
                vm.toggleTube = undefined;
            } else {
                tubeName.expanding = true;
                vm.toggleTube = tubeName.name;

                getCoreDetails.get(tubeName.name,function(coreResponse){ 
                    vm.coreResponse = coreResponse
                })
            }
        }

        vm.addCableDetails = function(){
            vm.cablesList = []
            vm.enteredCableName = entity.id + "_Cable01" 
            getCableName.get(entity.id,function(cableResponse){
                if(cableResponse.length > 0){
                    vm.cablesList = []
                    vm.enteredCableName = entity.id + "_Cable0" + (cableResponse.length + 1)
                    cableResponse.forEach(function (val){
                        vm.cablesList.push({"name":'[Name - ' + val.name +' ] [' + val.type + ']' })
                    })
                }
            })
            
            vm.cableSuccess = false;
            $("#addCableDetails").show();
            vm.spreadCoordinates = [];
            for (var i = 0; i < entity.assetCoordinates.length; i++) {
                vm.spreadCoordinates.push({
                    latitude: entity.assetCoordinates[i].latitude,
                    longitude: entity.assetCoordinates[i].longitude,
                });
            }
            $scope.cableData = {
                length: (entity.assetLength * 1000).toFixed(2),
                tenantId: vm.tenantId,
                spreadAssetId: entity.id,
                assetCoordinates: vm.spreadCoordinates,
                listOfTubes: [], // Initialize listOfTubes as an empty array
            };
        }

        vm.saveCableDetails = function () {
            $scope.cableData.noOfTubes = vm.selectedTube;
            $scope.cableData.name = vm.enteredCableName;
            $scope.cableData.type = vm.selectedCableType;
            postCoreCable.get($scope.cableData, function () {
                vm.cableSuccess = true;
                $timeout(function () {
                    vm.closeModal("addCableDetails");;
                }, 2000);
            },function(){
                vm.closeModal("addCableDetails");
            });
        };

    }
})();