(function () {
    "use strict";
    angular.module("trakeyeApp").factory("WorkOrder", WorkOrder);

    WorkOrder.$inject = ["$resource", "DateUtils"];

    function WorkOrder($resource, DateUtils) {
        var resourceUrl = "api/work-orders/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
                update: {
                    method: "PUT",
                },
            }
        );
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('WorkOrderStats', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (name,callback) {
            $http.get('api/workorderstats/'+name).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .factory("WorkOrderPriority", WorkOrderPriority);

    WorkOrderPriority.$inject = ["$resource"];

    function WorkOrderPriority($resource) {
        var service = $resource(
            "/api/work-orders/priority/:priority",
            {
                priority: "@priorirty",
            },
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );

        return service;
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkOrderSearch", WorkOrderSearch);

    WorkOrderSearch.$inject = ["$resource"];

    function WorkOrderSearch($resource) {
        var service = $resource(
            "/api/work-orders/searchvalue/:search",
            {},
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );

        return service;
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkorderCountByGroup", [
        "$http",
        function ($http) {
            var service = {};
            service.getAll = function (callback) {
                $http
                    .get("api/workordercountbygroup")
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkorderCountByStatus", [
        "$http",
        function ($http) {
            var service = {};
            service.getAll = function (callback) {
                $http
                    .get("api/workordercountbystatus")
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";
    angular.module("trakeyeApp").factory("WorkOrderGroup", WorkOrderGroup);

    WorkOrderGroup.$inject = ["$resource", "DateUtils"];

    function WorkOrderGroup($resource, DateUtils) {
        var resourceUrl = "api/work-orders-group";

        return $resource(resourceUrl, {
            query: {
                method: "GET",
                isArray: true,
            },
        });
    }
})();

(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .factory("WorkOrderGroupSearch", WorkOrderGroupSearch);

    WorkOrderGroupSearch.$inject = ["$resource"];

    function WorkOrderGroupSearch($resource) {
        var service = $resource(
            "/api/work-orders-group/searchvalue/:search",
            {},
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );

        return service;
    }
})();

(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .factory("WorkOrderGroupPrioritySearch", WorkOrderGroupPrioritySearch);

    WorkOrderGroupPrioritySearch.$inject = ["$resource"];

    function WorkOrderGroupPrioritySearch($resource) {
        var service = $resource(
            "/api/work-orders-group/prioritysearch/:priority/:searchtext",
            {
                priority: "@priorirty",
                searchtext: "@searchtext",
            },
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );

        return service;
    }
})();

(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .factory("WorkOrderGroupPriority", WorkOrderGroupPriority);

    WorkOrderGroupPriority.$inject = ["$resource"];

    function WorkOrderGroupPriority($resource) {
        var service = $resource(
            "/api/work-orders-group/priority/:priority",
            {
                priority: "@priorirty",
            },
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );

        return service;
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("AllWorkOrderTypes", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getAll = function (callback) {
                $http.get("api/workorder-alltypes").then(function (response) {
                    callback(response.data);
                });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .factory("WorkOrderPrioritySearch", WorkOrderPrioritySearch);

    WorkOrderPrioritySearch.$inject = ["$resource"];

    function WorkOrderPrioritySearch($resource) {
        var service = $resource(
            "/api/work-orders/prioritysearch/:priority/:searchtext",
            {
                priority: "@priorirty",
                searchtext: "@searchtext",
            },
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );

        return service;
    }
})();

(function () {
    "use strict";
    angular
        .module("trakeyeApp")
        .factory("WorkOrderUpdateGroup", WorkOrderUpdateGroup);

    WorkOrderUpdateGroup.$inject = ["$resource"];

    function WorkOrderUpdateGroup($resource) {
        var resourceUrl = "api/work-orders/groups";

        return $resource(
            resourceUrl,
            {},
            {
                update: {
                    method: "PUT",
                },
            }
        );
    }
})();

(function () {
    "use strict";
    angular
        .module("trakeyeApp")
        .factory("WorkOrderBulkDelete", WorkOrderBulkDelete);

    WorkOrderBulkDelete.$inject = ["$resource"];

    function WorkOrderBulkDelete($resource) {
        var resourceUrl = "api/work-orders/bulk/delete";

        return $resource(
            resourceUrl,
            {},
            {
                save: {
                    method: "POST",
                },
            }
        );
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkOrderUser", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getworkordersuser = function (callback) {
                $http
                    .get("api/work-orders/live-logs")
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getworkordersuserbyid = function (id, callback) {
                $http
                    .get("api/work-orders/live-logs/" + id)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.searchworkordersuserbyvalue = function (id, callback) {
                $http
                    .get("api/work-orders/search/" + id)
                    .then(function (response) {
                        callback(response.data);
                    });
            };
            service.getworkordersanduserbypriority = function (
                priority,
                callback
            ) {
                $http
                    .get("api/work-orders/live-logs/priority/" + priority)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getAgents = function (callback) {
                $http
                    .get("api/dashboard/agentdashboard")
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkOrderEdit", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getallworkorders = function (callback) {
                $http.get("api/tr-allworkorders").then(function (response) {
                    callback(response.data);
                });
            };

            service.editworkorder = function (data, callback) {
                $http
                    .post(
                        "api/work-orderedit/" +
                            data.userId +
                            "/" +
                            data.workorderId,
                        {}
                    )
                    .then(
                        function (response) {
                            callback(response, response.headers);
                        },
                        function (error) {
                            callback(error, error.headers);
                        }
                    );

                /*
                 * $http.post('api/work-orderedit/'+data.userId+'/'+data.workorderId).then(function(response){
                 * callback(response.data); });
                 */
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";
    angular.module("trakeyeApp").factory("WorkOrderUpload", WorkOrderUpload);

    WorkOrderUpload.$inject = ["$http"];

    function WorkOrderUpload($http) {
        var service = {};
        service.workorderupload = function (data, callback) {
            $http
                .post("api/workorderimport", data, {
                    transformRequest: angular.identity,
                    headers: {
                        "Content-Type": undefined,
                    },
                })
                .then(
                    function (response) {
                        callback(response, response.headers);
                    },
                    function (error) {
                        callback(error, error.headers);
                    }
                );
        };

        return service;
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("TrGroupWorkOrderSearchForMap", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.searchworkorderformap = function (search, callback) {
                $http
                    .get("api/tr-groupworkordersformap/searchvalue/" + search)
                    .then(function (response) {
                        callback(response.data);
                    });
            };
            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("TrGroupWorkOrderForMap", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getAllWorkOrders = function (callback) {
                $http
                    .get("api/tr-groupworkordersformap")
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkOrderSearchForMap", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.searchworkorderformap = function (search, callback) {
                $http
                    .get("api/work-ordersformap/searchvalue/" + search)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.prioritysearchworkorderformap = function (
                priority,
                callback
            ) {
                $http
                    .get("api/work-ordersformap/priority/" + priority)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkOrderLastLocation", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};

            service.getlastlogs = function (callback) {
                $http
                    .get("api/location-logs/lastuserpath")
                    .then(function (response) {
                        callback(response.data);
                    });
            };
            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkOrdersForMapByAdmin", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.workordersformapbyadmin = function (adminid, callback) {
                $http
                    .get("api/work-ordersformap/" + adminid)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkOrderForMap", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getAllWorkOrders = function (callback) {
                $http.get("api/work-ordersformap").then(function (response) {
                    callback(response.data);
                });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";
    angular.module("trakeyeApp").factory("ChildWorkflows", ChildWorkflows);

    ChildWorkflows.$inject = ["$resource"];

    function ChildWorkflows($resource) {
        var resourceUrl = "api/linkedWorkFlows/:parentId";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );
    }
})();

(function () {
    "use strict";
    angular.module("trakeyeApp").factory("ChildServices", ChildServices);

    ChildServices.$inject = ["$resource"];

    function ChildServices($resource) {
        var resourceUrl = "api/linkedServices/:parentId";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );
    }
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('Configs', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
        var config_catName = "ROLLOUT_FEATURES"
		service.get = function (name, callback) {
			$http.get('api/configs/' + name).then(function (response) {
				callback(response.data);
			});
		};

        service.rolloutFeatures = function (callback) {
			$http.get('api/configs/'+config_catName).then(function (response) {
                callback(response.data);
            },
            function (error) {
                callback(error);
            });
		};

		return service;
	}]);
})();

(function () {
    "use strict";
    angular
        .module("trakeyeApp")
        .factory("WorkOrderWorkflowAssets", WorkOrderWorkflowAssets);

    WorkOrderWorkflowAssets.$inject = ["$resource"];

    function WorkOrderWorkflowAssets($resource) {
        var resourceUrl = "api/linkedAssets/:parentId";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );
    }
})();


(function () {
    'use strict';

    angular.module('trakeyeApp').factory('WorkFlowListByWorkOrderID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/workorderworkflows/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetsByWorkOrderID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/assetsbywo/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetsByWorkFlowID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/assetsbywf/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();