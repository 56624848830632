(function () {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('AssetType', AssetType);

    AssetType.$inject = ['$resource'];

    function AssetType($resource) {
        var resourceUrl = 'api/asset-types/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' }
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('AssetTypeSearch', AssetTypeSearch);

    AssetTypeSearch.$inject = ['$resource'];

    function AssetTypeSearch($resource) {
        var service = $resource('/api/asset-types/searchvalue/:searchvalue', { searchvalue: '@id' }, {}, {
            'query': { method: 'GET', isArray: true },
        });

        return service;
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AllCustomAssetTypeAttribute', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/all-custom-asset-type-attributes').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetTypesByGeofenceID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (geofenceId, callback) {
            $http.get('api/asset-types-geofence/' + geofenceId).then(function (response) {
                callback(response.data);
            });
        };
        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetTypesByGeofenceIDs', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function ( data, callback) {
            $http.post("api/asset-types-geofences", data).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();