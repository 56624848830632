(function () {
	'use strict';

	angular.module('trakeyeApp').controller('TrCaseDialogController', TrCaseDialogController).directive('mySrc', function () {
		return {
			restrict: 'A',
			scope: {
				callback: '&'
			},
			link: function (scope, elem, attrs) {
				elem.attr('src', scope.callback());
			}
		};
	})
	TrCaseDialogController.$inject = ['$rootScope', '$timeout', '$scope', 'DEFAULT_LATITUDE', 'DEFAULT_LONGITUDE', '$stateParams', '$q', 'entity', 'TrCase', 'UserGroupIDs', 'User', 'CaseType', 'Principal', 'Asset', '$state', 'ActivatedUsers', 'UserValueSearch',
		'AlertService', 'ActivatedUserSearch', 'TrCaseUpload', 'AssetSearchForMap', 'AssetSearch', 'AllCaseTypes', 'TrCaseImages', 'AllCustomCaseTypeAttribute', 'AssetGroupES', 'AssetByID', 'UserAccount', 'GeofenceIDs', 'UserGeofences', 'Geofence', '$ngConfirm', 'AssocitedVendorSearch'
	,'NearByBGOH','AttributeMappingId','getCaseImage','$localStorage','AttributeMappingList','Configs'];

	function TrCaseDialogController($rootScope, $timeout, $scope, DEFAULT_LATITUDE, DEFAULT_LONGITUDE, $stateParams, $q, entity, TrCase, UserGroupIDs, User, CaseType, Principal, Asset, $state, ActivatedUsers, UserValueSearch, AlertService, ActivatedUserSearch,
		TrCaseUpload, AssetSearchForMap, AssetSearch, AllCaseTypes, TrCaseImages, AllCustomCaseTypeAttribute, AssetGroupES, AssetByID, UserAccount, GeofenceIDs, UserGeofences, Geofence, $ngConfirm, AssocitedVendorSearch,NearByBGOH,AttributeMappingId,getCaseImage,$localStorage,AttributeMappingList,Configs) {
		var vm = this;

		vm.trCase = entity;
		vm.showCaseAttachment = false

		var entity_old = angular.copy(vm.trCase);
		// vm.caseImages; // = entity_old.caseImages;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.save = save;
		vm.submit = submit
		vm.decodeImage = decodeImage;
		vm.filterUser = filterUser;
		vm.filterActivatedUser = filterActivatedUser;
		vm.assetList = "";
		vm.filterAssetsList = filterAssetsList;
		vm.getAssetAddress = getAssetAddress;
		vm.filterAssetsOnMapView = filterAssetsOnMapView;
		vm.closeMapFilterModal = closeMapFilterModal;
		vm.closePopupDialogModal = closePopupDialogModal;
		vm.saveFilter = saveFilter;
		vm.showExisting = showExisting;
		vm.customAttributesMap = {};
		vm.customAttributesMapp = [];
		var usersMap = {};
		var openedInfoWindow = null;
		var locations = {};
		var prevInfoWindow = null;
		vm.checkattributeMappingList = []
		vm.closeModal = closeModal
		vm.showCableSplice = false
		vm.showFmsTermination = false
		vm.saveDetails = saveDetails
		// vm.showAddressAddress = true;

		var markers = [];
		var polylines = [];
		var noDataText = null;
		vm.userGeofences = [];
		vm.canFilter = false;

		vm.userAdmin = {};
		vm.userGeofenceList = null;

		$scope.allimages = false;

		vm.prevStatus = vm.trCase.status;

		vm.img;
		var attachment,maxSize;

		/* if(vm.trCase.id){
			vm.assetName = vm.trCase.caseAssets.length > 0 ? " - " + vm.trCase.caseAssets[0].name + ' (Asset Name)' : "Edit Case"
		}

		if($rootScope.createCaseFromAssetMap){
			vm.assetNameInCreate = $rootScope.createCaseFromAssetMap.length > 0 ? " - " + $rootScope.createCaseFromAssetMap[0].name + ' (Asset Name)' : "Create Case"
		} */

		$scope.getImage = function (img) {
		    var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%" src="data:image/jpeg;base64,' +
                    img +
                    '" >'
            );
            imgWindow.document.close(); 
		};

		$scope.toggle = function (id) {
            switch (id) {
                case "image":
                    $scope.allimages = !$scope.allimages;
                    vm.showImages = !vm.showImages;
                    if ($scope.allimages) {
                        TrCaseImages.query(
                            {
                                caseId: vm.trCase.id,
                            },
                            onSuccess,
                            onError
                        );

                        function onSuccess(data, headers) {
                            // console.log('Recived data-----------');
                            vm.caseImages = data.filter(function(caseImages){
                                return caseImages.image
                             });
                        }

                        function onError(error) {
                            AlertService.error(error.data.message);
                        }
                    }
                    break;

                case "termination":
                    $scope.terminationDetails = !$scope.terminationDetails;
                    vm.showTermination = !vm.showTermination;
                    break;

                case "fms":
                    $scope.fmsDetails = !$scope.fmsDetails;
                    vm.showFMS = !vm.showFMS;
                    break;

                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                case "cable":
                    $scope.cableDetails = !$scope.cableDetails;
                    vm.showCable = !vm.showCable;
                    break;

                case "pdf":
                    $scope.allpdf = !$scope.allpdf;
                    vm.showPdf = !vm.showPdf;
                    if ($scope.allpdf) {
                        getCaseImage.get(vm.trCase.id, function (res) {
                            vm.attachments = res;
                        });
                    }

                    break;

                default:
                    console.log("");
            }
        };
		vm.trCase.caseImages = [];

		$(".select2").select2(); // Added search for select box
		// Added for upload
		$(":file").filestyle({
			buttonBefore: true
		});
		$(":file").filestyle('buttonText', 'Browse File');
		vm.caseTypes = loadPage(); // CaseType.query();
		vm.selectAttributes = selectedCaseTypeAttributes;
		$timeout(function () {
			angular.element('.form-group:eq(1)>input').focus();
		});

		vm.checkFrtPat = false

		$scope.editDetails = function (id, name) {
			vm.editDetails = false
			vm.new = JSON.parse(JSON.stringify(vm.trCase));
            switch (name) {
                case "cableDetails":
                    vm.selectedCable = id;
                    $("#cableDetails").show();
                    break;
                case "splicingDetails":
                    vm.selectedSlicing = id;
                    $("#splicingDetails").show();
                    break;
                case "fmsDetails":
					vm.selectedFms = id;
                    $("#fmsDetails").show();
                    break;
                case "terminationDetails":
					vm.selectedTermination = id;
                    $("#terminationDetails").show();
                    break;
                default:
                    console.log("");
            }
        };
		
		$scope.addDetails = function (id) {
			vm.editDetails = true
            switch (id) {
                case "cableDetails":
                    if (vm.trCase.cableDetails != null) {
                        vm.trCase.cableDetails.length++;
                        vm.selectedCable = vm.trCase.cableDetails.length - 1;

                        if (
                            vm.trCase.splicingDetails.length > 0 &&
                            vm.trCase.cableDetails.length > 0
                        ) {
                            vm.isSaving = false;
                        }
                    }
                    $("#cableDetails").show();
                    break;
                case "splicingDetails":
                    if (vm.trCase.splicingDetails != null) {
                        vm.trCase.splicingDetails.length++;
                        vm.selectedSlicing =
                            vm.trCase.splicingDetails.length - 1;

                        if (
                            vm.trCase.splicingDetails.length > 0 &&
                            vm.trCase.cableDetails.length > 0
                        ) {
                            vm.isSaving = false;
                        }
                    }
                    $("#splicingDetails").show();
                    break;
                case "fmsDetails":
					if (vm.trCase.fmsAndCableDetails != null) {
                        vm.trCase.fmsAndCableDetails.length++;
                        vm.selectedFms =
                            vm.trCase.fmsAndCableDetails.length - 1;

                        if (
                            vm.trCase.fmsAndCableDetails.length > 0 &&
                            vm.trCase.terminationDetails.length > 0
                        ) {
                            vm.isSaving = false;
                        }
                    }
                    $("#fmsDetails").show();
                    break;
                case "terminationDetails":
					if (vm.trCase.terminationDetails != null) {
                        vm.trCase.terminationDetails.length++;
                        vm.selectedTermination =
                            vm.trCase.terminationDetails.length - 1;

                        if (
                            vm.trCase.fmsAndCableDetails.length > 0 &&
                            vm.trCase.terminationDetails.length > 0
                        ) {
                            vm.isSaving = false;
                        }
                    }
                    $("#terminationDetails").show();
                    break;
                default:
                    console.log("");
            }
        };

		$scope.removeDetails = function (id, name) {
            switch (name) {
                case "cableDetails":
                    vm.trCase.cableDetails.splice(id, 1);
                    if (vm.trCase.cableDetails.length == 0) {
                        vm.isSaving = true;
                    }
                    break;
                case "splicingDetails":
                    vm.trCase.splicingDetails.splice(id, 1);
                    if (vm.trCase.splicingDetails.length == 0) {
                        vm.isSaving = true;
                    }
                    break;
                case "fmsDetails":
					vm.trCase.fmsAndCableDetails.splice(id, 1);
                    if (vm.trCase.fmsAndCableDetails.length == 0) {
                        vm.isSaving = true;
                    }
                    break;
                case "terminationDetails":
					vm.trCase.terminationDetails.splice(id, 1);
                    if (vm.trCase.terminationDetails.length == 0) {
                        vm.isSaving = true;
                    }
                    break;
                default:
                    console.log("");
            }
        };

		function saveDetails(id) {
            switch (id) {
                case "cableDetails":
                    if (vm.trCase.cableDetails[vm.selectedCable] == null) {
                        vm.trCase.cableDetails.splice(vm.selectedCable, 1);
                    }
                    break;
                case "splicingDetails":
                    if (vm.trCase.splicingDetails[vm.selectedSlicing] == null) {
                        vm.trCase.splicingDetails.splice(vm.selectedSlicing, 1);
                    }
                    break;
                case "fmsDetails":
					if (vm.trCase.fmsAndCableDetails[vm.selectedFms] == null) {
                        vm.trCase.fmsAndCableDetails.splice(vm.selectedFms, 1);
                    }
                    break;
                case "terminationDetails":
					if (vm.trCase.terminationDetails[vm.selectedTermination] == null) {
                        vm.trCase.terminationDetails.splice(vm.selectedTermination, 1);
                    }
                    break;
                default:
                    console.log("");
            }

            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

		function loadPage() {
			if(vm.trCase.id ==  null){
				vm.isSaving = true
			}
			
			Configs.get("MaxFileSize",function(response){
				maxSize = response[0].configValue
			})

			if(vm.trCase.id){
				Configs.get("CaseImage",function(response){
					if(response.length > 0){
						if(response[0].configValue == "True"){
							vm.showCaseAttachment = true
						}
					}
				})
			}

			vm.multiGroup = [];
			var selectedGroup = [];

			if (vm.trCase.id != null) {
				vm.trCase.groups.forEach(function (group) {
					selectedGroup.push(group.id);
				});
			}

			ReverseGeocode(parseFloat(vm.trCase.pinLat), parseFloat(vm.trCase.pinLong));
			AllCaseTypes.getAll(function (response) {
				vm.caseTypes = response;
			});

			if(vm.trCase.id){
				if(vm.trCase.caseType.name == "Splicing Details"){
					vm.showCableSplice = true;
					Configs.get("CableSide",function(response){ 
						vm.cableSide = response
					})
					Configs.get("CableName",function(response){ 
						vm.cableName = response
					})
					Configs.get("JEAvailability",function(response){ 
						vm.jeAvailability = response
					})
					Configs.get("CableCapacity",function(response){ 
						vm.cableCapacity = response
					})
					Configs.get("TubeSide",function(response){ 
						vm.tubeSide = response
					})
					Configs.get("JENumber",function(response){ 
						vm.jeNumber = response
					})
				}

				if(vm.trCase.caseType.name == "Termination Details"){
					vm.showFmsTermination = true; 
					Configs.get("CableSide",function(response){ 
						vm.cableSide = response
					})
					Configs.get("FMSNumber",function(response){ 
						vm.fmsNumber = response
					}) 
					Configs.get("FMSCapacity",function(response){ 
						vm.fmsCapacity = response
					}) 
					Configs.get("FMS",function(response){ 
						vm.fms = response
					}) 
					Configs.get("Type",function(response){ 
						vm.type = response
					}) 
					Configs.get("CableNumber",function(response){ 
						vm.cableNumber = response
					}) 
				}
			}
			
			UserGroupIDs.getAll(function (response) {
				response.forEach(function (group) {
					if (selectedGroup.indexOf(group.id) !== -1) {
						vm.multiGroup.push({ name: group.name, id: group.id, ticked: true });
					} else {
						vm.multiGroup.push({ name: group.name, id: group.id, ticked: false });
					}
				});
			});

			AllCustomCaseTypeAttribute.getAll(function (response) {
				//vm.customCaseTypeAttributes = response;
				response.forEach(function (arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customCaseTypeAttributeValues;
					vm.customAttributesMap[arrayItem.name].parentId = arrayItem.id
				});

				if(vm.trCase.id){
					var getSelectedName = []
					vm.trCase.caseTypeAttributeValues.forEach(function (val){
						if(val.caseTypeAttribute.attributeDataType.name == 'STRING' ||
						val.caseTypeAttribute.attributeDataType.name == 'FLOAT' ||
						val.caseTypeAttribute.attributeDataType.name == 'INTEGER' ||
						val.attributeValue == null){
							
						}else{
							getSelectedName.push(val)
						}
					})

					getSelectedName.forEach(function(item){ 
						vm.customAttributesMap[item.caseTypeAttribute.attributeDataType.name].forEach(function(item1){
							if(item.attributeValue == item1.value){
								AttributeMappingId.get(item1.id,function(res){ 
									res.forEach(function(val){
										if(vm.customAttributesMap[val.parentValue].parentId == val.parentId){
												vm.customAttributesMap[val.parentValue].length = 0
										}
									}) 
								res.forEach(function(val){
									vm.customAttributesMap[val.parentValue].push({id : val.id , value : val.value, parentId : val.parentId})
								})  
								})
							}
						})
					 })
				}

				/* response.forEach(function (arrayItem) {
					vm.customAttributesMapp.push({ name : arrayItem.name ,customValues : arrayItem.customCaseTypeAttributeValues});
				});

				 if(vm.trCase.id){
					var getSelectedName = []
					vm.trCase.caseTypeAttributeValues.forEach(function (val){
						if(val.caseTypeAttribute.attributeDataType.name == 'STRING' ||
						val.caseTypeAttribute.attributeDataType.name == 'FLOAT' ||
						val.caseTypeAttribute.attributeDataType.name == 'INTEGER' ||
						val.attributeValue == null){
							
						}else{
							getSelectedName.push(val)
						}
					})
					getSelectedName.forEach(function(item){
						 vm.customAttributesMapp.forEach(function(valueParam){
							if(valueParam.name == item.caseTypeAttribute.attributeDataType.name){
								valueParam.customValues.forEach(function(getId){
									if(getId.value  == item.attributeValue){
										AttributeMappingId.get(getId.id,function(res){
											if(res.length > 0){
												res.forEach(function(val){
													if(vm.customAttributesMap[val.parentValue].parentId == val.parentId){
															vm.customAttributesMap[val.parentValue].length = 0
													}
											}) 
											res.forEach(function(val){
												vm.customAttributesMap[val.parentValue].push({id : val.id , value : val.value, parentId : val.parentId})
											}) 
											}
										})
									}  
								})
							}
							 
						}) 
				   }) }  */
				   if(vm.trCase.id){
					$(":file").filestyle({
						buttonBefore: true
					});
					$(":file").filestyle('buttonText', 'Browse File');
				   }
				   AttributeMappingList.query(
					{
						page: 0,
						size: 1000,
						sort: "id,asc",
					},
					onSuccess,
					onError
				);
				function onSuccess(data, headers) {
					vm.attributeMappingList = data;
					vm.attributeMappingList.forEach(function (val){
						vm.checkattributeMappingList.push(val.caseTypeSubCategory.id)
					})
				}
				function onError(error) {
					AlertService.error(error.data.message);
				}
			});

			UserAccount.get(function (data) {
				vm.userAdmin = data;
				vm.reportToId = data.id
				var isAdmin = false;
				data.authorities.forEach(function (val) {
					if(val == 'ROLE_FRT' || val == 'ROLE_PATROLLER' ){
						vm.checkFrtPat = true
						vm.isSaving = false
					}

					if (val == "ROLE_USER_ADMIN") {
						isAdmin = true;
					}
					if (val == "ROLE_HOD") {
						isAdmin = true;
					}
				});
				if (isAdmin) {
					GeofenceIDs.getAll(function (response) {
						response.forEach(function (geofence) {
							if (geofence.name != 'Bangalore') {
								vm.userGeofences.push(geofence);
								if (vm.selectedGeofenceId == null) {
									vm.selectedGeofenceId = geofence.id;
									vm.canFilter = true;
								}
							}
						});
						setCaseGeofences();
					});
				} else {
					UserGeofences.getAll(function (response) {
						vm.userGeofenceList = response;
						response.forEach(function (geofence) {
							if (geofence.name != 'Bangalore') {
								vm.userGeofences.push(geofence);
								if (vm.selectedGeofenceId == null) {
									vm.selectedGeofenceId = geofence.id;
									vm.canFilter = true;
								}
							}
						});
						setCaseGeofences();
					});
				}
			});
		}

		function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    } /* 
                    {
                        elementType: "geometry.fill",
                        stylers: [
                            {
                                color: "#EFEFEF",
                            },
                        ],
                    }, */,
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    }
                ],
            });
        }

		function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
			var locationButton = document.createElement("button");
			locationButton.setAttribute("title","Locate Me");
			locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon)
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
							infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");	
                            infoWindow.open(map);
                            map.setCenter(pos);
							map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

		function ReverseGeocode(latitude, longitude) {
			var address = new google.maps.Geocoder();

			address.geocode({
				'latLng': { lat: latitude, lng: longitude }
			}, function (results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					if (results[0]) {
						vm.trCase.address = results[0].formatted_address;
					}
				}
			});
		}
		 function showExisting(){
			TrCase.save(vm.trCase, onSaveSuccess, onSaveError);
		}
	      
		$scope.attributeMappingChange = function(id){
			var passId;
			vm.customAttributesMap[id.caseTypeAttribute.attributeDataType.name].forEach(function(val){
				if(val.value  == id.attributeValue){
					passId = val.id
				}
			}) 
				AttributeMappingId.get(passId,function(res){
					if(res.length > 0){
						res.forEach(function(val){
								if(vm.customAttributesMap[val.parentValue].parentId == val.parentId){
										vm.customAttributesMap[val.parentValue].length = 0
								}
						}) 
						res.forEach(function(val){
							vm.customAttributesMap[val.parentValue].push({id : val.id , value : val.value, parentId : val.parentId})
						}) 
					} else{
						if(vm.checkattributeMappingList.indexOf(passId) == -1 ){
								AllCustomCaseTypeAttribute.getAll(function (response) {
								response.forEach(function (arrayItem) {
									vm.customAttributesMap[arrayItem.name] = arrayItem.customCaseTypeAttributeValues;
									vm.customAttributesMap[arrayItem.name].parentId = arrayItem.id
								}); 
								
							})
						} 
					} 
					/* if(res.length > 0){
						res.forEach(function(val){
								if(vm.customAttributesMap[val.parentValue].parentId == val.parentId){
										vm.customAttributesMap[val.parentValue].length = 0
								}
						}) 
						res.forEach(function(val){
							vm.customAttributesMap[val.parentValue].push({id : val.id , value : val.value, parentId : val.parentId})
						}) 
					} else{
							AllCustomCaseTypeAttribute.getAll(function (response) {
								response.forEach(function (arrayItem) {
									vm.customAttributesMap[arrayItem.name] = arrayItem.customCaseTypeAttributeValues;
									vm.customAttributesMap[arrayItem.name].parentId = arrayItem.id
								}); 
							})
					} */
				})
		}

		function save() {
			//vm.isSaving = true;
			if(vm.trCase.groups.length == 0 || vm.trCase.assignedToUser == null){
				vm.isSaving = true
			}

			if($rootScope.createCaseFromAssetMap){
				vm.trCase.caseAssets = $rootScope.createCaseFromAssetMap
			}

			if(vm.showCableSplice || vm.showFmsTermination){
				vm.trCase.priority = "LOW"
			}
			
			vm.trCase.submitted = false;
			if(vm.caseType.name != 'Vendor Civil Work'){
				var assignedToUser = $("#field_assignedToUser").val();
				if(vm.checkFrtPat){
					vm.trCase.reportedBy = vm.reportToId;
				}else{
					vm.trCase.assignedTo = usersMap[assignedToUser];
				}
			}
			angular.forEach(vm.assetList, function (value, key) {
				if (vm.trCase.asset.name === value.name) {
					vm.trCase.asset = value;
				}
			});
			var mandatoryCaseAttr = checkMandatoryAttributes(vm.trCase.caseTypeAttributeValues);
			if (mandatoryCaseAttr.length > 0) {
				// alert(mandatoryCaseAttr);

				var ele = document.getElementById("popupDialogModal");
				$(ele).modal('show');
				$("#popupDialogModal").show();
				$("#alertMessage").html("--- Mandatory Attributes --- <br />" + mandatoryCaseAttr.split(",").join("<br />"));
				vm.isSaving = false;
			} else {
				if(vm.isSaving == false && vm.trCase.assignedToUser != null){
					if (vm.trCase.id !== null) {
						TrCase.update(vm.trCase, onSaveSuccesss, onSaveError);
				} else {
					if(vm.caseType.name == 'UG OH Declaration'){ 
						
						NearByBGOH.getAll(vm.trCase, onSuccess, onError)
							function onSuccess(data) {
								//vm.isSaving = false;
								if(data.length > 0){
									$("#existingAsset").show();
								}else{
									TrCase.save(vm.trCase, onSaveSuccess, onSaveError);
								}
							}

							function onError(error) {
								/* vm.handlingError = error
								vm.isSaving = false
								AlertService.error(error); */
								if(error == 404){
									TrCase.save(vm.trCase, onSaveSuccess, onSaveError);
								}
							} 
						
					}else {
						TrCase.save(vm.trCase, onSaveSuccess, onSaveError);
					}
					
				}
				}
				
			}
		}
		
		function submit() {
			vm.isSaving = true;
			vm.trCase.submitted = true;
			var assignedToUser = $("#field_assignedToUser").val();
			vm.trCase.assignedTo = usersMap[assignedToUser];
			angular.forEach(vm.assetList, function (value, key) {
				if (vm.trCase.asset.name === value.name) {
					vm.trCase.asset = value;
				}
			});
			var mandatoryCaseAttr = checkMandatoryAttributes(vm.trCase.caseTypeAttributeValues);
			if (mandatoryCaseAttr.length > 0) {
				// alert(mandatoryCaseAttr);

				var ele = document.getElementById("popupDialogModal");
				$(ele).modal('show');
				$("#popupDialogModal").show();
				$("#alertMessage").html("--- Mandatory Attributes --- <br />" + mandatoryCaseAttr.split(",").join("<br />"));
				vm.isSaving = false;
			} else {
				if (vm.trCase.id !== null) {
					TrCase.update(vm.trCase, onSaveSuccess, onSaveError);
				} else {
					TrCase.save(vm.trCase, onSaveSuccess, onSaveError);
				}
			}
		}
		function checkMandatoryAttributes(caseTypeAttribute) {
			var resStr = "";
			
			caseTypeAttribute.forEach(function (value) {
				if (value.caseTypeAttribute.mandatory) {
					if (value.attributeValue == "" || value.attributeValue == "." || value.attributeValue == null) {
						if (((!vm.trCase.id && (value.caseTypeAttribute.caseCategory == 'CATEGORY1' || value.caseTypeAttribute.caseCategory == 'DEFAULT' || value.caseTypeAttribute.caseCategory == null))
							|| (vm.trCase.id && ((vm.prevStatus != 'ASSIGNED' && vm.prevStatus == 'NEW') || (vm.prevStatus != 'NEW' && vm.prevStatus == 'ASSIGNED')) && (value.caseTypeAttribute.caseCategory == 'CATEGORY1' || value.caseTypeAttribute.caseCategory == 'DEFAULT' || value.caseTypeAttribute.caseCategory == null))
							|| (vm.trCase.id && vm.prevStatus != 'ASSIGNED' && vm.prevStatus != 'NEW') || (vm.trCase.id && vm.prevStatus == 'ASSIGNED'  && $rootScope.has_ROLE_FRT && vm.trCase.caseType.name=='Incident Management')
							|| (vm.trCase.id && vm.prevStatus=='ASSIGNED' && (vm.trCase.caseType.name!='Issue on Field' && vm.trCase.caseType.name!='Asset Safety Related' && vm.trCase.caseType.name!='Productivity & NTP' && vm.trCase.caseType.name!='PM Activity' && vm.trCase.caseType.name!='UG OH Declaration' && vm.trCase.caseType.name!='Fiber Provisioning' && vm.trCase.caseType.name!='Chamber Declaration' && vm.trCase.caseType.name!='Vendor Civil Work' && vm.trCase.caseType.name!='Incident Management')))) /*FIBERIFY-482*/{
							if (resStr.length == 0) {
								resStr = value.caseTypeAttribute.name;
							} else {
								resStr = resStr + ", " + value.caseTypeAttribute.name;
							}
						}
					}
				}
			});

			/* caseTypeAttribute.forEach(function (value) { 
				var intial = value.attributeValue
				var parsedValue = JSON.parse(intial)
				value.attributeValue = parsedValue.value
			}) */
			return resStr;
		}

		$("#caseImages").change(function () {

			var filesSelected = document.getElementById("caseImages").files;
			vm.fileWarning = false
			$scope.$apply();
            for (var i = 0; i < filesSelected.length; i++) {
                if (filesSelected[i].size <= maxSize) {
                    var fileReader = new FileReader();

                    fileReader.onload = function (fileLoadedEvent) {
                        vm.trCase.caseImages.push({
                            image: window.btoa(fileLoadedEvent.target.result),
                            trCase: {
                                id: entity_old.id,
                            },
                        });
                    };
                    fileReader.readAsBinaryString(filesSelected[i]);
                }else{
					vm.fileWarning = true
					$scope.$apply();
				}
            }

		});

		$("#attachmentFile").change(function () { 
			attachment = document.getElementById("attachmentFile").files[0];
			vm.fileWarnings = (attachment.size > maxSize) ? true : false
			$scope.$apply();
		})
		
		$scope.deleteDisplayImage = function(id){
            vm.trCase.caseImages.splice(id,1)
        }

		$scope.newTabDisplay = function(id){
            var request = new XMLHttpRequest();
            request.open('GET',"api/trakeyecaseimages/"+vm.trCase.id+"/"+id, true);
            request.setRequestHeader('Authorization', 'Bearer '+$localStorage.authenticationToken);
            request.responseType = 'blob';
            request.onload = response;
            
            function response(e) {
                if (this.status === 200) {
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(this.response);
                window.open(imageUrl, "_blank")}
            } 
            request.send();
        } 

		function onSaveSuccesss(result) {
			if(vm.showCaseAttachment){
				if(attachment && attachment.type == "application/pdf" && attachment.size <= maxSize){
					var req = new XMLHttpRequest();
					var formData = new FormData();
					var name = attachment.name.split(".")[0];
					var type = attachment.name.split(".")[1];
					formData.append("file", attachment);
					req.open(
						"POST",
						"api/trakeyecaseimages/" +
							vm.trCase.id +
							"/" +
							name +
							"/" +
							type
					);
					req.setRequestHeader(
						"Authorization",
						"Bearer " + $localStorage.authenticationToken
					);
					req.send(formData);
					} 
			}
			$scope.$emit('trakeyeApp:trCaseUpdate', result);
			$state.go('tr-case');
			// $uibModalInstance.close(result);
			vm.isSaving = false;
		}

		function onSaveSuccess(result) {
			$scope.$emit('trakeyeApp:trCaseUpdate', result);
			$state.go('tr-case');
			// $uibModalInstance.close(result);
			vm.isSaving = false;
		}
		vm.caseType = entity.caseType;

		function selectedCaseTypeAttributes() {
			vm.showCableSplice = false;
			vm.showFmsTermination = false;
			$scope.allpdf = false;
			$scope.cableDetails = false;
			$scope.splicingDetails = false;
			$scope.fmsDetails = false;
			$scope.terminationDetails = false;
			vm.showTermination = false; 
			vm.showFMS = false;
			vm.showSplicing = false;
			vm.showCable = false;
			vm.showPdf = false;

			if(vm.caseType.name == "Splicing Details"){
				vm.trCase.cableDetails = []
				vm.trCase.splicingDetails = []
				vm.showCableSplice = true;
				vm.isSaving = true
				Configs.get("CableSide",function(response){ 
					vm.cableSide = response
				})
				Configs.get("CableName",function(response){ 
					vm.cableName = response
				})
				Configs.get("JEAvailability",function(response){ 
					vm.jeAvailability = response
				})
				Configs.get("CableCapacity",function(response){ 
					vm.cableCapacity = response
				})
				Configs.get("TubeSide",function(response){ 
					vm.tubeSide = response
				})
				Configs.get("JENumber",function(response){ 
					vm.jeNumber = response
				})
			}

			if(vm.caseType.name == "Termination Details"){
				vm.showFmsTermination = true;
				vm.trCase.fmsAndCableDetails = []
				vm.trCase.terminationDetails = []
				vm.isSaving = true
				Configs.get("CableSide",function(response){ 
					vm.cableSide = response
				})
				Configs.get("FMSNumber",function(response){ 
					vm.fmsNumber = response
				}) 
				Configs.get("FMSCapacity",function(response){ 
					vm.fmsCapacity = response
				}) 
				Configs.get("FMS",function(response){ 
					vm.fms = response
				}) 
				Configs.get("Type",function(response){ 
					vm.type = response
				}) 
				Configs.get("CableNumber",function(response){ 
					vm.cableNumber = response
				}) 
			}

			if (vm.caseType) {
				vm.trCase.caseType = vm.caseType;
				if (entity_old.caseType && vm.caseType.id === entity_old.caseType.id) {
					vm.trCase.caseTypeAttributeValues = [];
					vm.trCase.caseTypeAttributeValues = entity_old.caseTypeAttributeValues;
				} else {
					vm.trCase.caseTypeAttributeValues = [];
					$.each(vm.caseType.caseTypeAttribute, function (key, value) {
						vm.trCase.caseTypeAttributeValues.push({
							caseTypeAttribute: vm.caseType.caseTypeAttribute[key]
						});
					});
				}
			}
			
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		vm.datePickerOpenStatus.createDate = false;
		vm.datePickerOpenStatus.updateDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		function decodeImage(img) {
			return window.atob(img);
		}

		function filterUser() {
			if (vm.trCase.assignedToUser != null && vm.trCase.assignedToUser != "" && !angular.isUndefined(vm.trCase.assignedToUser)) {
				UserValueSearch.query({
					search: vm.trCase.assignedToUser,
				}, onSuccess, onError);

				function onSuccess(data, headers) {
					vm.users = data;
					angular.forEach(vm.users, function (value, key) {
						usersMap[value.login] = value.id;
					});
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
			}
		}

		$scope.openAssetMapModal = function () {
			var value = vm.trCase.asset;
			if (vm.trCase.asset.assetCoordinates.length > 0) {
				$("#assetsModal").show();
				var myCenter = new google.maps.LatLng(vm.trCase.pinLat, vm.trCase.pinLong);
				var ele = document.getElementById("assetsModal");
				$(ele).modal('show');
				//
				var locMap = new google.maps.Map(document
					.getElementById('map_canvas_assets'),myOption(14,myCenter) /* {
					center: myCenter,
					zoom: 14
				} */);

				//	            var markerA = new google.maps.Marker({
				//                    position: myCenter,
				//                    icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
				//                    map: locMap
				//                });

				//                var markerContent = '<div class="infoWindowhead"><b>'+ vm.trCase.description
				//    			+ '</b></div><div class="infoWindowContent">'
				//    			+'<b>Lat : </b>'+ vm.trCase.pinLat
				//    			+'<br><b>Lng : </b>'+ vm.trCase.pinLong
				//    			+'</div>';
				//                
				//                var infoWindow1 = new google.maps.InfoWindow({
				//                	content: markerContent
				//                });

				//    	        markerA.addListener('click', function () {
				//    	        	if (openedInfoWindow != null) {
				//                        openedInfoWindow.close();
				//                    }
				//    	        	infoWindow1.open(map, markerA);
				//    	        	openedInfoWindow = infoWindow1;
				//    	        });

				if (value.assetType != null) {
					if (value.assetType != null && value.assetType.layout == 'FIXED') {
						drawFixedAsset(value, locMap);
					} else if (value.assetType != null && value.assetType.layout == 'SPREAD') {
						if (value.assetCoordinates.length > 0) {
							drawPolyline(value, locMap);
						}
					}
				}

				$('#assetsModal').on('show.bs.modal', function (event) {
					$("#location-map-assets").css("width", "100%");
					$("#map_canvas_assets").css("width", "100%");
				});
			} else {
				//console.log(value.customerName + " - Location details missing!!!");
			}
		}

		function drawFixedAsset(fixed, locMap) {

			if (fixed.assetCoordinates.length > 0) {
				if (openedInfoWindow != null) {
					openedInfoWindow.close();
				}
				var marker = new google.maps.Marker({
					position: new google.maps.LatLng(fixed.assetCoordinates[0].latitude, fixed.assetCoordinates[0].longitude),
					map: locMap,
					icon: {
						scaledSize: new google.maps.Size(
							30, 30),
						origin: new google.maps.Point(
							0, 0),
						url: fixed.assetType.imagePath,
						anchor: new google.maps.Point(
							16, 16),

					}
				});

				marker.setMap(locMap);

				var infowindow = new google.maps.InfoWindow({
					content: fixed.name
				});

				google.maps.event.addListener(marker, 'mouseover', function () {
					if (openedInfoWindow != null) {
						openedInfoWindow.close();
					}
					infowindow.open(locMap, marker);
					openedInfoWindow = infowindow;
				});
				google.maps.event.addListener(marker, 'mouseout', function () {
					if (openedInfoWindow != null) {
						openedInfoWindow.close();
					}
				});
				openedInfoWindow = infowindow;
			}
		}

		function drawPolyline(spread, locMap) {

			var polylineColor = null;

			var spreadAssetCoordinates = [];
			var assetCoordinatesList = spread.assetCoordinates;

			assetCoordinatesList.forEach(function (value) {
				spreadAssetCoordinates.push({
					lat: value.latitude,
					lng: value.longitude
				});
			});

			if (spreadAssetCoordinates.length > 0) {
				var midLatLng = {
					lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
					lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
				};

				var infowindow = new google.maps.InfoWindow({
					content: '<div class="infoWindowhead">' +
						spread.name +
						'</div>'
				});

				if (spread.assetType.layout == "SPREAD") {
					polylineColor = spread.assetType.colorcode;
				}
				infowindow.setPosition(midLatLng);
				var polylinepath = new google.maps.Polyline({
					path: spreadAssetCoordinates,
					geodesic: true,
					strokeColor: polylineColor,
					strokeOpacity: 1.0,
					strokeWeight: 2.5,
					fillColor: spread.assetType.colorcode,
					fillOpacity: 0.3,
					editable: false
				});

				google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
					infowindow.close();
				});

				google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
					if (openedInfoWindow != null) {
						openedInfoWindow.close();
					}

					infowindow.setContent('<div class="infoWindowhead">' +
						spread.name +
						'</div>'
					);

					infowindow.mousemove
					infowindow.setPosition(event.latLng);
					infowindow.open(locMap);
					openedInfoWindow = infowindow;
				});

				if (spread.status != null && spread.status === false) {
					polylinepath.setOptions({
						strokeColor: polylineColor
					});
				}

				polylinepath.setMap(locMap);
			}
		}

		function filterActivatedUser() {
			if (vm.trCase.assignedToUser != null && vm.trCase.assignedToUser != "" && !angular.isUndefined(vm.trCase.assignedToUser)) {
				if($rootScope.has_ROLE_TEAM_LEADER && vm.prevStatus=="ASSIGNED" && vm.caseType.name=="Vendor Civil Work"){
					AssocitedVendorSearch.query({
						page: 0,
                        size: 10,
						userId: vm.trCase.assignedToUser,
					}, onSuccess, onError);
				}else{
					ActivatedUserSearch.query({
						userId: vm.trCase.assignedToUser,
					}, onSuccess, onError);
				}

				function onSuccess(data, headers) {
					vm.activatedusers = [];
					data.forEach(function (dataVal) {
						if (vm.trCase.id && $rootScope.has_ROLE_NETWORK_ENGINEER) {
							vm.activatedusers.push(dataVal);
							/* dataVal.authorities.forEach(function (val) {
								if (val == "ROLE_FRT") {
									
								}
							}); */

						} else {
							vm.activatedusers.push(dataVal);
						}
					});

					angular.forEach(vm.activatedusers, function (value, key) {
						usersMap[value.login] = value.id;
					});
					vm.isSaving = false
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
			}
		}

		

		// Added for asset search start
		function filterAssetsList() {
			if (vm.trCase.asset.name != null && vm.trCase.asset.name != "" && !angular.isUndefined(vm.trCase.asset.name)) {
				/*
				 * AssetSearchForMap.searchassetsformap(vm.trCase.asset.name,
				 * function (response) { vm.assetList = response; });
				 */

				AssetSearch.query({
					search: vm.trCase.asset.name,

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					vm.assetList = data;

				}

				function onError(error) {
					AlertService.error(error.data.message);
				}

			}
		};

		// Added for getting lat alng values from map
		var latlng = new google.maps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE);
		/* var settings = {
			zoom: 14,
			center: latlng,
			mapTypeControl: true,
			scaleControl: true,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
			},
			navigationControl: true,
			navigationControlOptions: {
				style: google.maps.NavigationControlStyle.DEFAULT
			},
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			backgroundColor: 'white',

		}; */

		locations.map = new google.maps.Map(document.getElementById('map_canvas'), myOption(14,latlng)/* settings */);
		geoLocation(locations.map)
		var address = new google.maps.Geocoder();
		google.maps.event.addListener(locations.map, 'click', function (event) {

			address.geocode({
				'latLng': event.latLng
			}, function (results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					if (results[0]) {
						$ngConfirm({
							title: 'Are you sure you want to select this address?',
							content: '<strong>Address: </strong>' + results[0].formatted_address + '<br/>' + '<strong>Latitude: </strong>' + event.latLng.lat() + '</br>' +
								'<strong>Longitude: </strong>' + event.latLng.lng(),
							type: "blue",
							typeAnimated: true,
							buttons: {
								OK: {
									text: 'OK',
									btnClass: 'btn-primary',
									action: function () {
										$scope.$apply(function () {
											vm.trCase.pinLat = event.latLng.lat();
											vm.trCase.pinLong = event.latLng.lng();
											vm.trCase.address = results[0].formatted_address;
											// alert('Lat: ' +
											// event.latLng.lat() + ' Lng: ' +
											// event.latLng.lng())
										})
									}
								},
								Cancel: {
									text: 'Cancel',
									btnClass: 'btn-danger',
									action: function () {

									}

								},

							}
						});

					}
				}
			});
		});

		function setCaseGeofences() {
			var isUserCaseAdmin = false;
			vm.userAdmin.authorities.forEach(function (user) {
				if (user == "ROLE_USER_ADMIN") {
					isUserCaseAdmin = true;
				}
				if (user == "ROLE_HOD") {
					isUserCaseAdmin = true;
				}
			});
			if (isUserCaseAdmin) {
				// Geofence.query({
				// 	page: 0,
				// 	size: 500,
				// }, onSuccess, onError);

				// function onSuccess(data, headers) {
				// 	vm.selectedGeofence = [];
				// 	data.forEach(function (value) {
				// 		if (value.zone) {
				// 			value.type = "MASTER";
				// 		} else {
				// 			value.type = "MINI";
				// 		}
				// 		vm.selectedGeofence.push(value);
				// 	});
				// 	drawLatestGeofence(vm.selectedGeofence);
				// }

				// function onError(error) {
				// 	// AlertService.error(error.data.message);
				// }

				GeofenceIDs.getAll(function (response) {
					vm.selectedGeofence = [];
					response.forEach(function (value) {
						if (value.zone) {
							value.type = "MASTER";
						} else {
							value.type = "MINI";
						}
						vm.selectedGeofence.push(value);
					});
					drawLatestGeofence(vm.selectedGeofence);
				});

			} else {
				drawLatestGeofence(vm.userGeofenceList);
			}
		}

		function drawLatestGeofence(response) {
			if (response) {
				initializeGeofenceMap(response, function (latlang) {
					locations.latlng = latlang;
					// function() {
					$.each(response, function (index, value) {
						try {
							if (value.type != "Master") {
								var path = JSON.parse(value.coordinates);
								// drawPolylineForGeoFence(path, locations.map, value.name);
								var infowindow = new google.maps.InfoWindow(
									{
										content: '<div class="infoWindowhead">'
											+ "name"
											+ '<div class="infoWindowContent"> <b>Description :</b> '
											+ "des" + '</div></div>'
									});

								infowindow.setPosition(latlang);
								var tempGeofences = new google.maps.Polyline({
									path: path,
									geodesic: true,
									strokeColor: "#ff0000",
									strokeOpacity: 0.5,
									strokeWeight: 3
								});
								google.maps.event.addListener(tempGeofences, 'mouseout', function (event) {
									infowindow.close();
								});

								google.maps.event.addListener(tempGeofences, 'click', function (event) {
									if (openedInfoWindow != null) {
										openedInfoWindow.close();
									}
									infowindow.setContent('<div class="infoWindowhead">'
										+ '<b>Geofence Name : </b><br><br>' + value.name + '</br></div>'
									);

									infowindow.mousemove;
									infowindow.open(locations.map);
									infowindow.setPosition(event.latLng);
									openedInfoWindow = infowindow;
								});
								tempGeofences.setMap(locations.map);
							}
						} catch (err) {
							console.log(err);
						}
					});
				});
			}
		}

		function initializeGeofenceMap(latlangs, callback) {
			if (latlangs && latlangs.length > 0) {
				latlng = new google.maps.LatLng(latlangs[0].pinLat, latlangs[0].pinLong);
				callback(latlng);
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (position) {

						latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
						callback(latlng);
					}, function () {

						callback(new google.maps.LatLng("5.431587", "101.128162"));
					});
				} else {
					callback(new google.maps.LatLng("5.431587", "101.128162"));
				}
			}
		}

		function filterAssetsOnMapView() {
			$("#mapFilterModal").show();
			noDataText = document.getElementById("noData");
			noDataText.style.display = "none";
			vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
		}

		function closeMapFilterModal(id) {
			vm.isSaving = false;
			$("#"+id).removeClass("in");
			$(".modal-backdrop").remove();
			$("#"+id).hide();
			vm.selectedGeofenceId = vm.prevSelectedGeofenceId;
		}

		function closePopupDialogModal() {
			$("#popupDialogModal").removeClass("in");
			$(".modal-backdrop").remove();
			$("#popupDialogModal").hide();
			vm.isSaving = false;
		}

		function closeModal(id) {
			$("#scrollTop").scrollTop(0);
			if(vm.editDetails){
				if(id == 'splicingDetails'){
					vm.trCase.splicingDetails.splice(vm.selectedSlicing,1)
				}
				if(id == 'cableDetails'){
					vm.trCase.cableDetails.splice(vm.selectedCable,1)
				}
				if(id == 'fmsDetails'){
					vm.trCase.fmsAndCableDetails.splice(vm.selectedFms,1)
				}
				if(id == 'terminationDetails'){
					vm.trCase.terminationDetails.splice(vm.selectedTermination,1)
				}
			}else{
				vm.trCase = vm.new
			}
			$("#"+id).removeClass("in");
			$(".modal-backdrop").remove();
			$("#"+id).hide();
		}

		function saveFilter() {
			vm.mapAssets = [];
			markers = [];
			polylines = [];
			/* var myOptions = {
				zoom: 16,
				center: latlng,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			}; */
			locations.map = new google.maps.Map(document.getElementById("map_canvas"), myOption(16,latlng));
			geoLocation(locations.map)
			getGroupAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets);
			vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
			setCaseGeofences();
			google.maps.event.addListener(locations.map, 'click', function (event) {

				address.geocode({
					'latLng': event.latLng
				}, function (results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						if (results[0]) {
							$ngConfirm({
								title: 'Are you sure you want to select this address?',
								content: '<strong>Address: </strong>' + results[0].formatted_address + '<br/>' + '<strong>Latitude: </strong>' + event.latLng.lat() + '</br>' +
									'<strong>Longitude: </strong>' + event.latLng.lng(),
								type: "blue",
								typeAnimated: true,
								buttons: {
									OK: {
										text: 'OK',
										btnClass: 'btn-primary',
										action: function () {
											$scope.$apply(function () {
												vm.trCase.pinLat = event.latLng.lat();
												vm.trCase.pinLong = event.latLng.lng();
												vm.trCase.address = results[0].formatted_address;
											})
										}
									},
									Cancel: {
										text: 'Cancel',
										btnClass: 'btn-danger',
										action: function () {

										}

									},

								}
							});

						}
					}
				});
			});
			$("#mapFilterModal").removeClass("in");
			$(".modal-backdrop").remove();
			$("#mapFilterModal").hide();
		}

		function getGroupAssetsFromES(geofenceIdVal, lastAssetId, mapAssetLists) {
			AssetGroupES.get({
                "geofences": [geofenceIdVal],
                "assetTypes": [],
                "phases": [],
                "workflowStatus": [],
                "lastAssetId": lastAssetId
              }, function (data) {
				vm.mapAssets = mapAssetLists;
				data.assets.forEach(function (asset) {
					var tmpAsset = {};

					tmpAsset.assetCoordinates = asset.assetCoordinates;
					tmpAsset.assetTypeName = asset.assetTypeName;
					tmpAsset.assetTypeColor = asset.assetColor;
					tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
					tmpAsset.assetTypeLayout = asset.assetTypeLayout;
					tmpAsset.id = asset.id;
					tmpAsset.name = asset.name;

					vm.mapAssets.push(tmpAsset);
				});
				drawLatestAssets(vm.mapAssets);
				if (data.lastId != null) {
					getGroupAssetsFromES(geofenceIdVal, data.lastId, vm.mapAssets);
				} else {
					if (vm.mapAssets.length > 0) {
						var assetSelected = vm.mapAssets[0];
						AssetByID.get(assetSelected.id, function (assetVal) {
							var assetLatLng = ({
								lat: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].latitude,
								lng: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].longitude
							});
							locations.map.setCenter(assetLatLng);
						});
					}
				}
			});
		}

		function hideEditModal() {
			$("#userPathModal").removeClass("in");
			$(".modal-backdrop").remove();
			$("#userPathModal").hide();
		}
		$('#userPathModal').on('hidden.bs.modal', function () {
			//			filterAssetsformap();
		})

		function getAssetAddress() {
			if (vm.trCase.pinLat != null && vm.trCase.pinLong != null) {
				var latlng = new google.maps.LatLng(vm.trCase.pinLat, vm.trCase.pinLong);
				// This is making the Geocode request
				var geocoder = new google.maps.Geocoder();
				geocoder.geocode({ 'latLng': latlng }, function (results, status) {
					// if (status !== google.maps.GeocoderStatus.OK) {
					//	console.log(status);
					// }
					// This is checking to see if the Geoeode Status is OK before proceeding
					if (status == google.maps.GeocoderStatus.OK) {
						$scope.$apply(function () {
							vm.trCase.address = results[0].formatted_address;
						});
					}
				});
			}
		}

		function drawLatestAssets(response) {
			if (response) {
				initializeAssetMap(response, function (latlang) {
					locations.latlng = latlang;

					setupAssetMap(function () {
						$.each(response, function (index, value) {
							if (value.assetTypeLayout == null) {
								value.assetTypeName = value.assetType.name;
								value.assetTypeLayout = value.assetType.layout;
								value.assetTypeImagePath = value.assetType.imagePath;
							}
							if (value.assetTypeLayout != null && value.assetTypeLayout == 'FIXED') {
								if (value.assetCoordinates.length > 1) {
									drawPolyline(value);
									var value1 = value;
									value1.assetCoordinates = [value.assetCoordinates[value.assetCoordinates.length - 1]];

									drawFixedAsset(value1, false);

								} else {
									drawFixedAsset(value, false);
								}
							}
							if (value.assetTypeLayout != null && value.assetTypeLayout == 'SPREAD') {
								drawPolyline(value, locations.map);
							}

						});
					});
				});
			}
		}

		function initializeAssetMap(latlangs, callback) {

			if (latlangs && latlangs.length > 0) {
				var latVal = null;
				var lngVal = null;
				latlangs.forEach(function (value) {
					if (latVal == null && lngVal == null) {
						if (value.assetCoordinates.length > 0) {
							latVal = value.assetCoordinates[value.assetCoordinates.length - 1].latitude;
							lngVal = value.assetCoordinates[value.assetCoordinates.length - 1].longitude;
						}
					}
				});
				if (latVal != null && lngVal != null) {
					latlng = new google.maps.LatLng(latVal, lngVal);
					callback(latlng);
				}
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (
						position) {

						latlng = new google.maps.LatLng(
							position.coords.latitude,
							position.coords.longitude);
						callback(latlng);
					}, function () {

						// callback(new google.maps.LatLng("12.9716", "77.5946"));
						callback(new google.maps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE));
					});
				} else {
					callback(new google.maps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE));
				}
			}
		}

		function setupAssetMap(callback) {
			if (locations.map == null) {
				/* var myOptions = {
					zoom: 14,
					center: locations.latlng,
					mapTypeId: google.maps.MapTypeId.ROADMAP
				}; */

				locations.map = new google.maps.Map(document
					.getElementById("map_canvas"), myOption(14,locations.latlng));
				geoLocation(locations.map)
				google.maps.event.addListener(locations.map, 'zoom_changed', function () {
					vm.zoomLevel = locations.map.getZoom();
					if ((vm.prevZoomLevel == 17 && vm.zoomLevel == 18) || (vm.prevZoomLevel == 18 && vm.zoomLevel == 17)) {
						drawLatestAssets(vm.mapAssets);
					}
					vm.prevZoomLevel = vm.zoomLevel;

				});
			} else {
				// Loop through all the markers and polylines to remove

				for (var i = 0; i < markers.length; i++) {
					markers[i].setMap(null);
				}

				for (i = 0; i < polylines.length; i++) {
					polylines[i].setMap(null);
				}

				markers = [];
				polylines = [];
			}
			locations.overlay = new google.maps.OverlayView();
			locations.overlay.draw = function () { };
			locations.overlay.setMap($scope.map);
			locations.element = document.getElementById('map_canvas');
			callback();
		}

		function drawPolyline(spread, map) {

			var spreadAssetCoordinates = [];
			$.each(spread.assetCoordinates, function (index, value) {

				spreadAssetCoordinates.push({
					lat: value.latitude,
					lng: value.longitude
				});
			});
			if (spreadAssetCoordinates.length > 1) {
				var midLatLng = {
					lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
					lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
				};

				var polylineColor = '';
				var distanceFromPOP = '';
				var fiberCoreVal = '';
				var infowindow = null;
				if (spread.assetTypeLayout == "SPREAD") {
					polylineColor = spread.assetTypeColor
				}
				var polylineWeight = 2.5;
				var strokeOpacityVal = 1.0;

				var polylinepath = new google.maps.Polyline({
					path: spreadAssetCoordinates,
					geodesic: true,
					strokeColor: polylineColor,
					strokeOpacity: strokeOpacityVal,
					strokeWeight: polylineWeight,
					fillColor: polylineColor,
					fillOpacity: 0.3,
					editable: false
				});

				google.maps.event.addListener(polylinepath, 'click', function (event) {
					if (openedInfoWindow != null) {
						openedInfoWindow.close();
					}
					var disVal = 0;
					var cLocations = [];

					if (spread.description == null || spread.description == undefined) {
						spread.description = "";
					}

					if (spread.assetTypeLayout == "SPREAD") {
						AssetByID.get(spread.id, function (assetVal) {
							spread = assetVal;
							var infoStr = "";
							var assetTypeAttributeValuesList = spread.assetTypeAttributeValues;
							assetTypeAttributeValuesList.sort(function (a, b) {
								var nameA = a.assetTypeAttribute.name.toLowerCase(),
									nameB = b.assetTypeAttribute.name.toLowerCase()
								if (nameA < nameB) // sort string ascending
									return -1
								if (nameA > nameB)
									return 1
								return 0 // default return value (no sorting)
							});
							for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
								var home = assetTypeAttributeValuesList[i];

								var attrVal = "";
								if (home.attributeValue != null) {
									attrVal = home.attributeValue;
								}
								infoStr += '<br><b>' +
									home.assetTypeAttribute.name +
									'</b> : ' +
									attrVal;
							}
							var spreadCoordinates = spread.assetCoordinates;
							if (spreadCoordinates.length > 0) {
								for (var index in spreadCoordinates) {
									if (index > 0) {
										disVal += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
									}
								}
							}

							var userContent = document.createElement('div'),
								button;

							userContent.innerHTML = '<div class="infoWindowhead">' +
								'<b>Name : </b> ' + spread.name + '</br>' +
								'<b>Distance : </b> ' + (parseFloat(disVal)).toFixed(2) + ' Km</br>';

							infowindow = new google.maps.InfoWindow({
								content: userContent
							});
						});
					}
					if (infowindow != null) {
						infowindow.setPosition(event.latLng);
						infowindow.open(locations.map);
						openedInfoWindow = infowindow;
					}
				});

				var polylinepathDuct = new google.maps.Polyline({
					path: spreadAssetCoordinates,
					geodesic: true,
					strokeColor: polylineColor,
					strokeOpacity: 1,
					strokeWeight: 2,
					fillColor: polylineColor,
					fillOpacity: 0.3,
					editable: false
				});

				polylinepath.setMap(locations.map);

				polylines.push(polylinepath);
			}
		}

		function drawFixedAsset(fixed, hasParent) {

			$.each(fixed.assetCoordinates, function (index, value1) {
				if (openedInfoWindow != null) {
					openedInfoWindow.close();
				}

				var infoWindow = new google.maps.InfoWindow();


				var iconUrl = "";
				var isFixedAsset = false;
				iconUrl = fixed.assetTypeImagePath;
				isFixedAsset = true;

				var iconSize = 8;
				if (vm.zoomLevel == 10) {
					iconSize = 8;
				} else if (vm.zoomLevel == 11) {
					iconSize = 16;
				}

				if (isFixedAsset) {
					iconSize = 24;
				}

				var startMarker = new google.maps.Marker({
					map: locations.map,
					position: new google.maps.LatLng(
						value1.latitude,
						value1.longitude),
					title: fixed.name,
					icon: {
						scaledSize: new google.maps.Size(
							iconSize, iconSize),
						url: iconUrl,
						anchor: new google.maps.Point(
							iconSize / 2, iconSize / 2),
					},
				});


				markers.push(startMarker);

				var infoWindow2 = new google.maps.InfoWindow();
				var infoStr = "";
				var otdr = "";

				var userContent = document.createElement('div'),
					button;

				userContent.innerHTML = '<div class="infoWindowhead">' + startMarker.title +
					'</div><div class="infoWindowContent">' +
					'<b>Lat</b> :' +
					fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
					'<br><b>Long</b> :' +
					fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
					'<br>' + infoStr + '</div>';

				google.maps.event.addListener(startMarker,
					'dblclick',
					function () {
						infoWindow2.close();
					});

				var activeInfoWindow;
				var infoWindow2 = new google.maps.InfoWindow({
					content: userContent
				});
				// add listener on InfoWindow for click
				// event
				google.maps.event.addListener(startMarker, 'click', function () {
					AssetByID.get(fixed.id, function (response) {
						var infoWindow2 = new google.maps.InfoWindow();
						var infoStr = "";
						var otdr = "";
						var assetTypeAttributeValuesList = response.assetTypeAttributeValues;
						assetTypeAttributeValuesList.sort(function (a, b) {
							var nameA = a.assetTypeAttribute.name.toLowerCase(),
								nameB = a.assetTypeAttribute.name.toLowerCase()
							if (nameA < nameB) // sort string ascending
								return -1
							if (nameA > nameB)
								return 1
							return 0 // default return value (no sorting)
						});
						for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
							var home = assetTypeAttributeValuesList[i];
							infoStr += '<br><b>' +
								home.assetTypeAttribute.name +
								'</b> : ' +
								home.attributeValue;
							otdr = home;
						}

						var userContent = document.createElement('div'),
							buttonA, buttonB, buttonC, buttonD;
						userContent.innerHTML = '<div class="infoWindowhead">' + startMarker.title +
							'</div><div class="infoWindowContent">' +
							'<b>Lat</b> :' +
							fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
							'<br><b>Long</b> :' +
							fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
							'<br>' + infoStr + '</div>';

						var infoWindow2 = new google.maps.InfoWindow({
							content: userContent
						});
						if (prevInfoWindow != null)
							prevInfoWindow.close();
						infoWindow2.open(locations.map, startMarker);
						setTimeout(function () { infoWindow2.close(); }, 5000);

						activeInfoWindow = infoWindow2;
						prevInfoWindow = infoWindow2;
					});

				});
				openedInfoWindow = infoWindow2;
			});
		}

		function distanceCal(lat1, lon1, lat2, lon2) {
			var theta = lon1 - lon2;
			var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
				Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
			dist = Math.acos(dist);
			dist = radians_to_degrees(dist);
			dist = dist * 60 * 1.1515;
			dist = dist * 1.609344;
			if (isNaN(dist)) {
				return 0.0;
			} else {
				return (dist);
			}
		}

		function degrees_to_radians(degrees) {
			var pi = Math.PI;
			return degrees * (pi / 180);
		}

		function radians_to_degrees(radians) {
			var pi = Math.PI;
			return radians * (180 / pi);
		}

		vm.callGroup = function(){
			vm.isSaving = false
		}

	}
})();