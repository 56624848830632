(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("AssetController", AssetController)

        .directive("fileModel", [
            "$parse",
            function ($parse) {
                return {
                    restrict: "A",
                    link: function (
                        scope,
                        element,
                        attrs,
                        $rootScope,
                        $broadcast
                    ) {
                        var model = $parse(attrs.fileModel);
                        var modelSetter = model.assign;

                        element.bind("change", function () {
                            var files = element[0].files;
                            var allowedTypes = {
                                "image/png": true,
                                "image/jpg": true,
                                "image/jpeg": true,
                            };

                            var data = {
                                file: files,
                                types: allowedTypes,
                            };
                            scope.$emit("callFunction", data);
                            //uploadFiles("reference", allowedTypes,files);
                        });
                    },
                };
            },
        ])

        .directive("fileStyle", function () {
            return {
                link: function (scope, element) {
                    $(element).filestyle({
                        buttonBefore: true,
                    });
                    $(element).filestyle("buttonText", "Browse File");
                },
            };
        });

    AssetController.$inject = [
        "$scope",
        "$rootScope",
        "$state",
        "pagingParams",
        "paginationConstants",
        "ParseLinks",
        "DEFAULT_LATITUDE",
        "DEFAULT_LONGITUDE",
        "AssetTypesByGeofenceID",
        "UserGeofences",
        "GeofenceIDs",
        "AssetGroup",
        "AssetGroupSearch",
        "AssetGroupES",
        "AssetByID",
        "UserAccount",
        "AssetsBulkDelete",
        "AssetGroupWithGeofence",
        "AssetGroupSearchWithGeofence",
        "AssetType",
        "AlertService",
        "Asset",
        "AllCustomAssetTypeAttribute",
        "UserMasterGeofences",
        "GeofenceByID",
        "MiniGeofencesByMasterID",
        "AssetTypesByGeofenceIDs",
        "AssetGroupByGeofencesES",
        "$localStorage",
        "Configs",
        "casesInMap",
        "CaseType",
        "EntityImport",
        "$timeout",
        "ChildAssetMarkers",
        "AssetByMarkerId",
        "postCoreCable",
        "postCoreJoiningkit",
        "getSpreadAssetId",
        "getCableName",
        "getJkDetails"
    ];

    function AssetController(
        $scope,
        $rootScope,
        $state,
        pagingParams,
        paginationConstants,
        ParseLinks,
        DEFAULT_LATITUDE,
        DEFAULT_LONGITUDE,
        AssetTypesByGeofenceID,
        UserGeofences,
        GeofenceIDs,
        AssetGroup,
        AssetGroupSearch,
        AssetGroupES,
        AssetByID,
        UserAccount,
        AssetsBulkDelete,
        AssetGroupWithGeofence,
        AssetGroupSearchWithGeofence,
        AssetType,
        AlertService,
        Asset,
        AllCustomAssetTypeAttribute,
        UserMasterGeofences,
        GeofenceByID,
        MiniGeofencesByMasterID,
        AssetTypesByGeofenceIDs,
        AssetGroupByGeofencesES,
        $localStorage,
        Configs,
        casesInMap,
        CaseType,
        EntityImport,
        $timeout,
        ChildAssetMarkers,
        AssetByMarkerId,
        postCoreCable,
        postCoreJoiningkit,
        getSpreadAssetId,
        getCableName,
        getJkDetails
    ) {
        var vm = this;
        $scope.tab = 1;
        vm.selectedListGeofenceId = parseInt(pagingParams.listgeofenceid);
        if (isNaN(vm.selectedListGeofenceId)) {
            vm.selectedListGeofenceId = 0;
        }
        $scope.search = pagingParams.search;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        $("#pagenum").val(pagingParams.page + "");
        if (pagingParams.pagesize != null) {
            vm.itemsPerPage = pagingParams.pagesize;
            //Get select object
            var objSelect = document.getElementById("page_size");

            //Set selected
            setSelectedValue(objSelect, vm.itemsPerPage);
        }
        vm.filterAssetTypes = [];
        vm.filterAssetTypesCity = [];
        vm.assetToEdit = {};
        vm.transition = transition;
        vm.goToPage = goToPage;
        vm.filterAssetsOnListView = filterAssetsOnListView;
        vm.imageUpload = imageUpload;
        vm.showAssetImage = false;

        vm.userGeofences = [];
        vm.userGeofencesCity = [];
        vm.geofencesListView = [];
        vm.selectedMapGeofenceId = null;
        vm.prevSelectedGeofenceId = null;
        vm.selectedMapGeofenceIds = [];
        vm.prevSelectedMapGeofenceIds = [];
        vm.editAssetOnMapView = editAssetOnMapView;
        vm.editAssetMarker = editAssetMarker;
        vm.closeEditAssetModal = closeEditAssetModal;
        vm.showAssetMarkerDetails = showAssetMarkerDetails;
        vm.saveEditMarkerAssetModal = saveEditMarkerAssetModal;
        vm.editAssetMarkerOnMapView = editAssetMarkerOnMapView;
        vm.closeEditAssetMarkerModal = closeEditAssetMarkerModal;
        vm.saveEditAssetModal = saveEditAssetModal;
        vm.viewAssetMarkerOnMapView = viewAssetMarkerOnMapView;
        vm.closeViewAssetMarkerModal = closeViewAssetMarkerModal;
        vm.getAssetsForListView = getAssetsForListView;
        vm.filterAssetsOnMapView = filterAssetsOnMapView;
        vm.filterAssetsOnMapViewCity = filterAssetsOnMapViewCity;
        vm.closeMapFilterModal = closeMapFilterModal;
        vm.closeMapFilterModalCity = closeMapFilterModalCity;
        vm.closeImageUpload = closeImageUpload;
        vm.closeListFilterModal = closeListFilterModal;
        vm.saveListFilter = saveListFilter;
        vm.saveAssetTypeFilter = saveAssetTypeFilter;
        vm.saveAssetTypeFilterCity = saveAssetTypeFilterCity;
        vm.createAsset = createAsset;
        vm.saveFilter = saveFilter;
        vm.saveFilterCity = saveFilterCity;
        vm.selectAllAssets = selectAllAssets;
        vm.selectAssetEntity = selectAssetEntity;
        vm.selectAsets = selectAsets;
        vm.gotoLatitute = gotoLatitute;
        vm.gotoLatituteCity = gotoLatituteCity;
        vm.closeGotoLatitute = closeGotoLatitute;
        vm.closeGotoLatituteCity = closeGotoLatituteCity;
        vm.showLayers = showLayers;
        vm.showLayersCity = showLayersCity;
        vm.closeLayers = closeLayers;
        vm.showZoomLocation = showZoomLocation;
        vm.showAssetAttributesModal = showAssetAttributesModal;
        vm.closeAssetAttributesModal = closeAssetAttributesModal;
        vm.closeThreePointReference = closeThreePointReference;
        vm.confirmDelete = confirmDelete;
        vm.saveImage = saveImage;
        vm.customAttributesMap = {};
        vm.zoomLevel = 12;
        vm.zoomLevelCity = 10;
        vm.userMasterGeofences = [];
        vm.previousPolygons = [];

        vm.assetMarkerTable = assetMarkerTable;

        var gotoMarker = null;
        var isLevel1AssetsVisible = false;
        var isLevel2AssetsVisible = false;
        var isLevel3AssetsVisible = false;
        var isLevel4AssetsVisible = false;
        var isLevel5AssetsVisible = false;
        var isLevelNodeVisible = false;
        var assetVisibility19 = false;
        var assetVisibility20 = false;

        var isLevel1AssetsVisibleCity = true;
        var isLevel2AssetsVisibleCity = true;
        var isLevel3AssetsVisibleCity = true;
        var isLevel4AssetsVisibleCity = true;
        var isLevel5AssetsVisibleCity = true;

        vm.zoom13 = false;
        vm.zoom14 = false;
        vm.zoomLabel18 = false;
        vm.zoomLabel19 = false;
        vm.zoomLabel20 = false;
        vm.fileWarning = false;

        var nodeCluster,existingHighlight,existingHighlightFixed;
        var nodeMarkers = [], statusList = [], phaseList = [];
        var nodePolyline = [];

        var level1AssetsMarkers = [];
        var level2AssetsMarkers = [];
        var level3AssetsMarkers = [];
        var level4AssetsMarkers = [];
        var level5AssetsMarkers = [];
        var level6AssetsMarkers = [];
        var level7AssetsMarkers = [];

        var level1AssetsMarkersCity = [];
        var level2AssetsMarkersCity = [];
        var level3AssetsMarkersCity = [];
        var level4AssetsMarkersCity = [];
        var level5AssetsMarkersCity = [];

        var level1AssetsPolyline = [];
        var level2AssetsPolyline = [];
        var level3AssetsPolyline = [];
        var level4AssetsPolyline = [];
        var level5AssetsPolyline = [];

        var level1AssetsPolylineCity = [];
        var level2AssetsPolylineCity = [];
        var level3AssetsPolylineCity = [];
        var level4AssetsPolylineCity = [];
        var level5AssetsPolylineCity = [];

        vm.isAllAssetSelected = false;
        // vm.isShowing = true;
        $scope.selectAll = false;
        $scope.options = [];
        vm.assetTypeValues = [];
        vm.assetTypeValuesCity = [];
        $scope.selectedFilterAssetTypes = [];
        $scope.selectedFilterAssetTypesCity = [];
        var locations = {};
        var locationsCity = {};
        var latlng = null;
        var openedInfoWindow = null;
        var prevInfoWindow = null;

        var markers = [];
        var markersCity = [];
        var polylines = [];
        var polylinesCity = [];
        var noDataText = null;
        vm.mapAssets = [];
        vm.mapAssetsCity = [];

        vm.selectedAssets = [];
        vm.isAllAssetSelected = false;
        var tempGeofenceId = null,
            caseMarker = [],
            lastOpenedInfoWindow,
            caseTypeIds;

        vm.selectedMasterGeofenceId = 0;

        $scope.bool = true;
        $scope.boolCity = true;

        vm.currentZoom = 12;
        vm.currentZoomCity = 12;

        vm.getCenter;
        vm.getCenterCity;
        var infowindowSearchCity;
        var infowindowSearch;
        var activeInfo;
        var activeInfoCity;
        var spreadPoly;
        var spreadPolyCity;
        var assetTimer;
        var polylineTimer;
        var assetTimerCity;
        var polylineTimerCity;
        var closeClick,tempList;
        $scope.hidethis = true;
        vm.closeModal = closeModal;
        vm.saveAttachments = saveAttachments;
        var filesSelected,
            accessEditAsset = false;
        vm.importxlsx = /^(.*?)\.(csv)$/;

        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");

        $scope.$on("callFunction", function (event, data) {
            // Call the function in this controller
            uploadFiles(
                "reference",
                data.types,
                data.file,
                event.targetScope.attribute.id
            );
        });

        var maxSize;
        Configs.get("MaxFileSize", function (response) {
            maxSize = response[0].configValue;
        });

        vm.isAssetImport = false;
        Configs.get("Features", function (response) {
            response.forEach(function (val) {
                if (
                    val.configKey == "IsNewAssetImport" &&
                    val.configValue == "True"
                ) {
                    vm.isAssetImport = true;
                }
            });
        });

        function uploadFiles(id, allowedTypes, fileReference, referenceId) {
            filesSelected = fileReference;
            for (var i = 0; i < filesSelected.length; i++) {
                if (
                    allowedTypes[filesSelected[i].type] &&
                    filesSelected[i].size <= maxSize
                ) {
                    if (id == "reference") {
                        vm.assetRefAttributes.forEach(function (val) {
                            if (val.id == referenceId) {
                                var fileReader_reference = new FileReader();
                                fileReader_reference.onload = function (
                                    fileLoadedEvent
                                ) {
                                    val.attributeValue = window.btoa(
                                        fileLoadedEvent.target.result
                                    );
                                    val.attributeName =
                                        fileLoadedEvent.target.name;
                                    val.attributeType =
                                        fileLoadedEvent.target.type;
                                };
                                fileReader_reference.name = filesSelected[
                                    i
                                ].name.split(".")[0];
                                fileReader_reference.type = filesSelected[
                                    i
                                ].type
                                    .split("/")[1]
                                    .toUpperCase();
                                fileReader_reference.readAsBinaryString(
                                    filesSelected[i]
                                );
                            }
                        });
                    }
                }
            }
        }

        $scope.geofencelabel = {
            search: "Search",
            nothingSelected: "Geofence",
        };

        $scope.type = {
            assetTypeLabel: {
                search: "Search",
                nothingSelected: "Asset Type",
                selectAll: "All",
                selectNone: "None",
                Done: "Done",
            },
            caseTypeLabel: {
                search: "Search",
                nothingSelected: "Case Type",
                selectAll: "All",
                selectNone: "None",
                Done: "Done",
            },
        };

        $scope.GeofenceCity = {
            search: "Search",
            nothingSelected: "Geofence",
            selectAll: "All",
            selectNone: "None",
            Done: "Done",
        };

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.centerCapture = function () {
            vm.getCenterCity = locationsCity.mapCity.center;
            vm.currentZoomCity = locationsCity.mapCity.getZoom();
        };
        // $scope.search = null;

        function createAsset() {
            sessionStorage.setItem("pageValue", "asset");
            $state.go("asset.new");
        }

        $(".select2").select2();
        function setSelectedValue(selectObj, valueToSet) {
            for (var i = 0; i < selectObj.options.length; i++) {
                if (selectObj.options[i].text == valueToSet) {
                    selectObj.options[i].selected = true;
                    return;
                }
            }
        }

        $scope.toggle = function () {
            $scope.allimages = true;
        };

        $scope.deleteDisplayImage = function (id) {
            vm.assetAttachments.assetImages.splice(id, 1);
            if (vm.assetAttachments.assetImages.length == 0) {
                filesSelected = null;
                $(":file").filestyle("clear");
            }
        };

        $("#assetImages").change(function () {
            filesSelected = document.getElementById("assetImages").files;
            for (var i = 0; i < filesSelected.length; i++) {
                if (
                    (filesSelected[i].type == "image/png" ||
                        filesSelected[i].type == "image/jpg" ||
                        filesSelected[i].type == "image/jpeg" ||
                        filesSelected[i].type == "application/pdf") &&
                    filesSelected[i].size <= 2621440
                ) {
                    vm.fileWarning = false;
                    var fileReader = new FileReader();
                    /* var name = filesSelected[i].name.split('.')[0]
                        var type = filesSelected[i].name.split('.')[1] */
                    fileReader.onload = function (fileLoadedEvent) {
                        vm.assetAttachments.assetImages.push({
                            image: window.btoa(fileLoadedEvent.target.result),
                            asset: {
                                id: vm.assetIdImg,
                            },
                            imageName: fileLoadedEvent.target.name,
                            imageType: fileLoadedEvent.target.type,
                        });
                    };
                    fileReader.name = filesSelected[i].name.split(".")[0];
                    fileReader.type = filesSelected[i].type
                        .split("/")[1]
                        .toUpperCase();
                    fileReader.readAsBinaryString(filesSelected[i]);
                } else {
                    filesSelected = null;
                    vm.fileWarning = true;
                }
            }
        });

        function saveAttachments() {
            if (filesSelected) {
                Asset.update(
                    vm.assetAttachments,
                    function () {
                        $("#modalAttachment").hide();
                        $(":file").filestyle("clear");
                        $scope.allimages = false;
                    },
                    function () {}
                );
            }
        }

        /* function onSuccess(){
            
        }

        function onError(e){
            console.error(e)
        } */

        function imageUpload(id) {
            vm.assetId = id;
            $("#myModalUpload").show();
        }

        function saveImage() {
            var photo = document.getElementById("assetImages").files[0]; // file from input
            var req = new XMLHttpRequest();
            var formData = new FormData();
            if (photo) {
                if (
                    photo.type == "image/png" ||
                    photo.type == "image/jpg" ||
                    photo.type == "image/jpeg" ||
                    photo.type == "application/pdf"
                ) {
                    var name = photo.name.split(".")[0];
                    var type = photo.name.split(".")[1].toUpperCase();
                    vm.fileWarning = false;
                    formData.append("file", photo);
                    req.open(
                        "POST",
                        "api/trakeyeassetimages/" +
                            vm.assetId.id +
                            "/" +
                            name +
                            "/" +
                            type
                    );
                    req.setRequestHeader(
                        "Authorization",
                        "Bearer " + $localStorage.authenticationToken
                    );
                    req.onload = function (e) {
                        if (this.status === 200) {
                            closeImageUpload();
                            $("#successImage").show();
                        } else {
                            closeImageUpload();
                            $("#errorImage").show();
                        }
                    };
                    req.send(formData);
                } else {
                    vm.fileWarning = true;
                }
            }
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    } /* 
                    {
                        elementType: "geometry.fill",
                        stylers: [
                            {
                                color: "#EFEFEF",
                            },
                        ],
                    }, */,
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    },
                ],
            });
        }

        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            var goTo = document.createElement("button");
            goTo.setAttribute("title", "Location Coordinates");
            goTo.classList.add("custom-map-control-button");
            var gotoIcon = document.createElement("i");
            gotoIcon.classList.add("fa");
            gotoIcon.classList.add("fa-location-arrow");
            gotoIcon.classList.add("geo");
            goTo.appendChild(gotoIcon);
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(goTo);

            /* var zoomLabel = document.createElement("span");
            zoomLabel.setAttribute("title", "Zoom");
            zoomLabel.classList.add("custom-map-control-button");
            var textnode = document.createTextNode("Zoom :"+vm.zoomLevel);
            zoomLabel.appendChild(textnode);
            map.controls[google.maps.ControlPosition.LEFT_TOP].push(
                zoomLabel
            );  */

            goTo.addEventListener("click", function () {
                gotoLatitute();
            });

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };

                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            // var image = {
                            //     url: "content/images/geoLocation.png", // url
                            //     scaledSize: new google.maps.Size(25, 25), // scaled size
                            //     origin: new google.maps.Point(0, 0), // origin
                            //     anchor: new google.maps.Point(0, 0), // anchor
                            // };
                            // var markerGeo = new google.maps.Marker({
                            //     position: pos,
                            //     icon: image,
                            // });
                            // map.setZoom(20);
                            // markerGeo.setMap(map);
                            map.setZoom(18);
                            map.setCenter(pos);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        if ($scope.tab == 1) {
            UserAccount.get(function (data) {
                vm.tenantId = data.id;
                var isAdmin = false;
                data.authorities.forEach(function (val) {
                    if (val == "ROLE_USER_ADMIN") {
                        isAdmin = true;
                    } else if (val == "ROLE_HOD") {
                        isAdmin = true;
                    }

                    if (
                        val == "ROLE_USER_ADMIN" ||
                        val == "ROLE_CITY_PLANNER"
                    ) {
                        accessEditAsset = true;
                    }
                });
                if (isAdmin) {
                    vm.adminFilter = true;
                    filterAssetsOnListView();
                    getAssetsForListView();
                } else {
                    vm.adminFilter = false;
                    filterAssetsOnListView();
                    getAssetsForListView();
                }
            });
            Configs.get("AssetImage", function (response) {
                if (response.length > 0) {
                    if (response[0].configValue == "True") {
                        vm.showAssetImage = true;
                    }
                }
            });

            AllCustomAssetTypeAttribute.getAll(function (response) {
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customAssetTypeAttributeValues;
                });
            });
            AssetType.query(
                {
                    page: 0,
                    size: 100,
                    sort: sort(),
                },
                onSuccess,
                onError
            );
            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    //  result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.assetTypes = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        $scope.setTab = function (newTab) {
            $scope.search = null;
            $scope.tab = newTab;
            if ($scope.tab == 1) {
                if (tempGeofenceId) {
                    vm.selectedListGeofenceId = tempGeofenceId;
                    getAssetsForListView();
                    vm.geofencesListView.forEach(function (item) {
                        if (item.id == vm.selectedListGeofenceId) {
                            item.ticked = true;
                        } else {
                            item.ticked = false;
                        }
                    });
                }

                /* getAssetsForListView();
                filterAssetsOnListView()
                vm.selectedMapGeofenceId = null;
                vm.userGeofences.forEach(function (value) {
                    if (vm.selectedMapGeofenceId == null) {
                        vm.selectedMapGeofenceId = value.id;
                    }
                }); */
            } else if ($scope.tab == 2) {
                CaseType.query(
                    {
                        page: 0,
                        size: 100,
                        sort: "update_date,desc",
                    },
                    onSuccess,
                    onError
                );
                function onSuccess(data) {
                    vm.caseType = data;
                    caseTypeIds = data
                        .filter(function (item) {
                            return item.showInAssetsView;
                        })
                        .map(function (item) {
                            return item.id;
                        });
                }
                function onError(error) {
                    console.error(error);
                }
                //vm.selectedGeofence.length=0
                vm.mapAssets = [];
                vm.mapAssetsCity = [];
                /* AssetTypesByGeofenceID.get(
                    vm.selectedMapGeofenceId,
                    function (assetTypeVal) {
                        vm.assetTypeValues = assetTypeVal;
                        getGroupAssetsFromES(
                            vm.selectedMapGeofenceId,
                            null,
                            vm.mapAssets
                        );
                    }
                );*/
                document.getElementById("myspan").textContent = " ";
                /*  var myOptions = {
                    zoom: 12,
                    center: new google.maps.LatLng(
                        DEFAULT_LATITUDE,
                        DEFAULT_LONGITUDE
                    ),
                    scaleControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    myOption(
                        12,
                        new google.maps.LatLng(
                            DEFAULT_LATITUDE,
                            DEFAULT_LONGITUDE
                        )
                    )
                );
                geoLocation(locations.map);
                filterAssetsOnMapView();
                if (tempGeofenceId) {
                    vm.selectedMapGeofenceId = tempGeofenceId;
                    saveFilter();
                }
                var input = document.getElementById("field_pinLat");
                input.value = "";

            } else if ($scope.tab == 3) {
                vm.userGeofencesCity.length = 0;
                vm.mapAssets = [];
                vm.userMasterGeofences = [];
                UserMasterGeofences.getAll(function (response) {
                    vm.userMasterGeofences = response;
                });
                vm.mapAssetsCity = [];
                document.getElementById("myspanCity").textContent = " ";
                /* var myOptions = {
                    zoom: vm.zoomLevelCity,
                    center: new google.maps.LatLng(
                        DEFAULT_LATITUDE,
                        DEFAULT_LONGITUDE
                    ),
                    scaleControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locationsCity.mapCity = new google.maps.Map(
                    document.getElementById("map_canva"),
                    myOption(
                        12,
                        new google.maps.LatLng(
                            DEFAULT_LATITUDE,
                            DEFAULT_LONGITUDE
                        )
                    )
                );
                geoLocation(locationsCity.mapCity);
                var input = document.getElementById("field_pinLat");
                input.value = "";
            }
        };

        $("#masterGeofence").on("change", function () {
            $scope.searchAssetCity = "";
            vm.mapAssetsCity = [];
            vm.userGeofencesCity = [];
            var value = $(this).val();
            vm.selectedMasterGeofenceId = value;
            GeofenceByID.get(value, function (geofenceDetails) {
                var points = JSON.parse(geofenceDetails.coordinates);
                locationsCity.latlng = {
                    lat: points[0].lat,
                    lng: points[0].lng,
                };
                /* var myOptions = {
                    zoom: 10,
                    center: locationsCity.latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locationsCity.mapCity = new google.maps.Map(
                    document.getElementById("map_canva"),
                    myOption(10, locationsCity.latlng)
                );
                geoLocation(locationsCity.mapCity);
                drawPolygon(
                    geofenceDetails,
                    locationsCity.latlng,
                    "#fd7a24",
                    1
                );
                vm.selectedMapGeofenceIds = [];
                MiniGeofencesByMasterID.get(
                    geofenceDetails.id,
                    function (response) {
                        if (response.length > 0) {
                            mapZoomChangeCity(locationsCity.mapCity);
                        } else {
                            document.getElementById("myspanCity").textContent =
                                "";
                        }
                        vm.userGeofencesCity = [];
                        response.forEach(function (value, index) {
                            value.ticked = true;
                            $scope.selectAll = true;
                            $scope.options[index] = true;
                            value.label = value.name.substring(0, 15);
                            vm.userGeofencesCity.push(value);
                            vm.selectedMapGeofenceIds.push(value.id);
                            vm.previousPolygons.push(
                                drawPolygon(
                                    value,
                                    locationsCity.latlng,
                                    "#00e658",
                                    2
                                )
                            );
                        });
                        AssetTypesByGeofenceIDs.get(
                            {
                                geofences: vm.selectedMapGeofenceIds,
                            },
                            function (assetTypeVal) {
                                vm.assetTypeValuesCity = assetTypeVal;
                                vm.filterAssetTypesCity = [];

                                assetTypeVal.forEach(function (assettype) {
                                    vm.filterAssetTypesCity.push({
                                        id: assettype.id,
                                        name: assettype.assetTypeName,
                                        ticked: false,
                                    });
                                });
                                $scope.selectedFilterAssetTypesCity = [];
                                for (
                                    var i = 0;
                                    i < vm.filterAssetTypesCity.length;
                                    i++
                                ) {
                                    if (
                                        vm.filterAssetTypesCity[i].name ==
                                            "KML Link" ||
                                        vm.filterAssetTypesCity[i].name ==
                                            "Optical Fiber Cable"
                                    ) {
                                        $scope.selectedFilterAssetTypesCity.push(
                                            vm.filterAssetTypesCity[i]
                                        );
                                        vm.filterAssetTypesCity[
                                            i
                                        ].ticked = true;
                                    } else {
                                        vm.filterAssetTypesCity[
                                            i
                                        ].ticked = false;
                                    }
                                }
                                var assetLayersbyCity = [];
                                vm.mapAssetsCity = [];
                                $scope.selectedFilterAssetTypesCity.forEach(
                                    function (item) {
                                        assetLayersbyCity.push(item.name);
                                    }
                                );
                                getGroupAssetsFromESCity(
                                    vm.selectedMapGeofenceIds,
                                    null,
                                    vm.mapAssetsCity,
                                    assetLayersbyCity
                                );
                            }
                        );
                    }
                );
            });
        });

        function drawTheAssetsCity() {
            vm.zoomLevelCity = locationsCity.mapCity.getZoom();

            if (vm.zoomLevelCity > 9) {
                var assetListCity = [];
                var chamberListCity = [];
                var couplerListCity = [];
                var poleListCity = [];
                var otherListCity = [];
                var handholeListCity = [];
                var jointClosureListCity = [];
                var SiteListCity = [];
                var handholeCHListCity = [];

                for (
                    var index = 0;
                    index < vm.assetTypeValuesCity.length;
                    index++
                ) {
                    if (
                        vm.assetTypeValuesCity[index].zoomLevel >= 10 &&
                        vm.assetTypeValuesCity[index].zoomLevel <= 14
                    ) {
                        vm.assetTypeValuesCity[index].assets.forEach(function (
                            assetVal
                        ) {
                            assetListCity.push(assetVal);
                        });
                    }
                    if (vm.assetTypeValuesCity[index].zoomLevel == 15) {
                        vm.assetTypeValuesCity[index].assets.forEach(function (
                            assetVal
                        ) {
                            chamberListCity.push(assetVal);
                        });
                    }
                    if (vm.assetTypeValuesCity[index].zoomLevel == 15) {
                        vm.assetTypeValuesCity[index].assets.forEach(function (
                            assetVal
                        ) {
                            handholeListCity.push(assetVal);
                        });
                    }
                    if (vm.assetTypeValuesCity[index].zoomLevel == 15) {
                        vm.assetTypeValuesCity[index].assets.forEach(function (
                            assetVal
                        ) {
                            handholeCHListCity.push(assetVal);
                        });
                    }
                    if (vm.assetTypeValuesCity[index].zoomLevel == 15) {
                        vm.assetTypeValuesCity[index].assets.forEach(function (
                            assetVal
                        ) {
                            SiteListCity.push(assetVal);
                        });
                    }
                    if (vm.assetTypeValuesCity[index].zoomLevel == 16) {
                        vm.assetTypeValuesCity[index].assets.forEach(function (
                            assetVal
                        ) {
                            couplerListCity.push(assetVal);
                        });
                    }
                    if (vm.assetTypeValuesCity[index].zoomLevel == 17) {
                        vm.assetTypeValuesCity[index].assets.forEach(function (
                            assetVal
                        ) {
                            poleListCity.push(assetVal);
                        });
                    }
                    if (vm.assetTypeValuesCity[index].zoomLevel == 16) {
                        vm.assetTypeValuesCity[index].assets.forEach(function (
                            assetVal
                        ) {
                            jointClosureListCity.push(assetVal);
                        });
                    }
                    if (vm.assetTypeValuesCity[index].zoomLevel == 18) {
                        vm.assetTypeValuesCity[index].assets.forEach(function (
                            assetVal
                        ) {
                            otherListCity.push(assetVal);
                        });
                    }
                }
                drawLatestAssetsCity(assetListCity, "level1");

                drawAssetsCity(chamberListCity, "level2");
                drawAssetsCity(handholeListCity, "level2");
                drawAssetsCity(SiteListCity, "level2");

                drawAssetsCity(couplerListCity, "level3");

                drawAssetsCity(poleListCity, "level4");
                drawAssetsCity(jointClosureListCity, "level4");

                drawAssetsCity(otherListCity, "level5");
            }
        }

        $scope.toggleUserGeofences = function (index) {
            vm.userGeofencesCity[index].checked = !vm.userGeofencesCity[index]
                .checked;
            $scope.selectAll = true;
            vm.userGeofencesCity.forEach(function (value) {
                if (!value.checked) {
                    $scope.selectAll = false;
                }
            });
        };

        $scope.toggleAll = function () {
            var checked = $scope.selectAll;
            for (var i = 0; i < vm.userGeofencesCity.length; i++) {
                $scope.options[i] = checked;
                vm.userGeofencesCity[i].checked = checked;
            }
        };

        function drawPolygon(value, latlang, color, zIndex) {
            var path = JSON.parse(value.coordinates);
            // drawPolylineForGeoFence(path, locations.map, value.name);
            var infowindow = new google.maps.InfoWindow({
                content:
                    '<div class="infoWindowhead">' +
                    "name" +
                    '<div class="infoWindowContent"> <b>Description :</b> ' +
                    "des" +
                    "</div></div>",
            });

            infowindow.setPosition(latlang);
            var strokeColor = "#ff6a08";

            if (color == "green") {
                strokeColor = "#00e658";
            } else if (color == "blue") {
                strokeColor = "#0066CC";
            }

            var tempPolygon = new google.maps.Polygon({
                paths: path,
                strokeColor: strokeColor,
                strokeOpacity: 0.5,
                strokeWeight: 1.2,
                fillColor: strokeColor,
                fillOpacity: 0.1,
                zIndex: zIndex,
            });
            google.maps.event.addListener(
                tempPolygon,
                "mouseout",
                function (event) {
                    infowindow.close();
                }
            );

            google.maps.event.addListener(
                tempPolygon,
                "click",
                function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.setContent(
                        '<div class="infoWindowhead">' +
                            "<b>Geofence Name : </b><br><br>" +
                            value.name +
                            "</br></div>"
                    );

                    infowindow.mousemove;
                    infowindow.open(locationsCity.mapCity);
                    infowindow.setPosition(event.latLng);
                    openedInfoWindow = infowindow;
                }
            );
            tempPolygon.setMap(locationsCity.mapCity);
            return tempPolygon;
        }

        $(function () {
            $("#page_size").change(function () {
                vm.itemsPerPage = parseInt(
                    document.getElementById("page_size").value
                );
                vm.showAssetDelete = false;
                vm.selectedAssets = [];
                getAssetsForListView();
                vm.selectedMapGeofenceId = null;
                vm.userGeofences.forEach(function (value) {
                    if (vm.selectedMapGeofenceId == null) {
                        vm.selectedMapGeofenceId = value.id;
                    }
                });
            });
        });

        function getAssetsForListView() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                var str = $scope.search + "";
                str = str.replace("/", "slash");
                if (vm.selectedListGeofenceId > 0) {
                    AssetGroupSearchWithGeofence.query(
                        {
                            search: str,
                            geofenceId: vm.selectedListGeofenceId,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                        },
                        onSuccessListSearch,
                        onErrorListSearch
                    );
                } else {
                    AssetGroupSearch.query(
                        {
                            search: str,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                        },
                        onSuccessListSearch,
                        onErrorListSearch
                    );
                }

                function onSuccessListSearch(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.totalItems =
                        Math.ceil(vm.queryCount / vm.itemsPerPage) * 10;
                    vm.assetsForListView = data;
                    tempList = []
                    data.forEach(function(item){
                        tempList.push({id:item.id, burriedStatus : item.burried})
                    })
                    vm.page = pagingParams.page;
                }

                function onErrorListSearch(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                if (vm.selectedListGeofenceId > 0) {
                    AssetGroupWithGeofence.query(
                        {
                            geofenceId: vm.selectedListGeofenceId,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                        },
                        onSuccessList,
                        onErrorList
                    );
                } else {
                    AssetGroup.query(
                        {
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                        },
                        onSuccessList,
                        onErrorList
                    );
                }

                function onSuccessList(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.totalItems =
                        Math.ceil(vm.queryCount / vm.itemsPerPage) * 10;
                    vm.assetsForListView = data;
                    tempList = []
                    data.forEach(function(item){
                        tempList.push({id:item.id, burriedStatus : item.burried})
                    })
                    vm.page = pagingParams.page;
                }

                function onErrorList(error) {
                    AlertService.error(error.data.message);
                }
            }
        }

        $scope.accessibleCheckChange = function (asset) {
            vm.checkAccessible = asset.accessible;
            vm.changeAcessibleStatus = vm.checkAccessible
                ? "Accessible"
                : "Inaccessible";
            var yes = document.getElementById("yes-button");

            yes.onclick = function () {
                AssetByID.get(asset.id, function (assetVal) {
                    assetVal.accessible = asset.accessible;
                    assetVal.assetImages = []
                    Asset.update(assetVal);
                });
            };

            var no = document.getElementById("remove-button");

            no.onclick = function () {
                getAssetsForListView();
            };

            var close = document.getElementById("close-button");

            close.onclick = function () {
                getAssetsForListView();
            };

            var closeModal = document.getElementById("myModal");

            closeModal.onclick = function (e) {
                if (e.target == this) {
                    getAssetsForListView();
                }
            };
        };

        vm.onChangeBuried = function(asset){
            vm.assetTitle = asset.name
            var yes = document.getElementById("yesButtonBurried");
            var no = document.getElementById("noButtonBurried");
            var close = document.getElementById("closeButtonBurried");

            var tempBuriedStatus = tempList.find(function(item){
                return item.id == asset.id
            })

            yes.onclick = function () {
                AssetByID.get(asset.id, function (assetVal) {
                    assetVal.burried = asset.burried;
                    assetVal.assetImages = []
                    Asset.update(assetVal);
                });
            };

            no.onclick = function () {
                asset.burried = tempBuriedStatus.burriedStatus
                $scope.$apply()
            };

            close.onclick = function () {
                asset.burried = tempBuriedStatus.burriedStatus
                $scope.$apply()
            };
        }

        function getGroupAssetsFromES(
            geofenceIdVal,
            lastAssetId,
            mapAssetLists
        ) {
            
            AssetGroupES.get({
                "geofences": [geofenceIdVal],
                "assetTypes": [],
                "phases": phaseList,
                "workflowStatus": statusList,
                "lastAssetId": lastAssetId
              }, function (data) {
                vm.mapAssets = mapAssetLists;
                if (lastAssetId == null) {
                    vm.filterAssetTypes = [];
                    if(data.assettypes.length){
                        data.assettypes.forEach(function (assettype) {
                            if(vm.assetTypes.length){
                                vm.assetTypes.forEach(function (item){
                                    if(item.id == assettype.id){
                                        if (item.layout == "FIXED") {
                                            assettype.icon =
                                                '<img  src="' +
                                                item.imagePath +
                                                '"style="width: 24px; height: 24px;">';
                                        } else {
                                            assettype.icon =
                                                '<img class="square" style="background-color:' +
                                                item.colorcode +
                                                ';">';
                                        }
                                    }
                                })
                            }
                            
                            
                            vm.filterAssetTypes.push({
                                id: assettype.id,
                                name: assettype.assetTypeName,
                                ticked: true,
                                icon:assettype.icon
                            });
                        });
                    }
                }
                data.assets.forEach(function (asset) {
                    var tmpAsset = {};

                    tmpAsset.assetCoordinates = asset.assetCoordinates;
                    tmpAsset.assetTypeName = asset.assetTypeName;
                    tmpAsset.assetTypeColor = asset.assetColor;
                    tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
                    tmpAsset.assetTypeLayout = asset.assetTypeLayout;
                    tmpAsset.id = asset.id;
                    tmpAsset.name = asset.name;
                    tmpAsset.burried = asset.burried
                    //tmpAsset.healthIndicator = asset.healthIndicator;
                    //tmpAsset.healthRemarks = asset.healthRemarks;

                    vm.mapAssets.push(tmpAsset);
                });

                if (data.assets.length < 500) {
                    for (
                        var index = 0;
                        index < vm.assetTypeValues.length;
                        index++
                    ) {
                        var assetType = vm.assetTypeValues[index];
                        var typeName = assetType.assetTypeName;
                        var typeAssetsList = [];
                        if (assetType.assets != null) {
                            typeAssetsList = assetType.assets;
                        }

                        vm.mapAssets.forEach(function (asset) {
                            if (typeName == asset.assetTypeName) {
                                typeAssetsList.push(asset);
                            }
                        });
                        assetType.assets = typeAssetsList;
                        vm.assetTypeValues[index] = assetType;
                        if (vm.assetTypeValues[index].zoomLevel >= 15) {
                            var labelLength = vm.assetTypeValues[index].assets;
                            for (var i = 0; i < labelLength.length; i++) {
                                vm.assetTypeValues[index].assets[i].label =
                                    vm.assetTypeValues[index].zoomLevel;
                            }
                        }
                    }

                    if (vm.zoomLevel == 12) {
                        var assetList = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 12) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        assetList.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawLatestAssets(assetList, "level1");
                        isLevel1AssetsVisible = true;
                    }

                    vm.nodeAssets = [];
                    var tempNodeAssets = [];

                    vm.mapAssets.forEach(function (asset) {
                        if (
                            asset.assetTypeName == "BX1" ||
                            asset.assetTypeName == "BX2" ||
                            asset.assetTypeName == "BX3" ||
                            asset.assetTypeName == "BX4" ||
                            asset.assetTypeName == "DX4" ||
                            asset.assetTypeName == "DX16" ||
                            asset.assetTypeName == "DX11" ||
                            asset.assetTypeName == "DX10" ||
                            asset.assetTypeName == "DX15" ||
                            asset.assetTypeName == "ADX1" ||
                            asset.assetTypeName == "DX7" ||
                            asset.assetTypeName == "DX12" ||
                            asset.assetTypeName == "Sr Elements_Ax" ||
                            asset.assetTypeName == "Sr Elements_Bx" ||
                            asset.assetTypeName == "Sr Elements_Dx" ||
                            asset.assetTypeName == "Sr Elements_Mcr" ||
                            asset.assetTypeName == "Sr Elements_AX" ||
                            asset.assetTypeName == "Sr Elements_BX" ||
                            asset.assetTypeName == "Sr Elements_DX" ||
                            asset.assetTypeName == "Sr Elements_MCR" ||
                            asset.assetTypeName == "Sr_Elements_Cluster_New" ||
                            asset.assetTypeName == "Sr_Elements_Cluster"
                        ) {
                            var lat = asset.assetCoordinates[0].latitude.toFixed(
                                4
                            );
                            var lng = asset.assetCoordinates[0].longitude.toFixed(
                                4
                            );
                            tempNodeAssets.push({
                                lat: lat,
                                lng: lng,
                                assetDetails: asset,
                            });
                        }
                    });
                    vm.nodeAssets = Object.values(groupByKey(tempNodeAssets));
                }
                // drawLatestAssets(vm.mapAssets);
                if (data.lastId != null) {
                    getGroupAssetsFromES(
                        geofenceIdVal,
                        data.lastId,
                        vm.mapAssets
                    );
                } else {
                    if (vm.mapAssets.length > 0) {
                        var assetSelected = vm.mapAssets[0];
                        var assetLatLng = {
                            lat:
                                assetSelected.assetCoordinates[
                                    assetSelected.assetCoordinates.length - 1
                                ].latitude,
                            lng:
                                assetSelected.assetCoordinates[
                                    assetSelected.assetCoordinates.length - 1
                                ].longitude,
                        };
                        try {
                            locations.map.setCenter(assetLatLng);
                        } catch (error) {
                            console.log(error);
                        }
                        /* AssetByID.get(assetSelected.id, function (assetVal) {
                            var assetLatLng = {
                                lat:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].latitude,
                                lng:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].longitude,
                            };
                            try {
                                locations.map.setCenter(assetLatLng);
                            } catch (error) {
                                console.log(error);
                            }
                        }); */
                    }
                }
                if(vm.isEnableNavigate){
                    $scope.fillTextbox(vm.storeAsset)
                    $scope.searchAsset = "";
                }
            });
        }

        function groupByKey(array) {
            var flags = [],
                output = [];
            for (var i = 0; i < array.length; i++) {
                if (flags[array[i].lat]) continue;
                flags[array[i].lat] = true;
                output.push(array[i].lat);
            }
            var result = [];
            for (var j = 0; j < output.length; j++) {
                var objArray = [];
                for (var k = 0; k < array.length; k++) {
                    if (output[j] == array[k].lat) {
                        objArray.push(array[k]);
                    }
                }
                result.push(objArray);
            }
            return result;
        }

        function getGroupAssetsFromESCity(
            geofenceIds,
            lastAssetId,
            mapAssetLists,
            assetLayersbyCity
        ) {
            AssetGroupByGeofencesES.get(
                {
                    lastAssetId: lastAssetId,
                    geofences: geofenceIds,
                    assetTypes: assetLayersbyCity,
                },
                function (data) {
                    vm.mapAssetsCity = mapAssetLists;
                    data.assets.forEach(function (asset) {
                        var tmpAsset = {};

                        tmpAsset.assetCoordinates = asset.assetCoordinates;
                        tmpAsset.assetTypeName = asset.assetTypeName;
                        tmpAsset.assetTypeColor = asset.assetColor;
                        tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
                        tmpAsset.assetTypeLayout = asset.assetTypeLayout;
                        tmpAsset.id = asset.id;
                        tmpAsset.name = asset.name;

                        vm.mapAssetsCity.push(tmpAsset);
                    });

                    if (data.assets.length < 500) {
                        for (
                            var index = 0;
                            index < vm.assetTypeValuesCity.length;
                            index++
                        ) {
                            var assetType = vm.assetTypeValuesCity[index];
                            var typeName = assetType.assetTypeName;
                            var typeAssetsList = [];
                            if (assetType.assets != null) {
                                typeAssetsList = assetType.assets;
                            }

                            vm.mapAssetsCity.forEach(function (asset) {
                                if (typeName == asset.assetTypeName) {
                                    typeAssetsList.push(asset);
                                }
                            });
                            assetType.assets = typeAssetsList;
                            vm.assetTypeValuesCity[index] = assetType;
                        }
                        /* var assetListCity = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValuesCity.length;
                            index++
                        ) {
                            if (
                                vm.assetTypeValuesCity[index].assetTypeName ==
                                    "KML Link" ||
                                vm.assetTypeValuesCity[index].assetTypeName ==
                                    "SOH" ||
                                vm.assetTypeValuesCity[index].assetTypeName ==
                                    "Link" ||
                                vm.assetTypeValuesCity[index].assetTypeName ==
                                    "UG-OH"
                            ) {
                                vm.assetTypeValuesCity[index].assets.forEach(
                                    function (assetVal) {
                                        assetListCity.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawLatestAssetsCity(assetListCity, "level1");
                        */
                        if ($scope.selectedFilterAssetTypesCity.length > 0) {
                            drawTheAssetsCity();
                        }
                    }
                    // drawLatestAssets(vm.mapAssets);
                    if (data.lastId != null) {
                        getGroupAssetsFromESCity(
                            geofenceIds,
                            data.lastId,
                            vm.mapAssetsCity,
                            assetLayersbyCity
                        );
                    } else {
                        if (vm.mapAssetsCity.length > 0) {
                            var assetSelected = vm.mapAssetsCity[0];
                            var assetLatLng = {
                                lat:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].latitude,
                                lng:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].longitude,
                            };
                            try {
                                locationsCity.mapCity.setCenter(assetLatLng);
                            } catch (error) {
                                console.log(error);
                            }
                            /* AssetByID.get(
                                assetSelected.id,
                                function (assetVal) {
                                    
                                }
                            ); */
                        }
                    }
                }
            );
        }

        function selectAssetEntity(asset) {
            if (asset.id != null) {
                if (asset.isChecked) {
                    vm.showAssetDelete = true;
                    //vm.isAllAssetSelected = vm.assets.every(function (itm) { return itm.isChecked; });
                    vm.selectedAssets.push(asset.id);
                } else {
                    var index = vm.selectedAssets.indexOf(asset.id);
                    if (index > -1) {
                        vm.selectedAssets.splice(index, 1);
                    }
                }
                if (vm.selectedAssets.length == 0) {
                    // vm.groupAssets = true;
                    vm.showAssetDelete = false;
                    getAssetsForListView();
                } else {
                    vm.showAssetDelete = true;
                }
            }
        }

        function selectAllAssets() {
            var toggleStatus = vm.isAllAssetSelected;
            if (toggleStatus) {
                vm.showAssetDelete = true;
                vm.selectedAssets = [];
                vm.assetsForListView.forEach(function (asset) {
                    vm.selectedAssets.push(asset.id);
                });
            } else {
                vm.showAssetDelete = false;
                vm.selectedAssets = [];
            }
            angular.forEach(vm.assetsForListView, function (item) {
                item.isChecked = toggleStatus;
            });
        }

        function confirmDelete() {
            $("#deleteAsset").show();
        }

        function selectAsets() {
            if (vm.selectedAssets.length > 0) {
                var assetGroupArray = [];
                vm.selectedAssets.forEach(function (assetId) {
                    assetGroupArray.push({ id: assetId });
                });
                AssetsBulkDelete.save(assetGroupArray, onSuccess, onError);
                function onSuccess() {
                    $("#deleteAsset").removeClass("in");
                    $(".modal-backdrop").remove();
                    $("#deleteAsset").hide();
                    vm.showAssetDelete = false;
                    vm.selectedAssets = [];
                    vm.isAllAssetSelected = false;
                    getAssetsForListView();
                    // $state.go('asset');
                }
                function onError(error) {
                    $("#deleteAsset").removeClass("in");
                    $(".modal-backdrop").remove();
                    $("#deleteAsset").hide();
                    var msg =
                        "Asset deletion failed, Asset has some dependent data";
                    if (error.status == 400) {
                        AlertService.error(msg);
                    } else {
                        AlertService.error(error.data.message);
                    }
                }
            } else {
                alert("Please select assets to delete list");
            }
        }

        var input = document.getElementById("field_pinLat");
        input.addEventListener("input", function () {
            this.value = this.value.replace(/[^0-9\,\.]/, "");
        });

        function showZoomLocation() {
            var latlng = vm.getLocationCoordinate.split(",");
            if (gotoMarker != null) {
                gotoMarker.setMap(null);
            }

            if (vm.getLocationCoordinate != null) {
                var assetCenterLatLng = {
                    lat: parseFloat(latlng[0]),
                    lng: parseFloat(latlng[1]),
                };

                if ($scope.tab == 2) {
                    locations.map.setCenter(assetCenterLatLng);
                    locations.map.setZoom(18);
                    var startMarker = new google.maps.Marker({
                        map: locations.map,
                        position: assetCenterLatLng,
                    });
                }

                if ($scope.tab == 3) {
                    locationsCity.mapCity.setCenter(assetCenterLatLng);
                    locationsCity.mapCity.setZoom(18);
                    var startMarker = new google.maps.Marker({
                        map: locationsCity.mapCity,
                        position: assetCenterLatLng,
                    });
                }

                gotoMarker = startMarker;
                $("#modalLatLongs").removeClass("in");
                $(".modal-backdrop").remove();
                $("#modalLatLongs").hide();
            }
        }
        function gotoLatitute() {
            $("#modalLatLongs").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
        }

        function gotoLatituteCity() {
            $("#modalLatLongs").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
        }

        function closeGotoLatitute(id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();

            if (id == "addCableDetails") {
                $scope.cableData = [];
                vm.enteredCableName = null;
                vm.selectedCableType = null;
                vm.selectedTube = null;
            }

            if (id == "addJoiningKitDetails") {
                $scope.joiningKitData = [];
                vm.enteredJoiningKitName = null;
                vm.selectedCable = null;
            }
        }
        function closeGotoLatituteCity() {
            $("#modalLatLongs").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalLatLongs").hide();
        }

        function showLayers() {
            vm.currentZoom = locations.map.getZoom();

            vm.getCenter = locations.map.center;

            $("#modalLayer").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
        }
        function showLayersCity() {
            vm.currentZoomCity = locationsCity.mapCity.getZoom();

            vm.getCenterCity = locationsCity.mapCity.center;

            $("#modalLayer").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
        }

        function closeLayers() {
            $("#modalLayer").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalLayer").hide();
        }

        function filterAssetsOnMapView() {
            vm.userGeofences = [];
            if (vm.adminFilter) {
                GeofenceIDs.getAll(function (response) {
                    response.forEach(function (geofence) {
                        geofence.label = geofence.name.substring(0, 15) + "..";
                        vm.userGeofences.push(geofence);
                        /* if (vm.selectedMapGeofenceId == null) {
                            vm.selectedMapGeofenceId = geofence.id;
                        } */
                        if (tempGeofenceId == geofence.id) {
                            geofence.ticked = true;
                        }
                    });
                    vm.countLength = vm.userGeofences.length;
                });
            } else {
                UserGeofences.getAll(function (response) {
                    response.forEach(function (geofence) {
                        geofence.label = geofence.name.substring(0, 15) + "..";
                        vm.userGeofences.push(geofence);
                        /* if (vm.selectedMapGeofenceId == null) {
                            vm.selectedMapGeofenceId = geofence.id;
                        } */
                        if (tempGeofenceId == geofence.id) {
                            geofence.ticked = true;
                        }
                    });
                    vm.countLength = vm.userGeofences.length;
                });
            }

            //$("#mapFilterModal").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
            vm.prevSelectedGeofenceId = vm.selectedMapGeofenceId;
        }

        function filterAssetsOnMapViewCity() {
            $("#mapFilterModal").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
            vm.prevSelectedMapGeofenceIds = vm.selectedMapGeofenceIds;
        }

        function closeMapFilterModal() {
            $("#mapFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterModal").hide();
            vm.selectedMapGeofenceId = vm.prevSelectedGeofenceId;
        }

        function closeImageUpload() {
            $("#myModalUpload").removeClass("in");
            $(".modal-backdrop").remove();
            $("#myModalUpload").hide();
            setTimeout(function () {
                $("#successImage").hide();
            }, 2000);
            setTimeout(function () {
                $("#errorImage").hide();
            }, 2000);
        }

        function closeMapFilterModalCity() {
            $("#mapFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterModal").hide();
            vm.selectedMapGeofenceIds = vm.prevSelectedMapGeofenceIds;
        }

        function editAssetOnMapView(fixed) {
            $("#assetEditModal").show();
            noDataText = document.getElementById("noData");
            noDataText.style.display = "none";
        }

        function closeEditAssetModal() {
            document.getElementById("assetEditName").textContent = "Asset";
            $("#assetEditModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#assetEditModal").hide();
        }

        function editAssetMarkerOnMapView(fixed) {
            $("#assetMarkerEditModal").show();
            noDataText = document.getElementById("noData");
            noDataText.style.display = "none";
        }

        function closeEditAssetMarkerModal() {
            document.getElementById("assetMarkerEditName").textContent =
                "Asset";
            $("#assetMarkerEditModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#assetMarkerEditModal").hide();
        }

        function viewAssetMarkerOnMapView(fixed) {
            $("#assetMarkerViewModal").show();
            noDataText = document.getElementById("noData");
            noDataText.style.display = "none";
        }

        function closeViewAssetMarkerModal() {
            document.getElementById("assetMarkerViewName").textContent =
                "Asset";
            $("#assetMarkerViewModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#assetMarkerViewModal").hide();
        }

        vm.imageInNewTab = function (img) {
            var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' + img + '">'
            );
            imgWindow.document.close();
        };

        function showAssetMarkerDetails(assetMarker) {
            vm.markerAssetToView = assetMarker;
            viewAssetMarkerOnMapView(assetMarker);
        }

        function editAssetMarker(assetMarker) {
            vm.markerAssetToEdit = assetMarker;
            editAssetMarkerOnMapView(assetMarker);
        }

        function saveEditMarkerAssetModal() {
            AssetByMarkerId.get(vm.markerAssetToEdit.id, function (response) {
                var assetMarkersList = [];
                response.assetMarkers.forEach(function (value) {
                    if (value.id == vm.markerAssetToEdit.id) {
                        value = vm.markerAssetToEdit;
                    }
                    assetMarkersList.push(value);
                });
                response.assetMarkers = assetMarkersList;
                Asset.update(response, onSaveSuccess, onSaveError);
                function onSaveSuccess(result) {
                    closeEditAssetMarkerModal();
                    // alert(result.name + " updated successfully!");
                }

                function onSaveError() {
                    closeEditAssetMarkerModal();
                    // alert(vm.assetToEdit.name + " got error while updating!");
                }
            });
        }
        function saveEditAssetModal() {
            if (vm.assetRefAttributes != null) {
                vm.assetToEdit.assetReferenceAttributes = [];
                for (var i = 0; i < vm.assetRefAttributes.length; i += 3) {
                    var obj = {};

                    obj["assetAttributeName"] = vm.assetRefAttributes[i]
                        ? {
                              assetTypeAttribute: vm.assetRefAttributes[i],
                              attributeValue:
                                  vm.assetRefAttributes[i].attributeValue,
                              order: 0,
                              popupDisplay:
                                  vm.assetRefAttributes[i]
                                      .assetTypePopupDisplay,
                          }
                        : null;

                    obj["assetAttributeValue"] = vm.assetRefAttributes[i + 1]
                        ? {
                              assetTypeAttribute: vm.assetRefAttributes[i + 1],
                              attributeValue:
                                  vm.assetRefAttributes[i + 1].attributeValue,
                              order: 1,
                              popupDisplay:
                                  vm.assetRefAttributes[i + 1]
                                      .assetTypePopupDisplay,
                          }
                        : null;

                    obj["assetAttributeImage"] = vm.assetRefAttributes[i + 2]
                        ? {
                              assetTypeAttribute: vm.assetRefAttributes[i + 2],
                              image:
                                  vm.assetRefAttributes[i + 2].attributeValue,
                              imageName:
                                  vm.assetRefAttributes[i + 2].attributeName,
                              imageType:
                                  vm.assetRefAttributes[i + 2].attributeType,
                              imageId: vm.assetRefAttributes[i + 2].imageId,
                              attributeValue: "images",
                              order: 1,
                              popupDisplay:
                                  vm.assetRefAttributes[i + 2]
                                      .assetTypePopupDisplay,
                          }
                        : null;
                    vm.assetToEdit.assetReferenceAttributes.push(obj);
                }
            }
            vm.assetToEdit.assetImages = []
            Asset.update(vm.assetToEdit, onSaveSuccess, onSaveError);
            function onSaveSuccess(result) {
                closeEditAssetModal();
                var findAssetIndex;
                switch (result.assetType.zoomLevel) {
                    case 15:
                        for (var i = 0; i < level2AssetsMarkers.length; i++) {
                            if (result.id == level2AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level2AssetsMarkers[i].setLabel("");
                                level2AssetsMarkers[i].displayLabel.text =
                                    result.name;
                                level2AssetsMarkers[i].setLabel(
                                    level2AssetsMarkers[i].displayLabel
                                );
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 15) {
                                vm.assetTypeValues[index].assets[
                                    findAssetIndex
                                ].name = result.name;
                                break;
                            }
                        }
                        break;

                    case 16:
                        for (var i = 0; i < level3AssetsMarkers.length; i++) {
                            if (result.id == level3AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level3AssetsMarkers[i].setLabel("");
                                level3AssetsMarkers[i].displayLabel.text =
                                    result.name;
                                level3AssetsMarkers[i].setLabel(
                                    level3AssetsMarkers[i].displayLabel
                                );
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 16) {
                                vm.assetTypeValues[index].assets[
                                    findAssetIndex
                                ].name = result.name;
                                break;
                            }
                        }
                        break;

                    case 17:
                        for (var i = 0; i < level4AssetsMarkers.length; i++) {
                            if (result.id == level4AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level4AssetsMarkers[i].setLabel("");
                                level4AssetsMarkers[i].displayLabel.text =
                                    result.name;
                                level4AssetsMarkers[i].setLabel(
                                    level4AssetsMarkers[i].displayLabel
                                );
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 17) {
                                vm.assetTypeValues[index].assets[
                                    findAssetIndex
                                ].name = result.name;
                                break;
                            }
                        }
                        break;

                    case 18:
                        for (var i = 0; i < level5AssetsMarkers.length; i++) {
                            if (result.id == level5AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level5AssetsMarkers[i].setLabel("");
                                level5AssetsMarkers[i].displayLabel.text =
                                    result.name;
                                level5AssetsMarkers[i].setLabel(
                                    level5AssetsMarkers[i].displayLabel
                                );
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 18) {
                                vm.assetTypeValues[index].assets[
                                    findAssetIndex
                                ].name = result.name;
                                break;
                            }
                        }
                        break;

                    case 19:
                        for (var i = 0; i < level6AssetsMarkers.length; i++) {
                            if (result.id == level6AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level6AssetsMarkers[i].setLabel("");
                                level6AssetsMarkers[i].displayLabel.text =
                                    result.name;
                                level6AssetsMarkers[i].setLabel(
                                    level6AssetsMarkers[i].displayLabel
                                );
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 19) {
                                vm.assetTypeValues[index].assets[
                                    findAssetIndex
                                ].name = result.name;
                                break;
                            }
                        }
                        break;

                    case 20:
                        for (var i = 0; i < level7AssetsMarkers.length; i++) {
                            if (result.id == level7AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level7AssetsMarkers[i].setLabel("");
                                level7AssetsMarkers[i].displayLabel.text =
                                    result.name;
                                level7AssetsMarkers[i].setLabel(
                                    level7AssetsMarkers[i].displayLabel
                                );
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 20) {
                                vm.assetTypeValues[index].assets[
                                    findAssetIndex
                                ].name = result.name;
                                break;
                            }
                        }
                        break;

                    default:
                        console.log("not found");
                }
                vm.closeInfoWindow.close();
                //alert(result.name + " updated successfully!");
            }

            function onSaveError() {
                closeEditAssetModal();
                alert(vm.assetToEdit.name + " got error while updating!");
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: $scope.search,
                pagesize: vm.itemsPerPage,
                listgeofenceid: vm.selectedListGeofenceId,
            });
        }

        function goToPage() {
            vm.page = parseInt(document.getElementById("pagenum").value);
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: $scope.search,
                pagesize: vm.itemsPerPage,
                listgeofenceid: vm.selectedListGeofenceId,
            });
        }

        function showAssetAttributesModal(assetVal) {
            $("#modalAssetAttributes").show();
            var assetTypeAttributeValuesList =
                assetVal.assetTypeAttributeValues;
            document.getElementById("assetAttributeTable").innerHTML = "";

            var newTable =
                "<table align='center' style='border: 1px solid black;width:50%;'><tr><th style='border: 1px solid black;text-align: center;'>Name</th><th style='border: 1px solid black;text-align: center;'>Value</th></tr>";

            assetTypeAttributeValuesList.forEach(function (value) {
                if (
                    value.assetTypeAttribute.assetAttributeTypes ==
                    "NON_REFERENCE"
                ) {
                    newTable +=
                        "<tr><td style='border: 1px solid black;text-align: center;'>" +
                        value.assetTypeAttribute.name +
                        "</td><td style='border: 1px solid black;text-align: center;'>" +
                        value.attributeValue +
                        "</td></tr>";
                }
            });
            newTable += "</table>";
            document.getElementById("assetNameAssetAttributes").textContent =
                assetVal.name + " - " + assetVal.assetType.name;
            document.getElementById("assetAttributeTable").innerHTML = newTable;
        }

        function showThreePontReferenceModal(assetVal) {
            $("#modalThreePointReference").show();
            var assetTypeAttributeValuesList =
                    assetVal.assetTypeAttributeValues,
                assetReferenceAttributesList =
                    assetVal.assetReferenceAttributes,
                referenceAttribute = [];
            var newTable =
                "<table align='center' style='border: 1px solid black;width:50%;'><tr><th style='border: 1px solid black;text-align: center;'>Object</th><th style='border: 1px solid black;text-align: center;'>Distance (mtr)</th></tr>";
            var ref = [];
            var dist = [];
            if (assetVal.assetReferenceAttributes.length) {
                assetReferenceAttributesList.forEach(function (val) {
                    referenceAttribute.push(val["assetAttributeName"]);
                    referenceAttribute.push(val["assetAttributeValue"]);
                });
                referenceAttribute.forEach(function (val) {
                    if (
                        val.assetTypeAttribute.assetAttributeTypes ==
                        "THREE_PNT_REFERENCE"
                    ) {
                        if (
                            val.assetTypeAttribute.name ==
                                "3 Point Reference1" &&
                            val.attributeValue
                        ) {
                            ref.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name ==
                                "3 Point Reference2" &&
                            val.attributeValue
                        ) {
                            ref.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name ==
                                "3 Point Reference3" &&
                            val.attributeValue
                        ) {
                            ref.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name == "Distance1" &&
                            val.attributeValue
                        ) {
                            dist.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name == "Distance2" &&
                            val.attributeValue
                        ) {
                            dist.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name == "Distance3" &&
                            val.attributeValue
                        ) {
                            dist.push(val.attributeValue);
                        }
                    }
                });
            } else {
                assetTypeAttributeValuesList.forEach(function (value) {
                    if (
                        value.assetTypeAttribute.assetAttributeTypes ==
                        "THREE_PNT_REFERENCE"
                    ) {
                        if (
                            value.assetTypeAttribute.name ==
                            "3 Point Reference1"
                        ) {
                            ref.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name ==
                            "3 Point Reference2"
                        ) {
                            ref.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name ==
                            "3 Point Reference3"
                        ) {
                            ref.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name == "Distance1"
                        ) {
                            dist.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name == "Distance2"
                        ) {
                            dist.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name == "Distance3"
                        ) {
                            dist.push(value.attributeValue);
                        }
                    }
                });
            }

            for (var i = 0; i < 3; i++) {
                if (ref[i] || dist[i]) {
                    newTable +=
                        "<tr><td style='border: 1px solid black;text-align: center;'>" +
                        ref[i] +
                        "</td><td style='border: 1px solid black;text-align: center;'>" +
                        dist[i] +
                        "</td></tr>";
                }
            }
            newTable += "</table>";
            document.getElementById(
                "assetNameThreePointReference"
            ).textContent = assetVal.name + " - " + assetVal.assetType.name;
            document.getElementById(
                "threePointReferenceTable"
            ).innerHTML = newTable;
        }

        function closeAssetAttributesModal() {
            $("#modalAssetAttributes").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalAssetAttributes").hide();
        }

        function closeThreePointReference() {
            $("#modalThreePointReference").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalThreePointReference").hide();
        }

        $scope.geofenceListView = function () {
            if ($scope.tab == 1) {
                vm.selectedListGeofenceId = vm.selectedGeofence[0].id;
                tempGeofenceId = vm.selectedGeofence[0].id;
                getAssetsForListView();
            } else if ($scope.tab == 2) {
                isLevel1AssetsVisible = false;
                isLevel2AssetsVisible = false;
                isLevel3AssetsVisible = false;
                isLevel4AssetsVisible = false;
                isLevel5AssetsVisible = false;
                isLevelNodeVisible = false;
                assetVisibility19 = false;
                assetVisibility20 = false;
                vm.isEnableNavigate = false
                vm.selectedMapGeofenceId = vm.selectedMapGeofence[0].id;
                tempGeofenceId = vm.selectedMapGeofence[0].id; // switiching between one view to another view
                saveFilter();
            }
        };

        function filterAssetsOnListView() {
            tempGeofenceId = $state.params.listgeofenceid;
            vm.geofencesListView = [];
            if (vm.adminFilter) {
                GeofenceIDs.getAll(function (response) {
                    if ($scope.tab == 1) {
                        if (response.length >= 2) {
                            vm.geofencesListView.push({
                                id: 0,
                                name: "All",
                                label: "All",
                            });
                        }
                    }
                    response.forEach(function (geofence) {
                        geofence.label = geofence.name.substring(0, 15) + "..";
                        geofence.ticked =
                            tempGeofenceId == geofence.id ? true : false;
                        vm.geofencesListView.push(geofence);
                        if (vm.selectedMapGeofenceId == null) {
                            vm.selectedMapGeofenceId = geofence.id;
                        }
                    });
                    vm.countLength = vm.geofencesListView.length;
                });
            } else {
                UserGeofences.getAll(function (response) {
                    if ($scope.tab == 1) {
                        if (response.length >= 2) {
                            vm.geofencesListView.push({
                                id: 0,
                                name: "All",
                                label: "All",
                            });
                        }
                    }
                    response.forEach(function (geofence) {
                        geofence.label = geofence.name.substring(0, 15) + "..";
                        geofence.ticked =
                            tempGeofenceId == geofence.id ? true : false;
                        vm.geofencesListView.push(geofence);
                        if (vm.selectedMapGeofenceId == null) {
                            vm.selectedMapGeofenceId = geofence.id;
                        }
                    });
                    vm.countLength = vm.geofencesListView.length;
                });
            }
            /* $("#listFilterModal").show();
            noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
            vm.PrevselectedListGeofenceId = vm.selectedListGeofenceId;
        }

        function closeListFilterModal() {
            $("#listFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#listFilterModal").hide();
            vm.selectedListGeofenceId = vm.PrevselectedListGeofenceId;
        }

        function saveListFilter() {
            vm.PrevselectedListGeofenceId = vm.selectedListGeofenceId;
            getAssetsForListView();
            $("#listFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#listFilterModal").hide();
        }

        $scope.initial = function (item) {
            if ($scope.bool) {
                // add item
                $scope.selectedFilterAssetTypes.push(item);
            }
        };

        $scope.initialCity = function () {
            if (vm.filterAssetTypesCity[0].isChecked != undefined) {
                $scope.selectedFilterAssetTypesCity = [];
                for (var i = 0; i < vm.filterAssetTypesCity.length; i++) {
                    if (
                        vm.filterAssetTypesCity[i].name == "KML Link" ||
                        vm.filterAssetTypesCity[i].name == "Optical Fiber Cable"
                    ) {
                        $scope.selectedFilterAssetTypesCity.push(
                            vm.filterAssetTypesCity[i]
                        );
                        vm.filterAssetTypesCity[i].ticked = true;
                    } else {
                        vm.filterAssetTypesCity[i].ticked = false;
                    }
                }
            }
        };

        $scope.sync = function () {
            $scope.selectedFilterAssetTypes = [];
            for (var i = 0; i < vm.selectedAsset.length; i++) {
                $scope.selectedFilterAssetTypes.push(vm.selectedAsset[i]);
            }
            vm.getCenter = locations.map.center;
            saveAssetTypeFilter();
        };

        /* $scope.sync = function (bool, item) {
            if (bool) {
                // add item
                $scope.selectedFilterAssetTypes.push(item);
            } else {
                // remove item

                for (
                    var i = 0;
                    i < $scope.selectedFilterAssetTypes.length;
                    i++
                ) {
                    if ($scope.selectedFilterAssetTypes[i].id == item.id) {
                        $scope.selectedFilterAssetTypes.splice(i, 1);
                    }
                }
            }
        };     */

        /* 
         ng-checked="isChecked(item.id)"

         $scope.isChecked = function(id){
            var match = true;
            for(var i=0 ; i < $scope.selectedFilterAssetTypes.length; i++) {
              if($scope.selectedFilterAssetTypes[i].id == id){
                match = true;
              }
            }
            return match;
        };*/

        function saveFilterCity() {
            $scope.searchAssetCity = "";
            vm.mapAssetsCity = [];
            vm.selectedMapGeofenceIds = [];
            vm.userGeofencesCity.forEach(function (geofence) {
                if (geofence.ticked) {
                    vm.selectedMapGeofenceIds.push(geofence.id);
                }
            });
            markersCity = [];
            polylinesCity = [];
            /* var myOptions = {
                zoom: 10,
                center: locationsCity.latlng,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            }; */
            locationsCity.mapCity = new google.maps.Map(
                document.getElementById("map_canva"),
                myOption(10, locationsCity.latlng)
            );
            geoLocation(locationsCity.mapCity);
            /* var measureTool = new MeasureTool(locationsCity.mapCity, {
                contextMenu: true,
                unit: MeasureTool.UnitTypeId.METRIC,
            }); */

            mapZoomChangeCity(locationsCity.mapCity);
            var assetLayersbyCity = [];

            AssetTypesByGeofenceIDs.get(
                {
                    geofences: vm.selectedMapGeofenceIds,
                },
                function (assetTypeVal) {
                    vm.assetTypeValuesCity = assetTypeVal;
                    vm.filterAssetTypesCity = [];
                    assetTypeVal.forEach(function (assettype) {
                        vm.filterAssetTypesCity.push({
                            id: assettype.id,
                            name: assettype.assetTypeName,
                            ticked: false,
                        });
                    });
                    $scope.selectedFilterAssetTypesCity = [];
                    for (var i = 0; i < vm.filterAssetTypesCity.length; i++) {
                        if (
                            vm.filterAssetTypesCity[i].name == "KML Link" ||
                            vm.filterAssetTypesCity[i].name ==
                                "Optical Fiber Cable"
                        ) {
                            $scope.selectedFilterAssetTypesCity.push(
                                vm.filterAssetTypesCity[i]
                            );
                            vm.filterAssetTypesCity[i].ticked = true;
                        } else {
                            vm.filterAssetTypesCity[i].ticked = false;
                        }
                    }
                    $scope.selectedFilterAssetTypesCity.forEach(function (
                        item
                    ) {
                        assetLayersbyCity.push(item.name);
                    });
                    vm.mapAssetsCity = [];
                    getGroupAssetsFromESCity(
                        vm.selectedMapGeofenceIds,
                        null,
                        vm.mapAssetsCity,
                        assetLayersbyCity
                    );
                }
            );
            vm.prevSelectedMapGeofenceIds = vm.selectedMapGeofenceIds;
            vm.previousPolygons.forEach(function (polygon) {
                polygon.setMap(null);
            });
            GeofenceByID.get(
                vm.selectedMasterGeofenceId,
                function (geofenceDetails) {
                    drawPolygon(
                        geofenceDetails,
                        locationsCity.latlng,
                        "red",
                        1
                    );
                }
            );
            vm.userGeofencesCity.forEach(function (value) {
                if (value.ticked) {
                    vm.previousPolygons.push(
                        drawPolygon(value, locationsCity.latlng, "green", 2)
                    );
                }
            });

            $("#mapFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterModal").hide();
            $scope.selectedFilterAssetTypesCity = [];
        }

        //vm.boolTrue;
        /*  $scope.check = function () {
            var myOptions = {
                zoom: vm.currentZoom1,
                center: vm.getCenter,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            };
            locations.map = new google.maps.Map(
                document.getElementById("map_canvas"),
                myOptions
            );
            var assetList = [];
            for (var index = 0; index < vm.assetTypeValues.length; index++) {
                if (
                    vm.assetTypeValues[index].assetTypeName == "KML Link" ||
                    vm.assetTypeValues[index].assetTypeName == "SOH" ||
                    vm.assetTypeValues[index].assetTypeName == "Link" ||
                    vm.assetTypeValues[index].assetTypeName == "UG-OH"
                ) {
                    vm.assetTypeValues[index].assets.forEach(function (
                        assetVal
                    ) {
                        assetList.push(assetVal);
                    });
                }
            }

            drawLatestAssets(assetList, "level1");
            isLevel1AssetsVisible = true;
            //saveFilter();
            //  for (var index = 0; index < vm.assetTypeValues.length; index++) {
            //     var assetType = vm.assetTypeValues[index];
            //     var typeName = assetType.assetTypeName;
            //     var typeAssetsList = [];
            //     if (assetType.assets != null) {
            //         typeAssetsList = assetType.assets;
            //     }

            //     vm.mapAssets.forEach(function (asset) {
            //         if (typeName == asset.assetTypeName) {
            //             typeAssetsList.push(asset);
            //         }
            //     });
            //     assetType.assets = typeAssetsList;
            //     vm.assetTypeValues[index] = assetType;
            // }
            
            // var assetList = [];
            // for (var index = 0; index < vm.assetTypeValues.length; index++) {
            //     if (
            //         vm.assetTypeValues[index].assetTypeName == "KML Link" ||
            //         vm.assetTypeValues[index].assetTypeName == "SOH" ||
            //         vm.assetTypeValues[index].assetTypeName == "Link" ||
            //         vm.assetTypeValues[index].assetTypeName == "UG-OH"
            //     ) {
            //         vm.assetTypeValues[index].assets.forEach(function (
            //             assetVal
            //         ) {
            //             assetList.push(assetVal);
            //         });
            //     }
            // }
            // drawLatestAssets(assetList, "level1");
            
            // isLevel1AssetsVisible = true; 

            // vm.nodeAssets = [];
            // var tempNodeAssets = [];

            // vm.mapAssets.forEach(function (asset) {
            //     if (
            //         asset.assetTypeName == "BX1" ||
            //         asset.assetTypeName == "BX2" ||
            //         asset.assetTypeName == "BX3" ||
            //         asset.assetTypeName == "BX4" ||
            //         asset.assetTypeName == "DX4" ||
            //         asset.assetTypeName == "DX16" ||
            //         asset.assetTypeName == "DX11" ||
            //         asset.assetTypeName == "DX10" ||
            //         asset.assetTypeName == "DX15" ||
            //         asset.assetTypeName == "ADX1" ||
            //         asset.assetTypeName == "DX7" ||
            //         asset.assetTypeName == "DX12" ||
            //         asset.assetTypeName == "Sr Elements_Ax" ||
            //         asset.assetTypeName == "Sr Elements_Bx" ||
            //         asset.assetTypeName == "Sr Elements_Dx" ||
            //         asset.assetTypeName == "Sr Elements_Mcr" ||
            //         asset.assetTypeName == "Sr Elements_AX" ||
            //         asset.assetTypeName == "Sr Elements_BX" ||
            //         asset.assetTypeName == "Sr Elements_DX" ||
            //         asset.assetTypeName == "Sr Elements_MCR"
            //     ) {
            //         var lat = asset.assetCoordinates[0].latitude.toFixed(4);
            //         var lng = asset.assetCoordinates[0].longitude.toFixed(4);
            //         tempNodeAssets.push({
            //             lat: lat,
            //             lng: lng,
            //             assetDetails: asset,
            //         });
            //     }
            // });
            // vm.nodeAssets = Object.values(groupByKey(tempNodeAssets));
        };   */

        function saveFilter() {
            vm.storeCases = null;
            vm.displayCaseType = false;
            $scope.$evalAsync();
            $scope.searchAsset = "";
            /* if (vm.prevSelectedGeofenceId != vm.selectedMapGeofenceId) {
                $scope.variableHealth = false;
            }
            vm.health = true; */

            vm.mapAssets = [];
            markers = [];
            polylines = [];

            /* var myOptions = {
                zoom: 12,
                center: locations.latlng,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            }; */
            locations.map = new google.maps.Map(
                document.getElementById("map_canvas"),
                myOption(12, locations.latlng)
            );

            geoLocation(locations.map);
            var measureTool = new MeasureTool(locations.map, {
                contextMenu: true,
                unit: MeasureTool.UnitTypeId.METRIC,
            });
            document.getElementById("myspan").textContent = "";
            vm.zoomLevel = locations.map.getZoom();
            AssetTypesByGeofenceID.get(
                vm.selectedMapGeofenceId,
                function (assetTypeVal) {
                    vm.assetTypeValues = assetTypeVal;
                    getGroupAssetsFromES(
                        vm.selectedMapGeofenceId,
                        null,
                        vm.mapAssets
                    );
                }
            );
            vm.prevSelectedGeofenceId = vm.selectedMapGeofenceId;

            /*  $("#mapFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterModal").hide(); */
            $scope.selectedFilterAssetTypes = [];
        }

        function saveAssetTypeFilter() {
            $scope.searchAsset = "";
            //closeLayers();
            drawAssets(vm.mapAssets, "level1");
            /* var myOptions = {
                zoom: vm.currentZoom,
                center: vm.getCenter,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            }; */
            locations.map = new google.maps.Map(
                document.getElementById("map_canvas"),
                myOption(vm.currentZoom, vm.getCenter)
            );
            geoLocation(locations.map);
            var measureTool = new MeasureTool(locations.map, {
                contextMenu: true,
                unit: MeasureTool.UnitTypeId.METRIC,
            });
            document.getElementById("myspan").textContent = "";
            if ($scope.selectedFilterAssetTypes.length > 0) {
                mapZoomChange(locations.map);
                mapZoomChangeFilter(locations.map);

                var assetList = [];
                for (
                    var index = 0;
                    index < vm.assetTypeValues.length;
                    index++
                ) {
                    if (
                        vm.assetTypeValues[index].zoomLevel >= 10 &&
                        vm.assetTypeValues[index].zoomLevel <= 14
                    ) {
                        vm.assetTypeValues[index].assets.forEach(function (
                            assetVal
                        ) {
                            assetList.push(assetVal);
                        });
                    }
                }

                drawLatestAssets(assetList, "level1");
                isLevel1AssetsVisible = true;
            }

            /* if (vm.mapAssets.length > 0) {
                var assetSelected = vm.mapAssets[0];
                AssetByID.get(assetSelected.id, function (assetVal) {
                    var assetLatLng = {
                        lat: assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].latitude,
                        lng: assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].longitude,
                    };
                    try {
                        locations.map.setCenter(assetLatLng);
                    } catch (error) {
                        console.log(error);
                    }
                }); 
            }*/
        }

        function saveAssetTypeFilterCity() {
            $scope.searchAssetCity = "";
            //closeLayers();
            // drawAssetsCity(vm.mapAssetsCity, "level1");
            $scope.selectedFilterAssetTypesCity = [];
            vm.filterAssetTypesCity.forEach(function (value) {
                if (value.ticked) {
                    $scope.selectedFilterAssetTypesCity.push(value);
                }
            });
            /*  var myOptions = {
                zoom: vm.currentZoomCity,
                center: vm.getCenterCity,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.TERRAIN,
            }; */
            locationsCity.mapCity = new google.maps.Map(
                document.getElementById("map_canva"),
                myOption(vm.currentZoomCity, vm.getCenterCity)
            );

            geoLocation(locationsCity.mapCity);
            document.getElementById("myspanCity").textContent = "";

            //drawTheAssetsCity();

            mapZoomChangeCity(locationsCity.mapCity);

            GeofenceByID.get(
                vm.selectedMasterGeofenceId,
                function (geofenceDetails) {
                    drawPolygon(
                        geofenceDetails,
                        locationsCity.latlng,
                        "red",
                        1
                    );
                }
            );
            vm.userGeofencesCity.forEach(function (value) {
                if (value.ticked) {
                    vm.previousPolygons.push(
                        drawPolygon(value, locationsCity.latlng, "green", 2)
                    );
                }
            });
            var assetLayersbyCity = [];
            vm.mapAssetsCity = [];

            $scope.selectedFilterAssetTypesCity.forEach(function (item) {
                assetLayersbyCity.push(item.name);
            });
            getGroupAssetsFromESCity(
                vm.selectedMapGeofenceIds,
                null,
                vm.mapAssetsCity,
                assetLayersbyCity
            );
        }

        $scope.complete = function () {
            $scope.hidethis = false;
        };

        $scope.hideAssetList = function () {
            $scope.hidethis = true;
        };
        $scope.fillTextbox = function (item) {
            $scope.searchAsset = item.name;
            $scope.hidethis = true;
            var assetSelected = item;
            clearTimeout(assetTimer);
            clearTimeout(polylineTimer);
            clearTimeout(closeClick);
            infowindowSearch = new google.maps.InfoWindow();
            if (activeInfo != null) {
                activeInfo.close();
                if (spreadPoly != null) {
                    spreadPoly.setMap(null);
                }
            }
            if (item.label <= 18) {
                locations.map.setZoom(18);
            } else if(item.label){
                locations.map.setZoom(item.label);
            }else{
                locations.map.setZoom(21);
            }

            var getMapZoom = locations.map.getZoom();
            AssetByID.get(assetSelected.id, function (assetVal) {
                if (assetVal.assetType.layout == "SPREAD") {
                    locations.map.setZoom(21);
                    var spreadPolyArr = [];
                    assetVal.assetCoordinates.forEach(function (val) {
                        spreadPolyArr.push({
                            lat: val.latitude,
                            lng: val.longitude,
                        });
                    });
                    spreadPoly = new google.maps.Polyline({
                        path: spreadPolyArr,
                        strokeColor: "#FFFF00",
                        strokeOpacity: 0.6,
                        strokeWeight: 19,
                    });

                    spreadPoly.setMap(locations.map);

                    polylineTimer = setTimeout(function () {
                        if (spreadPoly != null) {
                            spreadPoly.setMap(null);
                            spreadPoly = null;
                        }
                    }, 10000);
                }
                if (getMapZoom > 15) {
                    if (!isLevelNodeVisible) {
                        for (var i = 0; i < nodeMarkers.length; i++) {
                            for (
                                var index = 0;
                                index < nodeMarkers.length;
                                index++
                            ) {
                                nodeMarkers[index].setMap(null);
                            }
                            nodeCluster.clearMarkers();
                            nodeMarkers = [];
                        }
                        drawNodeAssets();
                        isLevelNodeVisible = true;
                    }
                }

                if (getMapZoom > 14) {
                    if (!isLevel2AssetsVisible) {
                        var zoomLevel_15 = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 15) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        zoomLevel_15.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(zoomLevel_15, "level2");

                        /* var handHoleList = [];
                    for (
                        var index = 0;
                        index < vm.assetTypeValues.length;
                        index++
                    ) {
                        if (
                            vm.assetTypeValues[index].assetTypeName ==
                            "Chamber / Handhole"
                        ) {
                            vm.assetTypeValues[index].assets.forEach(
                                function (assetVal) {
                                    handHoleList.push(assetVal);
                                }
                            );
                        }
                    }
                    drawAssets(handHoleList, "level2");

                    var handHoleCHList = [];
                    for (
                        var index = 0;
                        index < vm.assetTypeValues.length;
                        index++
                    ) {
                        if (
                            vm.assetTypeValues[index].assetTypeName ==
                            "Handhole CH"
                        ) {
                            vm.assetTypeValues[index].assets.forEach(
                                function (assetVal) {
                                    handHoleCHList.push(assetVal);
                                }
                            );
                        }
                    }
                    drawAssets(handHoleCHList, "level2");

                    var siteList = [];
                    for (
                        var index = 0;
                        index < vm.assetTypeValues.length;
                        index++
                    ) {
                        if (
                            vm.assetTypeValues[index].assetTypeName ==
                            "Site"
                        ) {
                            vm.assetTypeValues[index].assets.forEach(
                                function (assetVal) {
                                    siteList.push(assetVal);
                                }
                            );
                        }
                    }
                    drawAssets(siteList, "level2"); */
                        isLevel2AssetsVisible = true;
                    }
                }

                if (getMapZoom > 15) {
                    if (!isLevel3AssetsVisible) {
                        var couplerList = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 16) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        couplerList.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(couplerList, "level3");
                        isLevel3AssetsVisible = true;
                    }
                }

                if (getMapZoom > 16) {
                    if (!isLevel4AssetsVisible) {
                        var zoomLevel_17 = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 17) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        zoomLevel_17.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(zoomLevel_17, "level4");
                        isLevel4AssetsVisible = true;
                    }
                }

                if (getMapZoom > 17) {
                    if (!isLevel5AssetsVisible) {
                        var zoomLevel_18 = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 18) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        zoomLevel_18.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(zoomLevel_18, "level5");
                        isLevel5AssetsVisible = true;
                    }
                }

                if (getMapZoom > 18) {
                    if (!assetVisibility19) {
                        var zoomLevel_19 = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 19) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        zoomLevel_19.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(zoomLevel_19, "level6");
                        assetVisibility19 = true;
                    }
                }

                if (getMapZoom > 19) {
                    if (!assetVisibility20) {
                        var zoomLevel_20 = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 20) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        zoomLevel_20.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(zoomLevel_20, "level7");
                        assetVisibility20 = true;
                    }
                }

                /* if(getMapZoom == 19){
                    if(!vm.zoomLabel19){
                        for (var i = 0; i < level2AssetsMarkers.length; i++) {
                            if(level2AssetsMarkers[i].labelZoom == 19){
                                level2AssetsMarkers[i].setLabel(level2AssetsMarkers[i].displayLabel);
                            }
                        }
                        for (var i = 0; i < level3AssetsMarkers.length; i++) {
                            if(level3AssetsMarkers[i].labelZoom == 19){
                            level3AssetsMarkers[i].setLabel(level3AssetsMarkers[i].displayLabel);}
                        }
                        for (var i = 0; i < level4AssetsMarkers.length; i++) {
                            if(level4AssetsMarkers[i].labelZoom == 19){
                            level4AssetsMarkers[i].setLabel(level4AssetsMarkers[i].displayLabel);}
                        }
                        for (var i = 0; i < level5AssetsMarkers.length; i++) {
                            if(level5AssetsMarkers[i].labelZoom == 19){
                            level5AssetsMarkers[i].setLabel(level5AssetsMarkers[i].displayLabel);}
                        }
                        vm.zoomLabel19 = true 
                    }
                }

                if(getMapZoom == 20){
                    if(!vm.zoomLabel20){
                        for (var i = 0; i < level2AssetsMarkers.length; i++) {
                            if(level2AssetsMarkers[i].labelZoom == 20){
                                level2AssetsMarkers[i].setLabel(level2AssetsMarkers[i].displayLabel);
                            }
                        }
                        for (var i = 0; i < level3AssetsMarkers.length; i++) {
                            if(level3AssetsMarkers[i].labelZoom == 20){
                            level3AssetsMarkers[i].setLabel(level3AssetsMarkers[i].displayLabel);}
                        }
                        for (var i = 0; i < level4AssetsMarkers.length; i++) {
                            if(level4AssetsMarkers[i].labelZoom == 20){
                            level4AssetsMarkers[i].setLabel(level4AssetsMarkers[i].displayLabel);}
                        }
                        for (var i = 0; i < level5AssetsMarkers.length; i++) {
                            if(level5AssetsMarkers[i].labelZoom == 20){
                            level5AssetsMarkers[i].setLabel(level5AssetsMarkers[i].displayLabel);}
                        }
                        vm.zoomLabel20 =  true;
                    }
                } */

                var assetLatLng = {
                    lat:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].longitude,
                };
                locations.map.setCenter(assetLatLng);
                infowindowSearch.setContent(item.name);
                infowindowSearch.open(locations.map);
                activeInfo = infowindowSearch;
                infowindowSearch.setPosition(assetLatLng);
                assetTimer = setTimeout(function () {
                    if (activeInfo != null) {
                        activeInfo.close();
                    }
                }, 10000);
            });
            google.maps.event.addListener(locations.map, "click", function () {
                activeInfo.close();
            });
        };
        /* $("#searchAssetsOnMap").click(function () {
            clearTimeout(assetTimer)
            clearTimeout(polylineTimer)
            clearTimeout(closeClick)
            infowindowSearch = new google.maps.InfoWindow();
            var assetSelected = JSON.parse($("#searchAssetsOnMap").val());
            if (activeInfo != null) {
                activeInfo.close();
                if (spreadPoly != null) {
                    spreadPoly.setMap(null);
                }
            }
            locations.map.setZoom(18);
            var getMapZoom = locations.map.getZoom()
            AssetByID.get(assetSelected.id, function (assetVal) {
                if (
                    assetVal.assetType.name == "KML Link" ||
                    assetVal.assetType.name == "SOH" ||
                    assetVal.assetType.name == "Link" ||
                    assetVal.assetType.name == "UG-OH"
                ) {
                    locations.map.setZoom(21);
                    var spreadPolyArr = [];
                    assetVal.assetCoordinates.forEach(function (val) {
                        spreadPolyArr.push({
                            lat: val.latitude,
                            lng: val.longitude,
                        });
                    });
                    spreadPoly = new google.maps.Polyline({
                        path: spreadPolyArr,
                        strokeColor: "#FFFF00",
                        strokeOpacity: 0.6,
                        strokeWeight: 19,
                    });

                    spreadPoly.setMap(locations.map);

                    polylineTimer = setTimeout(function () {
                        if (spreadPoly != null) {
                            spreadPoly.setMap(null);
                            spreadPoly = null;
                        }
                    }, 10000);
                }
                
                if (getMapZoom > 15){
                    if(!isLevelNodeVisible){
                        for (var i = 0; i < nodeMarkers.length; i++) {
                            for (
                                var index = 0;
                                index < nodeMarkers.length;
                                index++
                            ) {
                                nodeMarkers[index].setMap(null);
                            }
                            nodeCluster.clearMarkers();
                            nodeMarkers = [];
                        }
                        drawNodeAssets();
                        isLevelNodeVisible = true;
                    }
                 } 
                 
                 if (getMapZoom > 14){
                    if(!isLevel2AssetsVisible){
                        var chamberList = [];
                    for (
                        var index = 0;
                        index < vm.assetTypeValues.length;
                        index++
                    ) {
                        if (
                            vm.assetTypeValues[index].assetTypeName ==
                            "Chamber"
                        ) {
                            vm.assetTypeValues[index].assets.forEach(
                                function (assetVal) {
                                    chamberList.push(assetVal);
                                }
                            );
                        }
                    }
                    drawAssets(chamberList, "level2");
                    isLevel2AssetsVisible = true;
                    }
                }
                
                if (getMapZoom > 15){
                    if (!isLevel3AssetsVisible) {
                        var couplerList = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (
                                vm.assetTypeValues[index].assetTypeName ==
                                "Coupler"
                            ) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        couplerList.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(couplerList, "level3");
                        isLevel3AssetsVisible = true;
                    }
                }
                
                var assetLatLng = {
                    lat:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].longitude,
                };
                locations.map.setCenter(assetLatLng);
                infowindowSearch.setContent(assetVal.name);
                infowindowSearch.open(locations.map);
                activeInfo = infowindowSearch;
                infowindowSearch.setPosition(assetLatLng);
                assetTimer = setTimeout(function () { 
                    if (activeInfo != null) {activeInfo.close(); }},10000)
            });
            google.maps.event.addListener(locations.map, "click", function () {
                activeInfo.close();
            });
        }); */

        $scope.searchAssetsOnMapCity = function (item) {
            $scope.searchAssetCity = item.name;
            $scope.hidethis = true;
            var assetSelected = item;
            clearTimeout(assetTimerCity);
            clearTimeout(polylineTimerCity);
            locationsCity.mapCity.setZoom(18);
            infowindowSearchCity = new google.maps.InfoWindow();
            if (activeInfoCity != null) {
                activeInfoCity.close();
                if (spreadPolyCity != null) {
                    spreadPolyCity.setMap(null);
                }
            }
            AssetByID.get(assetSelected.id, function (assetVal) {
                if (assetVal.assetType.layout == "SPREAD") {
                    locationsCity.mapCity.setZoom(21);
                    var spreadPolyArrCity = [];
                    assetVal.assetCoordinates.forEach(function (val) {
                        spreadPolyArrCity.push({
                            lat: val.latitude,
                            lng: val.longitude,
                        });
                    });
                    spreadPolyCity = new google.maps.Polyline({
                        path: spreadPolyArrCity,
                        strokeColor: "#FFFF00",
                        strokeOpacity: 0.6,
                        strokeWeight: 19,
                    });

                    spreadPolyCity.setMap(locationsCity.mapCity);

                    polylineTimerCity = setTimeout(function () {
                        if (spreadPolyCity != null) {
                            spreadPolyCity.setMap(null);
                            spreadPolyCity = null;
                        }
                    }, 10000);
                }
                var assetLatLng = {
                    lat:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].longitude,
                };
                locationsCity.mapCity.setCenter(assetLatLng);
                infowindowSearchCity.setContent(item.name);
                infowindowSearchCity.open(locationsCity.mapCity);
                activeInfoCity = infowindowSearchCity;
                infowindowSearchCity.setPosition(assetLatLng);
                assetTimerCity = setTimeout(function () {
                    if (activeInfoCity != null) {
                        activeInfoCity.close();
                    }
                }, 10000);
            });
        };
        /*  $("#searchAssetsOnMapCity").change(function () {
            clearTimeout(assetTimerCity)
            clearTimeout(polylineTimerCity)
            locationsCity.mapCity.setZoom(18);
            infowindowSearchCity = new google.maps.InfoWindow();
            var assetSelected = JSON.parse($("#searchAssetsOnMapCity").val());
            if (activeInfoCity != null) {
                activeInfoCity.close();
                if (spreadPolyCity != null) {
                    spreadPolyCity.setMap(null);
                }
            }
            AssetByID.get(assetSelected.id, function (assetVal) {
                if (
                    assetVal.assetType.name == "KML Link" ||
                    assetVal.assetType.name == "SOH" ||
                    assetVal.assetType.name == "Link" ||
                    assetVal.assetType.name == "UG-OH"
                ) {
                    locationsCity.mapCity.setZoom(21);
                    var spreadPolyArrCity = [];
                    assetVal.assetCoordinates.forEach(function (val) {
                        spreadPolyArrCity.push({
                            lat: val.latitude,
                            lng: val.longitude,
                        });
                    });
                    spreadPolyCity = new google.maps.Polyline({
                        path: spreadPolyArrCity,
                        strokeColor: "#FFFF00",
                        strokeOpacity: 0.6,
                        strokeWeight: 19,
                    });

                    spreadPolyCity.setMap(locationsCity.mapCity);

                    polylineTimerCity = setTimeout(function () {
                        if (spreadPolyCity != null) {
                            spreadPolyCity.setMap(null);
                            spreadPolyCity = null;
                        }
                    }, 10000);
                }
                var assetLatLng = {
                    lat:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        assetSelected.assetCoordinates[
                            assetSelected.assetCoordinates.length - 1
                        ].longitude,
                };
                locationsCity.mapCity.setCenter(assetLatLng);
                infowindowSearchCity.setContent(assetVal.name);
                infowindowSearchCity.open(locationsCity.mapCity);
                activeInfoCity = infowindowSearchCity;
                infowindowSearchCity.setPosition(assetLatLng);
                assetTimerCity = setTimeout(function () { 
                    if (activeInfoCity != null) {activeInfoCity.close(); }},10000)
            });
        }); */

        function drawLatestAssets(response, level) {
            if (response) {
                initializeAssetMap(response, function (latlang) {
                    locations.latlng = latlang;

                    setupAssetMap(function () {
                        $.each(response, function (index, value) {
                            if (value.assetTypeLayout == null) {
                                value.assetTypeName = value.assetType.name;
                                value.assetTypeLayout = value.assetType.layout;
                                value.assetTypeImagePath =
                                    value.assetType.imagePath;
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "FIXED"
                            ) {
                                if (value.assetCoordinates.length > 1) {
                                    // drawPolyline(value);
                                    var value1 = value;
                                    value1.assetCoordinates = [
                                        value.assetCoordinates[
                                            value.assetCoordinates.length - 1
                                        ],
                                    ];

                                    if (
                                        $scope.selectedFilterAssetTypes.length >
                                        0
                                    ) {
                                        $scope.selectedFilterAssetTypes.forEach(
                                            function (assetTypeVal) {
                                                if (
                                                    assetTypeVal.name ==
                                                    value1.assetTypeName
                                                ) {
                                                    drawFixedAsset(
                                                        value1,
                                                        false,
                                                        level
                                                    );
                                                }
                                            }
                                        );
                                    } else {
                                        drawFixedAsset(value1, false, level);
                                    }
                                } else {
                                    if (
                                        $scope.selectedFilterAssetTypes.length >
                                        0
                                    ) {
                                        $scope.selectedFilterAssetTypes.forEach(
                                            function (assetTypeVal) {
                                                if (
                                                    assetTypeVal.name ==
                                                    value.assetTypeName
                                                ) {
                                                    drawFixedAsset(
                                                        value,
                                                        false,
                                                        level
                                                    );
                                                }
                                            }
                                        );
                                    } else {
                                        drawFixedAsset(value, false, level);
                                    }
                                }
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "SPREAD"
                            ) {
                                if (
                                    $scope.selectedFilterAssetTypes.length > 0
                                ) {
                                    $scope.selectedFilterAssetTypes.forEach(
                                        function (assetTypeVal) {
                                            if (
                                                assetTypeVal.name ==
                                                value.assetTypeName
                                            ) {
                                                drawPolyline(
                                                    value,
                                                    locations.map,
                                                    level
                                                );
                                            }
                                        }
                                    );
                                } else {
                                    drawPolyline(value, locations.map, level);
                                }
                            }
                        });
                    });
                });
            }
            switch (level) {
                case "level1":
                    isLevel1AssetsVisible = true;
                    break;
                case "level2":
                    isLevel2AssetsVisible = true;
                    break;
                case "level3":
                    isLevel3AssetsVisible = true;
                    break;
                case "level4":
                    isLevel4AssetsVisible = true;
                    break;
                case "level5":
                    isLevel5AssetsVisible = true;
                    break;
                case "level6":
                    assetVisibility19 = true;
                    break;
                case "level7":
                    assetVisibility20 = true;
                    break;
                default:
                    break;
            }
        }

        function drawLatestAssetsCity(response, level) {
            if (response) {
                initializeAssetMap(response, function (latlang) {
                    locationsCity.latlng = latlang;

                    setupAssetMapCity(function () {
                        $.each(response, function (index, value) {
                            if (value.assetTypeLayout == null) {
                                value.assetTypeName = value.assetType.name;
                                value.assetTypeLayout = value.assetType.layout;
                                value.assetTypeImagePath =
                                    value.assetType.imagePath;
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "FIXED"
                            ) {
                                if (value.assetCoordinates.length > 1) {
                                    // drawPolyline(value);
                                    var value1 = value;
                                    value1.assetCoordinates = [
                                        value.assetCoordinates[
                                            value.assetCoordinates.length - 1
                                        ],
                                    ];

                                    if (
                                        $scope.selectedFilterAssetTypesCity
                                            .length > 0
                                    ) {
                                        $scope.selectedFilterAssetTypesCity.forEach(
                                            function (assetTypeVal) {
                                                if (
                                                    assetTypeVal.name ==
                                                    value1.assetTypeName
                                                ) {
                                                    drawFixedAssetCity(
                                                        value1,
                                                        false,
                                                        level
                                                    );
                                                }
                                            }
                                        );
                                    } else {
                                        drawFixedAssetCity(
                                            value1,
                                            false,
                                            level
                                        );
                                    }
                                } else {
                                    if (
                                        $scope.selectedFilterAssetTypesCity
                                            .length > 0
                                    ) {
                                        $scope.selectedFilterAssetTypesCity.forEach(
                                            function (assetTypeVal) {
                                                if (
                                                    assetTypeVal.name ==
                                                    value.assetTypeName
                                                ) {
                                                    drawFixedAssetCity(
                                                        value,
                                                        false,
                                                        level
                                                    );
                                                }
                                            }
                                        );
                                    } else {
                                        drawFixedAssetCity(value, false, level);
                                    }
                                }
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "SPREAD"
                            ) {
                                if (
                                    $scope.selectedFilterAssetTypesCity.length >
                                    0
                                ) {
                                    $scope.selectedFilterAssetTypesCity.forEach(
                                        function (assetTypeVal) {
                                            if (
                                                assetTypeVal.name ==
                                                value.assetTypeName
                                            ) {
                                                drawPolylineCity(
                                                    value,
                                                    locationsCity.mapCity,
                                                    level
                                                );
                                            }
                                        }
                                    );
                                } else {
                                    drawPolylineCity(
                                        value,
                                        locationsCity.mapCity,
                                        level
                                    );
                                }
                            }
                        });
                    });
                });
            }
            switch (level) {
                case "level1":
                    isLevel1AssetsVisibleCity = true;
                    break;
                case "level2":
                    isLevel2AssetsVisibleCity = true;
                    break;
                case "level3":
                    isLevel3AssetsVisibleCity = true;
                    break;
                case "level4":
                    isLevel4AssetsVisibleCity = true;
                    break;
                case "level5":
                    isLevel5AssetsVisibleCity = true;
                    break;
                default:
                    break;
            }
        }

        function llFromDistance(
            latitude,
            longitude,
            distance,
            bearing,
            number
        ) {
            // distance in KM, bearing in degrees

            var R = 6378.1; // Radius of the Earth
            var brng = (bearing * Math.PI) / 180; // Convert bearing to radian
            var lat = (latitude * Math.PI) / 180; // Current coords to radians
            var lon = (longitude * Math.PI) / 180;

            lat = Math.asin(
                Math.sin(lat) * Math.cos(distance / R) +
                    Math.cos(lat) * Math.sin(distance / R) * Math.cos(brng)
            );
            lon += Math.atan2(
                Math.sin(brng) * Math.sin(distance / R) * Math.cos(lat),
                Math.cos(distance / R) - Math.sin(lat) * Math.sin(lat)
            );
            // Coords back to degrees and return
            return [
                "" + (number + 1) + "",
                (lat * 180) / Math.PI,
                (lon * 180) / Math.PI,
                number + 1,
            ];
        }

        function getPentagonPoints(latlng, distance, numPoints) {
            var pentagonLatLng = [];
            for (var i = 0; i < numPoints; i++) {
                var bearing = Math.round((360 / numPoints) * i);
                pentagonLatLng.push(
                    llFromDistance(latlng.lat, latlng.lng, distance, bearing, i)
                );
            }
            return pentagonLatLng;
        }

        function drawNodeAssets() {
            nodeMarkers = [];
            nodeCluster = null;
            for (var index = 0; index < vm.nodeAssets.length; index++) {
                var nodeAssetVal = vm.nodeAssets[index];
                var latitude =
                    nodeAssetVal[0].assetDetails.assetCoordinates[0].latitude;
                var longitude =
                    nodeAssetVal[0].assetDetails.assetCoordinates[0].longitude;
                var points = getPentagonPoints(
                    {
                        lat: latitude,
                        lng: longitude,
                    },
                    0.01,
                    nodeAssetVal.length
                );

                var infowindow = new google.maps.InfoWindow();

                for (var i = 0; i < nodeAssetVal.length; i++) {
                    var pointVal = points[i];
                    var iconSize = 48;
                    var newMarker = new google.maps.Marker({
                        position: new google.maps.LatLng(
                            pointVal[1],
                            pointVal[2]
                        ),
                        map: locations.map,
                        icon: {
                            labelOrigin: new google.maps.Point(24, -4),
                            scaledSize: new google.maps.Size(
                                iconSize,
                                iconSize
                            ),
                            url:
                                nodeAssetVal[i].assetDetails.assetTypeImagePath,
                            anchor: new google.maps.Point(
                                iconSize / 2,
                                iconSize / 2
                            ),
                        },
                        label: nodeAssetVal[i].assetDetails.name,
                    });

                    google.maps.event.addListener(
                        newMarker,
                        "click",
                        (function (newMarker) {
                            var assetDetails = nodeAssetVal[i].assetDetails;
                            var userContent = document.createElement("div"),
                                btnShowAttributes,
                                btnCr,
                                btnText,
                                btnEditAsset,
                                btnCreateCase,
                                btnThreePointRef,
                                brBreak;

                            userContent.innerHTML =
                                '<div class="infoWindowhead">' +
                                assetDetails.name +
                                '</div><div class="infoWindowContent">' +
                                "<b>Lat</b> :" +
                                pointVal[1] +
                                "<br><b>Long</b> :" +
                                pointVal[2] +
                                "</div><br>";

                            btnText = userContent.appendChild(
                                document.createElement("b")
                            );
                            btnText.textContent = "Accessible";
                            btnText.style.marginRight = "4px";

                            btnCr = userContent.appendChild(
                                document.createElement("input")
                            );
                            var attr = document.createAttribute("data-toggle");
                            attr.value = "modal";
                            var attr1 = document.createAttribute("data-target");
                            attr1.value = "#myModalMap";

                            btnCr.setAttributeNode(attr);
                            btnCr.setAttributeNode(attr1);

                            btnCr.style.marginRight = "18px";
                            btnCr.type = "checkbox";

                            google.maps.event.addDomListener(
                                newMarker,
                                "click",
                                function () {
                                    AssetByID.get(
                                        assetDetails.id,
                                        function (response) {
                                            btnCr.id = response.id;

                                            btnCr.checked = response.accessible;

                                            vm.changeAcessible = response.accessible
                                                ? "Inaccessible"
                                                : "Accessible";

                                            var yes = document.getElementById(
                                                "yesButton"
                                            );

                                            yes.onclick = function () {
                                                if (btnCr.checked == true) {
                                                    response.accessible = true;
                                                }
                                                if (btnCr.checked == false) {
                                                    response.accessible = false;
                                                }
                                                response.assetImages = []
                                                Asset.update(response);
                                            };

                                            var noBtn = document.getElementById(
                                                "noButton"
                                            );

                                            noBtn.onclick = function () {
                                                if (btnCr.checked == true) {
                                                    document.getElementById(
                                                        btnCr.id
                                                    ).checked = false;
                                                } else {
                                                    document.getElementById(
                                                        btnCr.id
                                                    ).checked = true;
                                                }
                                            };

                                            var closeBtn = document.getElementById(
                                                "closeButton"
                                            );

                                            closeBtn.onclick = function () {
                                                if (btnCr.checked == true) {
                                                    document.getElementById(
                                                        btnCr.id
                                                    ).checked = false;
                                                } else {
                                                    document.getElementById(
                                                        btnCr.id
                                                    ).checked = true;
                                                }
                                            };

                                            var closeModal = document.getElementById(
                                                "myModalMap"
                                            );

                                            closeModal.onclick = function (e) {
                                                if (e.target == this) {
                                                    if (btnCr.checked == true) {
                                                        document.getElementById(
                                                            btnCr.id
                                                        ).checked = false;
                                                    } else {
                                                        document.getElementById(
                                                            btnCr.id
                                                        ).checked = true;
                                                    }
                                                }
                                            };
                                        }
                                    );
                                }
                            );

                            brBreak = userContent.appendChild(
                                document.createElement("br")
                            );
                            brBreak = userContent.appendChild(
                                document.createElement("br")
                            );

                            btnCreateCase = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnCreateCase.type = "button";
                            btnCreateCase.value = "Create Case";
                            btnCreateCase.className = "button";

                            btnEditAsset = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnEditAsset.type = "button";
                            btnEditAsset.value = "Edit Asset";
                            btnEditAsset.className = "button";

                            btnShowAttributes = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnShowAttributes.type = "button";
                            btnShowAttributes.value = "Show Attributes";
                            btnShowAttributes.className = "button";

                            btnThreePointRef = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnThreePointRef.type = "button";
                            btnThreePointRef.value = "3 Point Reference";
                            btnThreePointRef.className = "button";

                            google.maps.event.addDomListener(
                                btnCreateCase,
                                "click",
                                function () {
                                    $state.go("tr-case.new", {
                                        lat: pointVal[1],
                                        lng: pointVal[2],
                                        desc: "Case for " + assetDetails.name,
                                    });
                                }
                            );

                            google.maps.event.addDomListener(
                                btnEditAsset,
                                "click",
                                function () {
                                    AssetByID.get(
                                        assetDetails.id,
                                        function (response) {
                                            vm.assetToEdit = response;
                                            document.getElementById(
                                                "assetEditName"
                                            ).textContent =
                                                "Asset - " +
                                                vm.assetToEdit.name;
                                            editAssetOnMapView(assetDetails);
                                        }
                                    );
                                }
                            );

                            google.maps.event.addDomListener(
                                btnShowAttributes,
                                "click",
                                function () {
                                    AssetByID.get(
                                        assetDetails.id,
                                        function (response) {
                                            showAssetAttributesModal(response);
                                        }
                                    );
                                }
                            );

                            google.maps.event.addDomListener(
                                btnThreePointRef,
                                "click",
                                function () {
                                    AssetByID.get(
                                        assetDetails.id,
                                        function (response) {
                                            showThreePontReferenceModal(
                                                response
                                            );
                                        }
                                    );
                                }
                            );
                            return function () {
                                infowindow.setContent(userContent);
                                infowindow.open(locations.map, newMarker);
                                infowindow.open(locations.map, nodeCluster);
                                infowindow.setPosition(
                                    new google.maps.LatLng(
                                        newMarker.getPosition().lat(),
                                        newMarker.getPosition().lng()
                                    )
                                );
                                setTimeout(function () {
                                    infowindow.close();
                                }, 20000);
                            };
                        })(newMarker, i)
                    );

                    nodeMarkers.push(newMarker);
                }
            }
            nodeCluster = new MarkerClusterer(locations.map, nodeMarkers, {
                imagePath:
                    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            });

            google.maps.event.addDomListener(nodeCluster, "click", function () {
                closeClick = setTimeout(function () {
                    locations.map.setZoom(16);
                }, 10000);
            });

            /*  var zoomClose;
            google.maps.event.addDomListener(
                locations.map,
                "zoom_changed",
                function () {
                    var nodeZoom = locations.map.getZoom()
                    if(nodeZoom > 20){
                        clearTimeout(zoomClose)
                        zoomClose = setTimeout(function(){ locations.map.setZoom(16)},12000)
                    }
                }
            );  */
        }

        function drawNodeAssetsPolyline() {
            nodePolyline = [];

            for (var index = 0; index < vm.nodeAssets.length; index++) {
                if (vm.nodeAssets[index].length > 1) {
                    var nodeAssetVal = vm.nodeAssets[index];
                    var nodeAssetFirstVal = nodeAssetVal[0];
                    var nodeAssetFirstCoor =
                        nodeAssetFirstVal.assetDetails.assetCoordinates[0];
                    var latitude = nodeAssetFirstCoor.latitude;
                    var longitude = nodeAssetFirstCoor.longitude;
                    var points = getPentagonPoints(
                        {
                            lat: latitude,
                            lng: longitude,
                        },
                        0.01,
                        nodeAssetVal.length
                    );

                    for (var i = 0; i < nodeAssetVal.length; i++) {
                        var path = [];
                        var pointVal = points[i];
                        path.push({ lat: latitude, lng: longitude });
                        path.push({ lat: pointVal[1], lng: pointVal[2] });
                        var polyline = new google.maps.Polyline({
                            path: path,
                            strokeColor: "#000000",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                        });

                        polyline.setMap(locations.map);

                        nodePolyline.push(polyline);
                    }
                }
            }
        }

        function drawAssets(response, level) {
            if (response) {
                $.each(response, function (index, value) {
                    if (value.assetTypeLayout == null) {
                        value.assetTypeName = value.assetType.name;
                        value.assetTypeLayout = value.assetType.layout;
                        value.assetTypeImagePath = value.assetType.imagePath;
                    }
                    if (
                        value.assetTypeLayout != null &&
                        value.assetTypeLayout == "FIXED"
                    ) {
                        if (value.assetCoordinates.length > 1) {
                            // drawPolyline(value);
                            var value1 = value;
                            value1.assetCoordinates = [
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ],
                            ];
                            if ($scope.selectedFilterAssetTypes.length > 0) {
                                $scope.selectedFilterAssetTypes.forEach(
                                    function (assetTypeVal) {
                                        if (
                                            assetTypeVal.name ==
                                            value1.assetTypeName
                                        ) {
                                            drawFixedAsset(
                                                value1,
                                                false,
                                                level
                                            );
                                        }
                                    }
                                );
                            } else {
                                drawFixedAsset(value1, false, level);
                            }
                        } else {
                            if ($scope.selectedFilterAssetTypes.length > 0) {
                                $scope.selectedFilterAssetTypes.forEach(
                                    function (assetTypeVal) {
                                        if (
                                            assetTypeVal.name ==
                                            value.assetTypeName
                                        ) {
                                            drawFixedAsset(value, false, level);
                                        }
                                    }
                                );
                            } else {
                                drawFixedAsset(value, false, level);
                            }
                        }
                    }
                    if (
                        value.assetTypeLayout != null &&
                        value.assetTypeLayout == "SPREAD"
                    ) {
                        if ($scope.selectedFilterAssetTypes.length > 0) {
                            $scope.selectedFilterAssetTypes.forEach(function (
                                assetTypeVal
                            ) {
                                if (assetTypeVal.name == value.assetTypeName) {
                                    drawPolyline(value, locations.map, level);
                                }
                            });
                        } else {
                            drawPolyline(value, locations.map, level);
                        }
                    }
                });
            }
            switch (level) {
                case "level1":
                    isLevel1AssetsVisible = true;
                    break;
                case "level2":
                    isLevel2AssetsVisible = true;
                    break;
                case "level3":
                    isLevel3AssetsVisible = true;
                    break;
                case "level4":
                    isLevel4AssetsVisible = true;
                    break;
                case "level5":
                    isLevel5AssetsVisible = true;
                    break;
                case "level6":
                    assetVisibility19 = true;
                    break;
                case "level7":
                    assetVisibility20 = true;
                    break;
                default:
                    break;
            }
        }

        function drawAssetsCity(response, level) {
            if (response) {
                $.each(response, function (index, value) {
                    if (value.assetTypeLayout == null) {
                        value.assetTypeName = value.assetType.name;
                        value.assetTypeLayout = value.assetType.layout;
                        value.assetTypeImagePath = value.assetType.imagePath;
                    }
                    if (
                        value.assetTypeLayout != null &&
                        value.assetTypeLayout == "FIXED"
                    ) {
                        if (value.assetCoordinates.length > 1) {
                            // drawPolyline(value);
                            var value1 = value;
                            value1.assetCoordinates = [
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ],
                            ];
                            if (
                                $scope.selectedFilterAssetTypesCity.length > 0
                            ) {
                                $scope.selectedFilterAssetTypesCity.forEach(
                                    function (assetTypeVal) {
                                        if (
                                            assetTypeVal.name ==
                                            value1.assetTypeName
                                        ) {
                                            drawFixedAssetCity(
                                                value1,
                                                false,
                                                level
                                            );
                                        }
                                    }
                                );
                            } else {
                                drawFixedAssetCity(value1, false, level);
                            }
                        } else {
                            if (
                                $scope.selectedFilterAssetTypesCity.length > 0
                            ) {
                                $scope.selectedFilterAssetTypesCity.forEach(
                                    function (assetTypeVal) {
                                        if (
                                            assetTypeVal.name ==
                                            value.assetTypeName
                                        ) {
                                            drawFixedAssetCity(
                                                value,
                                                false,
                                                level
                                            );
                                        }
                                    }
                                );
                            } else {
                                drawFixedAssetCity(value, false, level);
                            }
                        }
                    }
                    if (
                        value.assetTypeLayout != null &&
                        value.assetTypeLayout == "SPREAD"
                    ) {
                        if ($scope.selectedFilterAssetTypesCity.length > 0) {
                            $scope.selectedFilterAssetTypesCity.forEach(
                                function (assetTypeVal) {
                                    if (
                                        assetTypeVal.name == value.assetTypeName
                                    ) {
                                        drawPolylineCity(
                                            value,
                                            locationsCity.mapCity,
                                            level
                                        );
                                    }
                                }
                            );
                        } else {
                            drawPolylineCity(
                                value,
                                locationsCity.mapCity,
                                level
                            );
                        }
                    }
                });
            }
            switch (level) {
                case "level1":
                    isLevel1AssetsVisibleCity = true;
                    break;
                case "level2":
                    isLevel2AssetsVisibleCity = true;
                    break;
                case "level3":
                    isLevel3AssetsVisibleCity = true;
                    break;
                case "level4":
                    isLevel4AssetsVisibleCity = true;
                    break;
                case "level5":
                    isLevel5AssetsVisibleCity = true;
                    break;
                default:
                    break;
            }
        }

        function setupAssetMap(callback) {
            if (locations.map == null) {
                /* var myOptions = {
                    zoom: 12,
                    center: locations.latlng,
                    scaleControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    myOption(12, locations.latlng)
                );
                geoLocation(locations.map);
                document.getElementById("myspan").textContent = "";
                mapZoomChange(locations.map);
                /* var measureTool = new MeasureTool(locations.map, {
                    contextMenu: true,
                    unit: MeasureTool.UnitTypeId.METRIC,
                }); */
            } else {
                // Loop through all the markers and polylines to remove

                for (var i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }

                for (i = 0; i < polylines.length; i++) {
                    polylines[i].setMap(null);
                }

                markers = [];
                polylines = [];
                mapZoomChange(locations.map);
            }
            locations.overlay = new google.maps.OverlayView();
            locations.overlay.draw = function () {};
            locations.overlay.setMap($scope.map);
            locations.element = document.getElementById("map_canvas");

            callback();
        }
        function setupAssetMapCity(callback) {
            if (locationsCity.mapCity == null) {
                /* var myOptions = {
                    zoom: 12,
                    center: locationsCity.latlng,
                    scaleControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locationsCity.mapCity = new google.maps.Map(
                    document.getElementById("map_canva"),
                    myOption(12, locationsCity.latlng)
                );
                geoLocation(locationsCity.mapCity);
                document.getElementById("myspanCity").textContent = "";

                //drawTheAssetsCity();
                mapZoomChangeCity(locationsCity.mapCity);
                /* var measureTool = new MeasureTool(locationsCity.mapCity, {
                    contextMenu: true,
                    unit: MeasureTool.UnitTypeId.METRIC,
                }); */
            }
            locationsCity.overlay = new google.maps.OverlayView();
            locationsCity.overlay.draw = function () {};
            locationsCity.overlay.setMap($scope.mapCity);
            locationsCity.element = document.getElementById("map_canva");

            callback();
        }

        function showAssetsonZoom(zoom) {
            var assetList = [];
            for (var index = 0; index < vm.assetTypeValues.length; index++) {
                if (vm.assetTypeValues[index].zoomLevel == zoom) {
                    vm.assetTypeValues[index].assets.forEach(function (
                        assetVal
                    ) {
                        assetList.push(assetVal);
                    });
                }
            }
            drawLatestAssets(assetList, "level1");
        }

        function showLabelBounds() {
            var bounds = locations.map.getBounds();
            for (var i = 0; i < level2AssetsMarkers.length; i++) {
                //
                if (bounds.contains(level2AssetsMarkers[i].position)) {
                    level2AssetsMarkers[i].setLabel("");
                    level2AssetsMarkers[i].setLabel(
                        level2AssetsMarkers[i].displayLabel
                    );
                }
            }

            for (var i = 0; i < level3AssetsMarkers.length; i++) {
                //
                if (bounds.contains(level3AssetsMarkers[i].position)) {
                    level3AssetsMarkers[i].setLabel("");
                    level3AssetsMarkers[i].setLabel(
                        level3AssetsMarkers[i].displayLabel
                    );
                }
            }
            for (var i = 0; i < level4AssetsMarkers.length; i++) {
                //
                if (bounds.contains(level4AssetsMarkers[i].position)) {
                    level4AssetsMarkers[i].setLabel("");
                    level4AssetsMarkers[i].setLabel(
                        level4AssetsMarkers[i].displayLabel
                    );
                }
            }

            for (var i = 0; i < level5AssetsMarkers.length; i++) {
                //
                if (bounds.contains(level5AssetsMarkers[i].position)) {
                    level5AssetsMarkers[i].setLabel("");
                    level5AssetsMarkers[i].setLabel(
                        level5AssetsMarkers[i].displayLabel
                    );
                }
            }

            for (var i = 0; i < level6AssetsMarkers.length; i++) {
                //
                if (bounds.contains(level6AssetsMarkers[i].position)) {
                    level6AssetsMarkers[i].setLabel("");
                    level6AssetsMarkers[i].setLabel(
                        level6AssetsMarkers[i].displayLabel
                    );
                }
            }

            for (var i = 0; i < level7AssetsMarkers.length; i++) {
                //
                if (bounds.contains(level7AssetsMarkers[i].position)) {
                    level7AssetsMarkers[i].setLabel("");
                    level7AssetsMarkers[i].setLabel(
                        level7AssetsMarkers[i].displayLabel
                    );
                }
            }
        }
        function showLabelOnView() {
            google.maps.event.addListener(
                locations.map,
                "dragend",
                function () {
                    //var bounds = locations.map.getBounds();
                    if (vm.zoomLevel < 18) {
                        clearLabel();
                    }
                    if (vm.zoomLevel >= 18) {
                        showLabelBounds();
                    }
                }
            );
        }

        function clearLabel() {
            for (var i = 0; i < level2AssetsMarkers.length; i++) {
                level2AssetsMarkers[i].setLabel("");
            }
            for (var i = 0; i < level3AssetsMarkers.length; i++) {
                level3AssetsMarkers[i].setLabel("");
            }
            for (var i = 0; i < level4AssetsMarkers.length; i++) {
                level4AssetsMarkers[i].setLabel("");
            }
            for (var i = 0; i < level5AssetsMarkers.length; i++) {
                level5AssetsMarkers[i].setLabel("");
            }
            for (var i = 0; i < level6AssetsMarkers.length; i++) {
                level6AssetsMarkers[i].setLabel("");
            }
            for (var i = 0; i < level7AssetsMarkers.length; i++) {
                level7AssetsMarkers[i].setLabel("");
            }
            vm.zoomLabel18 = false;
            /* vm.zoomLabel19 = false
            vm.zoomLabel20 = false */
        }

        vm.showCases = function () {
            clearCaseMarker();
            if (vm.storeCases && vm.zoomLevel >= 15 && vm.selectedCase.length) {
                callTempUG(vm.selectedCase);
            }
        };

        function clearCaseMarker() {
            for (var i = 0; i < caseMarker.length; i++) {
                caseMarker[i].setMap(null);
            }

            /* for (var i = 0; i < caseMarker.length; i++) {
                caseMarker[i].setLabel("");
            } */
        }

        function callTempUG(arr) {
            var tempUGMapMarker,
                image,
                userContent,
                infowindow,
                btnEditCase,
                btnViewCase;
            infowindow = new google.maps.InfoWindow();

            arr.forEach(function (arrId) {
                vm.storeCases[arrId.id].forEach(function (item) {
                    if (vm.caseTypes) {
                        var caseType = vm.caseTypes.find(function (caseType) {
                            return caseType.name == item.caseTypeName;
                        });
                        image =
                            caseType && caseType.image
                                ? "data:image/png;base64," + caseType.image
                                : "content/images/dot_marker.png";
                    }

                    tempUGMapMarker = new google.maps.Marker({
                        map: locations.map,
                        position: new google.maps.LatLng(
                            item.pinLat,
                            item.pinLong
                        ),
                        title: item.description,
                        icon: {
                            url: image,
                            scaledSize: new google.maps.Size(24, 24),
                            labelOrigin: new google.maps.Point(25, -4),
                        },
                    });

                    tempUGMapMarker.setMap(locations.map);
                    /* tempUGMapMarker.displayLabel =  {
                        text: item.description,
                        color: '#000',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }
    
                    tempUGMapMarker.setLabel(tempUGMapMarker.displayLabel); */
                    userContent = document.createElement("div");
                    userContent.innerHTML =
                        '<div class="infoWindowhead">' +
                        "<b> Case Type : </b>" +
                        item.caseTypeName +
                        "<br> <b> Description : </b>" +
                        item.description +
                        '</div><div class="infoWindowContent">' +
                        "<b> Case Id : </b>" +
                        item.id +
                        "<br><b> Priority : </b>" +
                        item.priority +
                        "<br> <b> Status : </b>" +
                        item.status +
                        "<br><b>Latitude</b> :" +
                        item.pinLat +
                        "<br><b>Longitude</b> :" +
                        item.pinLong;
                    ("</div>");

                    /* btnEditCase = userContent.appendChild(
                        document.createElement("input")
                    );
    
                    btnEditCase.type = "button";
                    btnEditCase.value = "Edit Case";
                    btnEditCase.className = "button"; */

                    btnViewCase = userContent.appendChild(
                        document.createElement("input")
                    );

                    btnViewCase.type = "button";
                    btnViewCase.value = "View Case";
                    btnViewCase.className = "button";

                    /* google.maps.event.addDomListener(
                        btnEditCase,
                        "click",
                        function () {
                            $state.go("tr-case.edit", {
                                     id: item.id,
                                 });
                        }
                    ); */

                    google.maps.event.addDomListener(
                        btnViewCase,
                        "click",
                        function () {
                            $rootScope.hideCaseEditBtn = true;
                            $state.go("tr-case-detail", {
                                id: item.id,
                            });
                        }
                    );

                    google.maps.event.addListener(
                        tempUGMapMarker,
                        "click",
                        (function (tempUGMapMarker, userContent, infowindow) {
                            return function () {
                                closeLastOpenedInfoWindow();
                                infowindow.setContent(userContent);
                                infowindow.open(locations.map, tempUGMapMarker);
                                lastOpenedInfoWindow = infowindow;
                                setTimeout(function () {
                                    lastOpenedInfoWindow.close();
                                }, 8000);
                            };
                        })(tempUGMapMarker, userContent, infowindow)
                    );

                    caseMarker.push(tempUGMapMarker);
                });
            });
        }

        function closeLastOpenedInfoWindow() {
            if (lastOpenedInfoWindow) {
                lastOpenedInfoWindow.close();
            }
            if (vm.closeInfoWindow) {
                vm.closeInfoWindow.close();
            }
            if (openedInfoWindow) {
                openedInfoWindow.close();
            }
        }

        function mapZoomChange(map) {
            google.maps.event.addListener(
                locations.map,
                "zoom_changed",
                function () {
                    vm.zoomLevel = locations.map.getZoom();
                    vm.currentZoom = locations.map.getZoom();
                    document.getElementById("myspan").textContent =
                        "ZOOM : " + vm.zoomLevel;
                    if (vm.prevZoomLevel == 19 && vm.zoomLevel <= 18) {
                        clearTimeout(closeClick);
                    }
                    if (vm.zoomLevel < 12) {
                        for (var i = 0; i < level1AssetsMarkers.length; i++) {
                            level1AssetsMarkers[i].setMap(null);
                        }

                        for (i = 0; i < level1AssetsPolyline.length; i++) {
                            level1AssetsPolyline[i].setMap(null);
                        }
                        isLevel1AssetsVisible = false;
                        vm.zoom13 = false;
                        vm.zoom14 = false;
                    }
                    if (vm.zoomLevel == 12) {
                        if (!isLevel1AssetsVisible) {
                            var assetList = [];
                            for (
                                var index = 0;
                                index < vm.assetTypeValues.length;
                                index++
                            ) {
                                if (vm.assetTypeValues[index].zoomLevel == 12) {
                                    vm.assetTypeValues[index].assets.forEach(
                                        function (assetVal) {
                                            assetList.push(assetVal);
                                        }
                                    );
                                }
                            }
                            drawLatestAssets(assetList, "level1");
                            isLevel1AssetsVisible = true;
                        }
                    }

                    if (vm.zoomLevel == 13) {
                        if (!vm.zoom13) {
                            showAssetsonZoom(13);
                            vm.zoom13 = true;
                        }
                    }

                    if (vm.zoomLevel == 14) {
                        if (!vm.zoom14) {
                            showAssetsonZoom(14);
                            vm.zoom14 = true;
                        }
                    }
                    if (vm.zoomLevel < 15) {
                        vm.displayCaseType = false;
                        $scope.$evalAsync();

                        for (var i = 0; i < level2AssetsMarkers.length; i++) {
                            level2AssetsMarkers[i].setMap(null);
                        }

                        for (i = 0; i < level2AssetsPolyline.length; i++) {
                            level2AssetsPolyline[i].setMap(null);
                        }

                        clearCaseMarker();

                        isLevel2AssetsVisible = false;
                    }

                    if (vm.zoomLevel < 18) {
                        clearLabel();
                    }

                    /* if(vm.prevZoomLevel == 18 && vm.zoomLevel == 19) {
                    if(!vm.zoomLabel19){
                        showLabelBounds()
                        showLabelOnView()
                        
                         for (var i = 0; i < level2AssetsMarkers.length; i++) {
                            if(level2AssetsMarkers[i].labelZoom == 19){
                                level2AssetsMarkers[i].setLabel(level2AssetsMarkers[i].displayLabel);
                            }
                        }
                        for (var i = 0; i < level3AssetsMarkers.length; i++) {
                            if(level3AssetsMarkers[i].labelZoom == 19){
                            level3AssetsMarkers[i].setLabel(level3AssetsMarkers[i].displayLabel);}
                        }
                        for (var i = 0; i < level4AssetsMarkers.length; i++) {
                            if(level4AssetsMarkers[i].labelZoom == 19){
                            level4AssetsMarkers[i].setLabel(level4AssetsMarkers[i].displayLabel);}
                        }
                        for (var i = 0; i < level5AssetsMarkers.length; i++) {
                            if(level5AssetsMarkers[i].labelZoom == 19){
                            level5AssetsMarkers[i].setLabel(level5AssetsMarkers[i].displayLabel);}
                        } 
                        vm.zoomLabel19 = true 
                    }
                } */

                    if (vm.prevZoomLevel == 14 && vm.zoomLevel == 15) {
                        vm.displayCaseType = true;
                        $scope.$evalAsync();
                        vm.getCenter = locations.map.center;
                        vm.currentZoom1 = locations.map.getZoom();

                        if (!vm.storeCases) {
                            casesInMap.get(
                                {
                                    geofenceId: vm.selectedMapGeofenceId,
                                    caseTypeIds: caseTypeIds,
                                },
                                function (response) {
                                    vm.tempLength = response.cases.length > 0;
                                    var result = {},
                                        arrId = [];
                                    if (vm.tempLength) {
                                        response.casetypes.forEach(function (
                                            casetype
                                        ) {
                                            result[
                                                casetype.id
                                            ] = response.cases.filter(function (
                                                c
                                            ) {
                                                return (
                                                    c.caseTypeName ===
                                                    casetype.name
                                                );
                                            });
                                        });
                                    }

                                    vm.storeCases = vm.tempLength
                                        ? result
                                        : false;
                                    vm.caseTypes =
                                        response.cases.length > 0
                                            ? vm.caseType.filter(function (
                                                  obj1
                                              ) {
                                                  obj1.showInAssetsView = true;

                                                  for (var property in result) {
                                                      if (property == obj1.id) {
                                                          obj1.displayName =
                                                              obj1.name +
                                                              " - (" +
                                                              result[obj1.id]
                                                                  .length +
                                                              ")";
                                                      }
                                                  }

                                                  return response.casetypes.some(
                                                      function (obj2) {
                                                          return (
                                                              obj1.id ===
                                                              obj2.id
                                                          );
                                                      }
                                                  );
                                              })
                                            : [];

                                    vm.caseTypes.forEach(function (id) {
                                        arrId.push({ id: id.id });
                                    });
                                    if (vm.storeCases) {
                                        callTempUG(arrId);
                                    }
                                }
                            );
                        } else if (vm.storeCases && vm.selectedCase.length) {
                            callTempUG(vm.selectedCase);
                        }

                        if (!isLevel2AssetsVisible) {
                            var zoomLevel_15 = [];
                            for (
                                var index = 0;
                                index < vm.assetTypeValues.length;
                                index++
                            ) {
                                /* vm.assetTypeValues.forEach(function (val){
                                val.assets.forEach(function(item){
                                    item.label = val.zoomLevelLabel
                                })
                            }) */
                                if (vm.assetTypeValues[index].zoomLevel == 15) {
                                    vm.assetTypeValues[index].assets.forEach(
                                        function (assetVal) {
                                            zoomLevel_15.push(assetVal);
                                        }
                                    );
                                }
                            }
                            drawAssets(zoomLevel_15, "level2");

                            /* var siteList = [];
                        for (
                        var index = 0;
                        index < vm.assetTypeValues.length;
                        index++
                        ) {
                        if (
                            vm.assetTypeValues[index].assetTypeName ==
                            "Site"
                        ) {
                            vm.assetTypeValues[index].assets.forEach(
                                function (assetVal) {
                                    siteList.push(assetVal);
                                }
                            );
                        }
                        }
                        drawAssets(siteList, "level2");

                        var chamberHandHoleList = [];
                        for (
                        var index = 0;
                        index < vm.assetTypeValues.length;
                        index++
                        ) {
                        if (
                            vm.assetTypeValues[index].assetTypeName ==
                            "Chamber / Handhole"
                        ) {
                            vm.assetTypeValues[index].assets.forEach(
                                function (assetVal) {
                                    chamberHandHoleList.push(assetVal);
                                }
                            );
                        }
                        }
                        drawAssets(chamberHandHoleList, "level2");

                        var handHoleChList = [];
                        for (
                        var index = 0;
                        index < vm.assetTypeValues.length;
                        index++
                        ) {
                        if (
                            vm.assetTypeValues[index].assetTypeName ==
                            "Handhole CH"
                        ) {
                            vm.assetTypeValues[index].assets.forEach(
                                function (assetVal) {
                                    handHoleChList.push(assetVal);
                                }
                            );
                        }
                        }
                        drawAssets(handHoleChList, "level2"); */

                            isLevel2AssetsVisible = true;
                        }
                    }
                    /* if (vm.currentZoom >= 15) {
                    vm.getCenter = locations.map.center;
                    if (isLevel2AssetsVisible) {
                        var chamberList = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (
                                vm.assetTypeValues[index].assetTypeName ==
                                "Chamber"
                            ) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        chamberList.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(chamberList, "level2");
                        isLevel2AssetsVisible = true;
                    }
                } */

                    if (vm.zoomLevel <= 15) {
                        for (var i = 0; i < level3AssetsMarkers.length; i++) {
                            level3AssetsMarkers[i].setMap(null);
                        }

                        for (i = 0; i < level3AssetsPolyline.length; i++) {
                            level3AssetsPolyline[i].setMap(null);
                        }
                        isLevel3AssetsVisible = false;
                    }

                    if (vm.prevZoomLevel == 15 && vm.zoomLevel == 16) {
                        vm.getCenter = locations.map.center;
                        vm.currentZoom1 = locations.map.getZoom();
                        if (!isLevel3AssetsVisible) {
                            var couplerList = [];
                            for (
                                var index = 0;
                                index < vm.assetTypeValues.length;
                                index++
                            ) {
                                if (vm.assetTypeValues[index].zoomLevel == 16) {
                                    vm.assetTypeValues[index].assets.forEach(
                                        function (assetVal) {
                                            couplerList.push(assetVal);
                                        }
                                    );
                                }
                            }
                            drawAssets(couplerList, "level3");
                            isLevel3AssetsVisible = true;
                        }
                    }

                    /*  if (vm.currentZoom >= 16) {
                    vm.getCenter = locations.map.center;
                    if (isLevel3AssetsVisible) {
                        var couplerList = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (
                                vm.assetTypeValues[index].assetTypeName ==
                                "Coupler"
                            ) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        couplerList.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(couplerList, "level3");
                        isLevel3AssetsVisible = true;
                    }
                } */

                    if (vm.zoomLevel <= 16) {
                        for (var i = 0; i < level4AssetsMarkers.length; i++) {
                            level4AssetsMarkers[i].setMap(null);
                        }

                        for (i = 0; i < level4AssetsPolyline.length; i++) {
                            level4AssetsPolyline[i].setMap(null);
                        }
                        isLevel4AssetsVisible = false;
                    }

                    if (vm.prevZoomLevel == 16 && vm.zoomLevel == 17) {
                        vm.getCenter = locations.map.center;
                        vm.currentZoom1 = locations.map.getZoom();
                        if (!isLevel4AssetsVisible) {
                            var zoomLevel_17 = [];
                            for (
                                var index = 0;
                                index < vm.assetTypeValues.length;
                                index++
                            ) {
                                if (vm.assetTypeValues[index].zoomLevel == 17) {
                                    vm.assetTypeValues[index].assets.forEach(
                                        function (assetVal) {
                                            zoomLevel_17.push(assetVal);
                                        }
                                    );
                                }
                            }
                            drawAssets(zoomLevel_17, "level4");

                            /* var jointClosureList = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (
                                vm.assetTypeValues[index].assetTypeName ==
                                "Joint Closure"
                            ) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        jointClosureList.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(jointClosureList, "level4"); */
                            isLevel4AssetsVisible = true;
                        }
                    }

                    if (vm.zoomLevel <= 17) {
                        for (var i = 0; i < level5AssetsMarkers.length; i++) {
                            level5AssetsMarkers[i].setMap(null);
                        }
                        isLevel5AssetsVisible = false;
                    }
                    if (vm.prevZoomLevel == 17 && vm.zoomLevel == 18) {
                        if (!isLevel5AssetsVisible) {
                            var otherList = [];
                            for (
                                var index = 0;
                                index < vm.assetTypeValues.length;
                                index++
                            ) {
                                if (vm.assetTypeValues[index].zoomLevel == 18) {
                                    vm.assetTypeValues[index].assets.forEach(
                                        function (assetVal) {
                                            otherList.push(assetVal);
                                        }
                                    );
                                }
                            }
                            drawAssets(otherList, "level5");
                            isLevel5AssetsVisible = true;
                        }
                    }

                    if (vm.zoomLevel <= 18) {
                        for (var i = 0; i < level6AssetsMarkers.length; i++) {
                            level6AssetsMarkers[i].setMap(null);
                        }
                        assetVisibility19 = false;
                    }
                    if (vm.prevZoomLevel == 18 && vm.zoomLevel == 19) {
                        if (!assetVisibility19) {
                            var assetZoom19 = [];
                            for (
                                var index = 0;
                                index < vm.assetTypeValues.length;
                                index++
                            ) {
                                if (vm.assetTypeValues[index].zoomLevel == 19) {
                                    vm.assetTypeValues[index].assets.forEach(
                                        function (assetVal) {
                                            assetZoom19.push(assetVal);
                                        }
                                    );
                                }
                            }
                            drawAssets(assetZoom19, "level6");
                            showLabelBounds();
                            assetVisibility19 = true;
                        }
                    }

                    if (vm.zoomLevel <= 19) {
                        for (var i = 0; i < level7AssetsMarkers.length; i++) {
                            level7AssetsMarkers[i].setMap(null);
                        }
                        assetVisibility20 = false;
                    }
                    if (vm.prevZoomLevel == 19 && vm.zoomLevel == 20) {
                        if (!assetVisibility20) {
                            var assetZoom20 = [];
                            for (
                                var index = 0;
                                index < vm.assetTypeValues.length;
                                index++
                            ) {
                                if (vm.assetTypeValues[index].zoomLevel == 20) {
                                    vm.assetTypeValues[index].assets.forEach(
                                        function (assetVal) {
                                            assetZoom20.push(assetVal);
                                        }
                                    );
                                }
                            }
                            drawAssets(assetZoom20, "level7");
                            showLabelBounds();
                            assetVisibility20 = true;
                        }
                    }
                    if (vm.zoomLevel <= 15) {
                        for (var i = 0; i < nodeMarkers.length; i++) {
                            for (
                                var index = 0;
                                index < nodeMarkers.length;
                                index++
                            ) {
                                nodeMarkers[index].setMap(null);
                            }
                            nodeCluster.clearMarkers();
                            nodeMarkers = [];
                            isLevelNodeVisible = false;
                        }
                    }

                    if (vm.prevZoomLevel == 17 && vm.zoomLevel == 18) {
                        if (!vm.zoomLabel18) {
                            showLabelBounds();
                            showLabelOnView();
                            /* for (var i = 0; i < level2AssetsMarkers.length; i++) {
                            if(level2AssetsMarkers[i].labelZoom == 18){
                                level2AssetsMarkers[i].setLabel(level2AssetsMarkers[i].displayLabel);
                            }
                        }
                        for (var i = 0; i < level3AssetsMarkers.length; i++) {
                            if(level3AssetsMarkers[i].labelZoom == 18){
                            level3AssetsMarkers[i].setLabel(level3AssetsMarkers[i].displayLabel);}
                        }
                        for (var i = 0; i < level4AssetsMarkers.length; i++) {
                            if(level4AssetsMarkers[i].labelZoom == 18){
                            level4AssetsMarkers[i].setLabel(level4AssetsMarkers[i].displayLabel);}
                        }
                        for (var i = 0; i < level5AssetsMarkers.length; i++) {
                            if(level5AssetsMarkers[i].labelZoom == 18){
                            level5AssetsMarkers[i].setLabel(level5AssetsMarkers[i].displayLabel);}
                        } */
                            vm.zoomLabel18 = true;
                        }
                    }

                    if (vm.prevZoomLevel == 15 && vm.zoomLevel == 16) {
                        if (!isLevelNodeVisible) {
                            for (var i = 0; i < nodeMarkers.length; i++) {
                                for (
                                    var index = 0;
                                    index < nodeMarkers.length;
                                    index++
                                ) {
                                    nodeMarkers[index].setMap(null);
                                }
                                nodeCluster.clearMarkers();
                                nodeMarkers = [];
                            }
                            drawNodeAssets();
                            isLevelNodeVisible = true;
                        }
                    }

                    if (vm.prevZoomLevel >= 19 && vm.zoomLevel <= 18) {
                        for (var i = 0; i < nodePolyline.length; i++) {
                            for (
                                var index = 0;
                                index < nodePolyline.length;
                                index++
                            ) {
                                nodePolyline[index].setMap(null);
                            }
                            nodePolyline = [];
                        }
                    }
                    if (vm.zoomLevel >= 19 && nodePolyline.length == 0) {
                        drawNodeAssetsPolyline();
                    }

                    vm.prevZoomLevel = vm.zoomLevel;
                }
            );
        }

        function mapZoomChangeCity(map) {
            google.maps.event.addListener(
                locationsCity.mapCity,
                "idle",
                function () {
                    vm.zoomLevelCity = locationsCity.mapCity.getZoom();

                    document.getElementById("myspanCity").textContent =
                        "ZOOM : " + vm.zoomLevelCity;
                }
            );
        }

        function mapZoomChangeFilter(map) {
            google.maps.event.addListener(locations.map, "idle", function () {
                vm.currentZoom = locations.map.getZoom();

                document.getElementById("myspan").textContent =
                    "ZOOM : " + vm.currentZoom;

                if (vm.currentZoom >= 15) {
                    if (isLevel2AssetsVisible) {
                        var zoomLevel_15 = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 15) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        zoomLevel_15.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(zoomLevel_15, "level2");

                        /* var siteList = [];
                        for (
                        var index = 0;
                        index < vm.assetTypeValues.length;
                        index++
                        ) {
                        if (
                            vm.assetTypeValues[index].assetTypeName ==
                            "Site"
                        ) {
                            vm.assetTypeValues[index].assets.forEach(
                                function (assetVal) {
                                    siteList.push(assetVal);
                                }
                            );
                        }
                        }
                        drawAssets(siteList, "level2"); */

                        isLevel2AssetsVisible = true;
                    }
                    if (vm.storeCases && vm.selectedCase.length) {
                        callTempUG(vm.selectedCase);
                    }
                }

                if (vm.currentZoom >= 16) {
                    if (isLevel3AssetsVisible) {
                        var couplerList = [];
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 16) {
                                vm.assetTypeValues[index].assets.forEach(
                                    function (assetVal) {
                                        couplerList.push(assetVal);
                                    }
                                );
                            }
                        }
                        drawAssets(couplerList, "level3");
                        isLevel3AssetsVisible = true;
                    }
                }
            });
        }

        function initializeAssetMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                var latVal = null;
                var lngVal = null;
                latlangs.forEach(function (value) {
                    if (latVal == null && lngVal == null) {
                        if (value.assetCoordinates.length > 0) {
                            latVal =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].latitude;
                            lngVal =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].longitude;
                        }
                    }
                });
                if (latVal != null && lngVal != null) {
                    latlng = new google.maps.LatLng(latVal, lngVal);
                    callback(latlng);
                }
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            );
                            callback(latlng);
                        },
                        function () {
                            // callback(new google.maps.LatLng("12.9716", "77.5946"));
                            callback(
                                new google.maps.LatLng(
                                    DEFAULT_LATITUDE,
                                    DEFAULT_LONGITUDE
                                )
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    callback(
                        new google.maps.LatLng(
                            DEFAULT_LATITUDE,
                            DEFAULT_LONGITUDE
                        )
                    );
                }
            }
        }

        function drawFixedAsset(fixed, hasParent, level) {
            $.each(fixed.assetCoordinates, function (index, value1) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }

                var infoWindow = new google.maps.InfoWindow();

                var iconUrl = "";

                var isFixedAsset = false;

                /*  if ($scope.variableHealth) {
                    if (
                        fixed.healthIndicator == true &&
                        fixed.assetTypeName == "Chamber"
                    ) {
                        iconUrl = "content/images/chamber_green.png";
                    } else if (
                        fixed.healthIndicator == false &&
                        fixed.assetTypeName == "Chamber"
                    ) {
                        iconUrl = "content/images/chamber_red.png";
                    } else if (
                        fixed.healthIndicator == true &&
                        fixed.assetTypeName == "Coupler"
                    ) {
                        iconUrl = "content/images/coupler_green.png";
                    } else if (
                        fixed.healthIndicator == false &&
                        fixed.assetTypeName == "Coupler"
                    ) {
                        iconUrl = "content/images/coupler_red.png";
                    } else if (
                        fixed.healthIndicator == true &&
                        fixed.assetTypeName == "Pole"
                    ) {
                        iconUrl = "content/images/pole_green.png";
                    } else if (
                        fixed.healthIndicator == false &&
                        fixed.assetTypeName == "Pole"
                    ) {
                        iconUrl = "content/images/pole_red.png";
                    } else {
                        iconUrl = fixed.assetTypeImagePath;
                    }
                } else {
                    iconUrl = fixed.assetTypeImagePath;
                } */

                //buried chamber
                if(fixed.assetTypeName == "Chamber" && fixed.burried){
                    iconUrl = "content/images/buriedChamber.png"
                }else{
                    iconUrl = fixed.assetTypeImagePath;
                } 

                //iconUrl = fixed.assetTypeImagePath;
                isFixedAsset = true;

                var iconSize = 8;
                if (vm.zoomLevel == 10) {
                    iconSize = 8;
                } else if (vm.zoomLevel == 11) {
                    iconSize = 16;
                }

                if (isFixedAsset) {
                    iconSize = 24;
                }

                var startMarker = new google.maps.Marker({
                    map: locations.map,
                    position: new google.maps.LatLng(
                        value1.latitude,
                        value1.longitude
                    ),
                    title: fixed.name,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: iconUrl,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });
                startMarker.labelZoom = fixed.label;
                startMarker.assetId = fixed.id;
                startMarker.displayLabel = {
                    text: fixed.name,
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: "bold",
                };

                // if (vm.zoomLevel == 10) {
                //     startMarker.setLabel('');
                // } else if (vm.zoomLevel == 11) {
                //     var label = { color: '#000000', fontWeight: 'bold', fontSize: '12px', text: fixed.name };
                //     startMarker.setLabel(label);
                // }

                // if (fixed.status != null &&
                //     fixed.status === false) {
                //     startMarker.setLabel({
                //         color: 'red',
                //         fontSize: '30px',
                //         fontWeight: '20px',
                //         text: '\u2297'
                //     });
                // }
                // Add marker to the array.

                // markers.push(startMarker);

                switch (level) {
                    case "level1":
                        level1AssetsMarkers.push(startMarker);
                        break;
                    case "level2":
                        level2AssetsMarkers.push(startMarker);
                        break;
                    case "level3":
                        level3AssetsMarkers.push(startMarker);
                        break;
                    case "level4":
                        level4AssetsMarkers.push(startMarker);
                        break;
                    case "level5":
                        level5AssetsMarkers.push(startMarker);
                        break;
                    case "level6":
                        level6AssetsMarkers.push(startMarker);
                        break;
                    case "level7":
                        level7AssetsMarkers.push(startMarker);
                        break;
                    default:
                        markers.push(startMarker);
                        break;
                }
                var infoWindow2 = new google.maps.InfoWindow();
                var infoStr = "";
                var otdr = "";

                var userContent = document.createElement("div"),
                    button;

                userContent.innerHTML =
                    '<div class="infoWindowhead">' +
                    startMarker.title +
                    '</div><div class="infoWindowContent">' +
                    "<b>Lat</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .latitude +
                    "<br><b>Long</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .longitude +
                    "<br>" +
                    infoStr +
                    "</div>";

                google.maps.event.addListener(
                    startMarker,
                    "dblclick",
                    function () {
                        infoWindow2.close();
                    }
                );

                var activeInfoWindow;
                var infoWindow2 = new google.maps.InfoWindow({
                    content: userContent,
                });
                // add listener on InfoWindow for click
                // event
                google.maps.event.addListener(
                    startMarker,
                    "click",
                    function () {
                        if (existingHighlightFixed != null) {
                            existingHighlightFixed.setMap(null);
                            existingHighlightFixed = null;
                        }
                        
                        var circle = new google.maps.Circle({
                            map: locations.map, // The map object
                            center: { lat: startMarker.map.center.lat(), lng: startMarker.map.center.lng() }, // Center of the circle (same as the marker)
                            radius: 2, // Radius in meters (1000 meters = 1 kilometer)
                            strokeColor: '#fd7a24', // Color of the circle's border
                            strokeOpacity: 0.8, // Opacity of the circle's border
                            strokeWeight: 2, // Width of the circle's border
                            fillColor: '#fd7a24', // Fill color of the circle
                            fillOpacity: 1, // Opacity of the circle's fill color
                        });
                        
                        // Bind the circle to the marker so it moves with the marker if the marker is draggable
                        circle.bindTo('center', startMarker, 'position');
                        existingHighlightFixed = circle;
                        
                        var fixedHighlightTimer = setTimeout(function () {
                            if (circle != null) {
                                circle.setMap(null);
                                circle = null;
                            }
                        }, 6000);
  
                        if (activeInfo != null) {
                            activeInfo.close();
                        }
                        closeLastOpenedInfoWindow();

                        AssetByID.get(fixed.id, function (response) {
                            vm.assetRefAttributes = [];
                            if (response.assetReferenceAttributes.length) {
                                response.assetReferenceAttributes.forEach(
                                    function (get) {
                                        get[
                                            "assetAttributeName"
                                        ].assetTypeAttribute.attributeValue =
                                            get[
                                                "assetAttributeName"
                                            ].attributeValue;
                                        vm.assetRefAttributes.push(
                                            get["assetAttributeName"]
                                                .assetTypeAttribute
                                        );
                                        get[
                                            "assetAttributeValue"
                                        ].assetTypeAttribute.attributeValue =
                                            get[
                                                "assetAttributeValue"
                                            ].attributeValue;
                                        vm.assetRefAttributes.push(
                                            get["assetAttributeValue"]
                                                .assetTypeAttribute
                                        );
                                        get[
                                            "assetAttributeImage"
                                        ].assetTypeAttribute.attributeValue =
                                            get["assetAttributeImage"].image;
                                            get[
                                                "assetAttributeImage"
                                            ].assetTypeAttribute.attributeName =
                                                get["assetAttributeImage"].imageName;
                                        get[
                                                    "assetAttributeImage"
                                                ].assetTypeAttribute.imageId =
                                                    get["assetAttributeImage"].imageId;
                                                    get[
                                                        "assetAttributeImage"
                                                    ].assetTypeAttribute.attributeType =
                                                        get["assetAttributeImage"].imageType;
                                        vm.assetRefAttributes.push(
                                            get["assetAttributeImage"]
                                                .assetTypeAttribute
                                        );
                                    }
                                );
                            }
                            vm.assetToEdit = {};
                            vm.assetToEdit = response;
                            vm.assetAttachments = response;
                            var infoWindow2 = new google.maps.InfoWindow();
                            var infoStr = "";
                            var otdr = "";
                            var assetTypeAttributeValuesList =
                                response.assetTypeAttributeValues;
                            assetTypeAttributeValuesList.sort(function (a, b) {
                                var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                    nameB = a.assetTypeAttribute.name.toLowerCase();
                                if (nameA < nameB)
                                    // sort string ascending
                                    return -1;
                                if (nameA > nameB) return 1;
                                return 0; // default return value (no sorting)
                            });
                            for (
                                var i = 0;
                                i < assetTypeAttributeValuesList.length;
                                i++
                            ) {
                                var home = assetTypeAttributeValuesList[i];
                                infoStr +=
                                    "<br><b>" +
                                    home.assetTypeAttribute.name +
                                    "</b> : " +
                                    home.attributeValue;
                                otdr = home;
                            }

                            var userContent = document.createElement("div"),
                                btnShowAttributes,
                                btnCr,
                                btnText,
                                btnText1,
                                btnText2,
                                btnEditAsset,
                                btnCreateCase,
                                btnThreePointRef,
                                btnShowAttachments,
                                btnTextBuried,
                                btnBuried,
                                viewSplicingMap;
                            var url = $state.href('asset-detail', { id: response.id });
                            userContent.innerHTML =
                                '<div class="infoWindowhead">' +/* 
                                "<b>Id</b> : <a target='_blank' href='" + url + "'>" + response.id+
                                "</a><br><b>Name</b>"+
                                */ response.name +
                                '</div><div class="infoWindowContent">' +
                                "<b>Asset Type</b> : " +
                                fixed.assetTypeName +
                                "<br><b>Latitude</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].latitude +
                                "<br><b>Longitude</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].longitude +
                                "</div><br>";

                            vm.assetTitle = response.name;
                            btnText = userContent.appendChild(
                                document.createElement("b")
                            );

                            btnText.textContent = "Accessible";
                            btnText.style.marginRight = "4px";

                            btnCr = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnCr.style.marginRight = "18px";
                            btnCr.type = "checkbox";
                            btnCr.id = response.id;

                            btnCr.checked = response.accessible;

                            btnText1 = userContent.appendChild(
                                document.createElement("b")
                            );

                           
                            /* if (
                                ($scope.variableHealth == true &&
                                    response.healthIndicator == true) ||
                                ($scope.variableHealth == true &&
                                    response.healthIndicator == false)
                            ) {
                                btnText1.textContent =
                                    "Remarks:" + response.healthRemarks;
                                btnText1.style.marginLeft = "4px";
                            } */

                            var attr = document.createAttribute("data-toggle");
                            attr.value = "modal";
                            var attr1 = document.createAttribute("data-target");
                            attr1.value = "#myModalMap";

                            btnCr.setAttributeNode(attr);
                            btnCr.setAttributeNode(attr1);
                            
                            vm.changeAcessible = response.accessible
                                ? "Inaccessible"
                                : "Accessible";

                            var yes = document.getElementById("yesButton");

                            yes.onclick = function () {
                                if (btnCr.checked == true) {
                                    response.accessible = true;
                                }
                                if (btnCr.checked == false) {
                                    response.accessible = false;
                                }
                                response.assetImages = []
                                Asset.update(response);
                            };

                            var noBtn = document.getElementById("noButton");

                            noBtn.onclick = function () {
                                if (btnCr.checked == true) {
                                    document.getElementById(
                                        btnCr.id
                                    ).checked = false;
                                } else {
                                    document.getElementById(
                                        btnCr.id
                                    ).checked = true;
                                }
                            };

                            var closeBtn = document.getElementById(
                                "closeButton"
                            );

                            closeBtn.onclick = function () {
                                if (btnCr.checked == true) {
                                    document.getElementById(
                                        btnCr.id
                                    ).checked = false;
                                } else {
                                    document.getElementById(
                                        btnCr.id
                                    ).checked = true;
                                }
                            };

                            var closeModal = document.getElementById(
                                "myModalMap"
                            );

                            closeModal.onclick = function (e) {
                                if (e.target == this) {
                                    if (btnCr.checked == true) {
                                        document.getElementById(
                                            btnCr.id
                                        ).checked = false;
                                    } else {
                                        document.getElementById(
                                            btnCr.id
                                        ).checked = true;
                                    }
                                }
                            };

                            var brBreak = userContent.appendChild(
                                document.createElement("br")
                            );
                            var brBreak1 = userContent.appendChild(
                                document.createElement("br")
                            );

                            if(response.assetTypeName == "Chamber"){
                                btnTextBuried = userContent.appendChild(
                                    document.createElement("b")
                                );
                                btnTextBuried.textContent = "Buried Chamber";
                                btnTextBuried.style.marginRight = "4px";
    
                                btnBuried = userContent.appendChild(
                                    document.createElement("input")
                                );
                                btnBuried.style.marginRight = "18px";
                                btnBuried.type = "checkbox";
                                btnBuried.checked = response.burried;
                                btnBuried.id = response.id + '0';
    
                                var brBreak = userContent.appendChild(
                                    document.createElement("br")
                                );
                                var brBreak1 = userContent.appendChild(
                                    document.createElement("br")
                                );

                                var attr = document.createAttribute("data-toggle");
                            attr.value = "modal";
                            var attr1 = document.createAttribute("data-target");
                            attr1.value = "#myModalBuried";

                            btnBuried.setAttributeNode(attr);
                            btnBuried.setAttributeNode(attr1);

                            var yesBurried = document.getElementById("yesButtonBurried");

                            yesBurried.onclick = function () {
                                if (btnBuried.checked == true) {
                                    response.burried = true;
                                }
                                if (btnBuried.checked == false) {
                                    response.burried = false;
                                }
                                response.assetImages = []
                                Asset.update(response,onSaveSuccessBuried);
                            };

                            var noBtnBurried = document.getElementById("noButtonBurried");

                            noBtnBurried.onclick = function () {
                                if (btnBuried.checked == true) {
                                    document.getElementById(
                                        btnBuried.id
                                    ).checked = false;
                                } else {
                                    document.getElementById(
                                        btnBuried.id
                                    ).checked = true;
                                }
                            };

                            var closeBtnBurried = document.getElementById(
                                "closeButtonBurried"
                            );

                            closeBtnBurried.onclick = function () {
                                if (btnBuried.checked == true) {
                                    document.getElementById(
                                        btnBuried.id
                                    ).checked = false;
                                } else {
                                    document.getElementById(
                                        btnBuried.id
                                    ).checked = true;
                                }
                            };

                            /* var closeModalBurried = document.getElementById(
                                "myModalBuried"
                            );

                            closeModalBurried.onclick = function (e) {
                                if (e.target == this) {
                                    if (btnBuried.checked == true) {
                                        document.getElementById(
                                            btnBuried.id
                                        ).checked = false;
                                    } else {
                                        document.getElementById(
                                            btnBuried.id
                                        ).checked = true;
                                    }
                                }
                            }; */
                        }
                            
                            

                            btnCreateCase = userContent.appendChild(
                                document.createElement("input")
                            );

                            btnCreateCase.type = "button";
                            btnCreateCase.value = "Create Case";
                            btnCreateCase.className = "button";

                            btnEditAsset = userContent.appendChild(
                                document.createElement("input")
                            );

                            btnEditAsset.type = "button";
                            btnEditAsset.value = "Edit Asset";
                            btnEditAsset.className = "button";


                            //Splicing Map
                            getJkDetails.get(response.id,function(jkResponse){
                                vm.jkNameList = jkResponse

                                if(vm.jkNameList.length){
                                    viewSplicingMap = userContent.appendChild(
                                        document.createElement("b")
                                    );
        
                                    viewSplicingMap = userContent.appendChild(
                                        document.createElement("input")
                                    );
        
                                    viewSplicingMap.type = "button";
                                    viewSplicingMap.value = "Splicing Map";
                                    viewSplicingMap.className = "button";
                                    google.maps.event.addDomListener(
                                        viewSplicingMap,
                                        "click",
                                        function () {
                                                var url = $state.href('asset.splicing',{id: response.id,data: vm.jkNameList[0].name})
                                                window.open(url, '_blank');
                                        }
                                    );
                                }
                        })

                            brBreak = userContent.appendChild(
                                document.createElement("br")
                            );
                            brBreak1 = userContent.appendChild(
                                document.createElement("br")
                            );

                            btnShowAttributes = userContent.appendChild(
                                document.createElement("input")
                            );

                            btnShowAttributes.type = "button";
                            btnShowAttributes.value = "Show Attributes";
                            btnShowAttributes.className = "button";

                            btnThreePointRef = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnThreePointRef.type = "button";
                            btnThreePointRef.value = "3 Point Reference";
                            btnThreePointRef.className = "button";

                            vm.nameImages = [];
                            vm.assetIdImg = response.id;
                            response.assetImages.forEach(function (item) {
                                vm.nameImages.push(item);
                            });

                            btnShowAttachments = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnShowAttachments.type = "button";
                            btnShowAttachments.value = "Attachments";
                            btnShowAttachments.className = "button";

                            google.maps.event.addDomListener(
                                btnShowAttachments,
                                "click",
                                function () {
                                    vm.assetAttachments.assetImages = [];
                                    $("#modalAttachment").show();
                                }
                            );

                            google.maps.event.addDomListener(
                                btnCreateCase,
                                "click",
                                function () {
                                    $rootScope.createCaseFromAssetMap = [
                                        response,
                                    ];
                                    $state.go("tr-case.new", {
                                        lat:
                                            fixed.assetCoordinates[
                                                fixed.assetCoordinates.length -
                                                    1
                                            ].latitude,
                                        lng:
                                            fixed.assetCoordinates[
                                                fixed.assetCoordinates.length -
                                                    1
                                            ].longitude,
                                        desc: "Case for " + fixed.name,
                                    });
                                }
                            );

                            google.maps.event.addDomListener(
                                btnEditAsset,
                                "click",
                                function () {
                                    // $state.go("asset.edit", {
                                    //     id: fixed.id,
                                    // });

                                    document.getElementById(
                                        "assetEditName"
                                    ).textContent =
                                        "Asset - " + vm.assetToEdit.name;
                                    editAssetOnMapView(fixed);
                                }
                            );

                            google.maps.event.addDomListener(
                                btnShowAttributes,
                                "click",
                                function () {
                                    showAssetAttributesModal(response);
                                }
                            );

                            google.maps.event.addDomListener(
                                btnThreePointRef,
                                "click",
                                function () {
                                    showThreePontReferenceModal(response);
                                }
                            );

                            vm.closeInfoWindow = new google.maps.InfoWindow({
                                content: userContent,
                            });
                            if (prevInfoWindow != null) prevInfoWindow.close();
                            vm.closeInfoWindow.open(locations.map, startMarker);
                            setTimeout(function () {
                                vm.closeInfoWindow.close();
                            }, 10000);

                            activeInfoWindow = vm.closeInfoWindow;
                            prevInfoWindow = vm.closeInfoWindow;
                        });
                    }
                );
                openedInfoWindow = infoWindow2;
            });
        }

        function drawFixedAssetCity(fixed, hasParent, level) {
            $.each(fixed.assetCoordinates, function (index, value1) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }

                var infoWindow = new google.maps.InfoWindow();

                var iconUrl = "";
                var isFixedAsset = false;
                iconUrl = fixed.assetTypeImagePath;
                isFixedAsset = true;

                var iconSize = 8;
                if (vm.zoomLevelCity == 10) {
                    iconSize = 8;
                } else if (vm.zoomLevelCity == 11) {
                    iconSize = 16;
                }

                if (isFixedAsset) {
                    iconSize = 24;
                }

                var startMarker = new google.maps.Marker({
                    map: locationsCity.mapCity,
                    position: new google.maps.LatLng(
                        value1.latitude,
                        value1.longitude
                    ),
                    title: fixed.name,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: iconUrl,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                    },
                });

                // if (vm.zoomLevel == 10) {
                //     startMarker.setLabel('');
                // } else if (vm.zoomLevel == 11) {
                //     var label = { color: '#000000', fontWeight: 'bold', fontSize: '12px', text: fixed.name };
                //     startMarker.setLabel(label);
                // }

                // if (fixed.status != null &&
                //     fixed.status === false) {
                //     startMarker.setLabel({
                //         color: 'red',
                //         fontSize: '30px',
                //         fontWeight: '20px',
                //         text: '\u2297'
                //     });
                // }
                // Add marker to the array.

                // markers.push(startMarker);

                switch (level) {
                    case "level1":
                        level1AssetsMarkersCity.push(startMarker);
                        break;
                    case "level2":
                        level2AssetsMarkersCity.push(startMarker);
                        break;
                    case "level3":
                        level3AssetsMarkersCity.push(startMarker);
                        break;
                    case "level4":
                        level4AssetsMarkersCity.push(startMarker);
                        break;
                    case "level5":
                        level5AssetsMarkersCity.push(startMarker);
                        break;
                    default:
                        markersCity.push(startMarker);
                        break;
                }
                var infoWindow2 = new google.maps.InfoWindow();
                var infoStr = "";
                var otdr = "";

                var userContent = document.createElement("div"),
                    button;

                userContent.innerHTML =
                    '<div class="infoWindowhead">' +
                    startMarker.title +
                    '</div><div class="infoWindowContent">' +
                    "<b>Lat</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .latitude +
                    "<br><b>Long</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .longitude +
                    "<br>" +
                    infoStr +
                    "</div>";

                google.maps.event.addListener(
                    startMarker,
                    "dblclick",
                    function () {
                        infoWindow2.close();
                    }
                );

                var activeInfoWindow;
                var infoWindow2 = new google.maps.InfoWindow({
                    content: userContent,
                });
                // add listener on InfoWindow for click
                // event
                google.maps.event.addListener(
                    startMarker,
                    "click",
                    function () {
                        if (activeInfoCity != null) {
                            activeInfoCity.close();
                        }
                        AssetByID.get(fixed.id, function (response) {
                            vm.assetToEdit = {};
                            vm.assetToEdit = response;
                            var infoWindow2 = new google.maps.InfoWindow();
                            var infoStr = "";
                            var otdr = "";
                            var assetTypeAttributeValuesList =
                                response.assetTypeAttributeValues;
                            assetTypeAttributeValuesList.sort(function (a, b) {
                                var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                    nameB = a.assetTypeAttribute.name.toLowerCase();
                                if (nameA < nameB)
                                    // sort string ascending
                                    return -1;
                                if (nameA > nameB) return 1;
                                return 0; // default return value (no sorting)
                            });
                            for (
                                var i = 0;
                                i < assetTypeAttributeValuesList.length;
                                i++
                            ) {
                                var home = assetTypeAttributeValuesList[i];
                                infoStr +=
                                    "<br><b>" +
                                    home.assetTypeAttribute.name +
                                    "</b> : " +
                                    home.attributeValue;
                                otdr = home;
                            }

                            var userContent = document.createElement("div"),
                                btnShowAttributes,
                                btnCr,
                                btnText,
                                btnEditAsset,
                                btnCreateCase,
                                btnThreePointRef;

                            userContent.innerHTML =
                                '<div class="infoWindowhead">' +
                                "<b>Name</b> : " +
                                startMarker.title +
                                '</div><div class="infoWindowContent">' +
                                "<b>Asset Type</b> : " +
                                fixed.assetTypeName +
                                "<br><b>Latitude</b> : " +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].latitude +
                                "<br><b>Longitude</b> : " +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].longitude +
                                "</div><br>";

                            vm.assetTitle = startMarker.title;
                            btnText = userContent.appendChild(
                                document.createElement("b")
                            );

                            btnText.textContent = "Accessible";
                            btnText.style.marginRight = "4px";

                            btnCr = userContent.appendChild(
                                document.createElement("input")
                            );
                            var attr = document.createAttribute("data-toggle");
                            attr.value = "modal";
                            var attr1 = document.createAttribute("data-target");
                            attr1.value = "#myModalMap";

                            btnCr.setAttributeNode(attr);
                            btnCr.setAttributeNode(attr1);

                            btnCr.style.marginRight = "18px";
                            btnCr.type = "checkbox";
                            btnCr.id = response.id;

                            btnCr.checked = response.accessible;

                            vm.changeAcessible = response.accessible
                                ? "Inaccessible"
                                : "Accessible";

                            var yes = document.getElementById("yesButton");

                            yes.onclick = function () {
                                if (btnCr.checked == true) {
                                    response.accessible = true;
                                }
                                if (btnCr.checked == false) {
                                    response.accessible = false;
                                }
                                response.assetImages = []
                                Asset.update(response);
                            };

                            var noBtn = document.getElementById("noButton");

                            noBtn.onclick = function () {
                                if (btnCr.checked == true) {
                                    document.getElementById(
                                        btnCr.id
                                    ).checked = false;
                                } else {
                                    document.getElementById(
                                        btnCr.id
                                    ).checked = true;
                                }
                            };

                            var closeBtn = document.getElementById(
                                "closeButton"
                            );

                            closeBtn.onclick = function () {
                                if (btnCr.checked == true) {
                                    document.getElementById(
                                        btnCr.id
                                    ).checked = false;
                                } else {
                                    document.getElementById(
                                        btnCr.id
                                    ).checked = true;
                                }
                            };

                            var closeModal = document.getElementById(
                                "myModalMap"
                            );

                            closeModal.onclick = function (e) {
                                if (e.target == this) {
                                    if (btnCr.checked == true) {
                                        document.getElementById(
                                            btnCr.id
                                        ).checked = false;
                                    } else {
                                        document.getElementById(
                                            btnCr.id
                                        ).checked = true;
                                    }
                                }
                            };

                            var brBreak = userContent.appendChild(
                                document.createElement("br")
                            );
                            var brBreak1 = userContent.appendChild(
                                document.createElement("br")
                            );

                            btnCreateCase = userContent.appendChild(
                                document.createElement("input")
                            );

                            btnCreateCase.type = "button";
                            btnCreateCase.value = "Create Case";
                            btnCreateCase.className = "button";

                            btnEditAsset = userContent.appendChild(
                                document.createElement("input")
                            );

                            btnEditAsset.type = "button";
                            btnEditAsset.value = "Edit Asset";
                            btnEditAsset.className = "button";

                            btnShowAttributes = userContent.appendChild(
                                document.createElement("input")
                            );

                            btnShowAttributes.type = "button";
                            btnShowAttributes.value = "Show Attributes";
                            btnShowAttributes.className = "button";

                            btnThreePointRef = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnThreePointRef.type = "button";
                            btnThreePointRef.value = "3 Point Reference";
                            btnThreePointRef.className = "button";

                            google.maps.event.addDomListener(
                                btnCreateCase,
                                "click",
                                function () {
                                    $state.go("tr-case.new", {
                                        lat:
                                            fixed.assetCoordinates[
                                                fixed.assetCoordinates.length -
                                                    1
                                            ].latitude,
                                        lng:
                                            fixed.assetCoordinates[
                                                fixed.assetCoordinates.length -
                                                    1
                                            ].longitude,
                                        desc: "Case for " + fixed.name,
                                    });
                                }
                            );

                            google.maps.event.addDomListener(
                                btnEditAsset,
                                "click",
                                function () {
                                    // $state.go("asset.edit", {
                                    //     id: fixed.id,
                                    // });

                                    document.getElementById(
                                        "assetEditName"
                                    ).textContent =
                                        "Asset - " + vm.assetToEdit.name;
                                    editAssetOnMapView(fixed);
                                }
                            );

                            google.maps.event.addDomListener(
                                btnShowAttributes,
                                "click",
                                function () {
                                    showAssetAttributesModal(response);
                                }
                            );

                            google.maps.event.addDomListener(
                                btnThreePointRef,
                                "click",
                                function () {
                                    showThreePontReferenceModal(response);
                                }
                            );

                            var infoWindow2 = new google.maps.InfoWindow({
                                content: userContent,
                            });
                            if (prevInfoWindow != null) prevInfoWindow.close();
                            infoWindow2.open(
                                locationsCity.mapCity,
                                startMarker
                            );
                            setTimeout(function () {
                                infoWindow2.close();
                            }, 10000);

                            activeInfoWindow = infoWindow2;
                            prevInfoWindow = infoWindow2;
                        });
                    }
                );
                openedInfoWindow = infoWindow2;
            });
        }

        function drawPolyline(spread, map, level) {
            var spreadAssetCoordinates = [];
            var spreadColor = 0;
            var spreadWidth = 0;
            var spreadOpacity = 0;

            vm.assetTypes.forEach(function (assetType) {
                if (assetType.name == spread.assetTypeName) {
                    spreadColor = assetType.colorcode;
                    spreadWidth = assetType.lineWidth * 0.05;
                    spreadOpacity = assetType.transparency * 0.01;
                }
            });

            $.each(spread.assetCoordinates, function (index, value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude,
                });
            });
            if (spreadAssetCoordinates.length > 1) {
                var midLatLng = {
                    lat:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].longitude,
                };

                var polylineColor = "";
                var distanceFromPOP = "";
                var fiberCoreVal = "";
                var infowindow = null;
                if (spread.assetTypeLayout == "SPREAD") {
                    polylineColor = spreadColor;
                }
                var polylineWeight = spreadWidth;
                var strokeOpacityVal = spreadOpacity;

                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: strokeOpacityVal,
                    strokeWeight: polylineWeight,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                google.maps.event.addListener(
                    polylinepath,
                    "click",
                    function (event) {
                        if (existingHighlight != null) {
                            existingHighlight.setMap(null);
                            existingHighlight = null;
                        }

                        var polylinepathHighlight = new google.maps.Polyline({
                            path: spreadAssetCoordinates,
                            strokeColor: "#FFFF00",
                            strokeOpacity: 0.6,
                            strokeWeight: 19,
                        });
                        
                        
                        polylinepathHighlight.setMap(locations.map);
                        existingHighlight = polylinepathHighlight;
    
                        var polylineTimer = setTimeout(function () {
                            if (polylinepathHighlight != null) {
                                polylinepathHighlight.setMap(null);
                                polylinepathHighlight = null;
                            }
                        }, 10000);

                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        closeLastOpenedInfoWindow();
                        var disVal = 0;
                        var cLocations = [];

                        if (
                            spread.description == null ||
                            spread.description == undefined
                        ) {
                            spread.description = "";
                        }

                        if (spread.assetTypeLayout == "SPREAD" || spread.assetType.layout == "SPREAD") {
                            AssetByID.get(spread.id, function (assetVal) {
                                spread = assetVal;
                                vm.assetToEdit = {};
                                vm.assetToEdit = assetVal;
                                var infoStr = "";
                                var assetTypeAttributeValuesList =
                                    spread.assetTypeAttributeValues;
                                assetTypeAttributeValuesList.sort(function (
                                    a,
                                    b
                                ) {
                                    var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                        nameB = b.assetTypeAttribute.name.toLowerCase();
                                    if (nameA < nameB)
                                        // sort string ascending
                                        return -1;
                                    if (nameA > nameB) return 1;
                                    return 0; // default return value (no sorting)
                                });
                                for (
                                    var i = 0;
                                    i < assetTypeAttributeValuesList.length;
                                    i++
                                ) {
                                    var home = assetTypeAttributeValuesList[i];

                                    var attrVal = "";
                                    if (home.attributeValue != null) {
                                        attrVal = home.attributeValue;
                                    }
                                    infoStr +=
                                        "<br><b>" +
                                        home.assetTypeAttribute.name +
                                        "</b> : " +
                                        attrVal;
                                }
                                var spreadCoordinates = spread.assetCoordinates;
                                if (spreadCoordinates.length > 0) {
                                    for (var index in spreadCoordinates) {
                                        if (index > 0) {
                                            disVal += distanceCal(
                                                spreadCoordinates[index]
                                                    .latitude,
                                                spreadCoordinates[index]
                                                    .longitude,
                                                spreadCoordinates[index - 1]
                                                    .latitude,
                                                spreadCoordinates[index - 1]
                                                    .longitude
                                            );
                                        }
                                    }
                                }
                                var assetTypeAttrPopupNames = [];
                                assetVal.assetType.assetTypeAttributes.forEach(
                                    function (assetTypeAttrVal) {
                                        if (
                                            assetTypeAttrVal.assetTypePopupDisplay ==
                                            true
                                        ) {
                                            assetTypeAttrPopupNames.push(
                                                assetTypeAttrVal.name
                                            );
                                        }
                                    }
                                );
                                var getAttribute =
                                    assetVal.assetTypeAttributeValues;
                                var userContent = document.createElement("div"),
                                    btnShowAttributes,
                                    btnEditAssets;
                           var url = $state.href('asset-detail', { id: spread.id });
                                userContent.innerHTML =
                                    '<div class="infoWindowhead">' +"<b>Id</b> : <a target='_blank' href='" + url + "'>" + spread.id+
                                    "</a><br><b>Name : </b> " +
                                    spread.name +
                                    "<br><b>Asset Type : </b> " +
                                    spread.assetType.name +
                                    "</br>" +
                                    "<b>Distance : </b> " +
                                    parseFloat(disVal).toFixed(2) +
                                    " Km";

                                for (i = 0; i < getAttribute.length; i++) {
                                    if (
                                        assetTypeAttrPopupNames.includes(
                                            getAttribute[i].assetTypeAttribute
                                                .name
                                        )
                                    ) {
                                        userContent.innerHTML +=
                                            "<b>" +
                                            getAttribute[i].assetTypeAttribute
                                                .name +
                                            "</b>" +
                                            " : " +
                                            getAttribute[i].attributeValue +
                                            "</br>";
                                    }
                                }

                                if (accessEditAsset) {
                                    btnEditAssets = userContent.appendChild(
                                        document.createElement("input")
                                    );

                                    btnEditAssets.type = "button";
                                    btnEditAssets.value = "Edit Asset";
                                    btnEditAssets.className = "button";

                                    google.maps.event.addDomListener(
                                        btnEditAssets,
                                        "click",
                                        function () {
                                            $(":file").filestyle("clear");
                                            $("#assetEditModal").show();
                                        }
                                    );
                                }

                                btnShowAttributes = userContent.appendChild(
                                    document.createElement("input")
                                );

                                btnShowAttributes.type = "button";
                                btnShowAttributes.value = "Show Attributes";
                                btnShowAttributes.className = "button";

                                google.maps.event.addDomListener(
                                    btnShowAttributes,
                                    "click",
                                    function () {
                                        showAssetAttributesModal(assetVal);
                                    }
                                );

                                infowindow = new google.maps.InfoWindow({
                                    content: userContent,
                                });

                                if (infowindow != null) {
                                    infowindow.setPosition(event.latLng);
                                    infowindow.open(locations.map);
                                    openedInfoWindow = infowindow;
                                }
                            });

                            
                        }
                        
                    }
                );

                var polylinepathDuct = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                polylinepath.setMap(locations.map);

                // polylines.push(polylinepath);
                switch (level) {
                    case "level1":
                        level1AssetsPolyline.push(polylinepath);
                        break;
                    case "level2":
                        level2AssetsPolyline.push(polylinepath);
                        break;
                    case "level3":
                        level3AssetsPolyline.push(polylinepath);
                        break;
                    case "level4":
                        level4AssetsPolyline.push(polylinepath);
                        break;
                    case "level5":
                        level5AssetsPolyline.push(polylinepath);
                        break;
                    default:
                        polylines.push(polylinepath);
                        break;
                }
            }
        }

        function drawPolylineCity(spread, map, level) {
            var spreadAssetCoordinates = [];
            var spreadColor = 0;
            var spreadWidth = 0;
            var spreadOpacity = 0;

            vm.assetTypes.forEach(function (assetType) {
                if (assetType.name == spread.assetTypeName) {
                    spreadColor = assetType.colorcode;
                    spreadWidth = assetType.lineWidth * 0.05;
                    spreadOpacity = assetType.transparency * 0.01;
                }
            });

            $.each(spread.assetCoordinates, function (index, value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude,
                });
            });
            if (spreadAssetCoordinates.length > 1) {
                var midLatLng = {
                    lat:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].longitude,
                };

                var polylineColor = "";
                var distanceFromPOP = "";
                var fiberCoreVal = "";
                var infowindow = null;
                if (spread.assetTypeLayout == "SPREAD") {
                    polylineColor = spreadColor;
                }
                var polylineWeight = spreadWidth;
                var strokeOpacityVal = spreadOpacity;

                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: strokeOpacityVal,
                    strokeWeight: polylineWeight,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                google.maps.event.addListener(
                    polylinepath,
                    "click",
                    function (event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var disVal = 0;
                        var cLocations = [];

                        if (
                            spread.description == null ||
                            spread.description == undefined
                        ) {
                            spread.description = "";
                        }

                        if (spread.assetTypeLayout == "SPREAD") {
                            AssetByID.get(spread.id, function (assetVal) {
                                spread = assetVal;
                                var infoStr = "";
                                var assetTypeAttributeValuesList =
                                    spread.assetTypeAttributeValues;
                                assetTypeAttributeValuesList.sort(function (
                                    a,
                                    b
                                ) {
                                    var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                        nameB = b.assetTypeAttribute.name.toLowerCase();
                                    if (nameA < nameB)
                                        // sort string ascending
                                        return -1;
                                    if (nameA > nameB) return 1;
                                    return 0; // default return value (no sorting)
                                });
                                for (
                                    var i = 0;
                                    i < assetTypeAttributeValuesList.length;
                                    i++
                                ) {
                                    var home = assetTypeAttributeValuesList[i];

                                    var attrVal = "";
                                    if (home.attributeValue != null) {
                                        attrVal = home.attributeValue;
                                    }
                                    infoStr +=
                                        "<br><b>" +
                                        home.assetTypeAttribute.name +
                                        "</b> : " +
                                        attrVal;
                                }
                                var spreadCoordinates = spread.assetCoordinates;
                                if (spreadCoordinates.length > 0) {
                                    for (var index in spreadCoordinates) {
                                        if (index > 0) {
                                            disVal += distanceCal(
                                                spreadCoordinates[index]
                                                    .latitude,
                                                spreadCoordinates[index]
                                                    .longitude,
                                                spreadCoordinates[index - 1]
                                                    .latitude,
                                                spreadCoordinates[index - 1]
                                                    .longitude
                                            );
                                        }
                                    }
                                }

                                var userContent = document.createElement("div");

                                userContent.innerHTML =
                                    '<div class="infoWindowhead">' +
                                    "<b>Name : </b> " +
                                    spread.name +
                                    "</br>" +
                                    "<b>Distance : </b> " +
                                    parseFloat(disVal).toFixed(2) +
                                    " Km</br>";

                                infowindow = new google.maps.InfoWindow({
                                    content: userContent,
                                });
                            });
                        }
                        if (infowindow != null) {
                            infowindow.setPosition(event.latLng);
                            infowindow.open(locationsCity.mapCity);
                            openedInfoWindow = infowindow;
                        }
                    }
                );

                var polylinepathDuct = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                polylinepath.setMap(locationsCity.mapCity);

                // polylines.push(polylinepath);
                switch (level) {
                    case "level1":
                        level1AssetsPolylineCity.push(polylinepath);
                        break;
                    case "level2":
                        level2AssetsPolylineCity.push(polylinepath);
                        break;
                    case "level3":
                        level3AssetsPolylineCity.push(polylinepath);
                        break;
                    case "level4":
                        level4AssetsPolylineCity.push(polylinepath);
                        break;
                    case "level5":
                        level5AssetsPolylineCity.push(polylinepath);
                        break;
                    default:
                        polylinesCity.push(polylinepath);
                        break;
                }
            }
        }

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist =
                Math.sin(degrees_to_radians(lat1)) *
                    Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) *
                    Math.cos(degrees_to_radians(lat2)) *
                    Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return dist;
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

        function closeModal(id) {

            if(id == 'modalAttachment'){
                $(":file").filestyle("clear");
                $scope.allimages = false;
            }
            
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

        $scope.newTabDisplay = function (id, name) {
            var request = new XMLHttpRequest(),
                apiImage =
                    name == "reference"
                        ? "api/trakeyeassetrefimages/"
                        : "api/trakeyeassetimages/";
            request.open("GET", apiImage + vm.assetIdImg + "/" + id, true);
            request.setRequestHeader(
                "Authorization",
                "Bearer " + $localStorage.authenticationToken
            );
            request.responseType = "blob";
            request.onload = response;

            function response(e) {
                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(this.response);
                    window.open(imageUrl, "_blank");
                }
            }
            request.send();
        };

        vm.assetMarkerUpload = function (id) {
            vm.assetMarkerUploadId = id;
            $("#markerUploadModal").show();
        };

        vm.closeAssetMarkerModal = function () {
            $scope.uploadfile = null;
            $(":file").filestyle("clear");
            $("#markerUploadModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#markerUploadModal").hide();
        };

        vm.uploadAssetMarker = function () {
            var getFile, lastDotPosition, format;
            getFile = document.getElementById("uploadfile").files[0];
            lastDotPosition = getFile.name.lastIndexOf(".");
            format = getFile.name.substring(lastDotPosition + 1);

            var fileReader = new FileReader();
            fileReader.onload = function (fileLoadedEvent) {
                EntityImport.get(
                    {
                        entityName: "ASSETSMARKER",
                        parentEntityId: vm.assetMarkerUploadId.id,
                        parentEntityName: "ASSETS",
                        entityLayout: "SPREAD",
                        fileFormat: format,
                        messageType: "ADD",
                        messageStatus: "OPEN",
                        fileImage: window.btoa(fileLoadedEvent.target.result), // imported file
                    },
                    function (response) {
                        vm.success = true;
                        $timeout(function () {
                            vm.closeAssetMarkerModal();
                        }, 2300);
                        /* vm.showMessageError = false;
                        vm.showMessage = true;
                        vm.trackingId = response.id;
                        $timeout(function () {
                            $state.go(vm.currentState)
                        }, 4000); */
                    }
                );
            };
            fileReader.readAsBinaryString(getFile);
        };

        function assetMarkerTable(asset) {
            vm.assetsForListView.forEach(function (item) {
                item.isExpanded = false;
            });
            if (vm.toggle == asset.id) {
                vm.toggle = undefined;
            } else {
                asset.isExpanded = true;
                vm.toggle = asset.id;
            }
            if (asset.isExpanded) {
                ChildAssetMarkers.query(
                    {
                        assetId: asset.id,
                    },
                    onSuccess
                );

                function onSuccess(data) {
                    var assetMarkersRes = [];
                    data.forEach(function (values, index) {
                        values.no = index + 1;
                        assetMarkersRes.push(values);
                    });
                    vm.assetMarkers = assetMarkersRes;
                }
            }
        }

        vm.addCableDetails = function (assetId) {
            vm.cablesList = []
            vm.enteredCableName = assetId.id + "_Cable01" 
            getCableName.get(assetId.id,function(cableResponse){
                if(cableResponse.length > 0){
                    vm.cablesList = []
                    vm.enteredCableName = assetId.id + "_Cable0" + (cableResponse.length + 1)
                    cableResponse.forEach(function (val){
                        vm.cablesList.push({"name":'[Name - ' + val.name +' ] [' + val.type + ']' })
                    })
                }
            })
            
            vm.cableSuccess = false;
            vm.assetLength = assetId.length;
            vm.spreadAssetId = assetId.id;
            $("#addCableDetails").show();
            vm.spreadCoordinates = [];
            for (var i = 0; i < assetId.coordinates.length; i++) {
                vm.spreadCoordinates.push({
                    latitude: assetId.coordinates[i].latitude,
                    longitude: assetId.coordinates[i].longitude,
                });
            }
            $scope.cableData = {
                length: (vm.assetLength * 1000).toFixed(2),
                tenantId: vm.tenantId,
                spreadAssetId: vm.spreadAssetId,
                assetCoordinates: vm.spreadCoordinates,
                listOfTubes: [], // Initialize listOfTubes as an empty array
            };
        };

        vm.cableType = ["96F", "48F", "24F", "12F"];
        vm.noOfTubes = [];
        for (var i = 1; i <= 8; i++) {
            vm.noOfTubes.push("0" + i);
        }
        vm.noOfCables = ["01","02", "03","04","05","06"];
        vm.jkCableType = ["96F", "48F", "24F", "12F"];
        vm.cable48Type = ["04","06"]
        /* //vm.coreListView = [ ]
        vm.tubeListView = [{
            "id":"789",
            "name":"JPN_HSR_Cable_20112_Tube_1"

} ]

        vm.expandCore = function(tube){
            vm.tubeListView.forEach(function (item) {
                item.isExpanded = false;
            });
            if (vm.toggle == tube.id) {
                vm.toggle = undefined;
                vm.tubeIsExpanded = false
            } else {
                tube.isExpanded = true;
                vm.toggle = tube.id;
            }

            if(tube.isExpanded){
                vm.tubeIsExpanded = true
                vm.coreListView = [ ]
                vm.coreListView.push({

                    "name":"JPN_HSR_Cable_20112_Tube_1_Core_1"
        
        })
            }
            
        }

        vm.addTubeDetails = function(){
            vm.tubeListView.length++;
            //vm.selectedCable = vm.trCase.cableDetails.length - 1;
        } */

        $scope.expandedIndex = -1;

        $scope.toggleCoreList = function (index) {
            if ($scope.expandedIndex === index) {
                $scope.expandedIndex = -1; // Collapse if already expanded
            } else {
                $scope.expandedIndex = index; // Expand the clicked row
            }
        };

        $scope.addTube = function (iteration, noCore) {
            for (var i = 1; i <= iteration; i++) {
                var newTube = {
                    name: vm.enteredCableName + "_" + "T" + i,
                    noOfCores: noCore,
                    colorCodeHex: vm.tubeColor[i-1].hexcode,
                    tenantId: vm.tenantId,
                    coreList: [],
                    order : i
                };
                $scope.cableData.listOfTubes.push(newTube);
            }
        };

        $scope.addCore = function (index, iteration) {
            for (var j = 0; j <= index; j++) {
                for (var i = 1; i <= iteration; i++) {
                    var newCore = {
                        name:
                            $scope.cableData.listOfTubes[j].name +
                            "_" +
                            "C" +
                            i,
                        colorCodeHex: vm.coreColor[i-1].hexcode,
                        tenantId: vm.tenantId,
                        order:i
                    };
                    $scope.cableData.listOfTubes[j].coreList.push(newCore);
                }
            }
        };

        vm.coreColor = [
            { name: "Blue", hexcode: "0000FF" },
            { name: "Orange", hexcode: "FFA500" },
            { name: "Green", hexcode: "008000" },
            { name: "Brown", hexcode: "A52A2A" },
            { name: "Gray", hexcode: "71797E" },
            { name: "White", hexcode: "FFFFFF" },
            { name: "Red", hexcode: "FF0000" },
            { name: "Black", hexcode: "000000" },
            { name: "Yellow", hexcode: "FFFF00" },
            { name: "Violet", hexcode: "EE82EE" },
            { name: "Rose", hexcode: "FF007F" },
            { name: "Aqua", hexcode: "00FFFF" },
        ];

        vm.tubeColor = [
            { name: "Blue", hexcode: "0000FF" },
            { name: "Orange", hexcode: "FFA500" },
            { name: "Green", hexcode: "008000" },
            { name: "Brown", hexcode: "A52A2A" },
            { name: "Gray", hexcode: "71797E" },
            { name: "White", hexcode: "FFFFFF" },
            { name: "Red", hexcode: "FF0000" },
            { name: "Black", hexcode: "000000" },
        ];

        vm.saveCableDetails = function () {
            $scope.cableData.noOfTubes = vm.selectedTube;
            $scope.cableData.name = vm.enteredCableName;
            $scope.cableData.type = vm.selectedCableType;
            postCoreCable.get($scope.cableData, function () {
                vm.cableSuccess = true;
                $timeout(function () {
                    closeGotoLatitute("addCableDetails");;
                }, 1300);
            },function(){
                closeGotoLatitute("addCableDetails");
            });
        };

        vm.addJoiningKitDetails = function (assetId) {
            vm.jkNameList = []
            vm.enteredJoiningKitName = assetId.id + "_JK01" 

            getJkDetails.get(assetId.id,function(jkResponse){
                vm.jkNameList = jkResponse
                vm.enteredJoiningKitName = assetId.id + "_JK0" + (vm.jkNameList.length + 1)
            }) 

            vm.jkCableName = []
            vm.jkSuccess = false
            getSpreadAssetId.get(assetId.id,function(response){
                if(response.length >= 1){
                    response.forEach(function(item){
                        getCableName.get(item.id,function(cableDetails){
                            if(cableDetails.length >=1){
                                cableDetails.forEach(function(cableName){
                                    vm.jkCableName.push({"name":cableName.name,"type":cableName.type})
                                })
                            }
                        })
                    })
                  }
            })

            vm.fixedAssetId = assetId.id;
            vm.fixedCoordinates = assetId;
            $("#addJoiningKitDetails").show();
            $scope.joiningKitData = {
                tenantId: vm.tenantId,
                fixedAssetId: vm.fixedAssetId,
                assetCoordinate: {
                    latitude: assetId.coordinates[0].latitude,
                    longitude: assetId.coordinates[0].longitude,
                },
                listOfCables: [], // Initialize listOfTubes as an empty array
            };
        };

        vm.addCable = function () {
            $scope.joiningKitData.listOfCables = []
            for(var i=1;i<=vm.selectedCable;i++){
                var newCable = {
                    name: "",
                    type: "",
                    tenantId: vm.tenantId,
                };
                $scope.joiningKitData.listOfCables.push(newCable);
            } 
        };

        vm.saveJoiningKitDetails = function () {
            $scope.joiningKitData.noOfCablesConnected = vm.selectedCable;
            $scope.joiningKitData.name = vm.enteredJoiningKitName;
            postCoreJoiningkit.get($scope.joiningKitData, function () {
                vm.jkSuccess = true;
                $timeout(function () {
                    closeGotoLatitute("addJoiningKitDetails");
                }, 1300),function(){
                    closeGotoLatitute("addJoiningKitDetails");
                };
            });
        };

        // Define a mapping of cable types to their corresponding properties
        var cableTypeMapping = {
            "12F": { tube: 1, tubeCount: 12, coreIndex: 0, coreCount: 12 },
            "24F": { tube: 2, tubeCount: 12, coreIndex: 1, coreCount: 12 },
            "48F": { tube: 0, tubeCount: 0, coreIndex: 0, coreCount: 0 },
            "96F": { tube: 8, tubeCount: 12, coreIndex: 7, coreCount: 12 },
        };

        // Assuming vm.selectedCableType holds the selected cable type
       

        vm.fiberType = function () {
            var selectedCableType = vm.selectedCableType;
            $scope.cableData.listOfTubes = [];
            
            if(vm.selected48Type == "04"){
                cableTypeMapping["48F"].tube = 4
                cableTypeMapping["48F"].tubeCount = 12
                cableTypeMapping["48F"].coreIndex = 3
                cableTypeMapping["48F"].coreCount = 12
            }else if(vm.selected48Type == "06"){
                cableTypeMapping["48F"].tube = 6
                cableTypeMapping["48F"].tubeCount = 8
                cableTypeMapping["48F"].coreIndex = 5
                cableTypeMapping["48F"].coreCount = 8
            }

            if (cableTypeMapping[selectedCableType]) {
            var cableProperties = cableTypeMapping[selectedCableType];

            $scope.addTube(cableProperties.tube, cableProperties.tubeCount);
            $scope.addCore(
                cableProperties.coreIndex,
                cableProperties.coreCount
            );
            vm.selectedTube = cableProperties.tube;
        }
        };

        vm.statuslabel = { "phases":{search          : "Search",
        nothingSelected : "Phase",
        selectAll       : "All",
        selectNone      : "None", 
        Done : "Done"},"status":{search          : "Search",
        nothingSelected : "Status",
        selectAll       : "All",
        selectNone      : "None", 
        Done : "Done"}
             
        }

        vm.savePhases = function(){
            phaseList= []
            if(vm.selectedPhases.length){
                vm.selectedPhases.forEach(function(val){
                    phaseList.push(val.configKey)  
                })
            }

            if(vm.selectedMapGeofence.length){
                saveFilter()
            }
        }

        vm.saveStatus = function(){
            statusList = []
            if(vm.selectedStatus.length){
                vm.selectedStatus.forEach(function(val){
                    statusList.push(val.configKey)  
                })
            }
            if(vm.selectedMapGeofence.length){
                saveFilter()
            }
        }

        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
        });

        Configs.get("WorkFlowStatus", function (response) {
            vm.workFlowStatus = response
        });
        
        vm.updateType = function(jk) {
            var selectedItem = vm.jkCableName.find(function(item) {
                return item.name === jk.name;
            });
    
            if (selectedItem) {
                jk.type = selectedItem.type; // Update the type in the model
            }
        };

        function onSaveSuccessBuried(result) {
            
            var assetImagePath = result.assetType.imagePath
                    var findAssetIndex;
                switch (result.assetType.zoomLevel) {
                    case 15:
                        for (var i = 0; i < level2AssetsMarkers.length; i++) {
                            if (result.id == level2AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level2AssetsMarkers[i].setMap(null);
    
                                if(result.burried){ 
                                    level2AssetsMarkers[i].icon.url =
                                    "content/images/buriedChamber.png";
                                }else {
                                    level2AssetsMarkers[i].icon.url =
                                    assetImagePath;
                                }
                                
                                level2AssetsMarkers[i].setMap(locations.map);
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 15) {
                                if(result.burried){ 
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    "content/images/buriedChamber.png";
                                }else{
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    assetImagePath;
                                }
                                break;
                            }
                        }
                        break;
    
                    case 16:
                        for (var i = 0; i < level3AssetsMarkers.length; i++) {
                            if (result.id == level3AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level3AssetsMarkers[i].setMap(null);
    
                                if(result.burried){ 
                                    level3AssetsMarkers[i].icon.url =
                                    "content/images/buriedChamber.png";
                                }else {
                                    level3AssetsMarkers[i].icon.url =
                                    assetImagePath;
                                }
                                
                                level3AssetsMarkers[i].setMap(locations.map); 
                                break;
                            }
                        }
    
    
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 16) {
                                if(result.burried){ 
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    "content/images/buriedChamber.png";
                                }else{
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    assetImagePath;
                                }
                                
                                break;
                            }
                        }
                        break;
    
                    case 17:
                        for (var i = 0; i < level4AssetsMarkers.length; i++) {
                            if (result.id == level4AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level4AssetsMarkers[i].setMap(null);
    
                                if(result.burried){ 
                                    level4AssetsMarkers[i].icon.url =
                                    "content/images/buriedChamber.png";
                                }else {
                                    level4AssetsMarkers[i].icon.url =
                                    assetImagePath;
                                }
                                
                                level4AssetsMarkers[i].setMap(locations.map);
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 17) {
                                if(result.burried){ 
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    "content/images/buriedChamber.png";
                                }else{
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    assetImagePath;
                                }
                                break;
                            }
                        }
                        break;
    
                    case 18:
                        for (var i = 0; i < level5AssetsMarkers.length; i++) {
                            if (result.id == level5AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level5AssetsMarkers[i].setMap(null);
    
                                if(result.burried){ 
                                    level5AssetsMarkers[i].icon.url =
                                    "content/images/buriedChamber.png";
                                }else {
                                    level5AssetsMarkers[i].icon.url =
                                    assetImagePath;
                                }
                                
                                level5AssetsMarkers[i].setMap(locations.map);
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 18) {
                                if(result.burried){ 
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    "content/images/buriedChamber.png";
                                }else{
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    assetImagePath;
                                }
                                break;
                            }
                        }
                        break;
    
                    case 19:
                        for (var i = 0; i < level6AssetsMarkers.length; i++) {
                            if (result.id == level6AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level6AssetsMarkers[i].setMap(null);
    
                                if(result.burried){ 
                                    level6AssetsMarkers[i].icon.url =
                                    "content/images/buriedChamber.png";
                                }else {
                                    level6AssetsMarkers[i].icon.url =
                                    assetImagePath;
                                }
                                
                                level6AssetsMarkers[i].setMap(locations.map);
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 19) {
                                if(result.burried){ 
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    "content/images/buriedChamber.png";
                                }else{
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    assetImagePath;
                                }
                                break;
                            }
                        }
                        break;
    
                    case 20:
                        for (var i = 0; i < level7AssetsMarkers.length; i++) {
                            if (result.id == level7AssetsMarkers[i].assetId) {
                                findAssetIndex = i;
                                level7AssetsMarkers[i].setMap(null);
    
                                if(result.burried){ 
                                    level7AssetsMarkers[i].icon.url =
                                    "content/images/buriedChamber.png";
                                }else {
                                    level7AssetsMarkers[i].icon.url =
                                    assetImagePath;
                                }
                                
                                level7AssetsMarkers[i].setMap(locations.map);
                                break;
                            }
                        }
                        for (
                            var index = 0;
                            index < vm.assetTypeValues.length;
                            index++
                        ) {
                            if (vm.assetTypeValues[index].zoomLevel == 20) {
                                if(result.burried){ 
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    "content/images/buriedChamber.png";
                                }else{
                                    vm.assetTypeValues[index].assets[
                                        findAssetIndex
                                    ].assetTypeImagePath =
                                    assetImagePath;
                                }
                                break;
                            }
                        }
                        break;
    
                    default:
                        console.log("not found");
                }
                
                
            }

            vm.onNavigate = function(asset, geofence){
                assetVisibility20 = false
                assetVisibility19 = false
                isLevel4AssetsVisible = false
                isLevel3AssetsVisible = false
                isLevel2AssetsVisible = false
                isLevelNodeVisible = false
                isLevel5AssetsVisible = false
    
                vm.isEnableNavigate = true
                vm.storeAsset = asset
                tempGeofenceId = geofence[0].id
                /* var getZoom = vm.assetTypes.find(function(item){
                    return item.name == asset.assetTypeName
                })
                vm.getZoomLevel = getZoom.zoomLevel */
                $scope.setTab(2)
            }

    }
})();
