(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("DashboardPlanningController", DashboardPlanningController);

        DashboardPlanningController.$inject = [
        "$scope",
        "$rootScope",
        "DEFAULT_LATITUDE",
        "DEFAULT_LONGITUDE",
        "WorkOrderStats",
        "WorkOrder",
        "WorkFlowListByWorkOrderID",
        "AssetsByWorkFlowID"
    ];

    function DashboardPlanningController(
        $scope,
        $rootScope,
        DEFAULT_LATITUDE,
        DEFAULT_LONGITUDE,
        WorkOrderStats,
        WorkOrder,
        WorkFlowListByWorkOrderID,
        AssetsByWorkFlowID
    ) {
        var vm = this;
        var locations = {};
        var openedInfoWindow = null;
        var markers = [];
        var userPaths = {};
        vm.selectedWorkOrderDetails = null;
        vm.mapHeight = $rootScope.mapHeight;

        var centerGeofence = new google.maps.LatLng(
            DEFAULT_LATITUDE,
            DEFAULT_LONGITUDE
        );
        locations.map = new google.maps.Map(
            document.getElementById("map_canvas"),
            myOption(5, centerGeofence)
        );

        WorkOrderStats.get("PLANNING",function (response) {
            var totalCount = response.length;
            var redCount = 0;
            var amberCount = 0;
            var greenCount = 0;
            response.forEach(function (markerVal) {
                if (markerVal.ragStatus == "RED") {
                    redCount++;
                } else if (markerVal.ragStatus == "AMBER") {
                    amberCount++;
                } else if (markerVal.ragStatus == "GREEN") {
                    greenCount++;
                }
                drawMarker(markerVal);
            });
            loadWorkOrderStats(
                totalCount,
                redCount,
                amberCount,
                greenCount
            );
        });

        function loadWorkOrderStats(
            totalCount,
            redCount,
            amberCount,
            greenCount
        ) {
            var svgtemp = d3.select("#workorder-stats-chart");
            svgtemp.selectAll("*").remove();
            var dataset = [
                {
                    label: "RED",
                    count: redCount,
                    percentage: ((redCount / totalCount) * 100).toFixed(0),
                },
                {
                    label: "AMBER",
                    count: amberCount,
                    percentage: ((amberCount / totalCount) * 100).toFixed(0),
                },
                {
                    label: "GREEN",
                    count: greenCount,
                    percentage: ((greenCount / totalCount) * 100).toFixed(
                        0
                    ),
                },
            ];

            var width = 240;
            var height = 300;

            try {
                width = document.getElementById("workorder-stats-chart")
                    .offsetWidth - 380;
                height = document.getElementById("workorder-stats-chart")
                    .offsetHeight;
            } catch (error) {
                // console.log(error);
            }

            // var radius = Math.min(width, height) / 2;
            // var donutWidth = 40;

            var radius = Math.min(width, height) / 1.8;
            var legendRectSize = 12;
            var legendSpacing = 3;

            var color = d3.scale
                .ordinal()
                .range(["#d2222d", "#ffbf00", "#238823"]);
                
            var svg = d3
                .select("#workorder-stats-chart")
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("shape-rendering", "geometricPrecision")
                .attr(
                    "transform",
                    "translate(" + width / 2 + "," + height / 2 + ")"
                );

            var arc = d3.svg
                .arc()
                .innerRadius(radius - 70)
                .outerRadius(radius - 30);

            var pie = d3.layout
                .pie()
                .value(function (d) {
                    return d.count;
                })
                .sort(null);

            dataset.forEach(function (d) {
                d.count = +d.count;
                d.enabled = true;
            });

            var path = svg
                .selectAll("path")
                .data(pie(dataset))
                .enter()
                .append("path")
                .attr("d", arc)
                .attr("fill", function (d, i) {
                    return color(
                        d.data.label + " - " + d.data.percentage + " %"
                    );
                })
                .each(function (d) {
                    this._current = d;
                });

            var tooltip2 = d3
                .select("body")
                .append("div")
                .attr("class", "toolTipA");

            path.on("mouseover", function (d) {
                tooltip2
                    .style("left", d3.event.pageX - 50 + "px")
                    .style("top", d3.event.pageY - 50 + "px")
                    .style("display", "inline-block")
                    .style("fill", "black")
                    .html(d.data.label + "<br>" + d.data.count);
                return tooltip2.style("visibility", "visible");
            })
                .on("mousemove", function () {
                    return tooltip2
                        .style("top", d3.event.pageY - 10 + "px")
                        .style("left", d3.event.pageX + 10 + "px");
                })
                .on("mouseout", function () {
                    return tooltip2.style("visibility", "hidden");
                });

            var legend = svg
                .selectAll(".legend")
                .data(color.domain())
                .enter()
                .append("g")
                .attr("class", "legend")
                .attr("transform", function (d, i) {
                    var height = legendRectSize + legendSpacing;
                    var offset = (height * color.domain().length) / 2;
                    var horz = -4.5 * legendRectSize;
                    var vert = i * height - offset;
                    return "translate(" + horz + "," + vert + ")";
                });

            legend
                .append("rect")
                .attr("width", legendRectSize)
                .attr("height", legendRectSize)
                .style("fill", color)
                .style("stroke", color)
                .on("click", function (label) {
                    var rect = d3.select(this);
                    var enabled = true;
                    var totalEnabled = d3.sum(
                        dataset.map(function (d) {
                            return d.enabled ? 1 : 0;
                        })
                    );

                    if (rect.attr("class") === "disabled") {
                        rect.attr("class", "");
                    } else {
                        if (totalEnabled < 2) return;
                        rect.attr("class", "disabled");
                        enabled = false;
                    }

                    pie.value(function (d) {
                        if (d.label === label) d.enabled = enabled;
                        return d.enabled ? d.count : 0;
                    });

                    path = path.data(pie(dataset));

                    path.transition()
                        .duration(750)
                        .attrTween("d", function (d) {
                            var interpolate = d3.interpolate(this._current, d);
                            this._current = interpolate(0);
                            return function (t) {
                                return arc(interpolate(t));
                            };
                        });
                });

            legend
                .append("text")
                .attr("x", legendRectSize + legendSpacing)
                .attr("y", legendRectSize - legendSpacing)
                .style("font-family", "sans-serif")
                .style("font-size", "12px")
                .text(function (d) {
                    return d;
                });
        }

        function drawMarker(value) {
            if (openedInfoWindow != null) {
                openedInfoWindow.close();
            }
            var iconUrl = "content/images/red-marker-100.png";

            if (value.ragStatus == "RED") {
                iconUrl = "content/images/red-marker-100.png";
            } else if (value.ragStatus == "AMBER") {
                iconUrl = "content/images/amber-marker-100.png";
            } else if (value.ragStatus == "GREEN") {
                iconUrl = "content/images/green-marker-100.png";
            }
            var infoWindow = new google.maps.InfoWindow();

            var startMarker = new google.maps.Marker({
                map: locations.map,
                position: new google.maps.LatLng(
                    value.latitude,
                    value.longitude
                ),
                title: value.description,
                icon: {
                    scaledSize: new google.maps.Size(36, 36),
                    origin: new google.maps.Point(0, 0),
                    url: iconUrl,
                    anchor: new google.maps.Point(24, 24),
                },
            });

            markers.push(startMarker);

            var infoWindow2 = new google.maps.InfoWindow();

            var userContent = document.createElement("div"),
                button;

            userContent.innerHTML =
                '<div class="infoWindowhead">' +
                startMarker.title +
                '</div><div class="infoWindowContent">' +
                "<b>Lat</b> :" +
                "<br>" +
                "</div>";

            google.maps.event.addListener(startMarker, "dblclick", function () {
                infoWindow2.close();
            });

            var infoWindow2 = new google.maps.InfoWindow({
                content: userContent,
            });
            // add listener on InfoWindow for click
            // event
            google.maps.event.addListener(startMarker, "click", function () {

                WorkFlowListByWorkOrderID.get(value.id, function (workorderDetails) {
                    vm.selectedWorkOrderDetails = null;
                    vm.new = 0;
                    vm.trenching = 0;
                    vm.ducting = 0;
                    vm.fiberifing = 0;
                    vm.lightning = 0;
                    vm.dit = 0;
                    vm.at = 0;
                    vm.workflowCount = 0;
                    vm.newAssets = [];
                    vm.trenchingAssets = [];
                    vm.ductingAssets = [];
                    vm.fiberpullingAssets = [];
                    vm.lightningAssets = [];
                    vm.routeSurveyAssets = [];
                    vm.chamberAssetsCount = 0;
                    vm.couplerAssetsCount = 0;
                    vm.siteAssetsCount = 0;
                    vm.fiberAssetsCount = 0;
                    var barData = {};
                    vm.selectedWorkOrderDetails = workorderDetails[0];
                    vm.selectedWorkOrderDetails.workflows.forEach(function(workflow, index){
                        vm.workflowCount++;
                        AssetsByWorkFlowID.get(workflow.id, function(assets){
                            var workFlowAssets = [];
                            assets.forEach(function(asset){
                                if(asset.assetTypeLayout == "SPREAD"){
                                    workFlowAssets.push(asset);
                                    if(asset.assetTypeName =="Link"){
                                        vm.fiberAssetsCount++;
                                    } else if (asset.assetTypeName == "KML Link"){
                                        vm.fiberAssetsCount++;
                                    }
                                } else {
                                    if(asset.assetTypeName =="Chamber_Latest" || asset.assetTypeName == "Chamber"){
                                        vm.chamberAssetsCount++;
                                    } else if (asset.assetTypeName == "Coupler"){
                                        vm.couplerAssetsCount++;
                                    } else if (asset.assetTypeName == "Site") {
                                        vm.siteAssetsCount++;
                                    }
                                }
                            });
                            switch(workflow.status){
                                case "NEW":
                                    vm.new++;
                                    vm.newAssets = workFlowAssets;
                                    drawPolyline(workFlowAssets, "#6495ED", userPaths.map);
                                    break;
                                case "HDDTRENCHINGDONE":
                                    vm.trenching++;
                                    vm.trenchingAssets = workFlowAssets;
                                    drawPolyline(workFlowAssets, "#9FE2BF", userPaths.map);
                                    break;
                                case "DUCTINGDONE":
                                    vm.ducting++;
                                    vm.ductingAssets = workFlowAssets;
                                    drawPolyline(workFlowAssets, "#FF7F50", userPaths.map);
                                    break;
                                case "FIBERPULLINGDONE":
                                    vm.fiberifing++;
                                    vm.fiberpullingAssets = workFlowAssets;
                                    drawPolyline(workFlowAssets, "#FFBF00", userPaths.map);
                                    break;
                                case "LIGHTING":
                                    vm.lightning++;
                                    vm.lightningAssets = workFlowAssets;
                                    drawPolyline(workFlowAssets, "#DFFF00", userPaths.map);
                                    break;
                                case "ROUTESURVEYDONE":
                                    vm.dit++;
                                    vm.routeSurveyAssets = workFlowAssets;
                                    drawPolyline(workFlowAssets, "#CCCCFF", userPaths.map);
                                    break;
                            }
                            vm.newPercentage = parseInt((vm.new/vm.workflowCount)*100);
                            vm.trenchingPercentage = parseInt((vm.trenching/vm.workflowCount)*100);
                            vm.ductingPercentage = parseInt((vm.ducting/vm.workflowCount)*100);
                            vm.fiberifingPercentage = parseInt((vm.fiberifing/vm.workflowCount)*100);
                            vm.lightningPercentage = parseInt((vm.lightning/vm.workflowCount)*100);
                            vm.ditPercentage = parseInt((vm.dit/vm.workflowCount)*100);
                            vm.atPercentage = parseInt((vm.at/vm.workflowCount)*100);
                            barData = {
                                "geofences": [
                                    {
                                        "userStatusCount": {
                                            "Available": vm.fiberAssetsCount*1.5,
                                        },
                                        "label": "Fiber",
                                        "consumed": vm.fiberAssetsCount
                                    },
                                    {
                                        "userStatusCount": {
                                            "Available": vm.siteAssetsCount*1.5,
                                        },
                                        "label": "Tower", "consumed": vm.siteAssetsCount
                                    },
                                    {
                                        "userStatusCount": {
                                            "Available": vm.couplerAssetsCount*1.2,
                                        },
                                        "label": "Coupler",
                                            "consumed": vm.couplerAssetsCount
                                    },
                                    {
                                        "userStatusCount": {
                                            "Available": vm.chamberAssetsCount*1.3,
                                        },
                                        "label": "Chamber",
                                            "consumed": vm.chamberAssetsCount
                                    }
                                ]
                            }
                            if(index==0){
                                showDashboardData(barData);
                            }
                        });
                        
                    });
                    
                    /* var ele = document.getElementById("workorderChartModal");
                    $(ele).modal("show"); */
                    var centerGeofence = new google.maps.LatLng(
                        DEFAULT_LATITUDE,
                        DEFAULT_LONGITUDE
                    );
                    userPaths.map = new google.maps.Map(
                        document.getElementById("workflow_map_canvas"),
                        myOption(10, centerGeofence)
                    );
                   
                });
                
            });
            openedInfoWindow = infoWindow2;
        }

        var centerGeofence = new google.maps.LatLng(
            DEFAULT_LATITUDE,
            DEFAULT_LONGITUDE
        );
        userPaths.map = new google.maps.Map(
            document.getElementById("workflow_map_canvas"),
            myOption(10, centerGeofence)
        );
        var customDiv = document.getElementById("workorder-stats-chart");

        // Add the custom div to the map
        locations.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(customDiv);

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    },
                ],
            });
        }

  
        

        function drawPolyline(spreadAssets, color, map){
            var cableCoordinates = [];
            var pos = {};
            for (var i = 0; i < spreadAssets.length; i++) {
                cableCoordinates = [];
                var coord = spreadAssets[i].assetCoordinates;
                pos = {
                    lat: coord[0].latitude,
                    lng: coord[0].longitude,
                };
                map.setZoom(11);
                map.setCenter(pos);
                coord.forEach(function (item) {
                    cableCoordinates.push({
                        lat: item.latitude,
                        lng: item.longitude,
                    });
                    
                });
                var cablePath = new google.maps.Polyline({
                    path: cableCoordinates,
                    strokeColor: color,
                    strokeOpacity: 4,
                    strokeWeight: 10,
                });
                cablePath.setMap(map);
            }
            
        }
        function showDashboardData(data){
           
            vm.users = data.users;
            vm.isGeofences = data.geofences.length == 0
            var svgtempstatus = d3.select("#status");
                svgtempstatus.selectAll("*").remove(); 
            var svgtemp = d3.select("#stacked-chart-status");
                svgtemp.selectAll("*").remove();
            var tempHeight,tempWidth

            // geofence bar graph
            var margin2 = { top: 30, right: 10, bottom: 85, left: 30 };

            var width2 = 960;
            var height2 = 300 - margin2.top - margin2.bottom;

            try {
                width2 =
                    document.getElementById("stacked-chart-status")
                        .offsetWidth -
                    margin2.left -
                    margin2.right;

                
                height2 =
                    document.getElementById("stacked-chart-status")
                        .offsetHeight -
                    margin2.top -
                    margin2.bottom; 

                    if (width2 < 0 || height2 < 0) {
                        width2 = tempWidth;
                        height2 = tempHeight;
                    }
            } catch (error) {
                // console.log(error);
            }
            tempWidth = width2
            tempHeight = height2
            var svg = d3
                .select("#stacked-chart-status")
                .append("svg")
                .attr("width", width2 + margin2.left + margin2.right)
                .attr("height", height2 + margin2.top + margin2.bottom)
                .append("g")
                .attr(
                    "transform",
                    "translate(" + margin2.left + "," + margin2.top + ")"
                )
                .attr("shape-rendering", "geometricPrecision");
            // /* Data in strings like it would be if imported from a csv */

            var cases = data.geofences.map(function (d) {
                return {
                    x:
                        d.label.slice(0, 15) +
                        (d.label.length > 15 ? "..." : ""),
                    y: +d.consumed,
                    x1: d.label,
                };
            });
            // Transpose the data into layers
            //actual data
            var dataset = d3.layout.stack()(
                ["Available"].map(function (fruit) {
                    var groupData = data.geofences.slice(0, 40);
                    return groupData.map(function (d) {
                        return {
                            x:
                                d.label.slice(0, 15) +
                                (d.label.length > 15 ? "..." : ""),
                            y: +d.userStatusCount[fruit],
                            x1: fruit,
                        };
                    });
                    // }
                })
            );

            // Set x, y and colors
            var x = d3.scale
                .ordinal()
                .domain(
                    dataset[0].map(function (d) {
                        return d.x;
                    })
                )
                .rangeRoundBands([10, width2 - 50], 0.5);

            var countUsers = d3.max(dataset, function (d) {
                return d3.max(d, function (d) {
                    return d.y0 + d.y;
                });
            })

            if(countUsers >=1){
                var y = d3.scale
                .linear()
                .domain([
                    0,
                    d3.max(dataset, function (d) {
                        return d3.max(d, function (d) {
                            return d.y0 + d.y;
                        });
                    }),
                ])
                .range([height2, 0]);
            }else {
                var y = d3.scale
                .linear()
                .domain([
                    0,
                    d3.max(cases, function (d) {
                            return  d.y;
                    }),
                ])
                .range([height2, 0]); 
            }
            
            var colors = ["#99A98F", "#C1D0B5"];

            // Define and draw axes
            var yAxis = d3.svg
                .axis()
                .scale(y)
                .orient("left")
                .ticks(5)
                .tickSize(-width2, 0, 0)
                .tickFormat(function (d) { if((d % 1 == 0)){
                    return d
                }});

            var xAxis = d3.svg.axis().scale(x).orient("bottom");
            // .tickFormat(d3.time.format("%y"));

            svg.append("g").attr("class", "y axis").call(yAxis);

            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height2 + ")")
                .call(xAxis)
                .selectAll("text")
                .style("text-anchor", "end")
                .style("font-family", "sans-serif")
                .style("font-size", "11px")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-45)");

            // Create groups for each series, rects for each segment
            var groups = svg
                .selectAll("g.cost")
                .data(dataset)
                .enter()
                .append("g")
                .attr("class", "cost")
                .style("fill", function (d, i) {
                    return colors[i];
                });

            var rect = groups
                .selectAll("rect")
                .data(function (d) {
                    return d;
                })
                .enter()
                .append("rect")
                .attr("x", function (d) {
                    return x(d.x) + x.rangeBand() / 2;
                })
                .attr("y", function (d) {
                    return y(d.y0 + d.y);
                })
                .attr("height", function (d) {
                    return y(d.y0) - y(d.y0 + d.y);
                })
                .attr("width", x.rangeBand() / 2);

            var caseBar = svg.selectAll(".rollOut-bar")
                .data(cases)
                .enter()
                .append("g")
                .attr("class", "rollOut-bar")
                .append("rect")
                .attr("x", function (d) {
                    return x(d.x); // center it
                })
                .attr("width", x.rangeBand() / 2) // make it slimmer
                .attr("y", function (d) {
                    return y(d.y);
                })
                .attr("height", function (d) {
                    return height2 - y(d.y);
                }); 
            // Draw legend
          var svg1 = d3
                .select("#status")
                .append("svg")
                .attr("width", width2 + margin2.left + margin2.right)
                .attr("height", 15)
                .append("g")
                .attr("transform", "translate(" + (margin2.right - 25) + "," + -220 + ")")
                .attr("shape-rendering", "geometricPrecision"); 

            var legend = svg1
                .selectAll(".legend")
                .data(colors)
                .enter()
                .append("g")
                .attr("class", "legend")
                .attr("width", 15)
                .attr("height", 15)
                .attr("transform", function (d, i) {
                    if(i==2){
                        return "translate(" + ((i * 80)-18) + ",30)";
                    }else{
                        return "translate(" +((i * 80)-18) + ",30)";
                    }
                })
                // .on("click", function(d) {

                // });
                .on("click", function (d, i) {
                   /*  if (i == 1) {
                        if (
                            d3
                                .select(this)
                                .style("fill" == "#C1D0B5")
                                .style("opacity") == 1
                        ) {
                            d3.selectAll(".cost:nth-child(4)").style(
                                "opacity",
                                0
                            );
                            d3.select(this)
                                .style("fill", "#C1D0B5")
                                .style("opacity", "0.5");
                        } else {
                            d3.selectAll(".cost:nth-child(4)").style(
                                "opacity",
                                9
                            );
                            d3.select(this)
                                .style("fill", "#C1D0B5")
                                .style("opacity", 9);
                        }
                    } */
                    if (i == 2) {
                        if (
                            d3
                                .select(this)
                                .style("fill" == "#afafaf")
                                .style("opacity") == 1
                        ) {
                            d3.selectAll(".cost:nth-child(5)").style(
                                "opacity",
                                0
                            );
                            d3.select(this)
                                .style("fill", "#afafaf")
                                .style("opacity", "0.5");
                        } else {
                            d3.selectAll(".cost:nth-child(5)").style(
                                "opacity",
                                9
                            );
                            d3.select(this)
                                .style("fill", "#afafaf")
                                .style("opacity", 9);
                        }
                    }
                    if (i == 0) {
                        if (
                            d3
                                .select(this)
                                .style("fill" == "#99A98F")
                                .style("opacity") == 1
                        ) {
                            d3.selectAll(".cost:nth-child(3)").style(
                                "opacity",
                                0
                            );
                            d3.select(this)
                                .style("fill", "#99A98F")
                                .style("opacity", "0.5");
                        } else {
                            d3.selectAll(".cost:nth-child(3)").style(
                                "opacity",
                                9
                            );
                            d3.select(this)
                                .style("fill", "#99A98F")
                                .style("opacity", 9);
                        }
                    }
                });
            // .attr("transform", function(d,i) {
            // 	return "translate(" + width2 + "," + i * height2 + ")"; })

            legend
                .append("circle")
                .attr("cx", width2 / 2 - 96)
                .attr("cy", height2 + 67)
                .attr("r", 6)
                .style("fill", function (d, i) {
                    return colors[i];
                });

            legend
                .append("text")
                .attr("x", width2 / 2 - 85)
                .attr("y", height2 + 67)
                .attr("dy", ".35em")
                .style("text-anchor", "start")
                .style("font-family", "sans-serif")
                .style("font-size", "12px")
                .style("font-weight", "700")
                .style("cursor", "pointer")
                .text(function (d, i) {
                    switch (i) {
                        case 0:
                            return "Available";
                        case 1:
                            return "Consumed";
                    }
                });

            var tooltip1 = d3
                .select("body")
                .append("div")
                .attr("class", "toolTipA");

            rect.on("mouseover", function (d) {
                tooltip1
                    .style("left", d3.event.pageX - 50 + "px")
                    .style("top", d3.event.pageY - 50 + "px")
                    .style("display", "inline-block")
                    .style("fill", "black")
                    .html(d.x1 + "<br>" + d.y);
                return tooltip1.style("visibility", "visible");
            })
                .on("mousemove", function () {
                    return tooltip1
                        .style("top", d3.event.pageY - 10 + "px")
                        .style("left", d3.event.pageX + 10 + "px");
                })
                .on("mouseout", function () {
                    return tooltip1.style("visibility", "hidden");
                });
                
            caseBar.on("mouseover", function (d) {
                tooltip1
                    .style("left", d3.event.pageX - 50 + "px")
                    .style("top", d3.event.pageY - 50 + "px")
                    .style("display", "inline-block")
                    .style("fill", "black")
                    .html("Consumed" + "<br>" + d.y);
                return tooltip1.style("visibility", "visible");
            })
                .on("mousemove", function () {
                    return tooltip1
                        .style("top", d3.event.pageY - 10 + "px")
                        .style("left", d3.event.pageX + 10 + "px");
                })
                .on("mouseout", function () {
                    return tooltip1.style("visibility", "hidden");
                }); 
        }
    }
})();
