(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("SplicingDialogController", SplicingDialogController);

        SplicingDialogController.$inject = ['entity','$window',
        "getJkDetails",
        "getCoreDetails",
        "getTubeDetails",
        "getCableDetails",
        "postCoreSplicing",
        "$scope",
        "$state",
        "UserAccount"];
    
    function SplicingDialogController(entity,$window,
        getJkDetails,
        getCoreDetails,
        getTubeDetails,
        getCableDetails,
        postCoreSplicing,
        $scope,
        $state,
        UserAccount
    ) {
        var vm = this;
        UserAccount.get(function (data) {
            vm.tenantId = data.id })
        vm.goBack = function() {
            $window.history.back();
          };
          var resultObject = {}

          vm.closeModal = function(id) {
			$("#scrollTop").scrollTop(0);
			if(vm.editDetails){
				if(id == 'splicingDetails'){
					vm.splicingDetails.splice(vm.selectedSlicing,1)
				}

                if(id == 'config'){
					vm.configDetails.splice(vm.selectedconfigSlicing,1)
				}
			}
			$("#"+id).removeClass("in");
			$(".modal-backdrop").remove();
			$("#"+id).hide();
		}

        vm.showSplicing = true;
        $scope.splicingDetails = true;
        $scope.toggle = function (id) {
            switch (id) {
                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                default:
                    console.log("");
            }
        };

        vm.splicingDetails = []

        vm.jkName = []
            getJkDetails.get(entity.id,function(data){
                data.forEach(function (val){
                    vm.jkName.push(val.name)
                })
            })

            $scope.addDetails = function (id) {
                vm.cableSide = []
                vm.tubeASide = []
                vm.coreASide = []
                vm.tubeBSide = []
                vm.coreBSide = []
                    vm.editDetails = true
                    switch (id) {
                        case "splicingDetails":
                            vm.cableSide = []
                            getCableDetails.get(vm.selectedJKName,function(data){
                                data.forEach(function (val){
                                vm.cableSide.push(val.name)
                                })
                            })
                            if (vm.splicingDetails != null) {
                                vm.splicingDetails.length++;
                                vm.selectedSlicing =
                                    vm.splicingDetails.length - 1;
        
                                if (
                                    vm.splicingDetails.length > 0 
                                ) {
                                    vm.isSaving = false;
                                }
                            } 
                            $("#splicingDetails").show();
                            break;
        
                            /* case "config":
                                if (vm.configDetails != null) {
                                    vm.configDetails.length++;
                                    vm.selectedconfigSlicing =
                                        vm.configDetails.length - 1;
            
                                    if (
                                        vm.configDetails.length > 0 
                                    ) {
                                        vm.isSaving = false;
                                    }
                                } 
                                $("#config").show();
                                break; */

                                case "addAuto":
                                    sortArraysByOrder(resultObject);
                                    $("#addAuto").show();
                                    vm.selectedAutoAside = null
                                    vm.selectedAutoBside = null
                                    vm.selectedCableASide = []
                                    vm.selectedCableBSide = []
                                    break;
                                
                                case "addManual":
                                        sortArraysByOrder(resultObject);
                                        $("#addManual").show();
                                    break;
                       
                        default:
                            console.log("");
                    }
        
        
                /* vm.jkName = ["JK_338"]
                vm.cableSide = ["Cable1928","Cable1931"]
                vm.tubeASide = ["Tube1","Tube2","Tube3","Tube4"]
                vm.coreASide = ["Core1","Core2","Core3","Core4","Core5","Core6","Core7"]
                vm.tubeBSide = ["Tube1","Tube2"]
                vm.coreBSide = ["Core6","Core7","Core8","Core9","Core10"] */
            };

            vm.saveDetails = function(id) {
                switch (id) {
                    case "splicingDetails":
                        if (vm.splicingDetails[vm.selectedSlicing] == null) {
                            vm.splicingDetails.splice(vm.selectedSlicing, 1);
                        }
                        break;

                            case "addAuto":
                                if (vm.selectedSpliceA.length === vm.selectedSpliceB.length) { 
                                    updateResult(vm.selectedAutoAside, vm.selectedSpliceA,"disable");
                                    updateResult(vm.selectedAutoBside, vm.selectedSpliceB,"disable");
                                    vm.splicingDetails = []
                                    for(var i = 0 ;i < vm.selectedSpliceA.length; i++){ 
                                        if(vm.selectedSpliceA[i].selected && vm.selectedSpliceB[i].selected){
                                            vm.splicingDetails.push({ "fromFiber" :{
                                                "coreASide": vm.selectedSpliceA[i].name,
                                                "cable": vm.selectedSpliceA[i].cableName
                                            },"toFiber" :{
                                                "coreBSide": vm.selectedSpliceB[i].name,
                                                "cable": vm.selectedSpliceB[i].cableName
                                            },"order":i+1})
                                        }
                                    }
                                    $("#" + id).removeClass("in");
                                    $(".modal-backdrop").remove();
                                    $("#" + id).hide();
                                }else{
                                    alert("Cable A Side and Cable B Side Splice Not Equal!!!")
                                }
                                break;

                        case "editSplicingDetails": 
                        
                        if (vm.selectedSpliceAEdit.length !=0 && vm.selectedSpliceBEdit.length != 0) {  
                            vm.splicingDetails.splice(vm.indexOfSplicing,1,{ "fromFiber" :{
                                "coreASide": vm.selectedSpliceAEdit[0].name,
                                "cable": vm.selectedSpliceAEdit[0].cableName
                            },"toFiber" :{
                                "coreBSide": vm.selectedSpliceBEdit[0].name,
                                "cable": vm.selectedSpliceBEdit[0].cableName
                            },"order":vm.indexOfSplicing+1})
                            updateResult(vm.selectedSpliceAEdit[0].cableName, vm.selectedSpliceAEdit,"disable");
                            updateResult(vm.selectedSpliceBEdit[0].cableName, vm.selectedSpliceBEdit,"disable");
                            $("#" + id).removeClass("in");
                            $(".modal-backdrop").remove();
                            $("#" + id).hide();
                        }else{
                            updateResult(vm.cableFrom, vm.listFrom,"disable");
                            updateResult(vm.cableTo, vm.listTo,"disable");
                            $("#" + id).removeClass("in");
                            $(".modal-backdrop").remove();
                            $("#" + id).hide();
                        }
                            
                        
                        break;

                        case "addManual": 
                        if (vm.selectedSpliceAManual.length === vm.selectedSpliceBManual.length) { 
                            updateResult(vm.selectedAutoAside, vm.selectedSpliceAManual,"disable");
                            updateResult(vm.selectedAutoBside, vm.selectedSpliceBManual,"disable");
                                    vm.splicingDetails.push({ "fromFiber" :{
                                        "coreASide": vm.selectedSpliceAManual[0].name,
                                        "cable": vm.selectedSpliceAManual[0].cableName
                                    },"toFiber" :{
                                        "coreBSide": vm.selectedSpliceBManual[0].name,
                                        "cable": vm.selectedSpliceBManual[0].cableName
                                    },"order":vm.splicingDetails.length})
                                
                            $("#" + id).removeClass("in");
                            $(".modal-backdrop").remove();
                            $("#" + id).hide();
                        }else
                        {
                            alert("Cable A Side and Cable B Side Splice Not Equal!!!")
                        }
                        break;
                    default:
                        console.log("");
                }
                
               
            }

        $scope.editDetails = function (list,id, name) {
			vm.editDetails = false
			//vm.new = JSON.parse(JSON.stringify(vm.trCase));
            switch (name) {
                /* case "splicingDetails":
                    vm.selectedSlicing = id;
                    $("#splicingDetails").show();
                    break; */
                    case "splicingDetails":
                        vm.selectedSpliceAEdit  = []
                        vm.selectedSpliceBEdit =[]
                        vm.indexOfSplicing = id
                        vm.listFrom = list.fromFiber.coreASide
                        vm.listTo = list.toFiber.coreBSide
                        vm.cableFrom = list.fromFiber.cable
                        vm.cableTo = list.toFiber.cable
                        updateResult(vm.cableFrom,vm.listFrom ,"remove")
                        updateResult(vm.cableTo,vm.listTo ,"remove")
                        $("#editSplicingDetails").show();
                    break;
                default:
                    console.log("");
            }
        };

        $scope.removeDetails = function (list,id, name) {
            switch (name) {
                case "splicingDetails":

                    updateResult(list.fromFiber.cable, list.fromFiber.coreASide,"remove")
                    updateResult(list.toFiber.cable, list.toFiber.coreBSide,"remove")
                    vm.splicingDetails.splice(id, 1);
                   
                    break;
                default:
                    console.log("");
            }
        };

        vm.saveSplicingDetails = function(){
            var saveSplice = {
                "tenantId": vm.tenantId,
                "joiningKit": {
                  "name": vm.selectedJKName,
                  "fixedAssetId":entity.id,
                  "tenantId": vm.tenantId
                },
                coreTuplelist :[]}

            // Loop through the input array 'a'
            for (var i = 0; i < vm.splicingDetails.length; i++) {
                saveSplice.coreTuplelist.push({
                    "coreASide": {
                      "name":  (vm.splicingDetails[i].hasOwnProperty('fromFiber') && vm.splicingDetails[i].fromFiber.hasOwnProperty('coreASide'))? vm.splicingDetails[i].fromFiber.coreASide : '',
                      "tenantId": vm.tenantId,
                      "order" : vm.splicingDetails[i].order
                    },
                    "coreBSide": {
                      "name":  (vm.splicingDetails[i].hasOwnProperty('toFiber') && vm.splicingDetails[i].toFiber.hasOwnProperty('coreBSide')) ? vm.splicingDetails[i].toFiber.coreBSide : '',
                      "tenantId": vm.tenantId,
                      "order" : vm.splicingDetails[i].order
                    } })
            }
            postCoreSplicing.get(saveSplice,function(){
                    vm.goBack()
            })
        }

        /* vm.onChangeJKName  = function (){
            vm.cableSide = []
            getCableDetails.get(vm.splicingDetails[vm.selectedSlicing].jkName,function(data){
                data.forEach(function (val){
                    vm.cableSide.push(val.name)
                })
            })
        } */

        vm.onChangeCableA  = function (){
            vm.tubeASide = []
            getTubeDetails.get(vm.splicingDetails[vm.selectedSlicing].fromFiber.cableSide,function(data){
                data.forEach(function (val){
                    vm.tubeASide.push(val.name)
                })
            })
        }

        vm.onChangeTubeA  = function (){
            vm.coreASide = []
            getCoreDetails.get(vm.splicingDetails[vm.selectedSlicing].fromFiber.tubeSide,function(data){
                data.forEach(function (val){
                    vm.coreASide.push(val.name)
                })
            })
        }

        vm.onChangeCableB  = function (){
            vm.tubeBSide = []
            getTubeDetails.get(vm.splicingDetails[vm.selectedSlicing].toFiber.cableSide,function(data){
                data.forEach(function (val){
                    vm.tubeBSide.push(val.name)
                })
            })
        }

        vm.onChangeTubeB  = function (){ 
            vm.coreBSide = []
            getCoreDetails.get(vm.splicingDetails[vm.selectedSlicing].toFiber.tubeSide,function(data){
                data.forEach(function (val){
                    vm.coreBSide.push(val.name)
                })
            })
        }

        vm.splicingMapGetData = function (){
            //$rootScope.getSplicingData = vm.trCase.splicingDetails
            $state.go('asset.splicing',{id:entity.id})
        }

        vm.configDetails = []

        
        vm.onJKChange = function (){
            vm.isShowAuto = false
            vm.cableSideAuto = []

            //vm.isShowAuto = true
            //vm.cableSideAuto=["KMLCabe1","KMLCabe2"]
            
            var index = 0, temp = 0
           getCableDetails.get(vm.selectedJKName,function(cableResponse){
                cableResponse.forEach(function (cable) {
                    var coreNames = [];
                    vm.cableSideAuto.push(cable.name)
                    getTubeDetails.get(cable.name,function (tubeResponse) {
                        tubeResponse.forEach(function (tube) {
                        temp += tube.noOfCores
                        getCoreDetails.get(tube.name,function (coreResponse) {
                            coreNames.push({ name: '<strong>' + tube.name + '</strong>', msGroup: true, order: tube.order });
                        coreResponse.forEach(function (cores) {
                            index++
                            coreNames.push({name:cores.name,selected:false,disabled:false,order: tube.order,cableName:cable.name});
                        });
                        coreNames.push({ msGroup: false,order: tube.order });
                        resultObject[cable.name] = coreNames;
                        if(index == temp){
                            vm.isShowAuto = true
                        }
                        });
                    });
                    });
                    
                });
                
            }) 
        }

        $scope.statuslabel = {
            search          : "Search",
            nothingSelected : "Select",
            selectAll       : "All",
            selectNone      : "None", 
            Done : "Done" 
        }

        /* vm.onSelectADone = function (){
            //updateResult(vm.selectedAutoAside, vm.selectedSpliceA);
        }

        vm.onSelectBDone = function (){
            //updateResult(vm.selectedAutoBside, vm.selectedSpliceB);
        } */

        vm.onChangeASide = function(){
            if(vm.selectedCableASide){
                vm.selectedCableASide = []
            }
            
            if (resultObject[vm.selectedAutoAside]) {
                // Filter and retrieve the elements of the selectedValue
                vm.selectedCableASide = resultObject[vm.selectedAutoAside];
            }
        }

        vm.onChangeBSide = function(){
            if(vm.selectedCableBSide){
                vm.selectedCableBSide = []
            }
            if (resultObject[vm.selectedAutoBside]) {
            // Filter and retrieve the elements of the selectedValue
            vm.selectedCableBSide = resultObject[vm.selectedAutoBside];
        } 
        }

        function updateResult(selectedValue, output,action) {
            if (resultObject[selectedValue]) {

                if(action == "disable"){
                    resultObject[selectedValue].forEach(function(itemInResult) {
                        var matchingOutput
                        if(typeof output == 'string'){
                            matchingOutput = (output === itemInResult.name)
                        }else{
                            matchingOutput = output.find(function(itemInOutput) {return itemInOutput.name === itemInResult.name} );
                        }
                        
                        if (matchingOutput) {
                            itemInResult.disabled = matchingOutput.selected;
                        }
                    });
                }else if(action == "remove"){
                    resultObject[selectedValue].forEach(function(itemInResult) {
                        var matchingOutput = (output === itemInResult.name)
                        if (matchingOutput) {
                            itemInResult.disabled = false;
                            itemInResult.selected = false
                        }
                    });
                }
            } 
        }
        
        function sortArraysByOrder(data) {
            //to sort dropdown options order since its straight splice
            for (var key in data) {
              if (data.hasOwnProperty(key) && Array.isArray(data[key])) {
                data[key].sort(function(a, b) {
                  return a.order - b.order;
                });
              }
            }
          }

    }
})();