(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('HomeController', HomeController)
        .constant('DEFAULT_LATITUDE', "12.98330744645683")
        .constant('DEFAULT_LONGITUDE', "77.56018512933986");

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$rootScope'];

    function HomeController($scope, Principal, LoginService, $state, $rootScope) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $rootScope.$on('authenticationSuccess', function () {
            getAccount();
            $rootScope.listAuthorities();
            //  validateStates();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.isAuthenticated() == true) {
                    validateStates();
                }
            });
        }

        function validateStates() {
            if ($rootScope.has_ROLE_SUPER_ADMIN == true) {
                //$state.go('user-management');
                $state.go('tenant');
            } else if ($rootScope.has_ROLE_USER_ADMIN === true) {
                $state.go('dashboard');
            } else if ($rootScope.has_ROLE_VENDOR === true) {
                $state.go('dashboard');
            } else if ($rootScope.has_ROLE_HOD === true) {
                $state.go('dashboard');
            } else if ($rootScope.has_ROLE_CITY_MANAGER === true) {
                $state.go('dashboard');
            } else if ($rootScope.has_ROLE_CITY_PLANNER === true) {
                $state.go('dashboard');
            } else if ($rootScope.has_ROLE_ASSISTANT_MANAGER === true) {
                $state.go('dashboard');
            } else if ($rootScope.has_ROLE_TEAM_LEADER === true) {
                $state.go('dashboard');
            } else if ($rootScope.has_ROLE_NETWORK_ENGINEER === true) {
                $state.go('dashboard');
            } else if ($rootScope.has_ROLE_FRT == true) {
                $state.go('tr-case');
            }  else if ($rootScope.has_ROLE_PATROLLER == true) {
                $state.go('tr-case');
                /* }else if($rootScope.has_ROLE_USER ==true){
                //$state.go('location-log');
                $state.go('tr-case'); */
            } else if ($rootScope.has_ROLE_ROLLOUT_ADMIN == true) {
                $state.go('work-flow');
            } else if ($rootScope.has_ROLE_PLAN_ADMIN == true) {
                $state.go('work-order');
            }
        }


        function register() {
            $state.go('register');
        }

    }

})();