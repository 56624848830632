(function () {
    "use strict";
    angular.module("trakeyeApp").factory("Report", [
        "$rootScope",
        "$http",

        function ($rootScope, $http) {
            var service = {};
            
            service.getListEntity = function (callback) {
                $http.get("api/reports/email-report").then(function (response) {
                    callback(response.data);
                });
            };

            service.getUsers = function (name, callback) {
                $http.get("api/userslist").then(function (response) {
                    callback(response.data);
                });
            };
            service.getVehicles = function (name, callback) {
                $http.get("api/vehicleslist").then(function (response) {
                    callback(response.data);
                });
            };
            service.getGeofences = function (name, callback) {
                $http.get("api/geofenceslist").then(function (response) {
                    callback(response.data);
                });
            };
            service.getReport = function (data, callback) {
                $http.post("api/reports/view", data).then(function (response) {
                    callback(response.data);
                });
            };
            service.downloadUserAgentReport = function (data, callback) {
                $http
                    .post("api/reports/download-user-agent-view-report", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };
            
            service.downloadPdfReport = function (data, callback) {
                $http
                    .post("api/download-splicing-pdf", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getAttendenceReport = function (data, callback) {
                $http
                    .post("api/reports/attendence-view", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getAssetHealthListWithGeoReport = function (data, callback) {
                $http
                    .post("api/asset-health-list-with-geofence", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getUserLastLoginReport = function (data, callback) {
                $http
                    .post("api/reports/user-last-login", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.downloadUserLastLoginReport = function (data, callback) {
                $http
                    .post("api/reports/download-user-last-login", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.downloadUserGeofenceReport = function (data, callback) {
                $http
                    .post("api/reports/download-user-geofence", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.downloadGeofenceDump = function (data, callback) {
                $http
                    .post("api/reports/download-geofence", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.emailReport = function (
                data,
                successCallback,
                errorCallback
            ) {
                $http
                    .post("api/reports/email-report", data)

                    .then(
                        function (response) {
                            successCallback(response.data);
                        },
                        function (response) {
                            // this function handles error
                            errorCallback(response);
                        }
                    );
            };

            service.downloadGeofenceDumpKml = function (data, callback) {
                $http
                    .post("api/reports/download-geofence-kml", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getVehicleDistanceReport = function (data, callback) {
                $http
                    .post("api/reports/vehicleDistanceReport", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getUserStatsReport = function (data, callback) {
                $http
                    .post("api/reports/userDistStats", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getUsersAroundCase = function (data, callback) {
                $http
                    .post("api/reports/viewUsersArounCase", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getAttendenceReportDownload = function (data, callback) {
                $http
                    .post("api/reports/download-attendence-reports", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getVehicleDistanceReportDownload = function (
                data,
                callback
            ) {
                $http
                    .post("api/reports/download-vehicle-distance-reports", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getUserDistanceStatsReportDownload = function (
                data,
                callback
            ) {
                $http
                    .post("api/reports/download-user-dist-stats-reports", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getOpenCasesReportDownload = function (data, callback) {
                $http
                    .post("api/download-opencases-reports", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getAssetHealthStatusReportDownload = function (data, callback) {
                $http
                    .post("api/download-assethealthstatus-reports", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getClosedCasesReportDownload = function (data, callback) {
                $http
                    .post("api/download-closedcases-reports", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getReportingReport = function (data, callback) {
                $http
                    .post("api/reports/user-reporting-report", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.downloadReportingReport = function (data, callback) {
                $http
                    .post("api/reports/download-user-reporting-report", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getCaseReport = function (data, callback) {
                $http
                    .post("api/download-cases-reports", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getCaseReportList = function (data, callback) {
                $http
                    .post("api/cases-reports-list", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };
            service.getDetailedReport = function (data, callback) {
                $http.post("api/reports/detailed-report", data).then(
                    function (response) {
                        callback(response.data);
                    },
                    function (error) {
                        callback({ counts: {} });
                    }
                );
            };

            service.getbatteryReport = function (
                login,
                fromtime,
                totime,
                callback
            ) {
                $http
                    .get(
                        "api/reports/batteryreport/" +
                            login +
                            "/" +
                            fromtime +
                            "/" +
                            totime
                    )
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getDistanceReport = function (
                login,
                fromtime,
                totime,
                callback
            ) {
                $http
                    .get(
                        "api/reports/distancereport/" +
                            login +
                            "/" +
                            fromtime +
                            "/" +
                            totime
                    )
                    .then(function (response) {
                        callback(response.data);
                    });
            };
            service.getCasesReport = function (data, callback) {
                $http.post("api/reports/cases", data).then(
                    function (response) {
                        callback(response.data);
                    },
                    function (error) {
                        callback({ counts: {} });
                    }
                );
            };

            service.getCasesAgingReport = function (data, callback) {
                $http.post("api/reports/caseagingreport", data).then(
                    function (response) {
                        callback(response.data);
                    },
                    function (error) {
                        callback({ counts: {} });
                    }
                );
            };

            service.getCasesTatReport = function (data, callback) {
                $http.post("api/reports/casetatreport", data).then(
                    function (response) {
                        callback(response.data);
                    },
                    function (error) {
                        callback({ counts: {} });
                    }
                );
            };

            service.getCasesMttrReport = function (
                ageType,
                fromDate,
                toDate,
                callback
            ) {
                $http
                    .get(
                        "api/tr-cases-report/" +
                            ageType +
                            "/" +
                            fromDate +
                            "/" +
                            toDate
                    )
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getTeamReport = function (data, callback) {
                $http.post("api/reports/teamreport", data).then(
                    function (response) {
                        callback(response.data);
                    },
                    function (error) {
                        callback({ counts: {} });
                    }
                );
            };

            service.getDistReport = function (data, callback) {
                $http.post("api/reports/distance", data).then(
                    function (response) {
                        callback(response.data);
                    },
                    function (error) {
                        callback({ counts: {} });
                    }
                );
            };
            service.getAssetReport = function (
                assetIdReport,
                casetypeid,
                attname,
                fromDate,
                toDate,
                callback
            ) {
                $http
                    .get(
                        "api/reports/assetreport/" +
                            assetIdReport +
                            "/" +
                            casetypeid +
                            "/" +
                            attname +
                            "/" +
                            fromDate +
                            "/" +
                            toDate
                    )
                    .then(function (response) {
                        callback(response.data);
                    });
            };
            service.getAssetCaseTypeReport = function (
                assetIdReport,
                fromDate,
                toDate,
                callback
            ) {
                $http
                    .get(
                        "api/reports/assetcasetypereport/" +
                            assetIdReport +
                            "/" +
                            fromDate +
                            "/" +
                            toDate
                    )
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getAssetCaseReport = function (
                casetypeid,
                fromDate,
                toDate,
                callback
            ) {
                $http
                    .get(
                        "api/reports/assetcasereport/" +
                            casetypeid +
                            "/" +
                            fromDate +
                            "/" +
                            toDate
                    )
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getAssetCaseReportSearch = function (
                casetypeid,
                fromDate,
                toDate,
                searchText,
                callback
            ) {
                $http
                    .get(
                        "api/reports/assetcasereport/" +
                            casetypeid +
                            "/" +
                            fromDate +
                            "/" +
                            toDate +
                            "/" +
                            searchText
                    )
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getSurveyReportDownload = function (data, callback) {
                $http
                    .post("api/download-assetsurvey-reports", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            service.getSurveyReportList = function (data, callback) {
                $http
                    .post("api/assetsurvey-reports", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();
